import { useState } from 'react'
import useStore from '../../../store'
import CostCenterPanel from '../CostCenter/CostCenterPanel'
import Button from '../../common/Misc/Button'
import TripSummaryFlight from '../../common/Misc/TripSummaryFlight'
import TripStatus from './TripStatus'

import './TripRow.css'
import Loading from '../../common/Misc/Loading'
import OfferPanel from '../ClimateOptions/OfferPanel'
import { Flights } from '../../../helpers/Flights'
import { Networking } from '../../../helpers/Networking'
import OfferPanelGeneric from '../ClimateOptions/OfferPanelGeneric'

export default function TripRow(props) {
  const companyDetails = useStore((state) => state.companyDetails)
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdating, setUpdating] = useState(false)
  const [editCostCenter, setEditCostCenter] = useState(false)
  const [costCenterLocal, setCostCenterLocal] = useState(props.trip.cost_center)
  const [panelCollapsed, collapsePanel] = useState(props.trip.status === 'confirmed')

  const updateCostCenter = async (cc) => {
    setUpdating(true)
    let payload = {
      trip_id: props.trip.trip_id,
      cost_center: cc.cost_center_id
    }
    const result = await Networking.updateTripCostCenter(payload, props.trip.trip_id)
    if (result) {
      setEditCostCenter(false)
      setUpdating(false)
      setCostCenterLocal(cc.cost_center_id)
    } else {
      setEditCostCenter(false)
      setUpdating(false)
    }
  }

  if (isLoading) {
    return (
      <div style={{ height: 100 }}>
        <Loading />
      </div>
    )
  }

  var habitToBeat = Flights.getSectionFromRecommendations(
    props.trip.recommendations,
    'habit_to_beat'
  )

  // If there is no recommendations object, then we may have a manual trip at hand here...
  if (props.trip.recommendations === null) {
    habitToBeat = props.trip.flight
  }

  if (!habitToBeat) {
    return (
      <div
        style={{
          padding: 20,
          fontSize: 13,
          border: '1px solid #eee',
          borderRadius: 10,
          marginBottom: 10,
          marginTop: 10,
          backgroundColor: '#fff1f1'
        }}>
        There was an issue trying to process this trip. Please report this to your administrator for
        further assistance:
        <br />
        <br />
        TripID: <strong>[{props.trip.trip_id}]</strong>
      </div>
    )
  }

  let noFlight = props.trip.trip_type === 'online' || props.trip.trip_type === 'train'

  return (
    <div className="trip-row-container">
      <TripSummaryFlight
        onClick={() => {
          collapsePanel(!panelCollapsed)
        }}
        trip={habitToBeat}
        departure_date={props.trip.departure_date || null}
        return_date={props.trip.return_date || null}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TripStatus status={props.trip.status} />
          <div
            style={{
              cursor: 'pointer',
              borderRadius: 10,
              backgroundColor: '#ddd',
              width: 25,
              height: 25,
              marginLeft: 10
            }}>
            <img
              alt="Toggle Trip Panel"
              src="/chevron.svg"
              style={{
                position: 'relative',
                top: 2,
                left: 8,
                width: 10,
                transitionDuration: '0.3s',
                transitionProperty: 'transform',
                transform: panelCollapsed ? 'rotate(180deg)' : 'rotate(0deg)'
              }}
            />
          </div>
        </div>
      </TripSummaryFlight>

      {!panelCollapsed && (
        <>
          {props.trip.trip_type === 'flight' && (
            <OfferPanel
              showPolicyViolations={companyDetails.feature_policies || false}
              key={`offer_panel_${props.trip.trip_id}`}
              section={Flights.figureOutSectionForTrip(props.trip.flight)}
              disabled={true}
              habitToBeat={habitToBeat}
              offer={props.trip.flight}
              selected={true}
              hidePrice={props.trip.already_booked || false}
              carbonPricing={companyDetails.feature_carbon_pricing}
              displayCurrency={props.trip.display_currency || 'CHF'}
              currencyConversionRate={props.trip.currency_conversion_rate || 1.0}
            />
          )}

          {props.trip.trip_type === 'train' && (
            <OfferPanelGeneric
              key={`offer_panel_train_${props.trip.trip_id}`}
              section="option_train"
              compareTo={habitToBeat}
              disabled={true}
              selected={true}
            />
          )}
          {props.trip.trip_type === 'online' && (
            <OfferPanelGeneric
              key={`offer_panel_online_${props.trip.trip_id}`}
              section="option_online"
              compareTo={habitToBeat}
              disabled={true}
              selected={true}
            />
          )}

          {/* Lower Panel (Cost Center + Action Buttons) */}
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              borderTop: '1px dotted #ddd',
              paddingTop: 20,
              marginTop: 30,
              paddingRight: 20
            }}>
            {companyDetails.feature_cost_center && !noFlight && (
              <div style={{ paddingTop: 0, paddingLeft: 20, minWidth: 400 }}>
                <h3 style={{ marginBottom: 10, marginTop: 0 }}>Cost Center</h3>
                {props.trip.cost_center === null && <div>No cost center associated</div>}
                {props.trip.cost_center !== null && !isUpdating && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      small
                      title={editCostCenter ? 'Cancel' : 'Edit'}
                      onClick={() => {
                        setEditCostCenter(!editCostCenter)
                      }}
                    />{' '}
                    &nbsp; &nbsp;
                    <CostCenterPanel
                      onChange={(cc) => {
                        updateCostCenter(cc)
                      }}
                      costCenter={null}
                      costCenterId={costCenterLocal}
                      labelOnly={!editCostCenter}
                    />
                    {(costCenterLocal === null || costCenterLocal === '') && (
                      <div style={{ fontWeight: 'bold', color: '#aa0000' }}>MISSING</div>
                    )}
                  </div>
                )}
                {isUpdating && (
                  <div style={{ marginTop: 20, marginBottom: 20 }}>
                    <Loading />
                  </div>
                )}
              </div>
            )}

            <div style={{ flexGrow: 1 }}></div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 15,
                alignItems: props.trip.status === 'confirmed' ? 'center' : 'flex-start'
              }}>
              {props.trip.status === 'confirmed' && (
                <div style={{ padding: 20, paddingRight: 0, textAlign: 'right' }}>
                  <strong>This trip has been confirmed.</strong>
                </div>
              )}

              <Button
                secondary
                small={props.trip.status === 'confirmed'}
                text={'Delete'}
                onClick={() => {
                  let k = window.confirm(
                    'Are you sure you want to remove this trip from your records entirely?'
                  )
                  if (k) {
                    props.onTripDelete()
                    setIsLoading(true)
                    setTimeout(() => {
                      setIsLoading(false)
                    }, 600)
                  }
                }}
              />

              {props.trip.status !== 'confirmed' && (
                <Button
                  style={{ marginBottom: 10 }}
                  text={
                    props.trip.trip_type === 'online'
                      ? 'Yes, this meeting happened'
                      : 'Yes, I booked this trip'
                  }
                  onClick={() => {
                    props.onTripConfirmed()
                    setIsLoading(true)
                    setTimeout(() => {
                      setIsLoading(false)
                    }, 600)
                  }}
                />
              )}
            </div>
          </div>

          <div style={{ fontSize: 8, color: '#aaa', textAlign: 'right', marginRight: 20 }}>
            Reference: {props.trip.trip_id}
          </div>
        </>
      )}
    </div>
  )
}
