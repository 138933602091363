import { Tools } from '../helpers/Tools'
import StopoverPopover from './common/Misc/StopoverPopover'
import RouteLabel from './common/Misc/RouteLabel'
import Button from './common/Misc/Button'
import OutOfPolicyLabel from './employee/Misc/OutOfPolicyLabel'

import './FlightResultRow.css'
import useStore from '../store'

export default function FlightResultRow(props) {
  const companyDetails = useStore((store) => store.companyDetails)
  const currencyRateToday = useStore((store) => store.currencyRateToday)

  const renderDate = (d) => {
    let t = d.split('T')[1].split(':')[0] + ':' + d.split('T')[1].split(':')[1]
    return t
  }

  const isNextDay = (flight) => {
    let startDay = Tools.parseServerTime(flight[0].departure_time)
    let arrivalDay = Tools.parseServerTime(flight[flight.length - 1].arrival_time)
    if (startDay.getDate() !== arrivalDay.getDate()) return true
    return false
  }

  return (
    <div
      className={`flight-result-row ${props.grayedOut ? 'frr-grayed-out' : ''} ${
        props.confirmationPrompt ? 'frr-highlighted' : ''
      }`}
      onClick={() => {
        if (props.confirmationPrompt) {
          console.log('CONFIRM Blocked: BUTTON ONLY')
          return false
        } else {
          props.onSelected(props.flight)
        }
      }}>
      <div className="flight-result-row-container">
        <div className="frr-airline-icon">
          <img src={Tools.getAirlineLogo(props.flight)} width="40" alt="Airline" />
        </div>
        <div className="frr-from-to-and-carrier">
          <div>
            <strong>
              {renderDate(props.flight[0].departure_time)} &mdash;{' '}
              {renderDate(props.flight[props.flight.length - 1].arrival_time)}{' '}
            </strong>
            {isNextDay(props.flight) && (
              <div
                style={{
                  display: 'inline-block',
                  backgroundColor: '#ddd',
                  borderRadius: 4,
                  padding: '2px 3px',
                  fontSize: 9,
                  position: 'relative',
                  top: -1,
                  marginLeft: 5
                }}>
                +1
              </div>
            )}
          </div>
          <div className="frr-flight-number" style={{ fontSize: 13, color: '#666' }}>
            {props.flight
              .map((segment) => {
                return `${Tools.figureOutCarrier(segment)} ${segment.flight_number}`
              })
              .join(', ')}
          </div>
        </div>
        <div className="frr-timing-and-airports">
          <div>{Tools.minutesToDuration(Tools.calculateTotalFlightDuration(props.flight))}</div>
          <div className="frr-route-label" style={{ fontSize: 13, color: '#666' }}>
            <RouteLabel segments={props.flight} />
          </div>
        </div>
        <div className="frr-stops">
          {props.flight.length === 1 ? (
            'Non-Stop'
          ) : (
            <StopoverPopover position={'right'} segments={props.flight} />
          )}
        </div>
        {/*
      <div className="frr-co2">
        {Tools.calculateTotalClimateImpact(props.flight)}{' '}
        <span style={{ color: '#888', fontSize: 12, marginRight: 5 }}>
          kg CO<sub style={{ color: '#888' }}>2</sub>
        </span>
      </div>
          */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center'
          }}>
          <OutOfPolicyLabel flight={props.flight} rowId={props.rowId || '0'} />
        </div>
        <div style={{ flexGrow: 1 }}></div>
        {!props.pastFlight && (
          <div className="frr-price">
            <span style={{ color: '#888', fontSize: 12, marginRight: 5 }}>
              {companyDetails.currency}
            </span>
            {Tools.formatNumber(
              parseInt(props.flight[0].roundtrip_price * currencyRateToday),
              companyDetails.currency
            )}
          </div>
        )}
      </div>
      {(props.confirmationPrompt || false) && (
        <div className="confirmation-row">
          Note: This flight will be added to your "My Trips" log as a&nbsp;
          <span style={{ color: '#0050B0', fontWeight: 'bold' }}>booked trip</span> &nbsp;and&nbsp;
          <span style={{ color: '#0050B0', fontWeight: 'bold' }}>
            can only be deleted upon request.
          </span>
          <div style={{ flexGrow: 1 }}></div>
          <Button
            id="selected-flight-and-continue-bottom"
            text="Log Trip"
            onClick={() => {
              props.onSelected(props.flight)
            }}
          />
        </div>
      )}
    </div>
  )
}
