import { useState } from 'react'
import useStore from '../../store'
import { Networking } from '../../helpers/Networking'

import Loading from '../../components/common/Misc/Loading'
import Button from '../../components/common/Misc/Button'
import TripSummaryFlight from '../../components/common/Misc/TripSummaryFlight'
import OfferCustomizationRow from '../../components/agency/Offering/OfferCustomizationRow'
import ClimateOptionTrain from '../../components/agency/ClimateOptions/Train'
import ClimateOptionOnline from '../../components/agency/ClimateOptions/Online'

export default function WizardOffer(props) {
  // Local State
  const [isLoading, setLoading] = useState(false)

  // Global State
  const searchId = useStore((state) => state.searchId)
  const agencyClient = useStore((state) => state.agencyClient)
  const travelerId = useStore((state) => state.travelerId)

  const habitToBeat = useStore((state) => state.habitToBeat)
  const bestMatch = useStore((state) => state.bestMatch)
  const betterMatch = useStore((state) => state.betterMatch)
  const maxSavings = useStore((state) => state.maxSavings)
  const optionTrain = useStore((state) => state.trainOption)
  const optionOnline = useStore((state) => state.onlineOption)

  const setHabitToBeat = useStore((state) => state.setHabitToBeat)
  const setBestMatch = useStore((state) => state.setBestMatch)
  const setBetterMatch = useStore((state) => state.setBetterMatch)
  const setMaxSavings = useStore((state) => state.setMaxSavings)

  const setAgencyOfferId = useStore((state) => state.setAgencyOfferId)

  // This is needed to construct the train and online options
  const roundtripSkeleton = {
    price: { value: 0, currency: 'CHF' },
    fnz_points: 0,
    climate_impact: 0,
    outward_itinerary: {
      id: 0,
      cabin_class: '',
      segments: [],
      duration: 0,
      layover: 0,
      priority: true,
      price: { value: 0, currency: 'CHF' },
      fnz_points: 0
    },
    return_itinerary: {
      id: 0,
      cabin_class: '',
      segments: [],
      duration: 0,
      layover: 0,
      priority: true,
      price: { value: 0, currency: 'CHF' },
      fnz_points: 0
    }
  }

  const prepareOffer = () => {
    setLoading(true)

    // Let's see if we have a train or online meeting option that we should include as well
    let additionalOptions = []
    if (optionTrain)
      additionalOptions.push({ ...roundtripSkeleton, trip_options: { train: true, online: false } })
    if (optionOnline)
      additionalOptions.push({ ...roundtripSkeleton, trip_options: { online: true, train: false } })

    Networking.saveOffer({
      offer: {
        timestamp_created: new Date().toISOString(),
        search_flights_id: searchId,
        customer_id: agencyClient ? agencyClient.customer_id : 0,
        traveler_id: travelerId.toString(),
        status: 'created',
        selected: 0,
        roundtrips: [habitToBeat, bestMatch, betterMatch, maxSavings]
          .filter(
            (trip) => trip !== false // Only submit trips that have data
          )
          .concat(additionalOptions)
      }
    })
      .then((data) => {
        if (data.readable_id) {
          setAgencyOfferId(
            `${data.readable_id.date}-${data.readable_id.customer_acronym}-${data.readable_id.counter}`
          )
        }
        props.onCompleted()
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false)
      })
  }

  const tableColumnWidths = [
    110, // Cabin Class
    62, // Priority
    85, // Carrier
    100, // Route
    70, // Departs
    60, // Arrives
    80, // Duration
    80, // Stops
    100, // Climate Impact
    100, // Round Trip
    70 // FNZ Points
  ]

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center', marginTop: 200 }}>
        <Loading />
      </div>
    )
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 30, width: 950 }}>
        <h1>Finalize Offer</h1>
        <div style={{ flexGrow: 1 }}></div>
        <div>
          <Button text="Confirm Final Offer" large={true} onClick={prepareOffer} />
        </div>
      </div>

      <TripSummaryFlight trip={habitToBeat} />

      <div
        style={{
          display: 'flex',
          width: 950,
          paddingLeft: 15,
          paddingRight: 15,
          marginBottom: 20,
          marginTop: 50,
          fontSize: 13,
          fontWeight: 'bold',
          letterSpacing: '1px',
          justifyContent: 'left'
        }}>
        <div style={{ width: tableColumnWidths[0] }}>Cabin Class</div>
        <div style={{ width: tableColumnWidths[1], textAlign: 'center' }}>Priority</div>
        <div style={{ width: tableColumnWidths[2], textAlign: 'center' }}>Carrier</div>
        <div style={{ width: tableColumnWidths[3] }}>Route</div>
        <div style={{ width: tableColumnWidths[4] }}>DEP</div>
        <div style={{ width: tableColumnWidths[5] }}>ARR</div>
        <div style={{ width: tableColumnWidths[6] }}>Duration</div>
        <div style={{ width: tableColumnWidths[7] }}>Stops</div>
        <div style={{ width: tableColumnWidths[8], textAlign: 'right' }}>
          Climate Impact
          <br />
          <span style={{ color: '#aaa', fontSize: 10 }}>kg CO2e</span>
        </div>
        <div style={{ width: tableColumnWidths[9], textAlign: 'right' }}>
          Round Trip
          <br />
          <span style={{ color: '#aaa', fontSize: 10 }}>CHF</span>
        </div>
        <div style={{ width: tableColumnWidths[10], textAlign: 'right' }}>CPs</div>
      </div>

      {bestMatch && (
        <OfferCustomizationRow
          label="Best Match"
          columnWidths={tableColumnWidths}
          trip={bestMatch}
          habitToBeat={habitToBeat}
          onPriorityUpdate={(direction, state) => {
            let newState = bestMatch
            newState[direction].priority = state
            setBestMatch(newState)
          }}
          onPriceUpdate={(price) => {
            setBestMatch({
              ...bestMatch,
              price: { value: price, currency: bestMatch.price.currency }
            })
          }}
        />
      )}
      {betterMatch && (
        <OfferCustomizationRow
          label="Comfort Match"
          columnWidths={tableColumnWidths}
          trip={betterMatch}
          habitToBeat={habitToBeat}
          onPriorityUpdate={(direction, state) => {
            let newState = betterMatch
            newState[direction].priority = state
            setBetterMatch(newState)
          }}
          onPriceUpdate={(price) => {
            setBetterMatch({
              ...betterMatch,
              price: { value: price, currency: betterMatch.price.currency }
            })
          }}
        />
      )}
      {maxSavings && (
        <OfferCustomizationRow
          label="Max Savings"
          columnWidths={tableColumnWidths}
          trip={maxSavings}
          habitToBeat={habitToBeat}
          onPriorityUpdate={(direction, state) => {
            let newState = maxSavings
            newState[direction].priority = state
            setMaxSavings(newState)
          }}
          onPriceUpdate={(price) => {
            setMaxSavings({
              ...maxSavings,
              price: { value: price, currency: maxSavings.price.currency }
            })
          }}
        />
      )}
      {habitToBeat && (
        <OfferCustomizationRow
          label="Habit to Beat"
          columnWidths={tableColumnWidths}
          labelColor={'#333333'}
          trip={habitToBeat}
          habitToBeat={habitToBeat}
          onPriorityUpdate={(direction, state) => {
            let newState = habitToBeat
            newState[direction].priority = state
            setHabitToBeat(newState)
          }}
          onPriceUpdate={(price) => {
            setHabitToBeat({
              ...habitToBeat,
              price: { value: price, currency: habitToBeat.price.currency }
            })
          }}
        />
      )}
      {optionTrain && (
        <ClimateOptionTrain
          enabled={optionTrain}
          columnWidths={tableColumnWidths}
          bestMatch={bestMatch}
        />
      )}
      {optionOnline && (
        <ClimateOptionOnline
          enabled={optionOnline}
          columnWidths={tableColumnWidths}
          bestMatch={bestMatch}
        />
      )}
    </div>
  )
}
