import { useNavigate, useParams } from 'react-router'
import useStore from '../store'
import Button from '../components/common/Misc/Button'
import useEmployeeStore from '../employee_store'
import Loading from '../components/common/Misc/Loading'
import { useState } from 'react'
import { Networking } from '../helpers/Networking'

export default function DelegatePage() {
  const cognitoLogout = useStore((state) => state.cognitoLogout)
  const isAuthenticated = useStore((state) => state.isAuthenticated)
  const setDelegateEmployeeId = useEmployeeStore((state) => state.setDelegateEmployeeId)
  const setUserDetails = useStore((state) => state.setUserDetails)
  const { token } = useParams()
  const [processing, setProcessing] = useState(false)

  const navigate = useNavigate()

  const tokenCheck = (token) => {
    if (token.length !== 36) {
      return 'INVALID TOKEN'
    }
    if (token.split('-').length !== 5) {
      return 'INVALID TOKEN'
    }
    return token
  }

  if (processing) {
    return (
      <div style={{ marginTop: 200, textAlign: 'center' }}>
        Preparing Delegate Action...
        <br />
        <br />
        <Loading />
      </div>
    )
  }

  return (
    <div
      style={{
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 100,
        padding: 20,
        backgroundColor: 'white',
        boxShadow: '5px 5px 10px #eee',
        borderRadius: 10
      }}>
      <div style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold', marginBottom: 40 }}>
        <img src="/logo.png" alt="Logo" height="20" />
      </div>

      {!isAuthenticated && (
        <div>
          <h3>Sorry</h3>
          You need to log in to your ClimateSmart.Travel support account first, before you can
          continue as a delegate.
          <br />
          <br />
          Please{' '}
          <a style={{ fontWeight: 'bold' }} href="/login">
            go back to the login page
          </a>
          , log in, and then initiate the delegate flow again.
        </div>
      )}

      {isAuthenticated && (
        <div>
          <strong>Attention:</strong> You will be authenticating as a delegate.
          <br />
          <br />
          This is a <span style={{ color: '#ff0000' }}>administrator-only operation</span> that
          allows you to use the app <strong>exactly</strong> as if you were the user.
          <br />
          <br />
          Your actions and changes will be recorded as if you were the user. We DO NOT differentiate
          between "actions taken as a delegate" and actions taken by the user.{' '}
          <strong>They are exactly the same.</strong>
          <br />
          <br />
          This is the Employee ID you will impersonate:
          <br />
          <div
            style={{
              fontFamily: 'monospace',
              textAlign: 'center',
              fontSize: 15,
              color: '#999',
              padding: 5,
              border: '1px solid #ddd',
              borderRadius: 10,
              marginTop: 15,
              marginBottom: 15
            }}>
            {tokenCheck(token)}
          </div>
          <br />
          If you continue, you will become the user.
          <br />
          <br />
          <Button
            secondary
            title={'Cancel'}
            onClick={() => {
              navigate('/')
            }}
          />
          &nbsp;&nbsp;
          <Button
            title={'Switch User Profile'}
            onClick={() => {
              setDelegateEmployeeId(tokenCheck(token))
              setProcessing(true)
              setTimeout(async () => {
                try {
                  let newUserDetails = await Networking.getUser()
                  // Check if the user we received matches the delegate user ID we wanted.
                  // This can be a mismatch if the backend decides that we were not allowed to
                  // act as a delegate and only received the same user back we tried this with.
                  if (newUserDetails.user.id !== token) throw new Error('delegate_failed')
                  await setUserDetails(newUserDetails.user)
                  navigate('/')
                } catch (error) {
                  setProcessing(false)
                  setDelegateEmployeeId('')
                  if (error.message === 'delegate_failed') {
                    alert(
                      'Sorry, you are not allowed to act as a delegate for this user. Please try again.'
                    )
                    return
                  }
                  alert(
                    'Sorry, there was an issue authenticating. Most likely, you are logged in with the wrong support account (different company). This will now trigger a log out and you can try again.'
                  )
                  cognitoLogout().then(() => {
                    document.location.href = '/#bye?'
                  })
                }
              }, 1500)
            }}
          />
        </div>
      )}
    </div>
  )
}
