import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useStore from '../../../store'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import './Sidebar.css'
import Button from '../../common/Misc/Button'

export default function EmployeeSidebar(props) {
  const userDetails = useStore((state) => state.userDetails)
  const cognitoLogout = useStore((state) => state.cognitoLogout)
  const resetSearch = useStore((state) => state.resetSearch)
  const location = useLocation()
  const navigate = useNavigate()

  const MenuItem = ({ url, activeUrlMatch, title, icon, external, beforePageChange }) => {
    const urlMatch = activeUrlMatch || url
    var isActive = false
    if (urlMatch && typeof urlMatch === 'object' && Array.isArray(urlMatch)) {
      urlMatch.forEach((um) => {
        if (location.pathname.indexOf(um) >= 0) {
          isActive = true
        }
      })
    } else {
      isActive = location.pathname.indexOf(urlMatch) >= 0
    }

    if (external) {
      return (
        <a
          className={`main-menu-item ${isActive ? 'active' : ''}`}
          href={url}
          rel="noopener noreferrer"
          target={'_blank'}>
          <div style={{ width: 25, display: 'inline-block', opacity: 0.6 }}>
            <FontAwesomeIcon icon={icon} />
          </div>
          {title}
        </a>
      )
    }

    return (
      <Link
        onClick={() => {
          // Reset the page stage if we're clicking but are already on the page.
          // Or even if we're coming from another page!
          /*
          var samePageReload = false
          if (location.pathname === url) {
            samePageReload = true
            // This will be good to know later on.
            // For now, we need to reset the state either way.
          }
          */
          if (beforePageChange) {
            beforePageChange()
          }
        }}
        className={`main-menu-item ${isActive ? 'active' : ''}`}
        to={url}
        rel="noopener noreferrer"
        target={external ? '_blank' : '_self'}>
        <div style={{ width: 25, display: 'inline-block', opacity: 0.6 }}>
          <FontAwesomeIcon icon={icon} />
        </div>
        {title}
      </Link>
    )
    /* OLD CODE: Replaced with Link to keep STATE
    return (
      <a
        className={`main-menu-item ${isActive ? 'active' : ''}`}
        href={url}
        rel="noopener noreferrer"
        target={external ? '_blank' : '_self'}>
        <div style={{ width: 25, display: 'inline-block', opacity: 0.6 }}>
          <FontAwesomeIcon icon={icon} />
        </div>
        {title}
      </a>
    )*/
  }

  return (
    <nav className="employee-sidebar-container">
      <div className="employee-sidebar-logo">
        <img
          src="/logo.png"
          alt="Logo"
          width="100%"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate('/employee/flights/search')
          }}
        />
      </div>
      <div className="employee-menu">
        <MenuItem
          url={'/employee/flights/search'}
          activeUrlMatch={['/employee/flights', '/employee/car']}
          title={'Log Trip'}
          icon={'search'}
          beforePageChange={() => {
            // We need to reset the page state here.
            resetSearch()
          }}
        />
        <MenuItem url={'/employee/trips'} title={'My Trips'} icon={'clipboard-list'} />
        {/*
        <MenuItem url={'/employee/preferences'} title={'Preferences'} icon={'sliders-h'} />
        */}
        <MenuItem
          external={true}
          url={'https://climpact.ai/cst-faq'}
          title={'FAQ'}
          icon={'question-circle'}
        />
        <MenuItem
          external={true}
          url={'https://climpact.ai/cst-intro'}
          title={'Introduction'}
          icon={
            'chalkboard-teacher'
          } /* Icons: https://fontawesome.com/v5/search?o=r&m=free&f=classic */
        />
      </div>
      <div style={{ flexGrow: 1 }}></div>
      <div className="employee-sidebar-user">
        {userDetails && userDetails.email} &nbsp; &nbsp; &nbsp;
        <Button
          onClick={() => {
            cognitoLogout().then(() => {
              document.location.href = '/#bye?'
            })
          }}
          small
          secondary
          title="Log out"
        />
      </div>
      <div className="employee-sidebar-footer">
        <div style={{ fontSize: 10, color: '#ccc', marginBottom: 20 }}>
          {window.fnzBuildVersion}
        </div>
        <a href="https://climpact.ai" target="_blank" rel="noreferrer">
          climpact.ai
        </a>
        <br />
        ClimPact &copy; {new Date().getFullYear()}
      </div>
    </nav>
  )
}
