import './Button.css'
import Loading from './Loading'

export default function Button(props) {

  const handleClick = () => {
    if (props.onClick) {
      props.onClick()
    }
  }

  if (props.spinning) {
    return <Loading width={50} inline/>
  }

  if (props.x) {
    return (
      <button
        className={`fnz-round-remove-button ${props.red ? 'fnz-round-remove-button-red' : ''}`}
        onClick={handleClick}>
        ✕
      </button>
    )
  }

  if (props.linkStyle) {
    return (
      <button 
        type={'submit'}
        id={props.id || ''}
        className={'fnz-button-linkstyle'}
        disabled={props.disabled || false}
        style={props.style || null}
        onClick={handleClick}
        >
        {props.text || props.title || ''}
      </button>
    )
  }

  return (
    <button
      type={props.type || "submit"}
      id={props.id || ''}
      disabled={props.disabled || false}
      className={`fnz-button ${props.testPoint ? props.testPoint : ''} ${
        props.disabled ? 'button-disabled' : ''
      } ${props.muted ? 'fnz-button-muted' : ''} ${props.large ? 'button-large' : ''} ${
        props.small ? 'button-small' : ''
      } ${props.secondary ? 'button-secondary' : ''} ${props.danger ? 'button-danger' : ''} `}
      style={props.style || null}
      onClick={handleClick}>
      {props.text || props.title || ''}
    </button>
  )
}
