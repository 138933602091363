import Button from '../../common/Misc/Button'
import { Tools } from '../../../helpers/Tools'
import FlightRowMobile from './FlightRowMobile'

export default function OfferPanelMobile(props) {
  return (
    <div
      style={{
        marginTop: 0,
        marginBottom: 20,
        cursor: 'pointer',
        width: '100%'
      }}
      onClick={props.onSelect}>
      {/* This is the top label or badge (Best Match / Better Match / Habit to Beat) ------------------------ */}
      <div
        style={{
          position: 'relative',
          left: '50%',
          width: 140,
          textAlign: 'center',
          marginLeft: -70,
          top: 10,
          padding: '5px 15px',
          color: '#fff',
          fontSize: 12,
          fontWeight: 600,
          display: 'inline-block',
          backgroundColor: props.section === 'habit_to_beat' ? '#333' : '#0A1EAA',
          borderRadius: 4,
          borderTopLeftRadius: 16,
          borderBottomRightRadius: 16
        }}>
        {props.topLabel}
      </div>{' '}
      {/* End of Top Label */}
      {/* Body of the white panel container begins here --------------------------------------------- */}
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: 40,
          boxShadow: props.selected
            ? '0 16px 56px 0 rgba(10,30,170,0.24)'
            : '0 2px 8px 0 rgba(10,30,170,0.24)',
          padding: 20
        }}>
        {/* Title Row (Save 42% of CO2e and earn 1200 FNZ) in green or gray ------------------------ */}
        <div
          style={{
            backgroundColor: props.selected
              ? props.section === 'habit_to_beat'
                ? 'rgba(10,30,170,0.04)'
                : '#E0F88B'
              : 'rgba(10,30,170,0.04)',
            borderRadius: 32,
            padding: 12,
            marginBottom: 20,
            alignItems: 'center'
          }}>
          <div style={{ fontSize: 12, textAlign: 'center' }}>
            {props.section === 'habit_to_beat' && <div>Your selected flight</div>}
            {props.section !== 'habit_to_beat' && (
              <div>
                Save{' '}
                <strong>
                  {props.co2Savings.roundtrip_co2_percentage}% CO
                  <sub style={{ fontSize: 12 }}>2e</sub>
                </strong>{' '}
                {props.offer.fnz_points > 0 && (
                  <span>
                    and earn{' '}
                    <strong>{Tools.formatNumber(props.offer.fnz_points)} Climate Points</strong>
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        {/* End of Title Row */}

        {/* This is where the two Flight Rows start (outbound and return) -------------------------- */}
        <FlightRowMobile
          direction="outward_itinerary"
          section={props.section}
          offer={props.offer}
          habitToBeat={props.habitToBeat}
        />

        <div
          style={{
            borderBottom: '1px solid #eee',
            marginTop: 20,
            marginBottom: 20
          }}></div>

        <FlightRowMobile
          direction="return_itinerary"
          section={props.section}
          offer={props.offer}
          habitToBeat={props.habitToBeat}
        />
        {/* End of the flight rows */}

        <div
          style={{
            fontSize: 14,
            textAlign: 'center',
            marginTop: 20,
            borderTop: '1px solid #eee',
            paddingTop: 20
          }}>
          {!props.hidePrice && (
            <>
              Round trip cost: <strong>{props.costSavings.roundtrip_cost_percentage}%</strong>{' '}
              {Tools.formatNumber(props.costSavings.roundtrip_cost)} {props.offer.price.currency}
            </>
          )}
        </div>

        {!props.disabled && (
          <div style={{ marginTop: 20, textAlign: 'center' }}>
            <div style={{ textAlign: 'center' }}>
              <Button
                text={props.confirmLabel || `Select`}
                onClick={() => {
                  /* Mobile works a bit differently, we need to select the correct item first
                and then send the confirm signal to the server slightly afterwards */
                  props.onSelect()
                  setTimeout(() => {
                    props.onConfirm()
                  }, 300)
                }}
              />
            </div>
          </div>
        )}
      </div>
      {/* End of White Body Container */}
    </div>
  )
}
