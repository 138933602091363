import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './RadioButton.css'
export default function RadioButton(props) {
  if (props.selected) {
    return (
      <div id={props.id || ''} className="radio-button-component" onClick={props.onClick}>
        <FontAwesomeIcon icon="check" size="xs" className="radio-selection-icon" />
      </div>
    )
  } else {
    return (
      <div
        id={props.id || ''}
        className="radio-button-component radio-button-off"
        onClick={props.onClick}></div>
    )
  }
}
