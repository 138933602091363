import './TripStatus.css'
export default function TripStatus(props) {
  const statusDetails = (status) => {
    switch (status) {
      case 'open':
        return {
          label: 'OPEN',
          backgroundColor: '#ee0000',
          color: '#fff'
        }
      case 'confirmed':
        return {
          label: 'CONFIRMED',
          backgroundColor: '#009900',
          color: '#fff'
        }
      default:
        return {
          label: 'UNKNOWN',
          backgroundColor: '#fafafa',
          color: '#333333'
        }
    }
  }

  return (
    <div
      className="trip-status"
      style={{
        backgroundColor: statusDetails(props.status).backgroundColor,
        color: statusDetails(props.status).color,
        fontSize: 11,
        fontWeight: 'bold',
        letterSpacing: 0.4
      }}>
      {statusDetails(props.status).label}
    </div>
  )
}
