import { Tools } from '../../../helpers/Tools'
import OfferPanelGenericDesktop from './OfferPanelGenericDesktop'
import OfferPanelGenericMobile from './OfferPanelGenericMobile'

export default function OfferPanelGeneric(props) {
  const windowSize = Tools.useWindowSize()
  const isMobile = windowSize.width && windowSize.width < 1000

  const offerType = () => {
    if (props.section === 'option_train') return 'Train'
    if (props.section === 'option_online') return 'Meet Online'
  }

  // If we're on mobile, then let's use our optimized mobile version for this
  if (isMobile) {
    return (
      <OfferPanelGenericMobile
        topLabel={offerType().toUpperCase()}
        offerLabel={/*offerLabel()*/ ''}
        confirmLabel={props.confirmLabel || 'Select'}
        compareTo={props.compareTo}
        disabled={props.disabled || false}
        section={props.section}
        selected={props.selected}
        onSelect={props.onSelect}
        onConfirm={props.onConfirm}
      />
    )
  } else {
    return (
      <OfferPanelGenericDesktop
        topLabel={offerType().toUpperCase()}
        offerLabel={/*offerLabel()*/ ''}
        confirmLabel={props.confirmLabel || 'Select'}
        compareTo={props.compareTo}
        disabled={props.disabled || false}
        section={props.section}
        selected={props.selected}
        onSelect={props.onSelect}
        onConfirm={props.onConfirm}
      />
    )
  }
}
