import React from 'react'
import ReactTooltip from 'react-tooltip'

export default function Tabs(props) {
  const getInfo = (i) => {
    if (props.tabInfos) {
      if (props.tabInfos.length > i) {
        return props.tabInfos[i]
      }
    }
    return ''
  }
  return (
    <div style={{ display: 'flex' }}>
      {props.tabs.map((tab, i) => {
        return (
          <React.Fragment key={`tab_${i}`}>
            <div
              data-tip={getInfo(i)}
              onClick={() => {
                props.onChange(i)
              }}
              style={{
                cursor: 'pointer',
                userSelect: 'none',
                padding: 20,
                fontWeight: i === props.selectedIndex ? 'bold' : 'normal',
                borderBottom: i === props.selectedIndex ? '2px solid #004EB0' : 'none',
                color: i === props.selectedIndex ? '#004EB0' : '#aaa'
              }}>
              {tab}
            </div>
            <ReactTooltip
              className={'tooltip-container'}
              type={'dark'}
              delayShow={1500}
              place={'bottom'}
              multiline={true}
              backgroundColor={'#ffffff'}
              textColor={'#333333'}
              effect={'solid'}
            />
          </React.Fragment>
        )
      })}
    </div>
  )
}
