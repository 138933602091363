import ReactTooltip from 'react-tooltip'

export default function Tooltip(props) {
  return (
    <ReactTooltip
      className={'tooltip-container'}
      type={'dark'}
      delayShow={200}
      place={'bottom'}
      multiline={true}
      backgroundColor={'#ffffff'}
      textColor={'#333333'}
      effect={'solid'}
    />
  )
}
