import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Loading from '../../components/common/Misc/Loading'
import PageTitle from '../../components/common/Misc/PageTitle'
import TripSummaryFlight from '../../components/common/Misc/TripSummaryFlight'
import OfferPanel from '../../components/employee/ClimateOptions/OfferPanel'
import OfferPanelGeneric from '../../components/employee/ClimateOptions/OfferPanelGeneric'
import useEmployeeStore from '../../employee_store'
import { Flights } from '../../helpers/Flights'
import useStore from '../../store'
import BookingOptions from '../../components/employee/Booking/BookingOptions'

export default function EmployeeModuleCompleted(props) {
  const companyDetails = useStore((state) => state.companyDetails)
  const pastFlight = useEmployeeStore((state) => state.pastFlight)
  const climateOptionSection = useEmployeeStore((state) => state.climateOptionSection)
  const habitToBeat = useEmployeeStore((state) => state.habitToBeat)
  const recommendations = useEmployeeStore((state) => state.recommendations)
  const selectedTrip = useEmployeeStore((state) => state.selectedTrip)
  const currencyRateToday = useStore((store) => store.currencyRateToday)

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    console.log('[Final Page] [useEffect] Habit to Beat: ', habitToBeat, companyDetails)
    if (!habitToBeat || !companyDetails) {
      console.log('WOULD NAVIGATE AWAY')
      //navigate('/')
    } else {
      setIsLoading(false)
    }
  }, [habitToBeat, navigate, companyDetails])

  if (isLoading || !companyDetails) {
    return (
      <div style={{ marginTop: 100 }}>
        <Loading />
      </div>
    )
  }

  // Prepare the offer panel
  var offerPanelSection = climateOptionSection
  var offerPanelHabitToBeat = Flights.getSectionFromRecommendations(
    recommendations,
    'habit_to_beat'
  )
  var offerPanelOffer = selectedTrip
  if (pastFlight) {
    offerPanelSection = 'habit_to_beat'
    offerPanelHabitToBeat = habitToBeat
    offerPanelOffer = habitToBeat
  }

  return (
    <>
      <PageTitle title="Next steps: book externally">
        Your selected option has been added to your "My Trips" log.
        <br />
        Please book your selected option below externally and confirm your booking in "
        <Link
          to="/employee/trips"
          target="_blank"
          style={{ borderBottom: '1px dotted blue', paddingBottom: 2 }}>
          My Trips
        </Link>
        ".
      </PageTitle>
      <br />

      <div style={{ marginTop: 0, marginBottom: 20 }}>
        <TripSummaryFlight
          trip={habitToBeat}
          departure_date={habitToBeat.departure_date || null}
          return_date={habitToBeat.return_date || null}
          noBackground={true}
        />
      </div>

      {climateOptionSection !== 'train' && climateOptionSection !== 'online' && (
        <OfferPanel
          showPolicyViolations={companyDetails.feature_policies || false}
          key={`offer_panel`}
          section={offerPanelSection}
          disabled={true}
          carbonPricing={companyDetails.feature_carbon_pricing}
          habitToBeat={offerPanelHabitToBeat}
          offer={offerPanelOffer}
          selected={true}
          displayCurrency={companyDetails.currency || 'CHF'}
          currencyConversionRate={currencyRateToday || 1.0}
        />
      )}

      {climateOptionSection === 'train' && (
        <OfferPanelGeneric
          key="option_train"
          section="option_train"
          compareTo={habitToBeat}
          disabled={true}
          selected={true}
        />
      )}
      {climateOptionSection === 'online' && (
        <OfferPanelGeneric
          key="option_online"
          section="option_online"
          compareTo={habitToBeat}
          disabled={true}
          selected={true}
        />
      )}

      <BookingOptions />
    </>
  )
}
