import { Networking } from './Networking'

export const Flights = {
  getSectionFromRecommendations: (recommendations, section) => {
    // This is a bit of a flexible function. It takes one of two different
    // trip structures:
    //
    // Model 1: Original recommendation structure (across cabin classes)
    // connections.roundtrip_bundles.CABIN_CLASS[]
    //
    // Model 2: Array of trips
    // []
    //
    var trips = []
    if (recommendations === null) return null
    if (Array.isArray(recommendations)) {
      // Model 2:
      trips = recommendations
    } else {
      // Model 1:
      let cabin_classes = Object.keys(recommendations.connections.roundtrip_bundles)
      cabin_classes.forEach((cabin_class) => {
        trips = trips.concat(recommendations.connections.roundtrip_bundles[cabin_class])
      })
    }
    return trips.find((trip) => {
      return Flights.figureOutSectionForTrip(trip) === section
    })
  },

  figureOutSectionForTrip: (trip) => {
    if (trip.recommendation) {
      if (trip.recommendation.habit_to_beat) return 'habit_to_beat'
      if (trip.recommendation.best_match) return 'best_match'
      if (trip.recommendation.better_match) return 'better_match'
      if (trip.recommendation.max_savings) return 'max_savings'
    }
    return 'other'
  },

  recommendFlights: async (searchId, habit_to_beat, calibration = null) => {
    let payload = calibration
      ? {
          id: searchId,
          habit_to_beat: habit_to_beat,
          weights: calibration
        }
      : {
          id: searchId,
          habit_to_beat: habit_to_beat
        }
    return await Networking.recommendFlights(payload)
      .then((data) => {
        var habitToBeat = null
        var bestMatch = null
        var betterMatch = null
        var maxSavings = null
        var recommendedFlights = []

        // We need to declutter the backend result a bit as it's currently
        // split up by cabin classes, and we just need a list of all options
        // instead of filtering by cabin class.
        var roundtrips = []
        try {
          // Iterature through all cabin classes and concat all the results into our roundtrips collection
          Object.keys(data.result.connections.roundtrip_bundles).forEach((classOptions, i) => {
            roundtrips = roundtrips.concat(data.result.connections.roundtrip_bundles[classOptions])
          })
        } catch (e) {
          console.log(e)
        }

        // Now let's pick out any backend recommendations, if we get any...
        roundtrips.forEach((trip, i) => {
          if (trip.outward_itinerary.recommendation.habit_to_beat) {
            /* This one we technically already have (via props), but let's pick it up again fresh from here */
            habitToBeat = trip
          }
          if (trip.outward_itinerary.recommendation.best_match) {
            bestMatch = trip
          }
          if (trip.outward_itinerary.recommendation.better_match) {
            betterMatch = trip
          }
          if (trip.outward_itinerary.recommendation.max_savings) {
            maxSavings = trip
          }
          // Inject the recommendation higher up
          roundtrips[i]['recommendation'] = trip.outward_itinerary.recommendation
        })

        // All sorted, let's pass this to the state so we can render an UI
        recommendedFlights = roundtrips
        return {
          habit_to_beat: habitToBeat,
          best_match: bestMatch,
          better_match: betterMatch,
          max_savings: maxSavings,
          recommended_flights: recommendedFlights
        }
      })
      .catch((error) => {
        console.log(error)
        return {
          habit_to_beat: null,
          best_match: null,
          better_match: null,
          max_savings: null,
          recommended_flights: []
        }
      })
  }
}
