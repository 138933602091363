import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Networking } from '../helpers/Networking'
import { decodeJwt } from 'jose'

import Button from '../components/common/Misc/Button'
import ErrorText from '../components/common/Misc/ErrorText'
import TextInput from '../components/common/Misc/TextInput'

import Loading from '../components/common/Misc/Loading'

import PasswordChecklist from 'react-password-checklist'

export default function RegisterPage() {

  // Url Params
  const { token } = useParams();

  // Global state
  //const isAuthenticated = useStore((state) => state.isAuthenticated)

  // Local state
  const [fullname, setFullName] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [loading, setIsLoading] = useState(false)
  const [registrationIssue, setRegistrationIssue] = useState(false)
  const [passwordValid, setPasswordValid] = useState(false)
  const [checkEmail, setCheckEmail] = useState(false)
  const [code, setCode] = useState('')

  if (!token || token === "" || token.length < 30 || token.length > 1000 || (token.split(".").length !== 3) ) {
    return <div style={{textAlign: "center", marginTop:200}}>Sorry, this seems to be a broken link. Please try again with the correct link.<br/>If this continues to fail, make sure you copy and paste the entire link manually and try that.</div>
  }
  
  const tokenData = decodeJwt(token)

  console.log("Registration Invite Key: ", tokenData.invitation_key)

  // Actions
  const signUp = async (e) => {
    e.preventDefault() 
    
    // Data validation
    if (password !== password2) {
      setRegistrationIssue("Your passwords do not match.");
      return;
    }
    if (fullname.trim().length <= 4) {
      setRegistrationIssue("Please type in your full legal name");
      return;
    }


    setIsLoading(true)
    const result = await Networking.createAccount(username.toLowerCase().trim(), password.trim())
    if (result.status) {
      setIsLoading(false)
      setRegistrationIssue(false)
      setCheckEmail(true)
    } else {      
      console.log("There was an issue trying to create the account: ", result.error)
      setIsLoading(false)
      setCheckEmail(false)
      if (result.error === "invalid_password") {
        setRegistrationIssue(result.details ? result.details : `The password did not meet the security criteria. Please check the password requirements and try again.`)
      } else if (result.error === "invalid_email") {
        setRegistrationIssue("This does not seem to be a valid e-mail address.")
      } else if (result.error === "user_exists") {
        setRegistrationIssue("We already have an account with this e-mail. Can you try a password reset to get back into the account?")
      } else {
        setRegistrationIssue("We were unable to create the account. Please reach out via chat or e-mail so we can investigate the issue.")
      }
    }
  }

  const confirmAccount = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    const result = await Networking.confirmAccount(username.toLowerCase().trim(), code.trim(), token, fullname.trim())
    if (result.status) {
      // DONE! This means the user will be auto-logged in! So let's go back to the start and hope for the best!
      setTimeout(() => {
        document.location.href = "/?";      
      }, 3500); // Delay this a tiny bit so the backend has enough time to catch up!
    } else {
      setIsLoading(false)
      if (result.error === "invalid_code") {
        setRegistrationIssue("The code you entered was invalid. Can you try again?")
      } else if (result.error === "already_confirmed") {
        setRegistrationIssue("Your account already seems to be confirmed. Please go back to the homepage and try to log in from there.")
      } else {
        setRegistrationIssue("We were unable to confirm the account. Please reach out to us via chat.")
      }
    }
  }
  

  if (checkEmail) {

    if (loading) {
      return (
        <div style={{ marginTop: 300, marginBottom: 50 }}>
          <Loading />
          <div style={{fontSize: 14, color: "#666", marginTop:25, textAlign: "center"}}>
            We are creating the account, one moment
          </div>
        </div>
      )
    }

    return (
      <div
        style={{
          width: 460,
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: 100,
          padding: 20,
          backgroundColor: 'white',
          boxShadow: '5px 5px 10px #eee',
          borderRadius: 10
        }}>
          <h3>Confirm your Account</h3>

          <ErrorText show={registrationIssue}>
            {registrationIssue}
          </ErrorText>

          We have just sent an e-mail to the address you specified.<br/><span style={{fontSize: 13, color: "#aaa", fontStyle:"italic"}}>Check your spam folder if you don't get the e-mail in the next few minutes.</span><br/><br/><br/>
          <form onSubmit={confirmAccount}>
            <strong>Type the code from the e-mail to finish your account:</strong><br/><br/><br/>
            <div style={{display: "flex"}}>
              <TextInput
                type={'number'}
                name={'code'}
                value={code}
                placeholder="Verification Code..."
                onWhite={true}
                onChange={(d) => {
                  setCode(d)
                }}
              />
              <div style={{flexGrow:1}}></div>
              <Button disabled={(code==="") || code.length !== 6} testPoint="confirm-button" type="submit" text={'Finish Account'} />
            </div>
          </form>
      </div>
    )
  }

  return (
    <div
      style={{
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 100,
        padding: 20,
        backgroundColor: 'white',
        boxShadow: '5px 5px 10px #eee',
        borderRadius: 10
      }}>
      <div style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold', marginBottom: 40 }}>
        <img src="/logo.png" alt="Logo" height="20" />
      </div>

      <ErrorText show={registrationIssue}>
        {registrationIssue}
      </ErrorText>

      {loading && (
        <div style={{ marginTop: 50, marginBottom: 50 }}>
          <Loading />
        </div>
      )}

      {!loading && (
        <form onSubmit={signUp}>

          <div style={{fontSize:24, color: "#999",  marginBottom:40}}>Create your Account</div>

          <div style={{marginBottom:30}}>
            <div style={{ fontSize: 12, color: '#333', marginBottom: 5 }}>
              Organization:
            </div>
            <div>
              {tokenData.company_name}
            </div>
          </div>

          <TextInput
            type={'text'}
            label={'E-mail address:'}
            name={'username'}
            value={username}
            placeholder="Your organization e-mail address"
            onWhite={true}
            onChange={(d) => {
              setUsername(d)
              if (registrationIssue) setRegistrationIssue(false)
            }}
          />

          <br />

          <TextInput
            type={'text'}
            label={'Your full name (no titles):'}
            name={'full_name'}
            value={fullname}
            placeholder="Type your full name, e.g. Jane Doe, John Doe"
            onWhite={true}
            onChange={(d) => {
              setFullName(d)
              if (registrationIssue) setRegistrationIssue(false)
            }}
          />          

          <br />

          <TextInput
            type={'password'}
            label={'Password'}
            name={'password'}
            value={password}
            placeholder="Create your password"
            onWhite={true}
            onChange={(d) => {
              setPassword(d)
              if (registrationIssue) setRegistrationIssue(false)
            }}
          />

          <br/>

          { password !== "" && 
          <>
            <PasswordChecklist
              style={{ fontSize: 14 }}
              rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase']}
              minLength={8}
              value={password}
              valueAgain={password2}
              onChange={(isValid) => {
                setPasswordValid(isValid)
              }}
            />
            <br/>

            { passwordValid && 
            <TextInput
              type={'password'}
              label={'Verify your password'}
              name={'password2'}
              value={password2}
              placeholder="Type your password again"
              onWhite={true}
              onChange={(d) => {
                setPassword2(d)
                if (registrationIssue) setRegistrationIssue(false)
              }}
            />         
            }
          </>
          }

          <br />

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flexGrow: 1 }}></div>
            { passwordValid && 
            <Button testPoint="sign-up-button" type="submit" text={'Create Account'} />
            }
          </div>
        </form>
      )}
    </div>
  )
}
