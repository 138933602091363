import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Networking } from '../../helpers/Networking'
import PageTitle from '../../components/common/Misc/PageTitle'
import Loading from '../../components/common/Misc/Loading'
import Button from '../../components/common/Misc/Button'

export default function EmployeeModulePreferences(props) {
  const [loadingState, setLoadingState] = useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchPreferences = async () => {
      const data = await Networking.getUser()
      if (data) {
        // TODO: get preferences
        setLoadingState(2)
      }
    }
    if (loadingState === 0) {
      setLoadingState(1)
      fetchPreferences()
    }
  }, [loadingState, setLoadingState])

  if (loadingState === 1) {
    return (
      <>
        <PageTitle title={'Preferences'}></PageTitle>
        <Loading />
      </>
    )
  }

  return (
    <>
      <PageTitle title={'Preferences'}></PageTitle>
      <h3>Flight Habits</h3>
      You can fine-tune your flight habits to make future bookings faster and more relevant.
      <br />
      <br />
      <Button
        text="Update Flight Habits"
        onClick={() => {
          navigate('/employee/preferences/habit')
        }}
      />
    </>
  )
}
