import { useState } from 'react'
import PasswordChecklist from 'react-password-checklist'

import Button from '../../../components/common/Misc/Button'
import TextInput from '../../../components/common/Misc/TextInput'

import useStore from '../../../store'

export const CognitoPasswordUpdate = (props) => {
  // Global state
  const cognitoSetInitialPassword = useStore((state) => state.cognitoSetInitialPassword)
  const awsUserObject = useStore((state) => state.awsUserObject)

  // Local state
  //const [email, setEmail] = useState(awsUserObject.email)
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [passwordValid, setPasswordValid] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  // Actions
  const updatePassword = async (e) => {
    e.preventDefault()
    if (password !== password2) {
      alert('Your two passwords did not match. Please try again.')
      setPassword('')
      setPassword2('')
    }
    const result = await cognitoSetInitialPassword(awsUserObject, password)
    if (result) setShowSuccess(true)
  }

  if (showSuccess) {
    return (
      <div
        style={{
          width: 400,
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: 100,
          padding: 20,
          textAlign: 'center',
          backgroundColor: 'white',
          boxShadow: '5px 5px 10px #eee',
          borderRadius: 10
        }}>
        <div style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold', marginBottom: 40 }}>
          <img src="/logo.png" alt="Logo" height="20" />
        </div>
        <strong>Account setup complete</strong>
        <br />
        <br />
        We have applied the new password to your account.
        <br />
        <br />
        <br />
        <Button
          title={'Complete Boarding'}
          onClick={() => {
            document.location.href = '/?'
          }}
        />
      </div>
    )
  }

  return (
    <div
      style={{
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 100,
        padding: 20,
        backgroundColor: 'white',
        boxShadow: '5px 5px 10px #eee',
        borderRadius: 10
      }}>
      <div style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold', marginBottom: 40 }}>
        <img src="/logo.png" alt="Logo" height="20" />
      </div>

      <form onSubmit={updatePassword}>
        <strong>New Account</strong>
        <br />
        <br />
        In order to set up your new account, please set a new, secure password:
        <br />
        <br />
        <PasswordChecklist
          style={{ fontSize: 14 }}
          rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase', 'match']}
          minLength={8}
          value={password}
          valueAgain={password2}
          onChange={(isValid) => {
            setPasswordValid(isValid)
          }}
        />
        <br />
        {/*
        <TextInput
          type={'text'}
          name={'email'}
          value={email}
          placeholder="Your E-mail"
          onWhite={true}
          onChange={(d) => {
            setEmail(d)
          }}
        />
        */}
        <TextInput
          type={'password'}
          name={'password'}
          value={password}
          placeholder="Your password"
          onWhite={true}
          onChange={(d) => {
            setPassword(d)
          }}
        />
        <TextInput
          type={'password'}
          name={'password2'}
          value={password2}
          placeholder="Repeat your password once more"
          onWhite={true}
          onChange={(d) => {
            setPassword2(d)
          }}
        />
        <br />
        <div style={{ textAlign: 'right' }}>
          <Button type="submit" text={'Sign in'} disabled={!passwordValid} />
        </div>
      </form>
    </div>
  )
}
