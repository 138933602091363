import { useState } from "react";
import { useNavigate } from 'react-router-dom'
import { Networking } from '../../../helpers/Networking'
import AirlineSearchPanel from "./AirlineSearchPanel";
import TextInput from "./TextInput";
import Button from "./Button";
import Modal from '../Modal/Modal'
import useStore from '../../../store'
import useEmployeeStore from '../../../employee_store'

const SegmentPanel = (props) => {
  const [airline, setAirline] = useState(props.data.airline);
  const [flightNumber, setFlightNumber] = useState(props.data.flight_number);

  const editStyle = {zIndex: 10000, padding:25, backgroundColor:"white", border:"1px solid #ddd", paddingBottom:10, paddingTop: 20, boxShadow:"3px 3px 12px #333", borderRadius: 10, marginTop:15, marginRight: 15, marginBottom: 15, display:"inline-block"};
  const staticStyle = {padding:20,paddingBottom:10, paddingTop: 10, boxShadow:"3px 3px 12px #ddd", borderRadius: 10, marginTop:15, marginRight: 15, marginBottom: 15, display:"inline-block"};

  return (

    <div style={props.editMode ? editStyle : staticStyle}>
      
      {
        !props.editMode && 
        <div>
          <Button small muted text={'Edit'} onClick={() => { props.onEdit(); }}/>
          {!props.data.airline && <span style={{fontSize:12, marginLeft: 10, marginRight: 10}}>Add details</span>}
          {props.data.airline && 
          <span style={{marginRight: 15, marginLeft: 15, fontSize: 14}}>
            <div style={{marginRight:5, position:"relative", top:2, display: "inline-block", borderRadius: "50%", width: 15, height: 15, backgroundPosition: "center center", backgroundSize: "20px 20px", backgroundImage: `url(/airlines/${props.data.airline.iata.toLowerCase()}.png)`}}>
            </div>
            {(props.data.airline && props.data.airline.iata) || ""} {props.data.flight_number||""}
          </span>
          }
          <Button x red onClick={() => { props.onRemove(); }}/>
        </div>
      }

      {
        props.editMode && 
        <div>
          <div style={{fontSize:18, fontWeight:"bold", marginBottom:40}}>Please enter your flight number</div>    
          <div style={{display:"flex", alignItems: "flex-start", marginTop:20, marginBottom:10}}>
            <div style={{width:130, fontWeight: "bold", fontSize: 15}}>Airline name:</div>
            <div>
              <AirlineSearchPanel airline={airline} onChange={(airline) => { setAirline(airline) }}/>
            </div>
          </div>
          <div style={{display:"flex", alignItems: "flex-start", marginBottom:20, marginTop:20}}>
            <div style={{width:130, fontWeight: "bold", fontSize: 15}}>(Flight) Number:</div>
            <div> 
              <TextInput
                type={'text'}
                name={'flightnumber'}
                style={{ paddingLeft: 0, paddingTop: 0, marginTop:0, fontSize: 14, width: props.width ? props.width : 185 }}
                value={flightNumber}
                placeholder="Numbers only; no '0' at start"
                onWhite={true}
                onChange={(d) => {
                  setFlightNumber(d.replace(/[^0-9]/g,''))
                }}
              />
            </div>
          </div>
          <div style={{display:"flex", alignItems: "center", marginBottom:20, marginTop:20}}>   
            <Button muted title={'Cancel'} onClick={() => { props.onCancel() } }/>
            <div style={{flexGrow:1}}></div>
            <Button title={'Update'} onClick={() => { props.onConfirm({airline:airline, flight_number: flightNumber, direction: props.data.direction}) } }/>
          </div>
        </div>
      }


      
  </div>
  )
}

function useForceUpdate() {
  const [, setState] = useState({ value: 10 });
  function rerenderForcefully() {
    setState((prev) => {
      return { ...prev };
    });
  }
  return rerenderForcefully;
}

export default function ManualTripPanel(props) {

  const errorMessage = "<div style='line-height:1.6em;color:#aa0000;'><strong>There seems to be an issue with the entered details:</strong><br/><br/>&mdash; Correct airline code? If there are two, use the other.<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If an airline is missing, reach out.<br/>&mdash; Correct (flight) number? (no letter, no 0 at start)<br/>&mdash; Correct dates and airport chosen?<br/>&mdash; Stop-over flight missing?<br/>&mdash; Endless loop? Reach out.</div>"

  const forceUpdate = useForceUpdate(); // Needed because the removal of flight boxes didn't work properly
  const navigate = useNavigate()
  const companyDetails = useStore((state) => state.companyDetails)
  const setHabitToBeat = useEmployeeStore((state) => state.setHabitToBeat)
  const setPastFlight = useEmployeeStore((state) => state.setPastFlight)
  const [buttonSpinning, setButtonSpinning] = useState(false)
  const [showError, setShowError] = useState(false)

  const initialState = [
    //{airline: null, flight_number: "", direction: "out"},
    //{airline: null, flight_number: "", direction: "return"}
  ]
  const [segments, updateSegments] = useState(initialState);
  const [segmentInEditMode, setSegmentInEditMode] = useState(null);

  const addSegment = (direction) => {
    var oldSegments = segments;
    let newSegment = {
      airline: null,
      flight_number: "",
      direction: direction
    };
    oldSegments = oldSegments.concat(newSegment)
    updateSegments(oldSegments)
    setSegmentInEditMode(newSegment);
  }

  const removeSegment = (segment) => {
    setSegmentInEditMode(null)
    var oldSegments = segments;
    oldSegments.splice(oldSegments.indexOf(segment), 1)
    updateSegments(oldSegments)    
    forceUpdate();
  }

  const confirmSegment = (segment, newData) => {
    segments[segments.indexOf(segment)] = newData;
    updateSegments(segments)
    setSegmentInEditMode(null)
  }

  const editSegment = (segment) => {
    setSegmentInEditMode(segment)
  }

  const logTrip = async() => {

    // Build the flights (BAREBONES only, just enough to get a response from Atmosfair)
    let outward_segments = segments.filter((segment) => {
      return segment.direction === "out"
    }).map((segment,i) => {
      return {
        cabin_class: props.searchDetails.cabin_class, // same for all flights, not fully correct but that's what we got for now
        carrier_code: segment.airline.iata,
        flight_number: segment.flight_number,
        departure_date: props.searchDetails.departure_date        
      }
    })
    let return_segments = segments.filter((segment) => {
      return segment.direction === "return"
    }).map((segment,i) => {
      return {
        cabin_class: props.searchDetails.cabin_class, // same for all flights, not fully correct but that's what we got for now
        carrier_code: segment.airline.iata,
        flight_number: segment.flight_number,
        departure_date: props.searchDetails.return_date        
      }
    })

    var trip = {
      search_id: props.searchId,
      trip_type: "flight",
      already_booked: props.alreadyBooked,
      route_direction: props.searchDetails.route_direction,
      outward_segments: outward_segments || [],
      return_segments: return_segments || [],
      departure_date: props.searchDetails.departure_date,
      return_date: props.searchDetails.route_direction === 'oneway' ? '' : props.searchDetails.return_date,
      cabin_class: props.searchDetails.cabin_class
    }
    const tripResult = await Networking.saveManualTrip(trip)
    if (tripResult) {

      // If the trip has been processed by the backend,
      // we can reload it back in here and use this as a semi-official
      // trip object for further steps (cost center / done page).
      const finishedTrip = await Networking.getTrip(tripResult.trip_id);
      if (finishedTrip) {
        // This is important for EVERYTHING else to work later
        // We also need to inject the departure date and return date (if available)
        // which makes this a real Frankenstein, but that's what it is for now
        // until we bring in Typescript
        console.log("Commiting pastflight state to store: ", props.alreadyBooked)
        setPastFlight(props.alreadyBooked)
        setHabitToBeat({...finishedTrip.flight, departure_date: finishedTrip.departure_date, return_date: finishedTrip.return_date}) 
        if (companyDetails.feature_cost_center) {
          console.log("Redirecting to Cost Center")
          return '/employee/flights/costcenter'
        } else {
          console.log("No Cost Center, redirecting to last page")
          return '/employee/flights/done'
        }  
      }
    } else {
      console.log("Did not receive a proper trip result back: ", tripResult)
    }
    return false;
  }

  // This is helping the UI to keep state of first add vs. later add operations
  let count_outward_segments = segments.filter((segment) => { return segment.direction === "out"}).length
  let count_return_segments = segments.filter((segment) => { return segment.direction === "return"}).length

  // Only allow return flight if outbound is set!
  var allowReturn = false;
  if (count_outward_segments>0) allowReturn = true;

  // Figure out if we have at least one outbound (oneway) or outbound and return (roundtrip)
  var allowToLog = false;
  if (count_outward_segments>0) {
    if (props.searchDetails.route_direction === "roundtrip") {
      if (count_return_segments>0) {
        allowToLog = true;
      }
    } else {
      allowToLog = true;
    }
  }

  return (
    <>
    <Modal visible={segmentInEditMode!==null} noContainer>
      <SegmentPanel
        editMode={true}            
        key={`edit_segment`} 
        data={segmentInEditMode} 
        onCancel={()=>{ setSegmentInEditMode(null) }}
        onConfirm={(newData) => { confirmSegment(segmentInEditMode, newData) } }
      />
    </Modal>

    {showError && 
    <div style={{maxWidth:440}}>
      <div style={{fontSize: 14, color: "#aa0000"}} dangerouslySetInnerHTML={{__html: showError}}></div><br/>
      <div style={{display:"flex", marginTop:30}}>
        <div style={{width:200}}>
          <Button onClick={()=>{ setShowError(false) }} title={"Try again"}/>
        </div>
        <div style={{color: "#666", fontSize: 13, marginLeft: 20}}>
          Only if you still experience an issue, reach out via chat providing a <strong>screenshot</strong> of the entered flight details.
        </div>
      </div>
    </div>
    }

    {!showError && 
    <>

      <div style={{marginBottom:30, fontSize: 18, marginTop:10, fontWeight:"bold", borderBottom:"1px dotted #ddd", paddingBottom:20}}>
        Please enter the flight number to log the flight
      </div>
      <div>
        <strong>Your outbound flight</strong> ({props.searchDetails.origin} {` to `} {props.searchDetails.destination}):<br/>
        {segments.length>0 && 
        <>
          {segments.map((segment,i) => {
            if (segment.direction !== "out") return (null)
            return (
              <SegmentPanel 
                editMode={false}            
                key={`out_segment_${i}_${Date.now()}_${segments.length}`} 
                data={segment} 
                onEdit={() => { editSegment(segment) } }
                onRemove={()=>{ removeSegment(segment) }}
              />
            )
          })}
        </>
        }
        <br/>
        {segments.filter((s) => s.direction === "out").length < 3 &&
          <Button muted title={count_outward_segments === 0 ? 'Add Flight' : 'Add stop-over flight'} onClick={() => { addSegment("out") }}/>
        }
      </div>
      { allowReturn && props.searchDetails.route_direction === "roundtrip"
          && <>
          <br/><br/>
          <div>
            <strong>Your return flight</strong> ({props.searchDetails.destination} {` to `} {props.searchDetails.origin}):<br/>
            {segments.map((segment,i) => {
              if (segment.direction !== "return") return (null)
              return (
                <SegmentPanel
                  editMode={false}            
                  key={`return_segment_${i}_${Date.now()}_${segments.length}`} 
                  data={segment} 
                  onEdit={() => { editSegment(segment) } }
                  onRemove={()=>{ removeSegment(segment) }}
                />
              )
            })}
            <br/>
            {segments.filter((s) => s.direction === "return").length < 3 &&
              <Button muted title={count_return_segments === 0 ? 'Add Flight' : 'Add stop-over flight'} onClick={() => { addSegment("return") }}/>
            }
          </div>
          </>
      }
      {
        (props.searchDetails.route_direction === "roundtrip") && !allowReturn && <div style={{fontSize:13, fontWeight: "bold", color:"#333", marginTop:30, borderTop: "1px solid #ddd", paddingTop:30}}>Add the return flight once you have added the outbound flight</div>
      }
      <div style={{display:"flex", alignItems: "center", marginBottom:0, marginTop:30, fontSize: 13, borderTop:"1px dotted #ddd", paddingTop:20}}>
        When you're finished, you can log the trip. <div style={{flexGrow:1}}></div>
        <Button 
          disabled={!allowToLog}
          spinning={buttonSpinning} 
          title={'Log this trip'} 
          onClick={async () => { 
            setButtonSpinning(true)
            let nextStep = await logTrip() 
            setButtonSpinning(false)
            if (!nextStep) {
              // Something failed, show an error
              setShowError(errorMessage)
            } else {
              navigate(nextStep)
            }
          }}
        />
      </div>    
    </>
    }
    </>
  )

}