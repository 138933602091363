import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ErrorText from '../../components/common/Misc/ErrorText'
import FlightResultRow from '../../components/FlightResultRow'
import FlightSearchPanel from '../../components/common/FlightSearch/FlightSearchPanel'
import FilterAirlines from '../../components/common/SearchFilters/FilterAirlines'
import FilterConnectingAirport from '../../components/common/SearchFilters/FilterConnectingAirport'
import FilterLayover from '../../components/common/SearchFilters/FilterLayover'
import FilterPrice from '../../components/common/SearchFilters/FilterPrice'
import FilterStopovers from '../../components/common/SearchFilters/FilterStopovers'
import FilterTimes from '../../components/common/SearchFilters/FilterTimes'
import TextInput from '../../components/common/Misc/TextInput'

import { Networking } from '../../helpers/Networking'
import Search from '../../helpers/Search'
import { Tools } from '../../helpers/Tools'

import useStore from '../../store'
import useEmployeeStore from '../../employee_store'
import PageTitle from '../../components/common/Misc/PageTitle'
import { Flights } from '../../helpers/Flights'
import Loading from '../../components/common/Misc/Loading'
import UZHIntro from '../../components/UZHIntro'
import LogTripTabs from '../../components/common/Misc/LogTripTabs'

import { getPolicyViolations } from '../../components/employee/Misc/OutOfPolicyLabel'
import Modal from '../../components/common/Modal/Modal'
import ManualTripPanel from '../../components/common/Misc/ManualTripPanel'
import MissingFlight from '../../components/common/FlightSearch/MissingFlight'

export default function EmployeeModuleSearchFlight(props) {
  // Global State
  const flightResults = useStore((state) => state.flightResults)
  const setFlightResults = useStore((state) => state.setFlightResults)
  const setSearchQuery = useStore((state) => state.setSearchQuery)
  const companyDetails = useStore((state) => state.companyDetails)
  const userDetails = useStore((state) => state.userDetails)

  // Employee State
  const setHabitToBeat = useEmployeeStore((state) => state.setHabitToBeat)
  const setBestMatch = useEmployeeStore((state) => state.setBestMatch)
  const setBetterMatch = useEmployeeStore((state) => state.setBetterMatch)
  const setMaxSavings = useEmployeeStore((state) => state.setMaxSavings)
  const setRecommendations = useEmployeeStore((state) => state.setRecommendations)
  const setSearchId = useEmployeeStore((state) => state.setSearchId)
  const setPastFlight = useEmployeeStore((state) => state.setPastFlight)
  const searchId = useEmployeeStore((state) => state.searchId)

  const setClimateOptionSection = useEmployeeStore((store) => store.setClimateOptionSection)
  const setSelectedTrip = useEmployeeStore((store) => store.setSelectedTrip)

  // This contains all the airports and similar things
  const setDictionary = useStore((state) => state.setDictionary)

  // Local State
  const [isLoading, setLoading] = useState(false)
  const [queryFailed, setQueryFailed] = useState(false)
  const [noResultsFound, setNoResultsFound] = useState(false)
  const [textFilter, setTextFilter] = useState('')
  const [sortParameter, setSortParameter] = useState('')
  const [flightSelectionStage, setFlightSelectionStage] = useState(0) // 0 = departing, 1 = return flight
  const [outboundFlight, setOutboundFlight] = useState(null)
  const [showMissingFlightNotice, setShowMissingFlightNotice] = useState(false)
  const [flightIndexWaitingForConfirmation, setFlightIndexWaitingForConfirmation] = useState(-1)
  const [showManualTripModal, setShowManualTripModal] = useState(false)

  const [mock_flight_id, setMockFlightId] = useState('')
  const [debugMode, setDebugMode] = useState(false)
  const [debugDetectorString, setDebugDetectorString] = useState('')

  const useNewEngine = props.engine ? (props.engine === 'v2' ? true : false) : false
  const searchApiEndpoint = props.endpoint ? props.endpoint : 'amadeus'

  // Local filters
  const defaultMaxStops = 5
  const [maxStops, setMaxStopsFilter] = useState(defaultMaxStops)
  const [maxPrice, setMaxPrice] = useState(Search.getMaxPrice(flightResults))
  const defaultLayoverMinimum = 0
  const [layoverMinimum, setLayoverMinimum] = useState(defaultLayoverMinimum)
  const defaultLayoverMaximum = 24
  const [layoverMaximum, setLayoverMaximum] = useState(defaultLayoverMaximum)
  const [selectedAirlines, setAirlines] = useState(Search.getAvailableAirlines(flightResults))
  const [connectingAirports, setConnectingAirports] = useState(
    Search.getAvailableConnectingAirports(flightResults)
  )
  const [filterTimeOutboundDepartureFrom, setOutboundDepartureFrom] = useState(0)
  const [filterTimeOutboundDepartureTo, setOutboundDepartureTo] = useState(24)
  const [filterTimeOutboundArrivalFrom, setOutboundArrivalFrom] = useState(0)
  const [filterTimeOutboundArrivalTo, setOutboundArrivalTo] = useState(24)
  const [filterTimeReturnDepartureFrom, setReturnDepartureFrom] = useState(0)
  const [filterTimeReturnDepartureTo, setReturnDepartureTo] = useState(24)
  const [filterTimeReturnArrivalFrom, setReturnArrivalFrom] = useState(0)
  const [filterTimeReturnArrivalTo, setReturnArrivalTo] = useState(24)

  // React Router
  const navigate = useNavigate()

  // Prepare some default dates
  const defaultDates = {
    initialDepartureDate: new Date(new Date().getTime() + 86400000 * 14),
    initialReturnDate: new Date(new Date().getTime() + 86400000 * 17)
  }

  // Default Search
  const [searchDetails, updateSearchDetails] = useState({
    origin: '',
    destination: '',
    departure_date: Tools.getShortFormatDate(defaultDates.initialDepartureDate) /* 2 weeks out */,
    departure_time: '00:00:00',
    return_date: Tools.getShortFormatDate(
      defaultDates.initialReturnDate
    ) /* 2 weeks out + 3 days later */,
    return_time: '00:00:00',
    cabin_class: 'ECONOMY',
    route_direction: 'roundtrip',
    max_stops: 2
  })

  const confirmSelection = async (outward_itinerary, return_itinerary, searchId) => {
    setLoading(true)

    let trip =
      searchDetails.route_direction === 'oneway'
        ? Search.findRoundtripBundleFromOnewayFlight(flightResults, outward_itinerary)
        : Search.findRoundtripBundleFromFlights(flightResults, outward_itinerary, return_itinerary)
    if (trip) {
      setHabitToBeat(trip)
      setPastFlight(props.alreadyBooked)
      setSearchQuery(searchDetails) // We may need this later, there is also another
      // saveTrip call later down the road (in the future flight route), and we need
      // it there to save the correct search query details.

      // Now it depends if we're working with a past flight, where we would have to
      // allocate this to a cost center, if so, then show an additional screen here.
      if (props.alreadyBooked) {
        // The next step is already the cost center, so we need to
        // run the recommendations engine in the background as well for this
        const result = await Flights.recommendFlights(searchId, trip)
        if (result) {
          // The HTB from the server contains all the necessary data, but
          // also has the wrong dates. So let's take the Server HTB, and inject/overwrite
          // the dates by taking them back to whatever we had to offset them
          if (result.habit_to_beat) {
            var htb_modified = result.habit_to_beat

            // Use the same method used when forward-dating, to figure out
            // how far we need to go back again for each of the returned dates
            let originalSearchDetails = JSON.parse(JSON.stringify(searchDetails))

            // NOTE October 5th, 2022: PAST FLIGHTS could also be in the future.
            // So let's see if we even need time-shifting...
            if (Search.needsTimeshift(originalSearchDetails['departure_date'])) {
              let backwardAdjustments = Search.calculateWeekOffsetForPastFlight(
                originalSearchDetails['departure_date'],
                originalSearchDetails['return_date']
              )

              htb_modified.outward_itinerary = Search.backdateFlightItineraryDates(
                result.habit_to_beat.outward_itinerary,
                backwardAdjustments.departure_week_offset
              )
              if (result.habit_to_beat.return_itinerary) {
                htb_modified.return_itinerary = Search.backdateFlightItineraryDates(
                  result.habit_to_beat.return_itinerary,
                  backwardAdjustments.return_week_offset
                )
              }
              result.habit_to_beat = htb_modified
            }
          }

          setHabitToBeat(result.habit_to_beat) // Overwrite with version from server
          setBestMatch(result.best_match)
          setBetterMatch(result.better_match)
          setMaxSavings(result.max_savings)
          setRecommendations(result.recommended_flights)

          // Now, let's save the trip as well
          const tripResult = await Networking.saveTrip({
            search_id: searchId,
            flight: result.habit_to_beat,
            trip_type: 'flight', // Cannot be train or online here
            already_booked: props.alreadyBooked,
            route_direction: searchDetails.route_direction,
            departure_date: searchDetails.departure_date,
            return_date: searchDetails.route_direction === 'oneway' ? '' : searchDetails.return_date
          })

          if (tripResult) {
            if (companyDetails.feature_cost_center) {
              navigate('/employee/flights/costcenter')
            } else {
              // TODO: This is probably not correct
              navigate('/employee/flights/done')
            }
          }
        }
      } else {
        // We are basically skipping a step here. In the agency module, the individual
        // offers can be customized at this point. Here, we produce the final offer page
        // and then also make the selection.
        navigate('/employee/flights/options')
      }
    } else {
      alert('Sorry, there was an issue identifying the correct flight.')
    }
  }

  const parseFlightResults = (data) => {
    if (Object.keys(data.result.connections.roundtrip_bundles).length === 0) {
      setFlightResults([])
      setSearchId(data.result.id)
      setNoResultsFound(true)
    } else {
      setNoResultsFound(false)
    }

    var roundtrips = []
    try {
      // Iterature through all cabin classes and concat all the results into our roundtrips collection
      Object.keys(data.result.connections.roundtrip_bundles).forEach((classOptions, i) => {
        roundtrips = roundtrips.concat(data.result.connections.roundtrip_bundles[classOptions])
      })
    } catch (e) {
      console.log(e)
    }

    // Prepare the dictionary first so it is ready when we'll need it
    setDictionary(data.result.dictionaries)

    // Pre-set the filters
    if (Search.hasNonstopOutboundFlights(roundtrips)) {
      setMaxStopsFilter(0)
    } else {
      setMaxStopsFilter(defaultMaxStops)
    }

    // Okay, tell the state that we've got new flights ready to go!
    setFlightResults(roundtrips)

    // Let the wizard know what our search ID is. This ID will be used for
    // all subsequent requests, so that the backend knows what session we're talking about.
    setSearchId(data.result.id)
  }

  const launchSearch = () => {
    setLoading(true)

    // Important: We need to reset any previous flight search state here.
    setFlightResults([])
    setQueryFailed(false)
    setNoResultsFound(false)
    setFlightIndexWaitingForConfirmation(-1)
    setHabitToBeat(false)
    setBestMatch(false)
    setBetterMatch(false)
    setMaxSavings(false)
    setClimateOptionSection(null)
    setSelectedTrip(null)
    setRecommendations(null)

    // Are we researching past flights? If so, then we'll need to take the weekday
    // and move to a date 2 months from today and pick the exact same weekday
    var searchPayload = searchDetails
    if (props.alreadyBooked) {
      let modifiedSearchPayload = JSON.parse(JSON.stringify(searchDetails))

      // NOTE (October 5, 2022 - C1-1 Ticket)
      // A "PAST FLIGHT" is actually just a "already booked" flight, and does
      // not have to be in the past. It can be partially in the past (departure) and
      // partially in the future, or both in the future, or in the past...

      // ------

      // Task 1: Pick a date in the future to ensure the flight exists
      // Task 2: Make sure this is on the SAME day, the user selected
      // Task 3: Later, we need to walk back (back-date) this to ensure
      // the backend continues to think we selected a flight from the past
      // even though Amadeus just gave us future dates.

      // From user date, continue to go forward by 1 week until
      // we're about 8 weeks in the future from today. Then go back same amount
      // of weeks when we receive the Amadeus results.

      if (Search.needsTimeshift(modifiedSearchPayload['departure_date'])) {
        let forwardAdjustments = Search.calculateWeekOffsetForPastFlight(
          modifiedSearchPayload['departure_date'],
          modifiedSearchPayload['return_date']
        )

        modifiedSearchPayload['departure_date'] = Tools.getShortFormatDate(
          forwardAdjustments.forward_dated_departure_date
        )
        if (modifiedSearchPayload['return_date']) {
          modifiedSearchPayload['return_date'] = Tools.getShortFormatDate(
            forwardAdjustments.forward_dated_return_date
          )
        }
      }

      searchPayload = modifiedSearchPayload
    }

    // If oneway, then set return date and time to nothing
    if (searchDetails.route_direction === 'oneway') {
      searchPayload.return_date = ''
      searchPayload.return_time = ''
    }

    if (useNewEngine) {
      Networking.searchFlightsProxy({
        search_query: searchPayload,
        mock_flight_id: mock_flight_id,
        endpoint: searchApiEndpoint
      })
        .then((data) => {
          parseFlightResults(data)
          setLoading(false)
          setShowMissingFlightNotice(true)
        })
        .catch((error) => {
          console.log(error)
          setQueryFailed(true)
          setLoading(false)
        })
    } else {
      Networking.searchFlights({
        search_query: searchPayload,
        mock_flight_id: mock_flight_id
      })
        .then((data) => {
          parseFlightResults(data)
          setLoading(false)
          setShowMissingFlightNotice(true)
        })
        .catch((error) => {
          console.log(error)
          setQueryFailed(true)
          setLoading(false)
        })
    }
  }

  const preFilteredFlights = Search.filterFlights(
    {
      time: {
        outbound_departure_from: filterTimeOutboundDepartureFrom,
        outbound_departure_to: filterTimeOutboundDepartureTo,
        outbound_arrival_from: filterTimeOutboundArrivalFrom,
        outbound_arrival_to: filterTimeOutboundArrivalTo,
        return_departure_from: filterTimeReturnDepartureFrom,
        return_departure_to: filterTimeReturnDepartureTo,
        return_arrival_from: filterTimeReturnArrivalFrom,
        return_arrival_to: filterTimeReturnArrivalTo
      },
      connecting_airports: connectingAirports,
      airlines: selectedAirlines,
      max_stops: maxStops,
      max_price: maxPrice,
      layover: {
        max: layoverMaximum,
        min: layoverMinimum
      },
      direction: flightSelectionStage
    },
    flightResults.slice()
  )

  const qualifyingFlights = Search.compressFlightResults(
    Search.sortResults(
      sortParameter,
      flightSelectionStage === 0
        ? Search.filterByText(Search.getOutboundFlights(preFilteredFlights), textFilter)
        : Search.getReturnFlights(preFilteredFlights, outboundFlight)
    )
  )

  const backButtonDetected = () => {
    if (flightSelectionStage === 1) {
      setFlightSelectionStage(0)
      return false
    } else {
      setFlightSelectionStage(0)
      setTextFilter('')
      setSortParameter('')
      setFlightSelectionStage(0)
      setOutboundFlight(null)
      setShowMissingFlightNotice(false)
      setMaxStopsFilter(defaultMaxStops)
      setMaxPrice(0)
      setLayoverMinimum(defaultLayoverMinimum)
      setLayoverMaximum(defaultLayoverMaximum)
      setAirlines([])
      setConnectingAirports([])
      setOutboundDepartureFrom(0)
      setOutboundDepartureTo(24)
      setOutboundArrivalFrom(0)
      setOutboundArrivalTo(24)
      setReturnDepartureFrom(0)
      setReturnDepartureTo(24)
      setReturnArrivalFrom(0)
      setReturnArrivalTo(24)
      setFlightResults([])
      return false
    }
  }

  const keyActivator = useCallback(
    (event) => {
      setDebugDetectorString(debugDetectorString + event.key)
      if ((debugDetectorString + event.key).indexOf('injector') >= 0) {
        setDebugMode(true)
        setDebugDetectorString('')
        document.querySelectorAll('.debug-mode').forEach((e) => {
          e.style.display = 'flex'
        })
      }
    },
    [debugDetectorString]
  )

  const enrichSupportChannel = (email, id) => {
    try {
      //console.log(`Refreshing support channel meta-data [${id}]`)
      window.$crisp.push(['set', 'user:email', [email]])
      window.$crisp.push(['set', 'session:data', ['employee_id', id]])
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (userDetails) {
      if (userDetails.email) {
        if (userDetails.id) {
          enrichSupportChannel(userDetails.email, userDetails.id)
        }
      }
    }
  }, [userDetails])

  useEffect(() => {
    document.addEventListener('keydown', keyActivator, false)
    return () => {
      document.removeEventListener('keydown', keyActivator, false)
    }
  }, [keyActivator])

  useEffect(() => {
    window.addEventListener('popstate', backButtonDetected)
    return () => {
      window.removeEventListener('popstate', backButtonDetected)
    }
  })

  // This has become a bit of a science, but let's try to find the correct page title and subtitle
  var pageTitle = 'Log Flights'
  var subTitle = 'Search for the flight you intend to book'
  if (companyDetails) {
    if (
      companyDetails.feature_cost_center &&
      companyDetails.feature_past_flights &&
      !companyDetails.feature_carbon_pricing
    ) {
      subTitle = ''
    }
    if (
      companyDetails.feature_cost_center &&
      companyDetails.feature_carbon_pricing &&
      !companyDetails.feature_past_flights
    ) {
      subTitle = ''
    }
    if (
      companyDetails.feature_carbon_pricing &&
      companyDetails.feature_past_flights &&
      !companyDetails.feature_cost_center
    ) {
      subTitle = ''
    }
    if (
      companyDetails.feature_carbon_pricing &&
      companyDetails.feature_past_flights &&
      companyDetails.feature_cost_center
    ) {
      subTitle = ''
    }
  }

  // Show UZH Intro?
  var showIntroPanel = false
  if (
    searchId === '' &&
    userDetails &&
    (userDetails.company_id === 'e885de94-67ae-4a1e-8445-442f17e30bce' ||
      userDetails.company_id === '63c9c0a3-01ec-4d74-9dcf-02a6792813f5')
  ) {
    showIntroPanel = true
  }

  // Is there at least one flight that is out of policy?
  var policyViolations = []
  // Only run this if the company supports this...
  if (companyDetails.feature_policies) {
    flightResults.forEach((f) => {
      policyViolations = policyViolations.concat(getPolicyViolations({ trip: f }))
    })
  }

  // Is the next step already logging the flight? If so, we may need to show a warning!
  var showConfirmationPrompt = false
  if (props.alreadyBooked) {
    // Already booked
    if (searchDetails.route_direction === 'oneway') {
      if (flightSelectionStage === 0) {
        showConfirmationPrompt = true // Oneway, clicking this outbound flight will already log it
      }
    }
    if (searchDetails.route_direction === 'roundtrip') {
      if (flightSelectionStage === 1) {
        showConfirmationPrompt = true // Roundtrip, clicking the return flight will already log it
      }
    }
  }

  // Can we show identify flights?
  var allowIdentifyFlights = false
  if (companyDetails && companyDetails.feature_past_flights) {
    allowIdentifyFlights = true
  }

  return (
    <div>
      {useNewEngine && (
        <div
          style={{
            fontSize: 12,
            padding: 7,
            borderRadius: 5,
            backgroundColor: searchApiEndpoint === 'travelport_development' ? '#f9ffc7' : '#ffcccc',
            textAlign: 'center'
          }}>
          Using TravelPort:{' '}
          {searchApiEndpoint === 'travelport_development' ? (
            'TESTING ENDPOINT'
          ) : (
            <strong>PRODUCTION</strong>
          )}{' '}
          (
          <a
            href={`/employee/flights/search/v2/${
              searchApiEndpoint === 'travelport_development' ? 'production' : ''
            }`}>
            Switch
          </a>
          ){' '}
        </div>
      )}

      <PageTitle title={pageTitle}>{subTitle}</PageTitle>

      <LogTripTabs />

      <FlightSearchPanel
        pastFlight={props.alreadyBooked}
        details={searchDetails}
        defaultReturnDate={Tools.getShortFormatDate(defaultDates.initialReturnDate)}
        onUpdateSearch={(newDetails) => {
          updateSearchDetails(newDetails)
        }}
        onSearchEdited={() => {
          setFlightResults([])
          setFlightSelectionStage(0)
        }}
        onSearch={launchSearch}
        isLoading={isLoading}
        showSearchButton={flightResults.length === 0}>
        {debugMode && (
          <div
            style={{
              padding: 20,
              backgroundColor: mock_flight_id.length === 36 ? '#00ff00' : '#ffaaaa',
              marginTop: 100
            }}>
            <input
              placeholder="Enter Mock Flight ID"
              type="text"
              style={{ padding: 10, width: 300 }}
              value={mock_flight_id}
              onChange={(e) => {
                setMockFlightId(e.target.value.trim() || '')
              }}
            />{' '}
            <span style={{ fontSize: 12, marginLeft: 10 }}>
              Paste ID, start a regular search and if the parameters are valid, the flight will be
              injected
            </span>
          </div>
        )}

        {flightResults.length > 0 && (
          <div id="result-filter-container" style={{ display: 'flex', gap: 10, flexWrap: 'wrap' }}>
            {flightResults && flightResults.length > 0 && (
              <TextInput
                style={{ width: 70, fontSize: 12 }}
                debounce={true}
                type={'text'}
                value={textFilter}
                placeholder="Flight #"
                onWhite={true}
                onChange={(d) => {
                  setTextFilter(d)
                }}
              />
            )}

            {Search.hasLayoverFlights(flightResults) && (
              <FilterStopovers
                defaultValue={defaultMaxStops} // Any number of stops = 5
                value={maxStops}
                onChange={(maxStops) => {
                  setMaxStopsFilter(parseInt(maxStops, 10))
                }}
                onCancel={() => {
                  setMaxStopsFilter(defaultMaxStops)
                }}
              />
            )}

            {flightResults.length > 0 && (
              <FilterPrice
                defaultValue={Search.getMaxPrice(flightResults) || 100000}
                value={maxPrice || Search.getMaxPrice(flightResults) || 100000}
                onChange={(newMaxPrice) => {
                  setMaxPrice(parseFloat(newMaxPrice))
                }}
                onCancel={() => {
                  setMaxPrice(Search.getMaxPrice(flightResults))
                }}
              />
            )}

            {Search.hasLayoverFlights(flightResults) && (
              <FilterLayover
                defaultValue={[defaultLayoverMinimum, defaultLayoverMaximum]}
                value={[layoverMinimum, layoverMaximum]}
                onChange={(layoverDuration) => {
                  setLayoverMinimum(parseInt(layoverDuration[0], 10))
                  setLayoverMaximum(parseInt(layoverDuration[1], 10))
                }}
                onCancel={() => {
                  setLayoverMinimum(defaultLayoverMinimum)
                  setLayoverMaximum(defaultLayoverMaximum)
                }}
              />
            )}

            <FilterAirlines
              defaultValue={Search.getAvailableAirlines(flightResults)}
              value={selectedAirlines}
              availableAirlines={Search.getAvailableAirlines(flightResults)}
              onChange={(selectedAirlines) => {
                setAirlines([...selectedAirlines])
              }}
              onCancel={() => {
                setAirlines(Search.getAvailableAirlines(flightResults))
              }}
            />

            {Search.getAvailableConnectingAirports(flightResults).length > 0 && (
              <FilterConnectingAirport
                defaultValue={Search.getAvailableConnectingAirports(flightResults)}
                value={connectingAirports}
                availableAirports={Search.getAvailableConnectingAirports(flightResults)}
                onChange={(connectingAirports) => {
                  setConnectingAirports([...connectingAirports])
                }}
                onCancel={() => {
                  setConnectingAirports(Search.getAvailableConnectingAirports(flightResults))
                }}
              />
            )}

            {flightResults.length > 0 && (
              <FilterTimes
                outboundDepartureFrom={filterTimeOutboundDepartureFrom}
                outboundDepartureTo={filterTimeOutboundDepartureTo}
                outboundArrivalFrom={filterTimeOutboundArrivalFrom}
                outboundArrivalTo={filterTimeOutboundArrivalTo}
                returnDepartureFrom={filterTimeReturnDepartureFrom}
                returnDepartureTo={filterTimeReturnDepartureTo}
                returnArrivalFrom={filterTimeReturnArrivalFrom}
                returnArrivalTo={filterTimeReturnArrivalTo}
                onOutboundDepartureFromChange={(t) => setOutboundDepartureFrom(t)}
                onOutboundDepartureToChange={(t) => setOutboundDepartureTo(t)}
                onOutboundArrivalFromChange={(t) => setOutboundArrivalFrom(t)}
                onOutboundArrivalToChange={(t) => setOutboundArrivalTo(t)}
                onReturnDepartureFromChange={(t) => setReturnDepartureFrom(t)}
                onReturnDepartureToChange={(t) => setReturnDepartureTo(t)}
                onReturnArrivalFromChange={(t) => setReturnArrivalFrom(t)}
                onReturnArrivalToChange={(t) => setReturnArrivalTo(t)}
                onCancel={() => {
                  setOutboundDepartureFrom(0)
                  setOutboundDepartureTo(24)
                  setOutboundArrivalFrom(0)
                  setOutboundArrivalTo(24)
                  setReturnDepartureFrom(0)
                  setReturnDepartureTo(24)
                  setReturnArrivalFrom(0)
                  setReturnArrivalTo(24)
                }}
              />
            )}
          </div>
        )}
      </FlightSearchPanel>

      <div style={{ marginTop: 100, maxWidth: 860, display: queryFailed ? 'block' : 'none' }}>
        <ErrorText show={queryFailed}>
          There was an issue trying to query the flight engine. Please try again.
        </ErrorText>
      </div>

      {noResultsFound && (
        <div style={{ padding: 20, marginTop: 100, textAlign: 'center' }}>
          Sorry, there are no available flights for this cabin class or time-frame.
        </div>
      )}

      {policyViolations.length > 0 && (
        <div
          style={{
            padding: 20,
            marginTop: 40,
            color: '#aa0000',
            borderRadius: 15,
            border: '1px solid #aa000055',
            display: 'inline-block'
          }}>
          Flights on this route appear to be against your travel policy.
        </div>
      )}

      {!isLoading && (
        <>
          {flightResults.length > 0 && flightSelectionStage === 0 && (
            <>
              <div
                className="title-departing-flight"
                style={{ marginTop: 50, fontSize: 22, fontWeight: 'bold' }}>
                Departing flights
              </div>
              <div style={{ marginTop: 10, color: '#555' }}>
                {props.alreadyBooked &&
                  'Identify the flight you took and assign a cost center in the next step.'}
                {!props.alreadyBooked &&
                  'Identify the flight you would normally take and view Climate Options in the next step.'}
              </div>
            </>
          )}

          {flightResults.length > 0 && flightSelectionStage === 1 && (
            <>
              <div
                className="title-return-flight"
                style={{ marginTop: 50, fontSize: 22, fontWeight: 'bold' }}>
                Return flights
              </div>
              <div
                onClick={() => {
                  setOutboundFlight(null)
                  setFlightSelectionStage(0)
                  setFlightIndexWaitingForConfirmation(-1)
                }}
                style={{
                  cursor: 'pointer',
                  marginTop: 10,
                  color: '#999'
                }}>
                ←{' '}
                <span style={{ textDecoration: 'underline', color: '#999' }}>
                  Pick a different departure flight
                </span>
              </div>
            </>
          )}

          {flightResults.length > 0 && (
            <div
              style={{
                textAlign: 'right',
                marginTop: -30,
                maxWidth: 890,
                cursor: 'pointer',
                color: '#0063F2'
              }}>
              <select
                style={{ fontSize: 14, width: 'auto' }}
                value={sortParameter}
                onChange={(newSortParameter) => {
                  setSortParameter(newSortParameter.target.value)
                }}>
                <option value="">Sort by ⇅</option>
                <option value="price">Price</option>
                <option value="departure">Departure time</option>
                <option value="arrival">Arrival time</option>
                <option value="duration">Duration</option>
              </select>
            </div>
          )}

          {showMissingFlightNotice && props.alreadyBooked && allowIdentifyFlights && (
            <MissingFlight
              searchDetails={searchDetails}
              onLaunch={() => {
                setShowManualTripModal(true)
              }}
            />
          )}

          {flightResults.length > 0 && (
            <div style={{ marginTop: 50 }}>
              {/* ============ OUTBOUND FLIGHT ====================================================== */}
              {flightSelectionStage === 0 &&
                qualifyingFlights.map((segment, i) => {
                  return (
                    <FlightResultRow
                      confirmationPrompt={
                        showConfirmationPrompt && flightIndexWaitingForConfirmation === i
                      }
                      pastFlight={props.alreadyBooked}
                      key={`flight_outbound_${i}`}
                      rowId={`outbound_${i}`} // Needed for react tooltip, annoyingly
                      flight={segment}
                      onSelected={() => {
                        if (
                          (showConfirmationPrompt && flightIndexWaitingForConfirmation === i) ||
                          !showConfirmationPrompt
                        ) {
                          setOutboundFlight(segment)
                          // We need to artificially delay the next step, otherwise
                          // the interface changes so fast (yup) that the user may
                          // not realise things have changed and updated!
                          setLoading(true)
                          setTimeout(() => {
                            setLoading(false)
                            if (searchDetails.route_direction === 'oneway') {
                              confirmSelection(segment, null, searchId)
                            } else {
                              setFlightSelectionStage(1)
                            }
                          }, 400)
                        } else {
                          setFlightIndexWaitingForConfirmation(i)
                        }
                      }}
                    />
                  )
                })}
              {/* ============ RETURN FLIGHT ====================================================== */}
              {flightSelectionStage === 1 &&
                qualifyingFlights.map((segment, i) => {
                  return (
                    <FlightResultRow
                      confirmationPrompt={
                        showConfirmationPrompt && flightIndexWaitingForConfirmation === i
                      }
                      pastFlight={props.alreadyBooked}
                      key={`flight_return_${i}`}
                      rowId={`return_${i}`} // Needed for react tooltip, annoyingly
                      flight={segment}
                      onSelected={() => {
                        if (showConfirmationPrompt) {
                          // A click here only proceeds, once we have confirmed it.
                          // The first click merely selects this flight result row
                          if (flightIndexWaitingForConfirmation === i) {
                            confirmSelection(outboundFlight, segment, searchId)
                          } else {
                            setFlightIndexWaitingForConfirmation(i)
                          }
                        } else {
                          confirmSelection(outboundFlight, segment, searchId)
                        }
                      }}
                    />
                  )
                })}
            </div>
          )}
        </>
      )}

      {isLoading && (
        <div style={{ paddingTop: 100 }}>
          <Loading />
        </div>
      )}

      {showIntroPanel && <UZHIntro />}

      <Modal
        visible={showManualTripModal}
        onClose={() => {
          setShowManualTripModal(false)
        }}>
        <ManualTripPanel
          searchId={searchId}
          searchDetails={searchDetails}
          alreadyBooked={props.alreadyBooked}
        />
      </Modal>

      {showMissingFlightNotice && !props.alreadyBooked && allowIdentifyFlights && (
        <MissingFlight
          searchDetails={searchDetails}
          onLaunch={() => {
            setShowManualTripModal(true)
          }}
        />
      )}
    </div>
  )
}
