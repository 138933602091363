export const Airlines = {
  alliances: [
    {
      name: 'Star Alliance',
      id: 0,
      members: [
        {
          airline: 'Aegean Airlines',
          iata: 'A3'
        },
        {
          airline: 'Olympic Air',
          iata: 'OA'
        },
        {
          airline: 'Air Canada',
          iata: 'AC'
        },
        {
          airline: 'Air Canada Rouge',
          iata: 'RV'
        },
        {
          airline: 'Air China',
          iata: 'CA'
        },
        {
          airline: 'Air India',
          iata: 'AI'
        },
        {
          airline: 'Air New Zealand',
          iata: 'NZ'
        },
        {
          airline: 'ANA All Nippon Airways',
          iata: 'NH'
        },
        {
          airline: 'ANA Wings',
          iata: 'EH'
        },
        {
          airline: 'Asiana Airlines',
          iata: 'OZ'
        },
        {
          airline: 'Austrian Airlines',
          iata: 'OS'
        },
        {
          airline: 'Avianca',
          iata: 'AV'
        },
        {
          airline: 'Brussels Airlines',
          iata: 'SN'
        },
        {
          airline: 'Copa Airlines',
          iata: 'CM'
        },
        {
          airline: 'Croatia Airlines',
          iata: 'OU'
        },
        {
          airline: 'Egyptair',
          iata: 'MS'
        },
        {
          airline: 'Ethiopian Airlines',
          iata: 'ET'
        },
        {
          airline: 'EVA Air',
          iata: 'BR'
        },
        {
          airline: 'LOT Polish Airlines',
          iata: 'LO'
        },
        {
          airline: 'Lufthansa',
          iata: 'LH'
        },
        {
          airline: 'Lufthansa CityLine',
          iata: 'CL'
        },
        {
          airline: 'Scandinavian Airlines',
          iata: 'SK'
        },
        {
          airline: 'Shenzhen Airlines',
          iata: 'ZH'
        },
        {
          airline: 'Singapore Airlines',
          iata: 'SQ'
        },
        {
          airline: 'South African Airways',
          iata: 'SA'
        },
        {
          airline: 'Swiss International Air Lines',
          iata: 'LX'
        },
        {
          airline: 'TAP Air Portugal / Express',
          iata: 'TP'
        },
        {
          airline: 'Thai Airways International',
          iata: 'TG'
        },
        {
          airline: 'Thai Smile',
          iata: 'WE'
        },
        {
          airline: 'Turkish Airlines',
          iata: 'TK'
        },
        {
          airline: 'United Airlines',
          iata: 'UA'
        },
        {
          airline: 'Uni Air',
          iata: 'B7'
        },
        {
          airline: 'Air Dolomiti',
          iata: 'EN'
        },
        {
          airline: 'Copa Airlines Colombia',
          iata: 'P5'
        },
        {
          airline: 'Avianca El Salvador',
          iata: 'TA'
        },
        {
          airline: 'Edelweiss Air',
          iata: 'WK'
        },
      ]
    },
    {
      name: 'Oneworld',
      id: 1,
      members: [
        {
          airline: 'Alaska Airlines',
          iata: 'AS'
        },
        {
          airline: 'SkyWest Airlines',
          iata: 'OO'
        },
        {
          airline: 'American Airlines',
          iata: 'AA'
        },
        {
          airline: 'British Airways',
          iata: 'BA'
        },
        {
          airline: 'Sun-Air',
          iata: 'EZ'
        },
        {
          airline: 'Cathay Pacific',
          iata: 'CX'
        },
        {
          airline: 'Finnair',
          iata: 'AY'
        },
        {
          airline: 'Iberia',
          iata: 'IB'
        },
        {
          airline: 'Japan Airlines',
          iata: 'JL'
        },
        {
          airline: 'Malaysia Airlines',
          iata: 'MH'
        },
        {
          airline: 'Qantas',
          iata: 'QF'
        },
        {
          airline: 'Qatar Airways',
          iata: 'QR'
        },
        {
          airline: 'Royal Air Maroc',
          iata: 'AT'
        },
        {
          airline: 'Royal Jordanian',
          iata: 'RJ'
        },
        {
          airline: 'SriLankan Airlines',
          iata: 'UL'
        }
      ]
    },
    {
      name: 'SkyTeam',
      id: 2,
      members: [
        {
          airline: 'Aerolíneas Argentinas',
          iata: 'AR'
        },
        {
          airline: 'Aeroméxico',
          iata: 'AM'
        },
        {
          airline: 'Air Europa',
          iata: 'UX'
        },
        {
          airline: 'Air France',
          iata: 'AF'
        },
        {
          airline: 'China Airlines',
          iata: 'CI'
        },
        {
          airline: 'China Eastern Airlines',
          iata: 'MU'
        },
        {
          airline: 'Czech Airlines',
          iata: 'OK'
        },
        {
          airline: 'Delta',
          iata: 'DL'
        },
        {
          airline: 'Garuda Indonesia',
          iata: 'GA'
        },
        {
          airline: 'ITA Airways',
          iata: 'AZ'
        },
        {
          airline: 'Japan Transocean Air',
          iata: 'NU',
        },
        {
          airline: 'Kenya Airways',
          iata: 'KQ'
        },
        {
          airline: 'KLM',
          iata: 'KL'
        },
        {
          airline: 'Korean Air',
          iata: 'KE'
        },
        {
          airline: 'Middle East Airlines',
          iata: 'ME'
        },
        {
          airline: 'Saudia',
          iata: 'SV'
        },
        {
          airline: 'TAROM',
          iata: 'RO'
        },
        {
          airline: 'Vietnam Airlines',
          iata: 'VN'
        },
        {
          airline: 'Virgin Atlantic',
          iata: 'VS',
        },
        {
          airline: 'XiamenAir',
          iata: 'MF'
        }
      ]
    },
    {
      name: 'Low-Cost',
      id: 3,
      members: [
        { airline: 'Aer Lingus', iata: 'EI' },
        { airline: 'Air Baltic', iata: 'BT' },
        { airline: 'AirTran Airways', iata: 'FL' },
        { airline: 'easyJet', iata: 'U2' },
        { airline: 'Germanwings', iata: '4U' },
        { airline: 'Eurowings', iata: 'EW' },
        { airline: 'GOL Airlines (Gol Linhas Aéreas)', iata: 'G3' },
        { airline: 'Icelandair', iata: 'FI' },
        { airline: 'JetBlue Airways', iata: 'B6' },
        { airline: 'Jetstar Airways', iata: 'JQ' },
        { airline: 'Laudamotion', iata: 'OE' },
        { airline: 'Lion Air', iata: 'JT' },
        { airline: 'Norwegian Air Shuttle', iata: 'DY' },
        { airline: 'Ryanair', iata: 'FR' },
        { airline: 'Ryanair UK', iata: 'RK' },
        { airline: 'Southwest Airlines', iata: 'WN' },
        { airline: 'Vueling', iata: 'VY' },
        { airline: 'Westjet', iata: 'WS' },
        { airline: 'Wizz Air', iata: 'W6' },
        { airline: 'Wizz Air UK', iata: 'W9' }
      ]
    },
    {
      name: 'Other',
      id: 4,
      members: [
        { airline: 'Etihad Airways', iata: 'EY' },
        {
          iata: "2D",
          airline: "Eastern Airlines"
        },
        {
          iata: "2I",
          airline: "Star Perú"
        },
        {
          iata: "2J",
          airline: "Air Burkina"
        },
        {
          iata: "2L",
          airline: "Helvetic Airways"
        },
        {
          iata: "2S",
          airline: "Southwind"
        },
        {
          iata: "2Z",
          airline: "Passaredo Linhas Aéreas"
        },
        {
          iata: "3F",
          airline: "FLYONE ARMENIA"
        },
        {
          iata: "3H",
          airline: "Air Inuit"
        },
        {
          iata: "3K",
          airline: "Jetstar Asia Airways"
        },
        {
          iata: "3L",
          airline: "Air Arabia Abu Dhabi"
        },
        {
          iata: "3M",
          airline: "Silver Airways"
        },
        {
          iata: "3O",
          airline: "Air Arabia Maroc"
        },
        {
          iata: "3S",
          airline: "Air Antilles Express"
        },
        {
          iata: "3T",
          airline: "Tarco Airlines"
        },
        {
          iata: "3U",
          airline: "Sichuan Airlines"
        },
        {
          iata: "3Z",
          airline: "Smartwings Poland"
        },
        {
          iata: "4G",
          airline: "Gazpromavia"
        },
        {
          iata: "4J",
          airline: "United Caribbean Airlines"
        },
        {
          iata: "4N",
          airline: "Air North"
        },
        {
          iata: "4Y",
          airline: "Eurowings Discover"
        },
        {
          iata: "5F",
          airline: "Fly One"
        },
        {
          iata: "5J",
          airline: "Cebu Pacific"
        },
        {
          iata: "5N",
          airline: "SmartAvia"
        },
        {
          iata: "5O",
          airline: "ASL AIRLINES FRANCE"
        },
        {
          iata: "5P",
          airline: "PANORAMA AIRWAYS"
        },
        {
          iata: "5R",
          airline: "RUTACA Airlines"
        },
        {
          iata: "5T",
          airline: "Canadian North"
        },
        {
          iata: "5U",
          airline: "Transportes Aéreos Guatemaltecos"
        },
        {
          iata: "5W",
          airline: "Wizz Air Abu Dhabi"
        },
        {
          iata: "5Z",
          airline: "CemAir"
        },
        {
          iata: "6E",
          airline: "IndiGo Airlines"
        },
        {
          iata: "6H",
          airline: "Israir"
        },
        {
          iata: "6I",
          airline: "Air Alsie"
        },
        {
          iata: "6J",
          airline: "Solaseed Air"
        },
        {
          iata: "7C",
          airline: "Jeju Air"
        },
        {
          iata: "7G",
          airline: "StarFlyer"
        },
        {
          iata: "7H",
          airline: "Ravn Alaska"
        },
        {
          iata: "7P",
          airline: "Air Panama"
        },
        {
          iata: "7R",
          airline: "RusLine"
        },
        {
          iata: "7Y",
          airline: "Mann Yadanarpon Airlines"
        },
        {
          iata: "8B",
          airline: "TransNusa Air Services"
        },
        {
          iata: "8F",
          airline: "STP Airways"
        },
        {
          iata: "8G",
          airline: "Mid Africa Aviation"
        },
        {
          iata: "8L",
          airline: "Lucky Air"
        },
        {
          iata: "8M",
          airline: "Myanmar Airways International"
        },
        {
          iata: "8N",
          airline: "Regional Air Services"
        },
        {
          iata: "8P",
          airline: "Pacific Coastal Airlines"
        },
        {
          iata: "8T",
          airline: "Air Tindi"
        },
        {
          iata: "8U",
          airline: "Afriqiyah Airways"
        },
        {
          iata: "9C",
          airline: "Spring Airlines"
        },
        {
          iata: "9H",
          airline: "Air Changan"
        },
        {
          iata: "9J",
          airline: "Dana Airlines"
        },
        {
          iata: "9M",
          airline: "Central Mountain Air"
        },
        {
          iata: "9N",
          airline: "Tropic Air"
        },
        {
          iata: "9P",
          airline: "Air Arabia Jordan"
        },
        {
          iata: "9R",
          airline: "SATENA"
        },
        {
          iata: "9U",
          airline: "Air Moldova"
        },
        {
          iata: "9V",
          airline: "Avior Airlines"
        },
        {
          iata: "9X",
          airline: "Southern Airways Express"
        },
        {
          iata: "A2",
          airline: "Astra Airlines"
        },
        {
          iata: "A4",
          airline: "Aerocon"
        },
        {
          iata: "A9",
          airline: "Georgian Airways"
        },
        {
          iata: "AB",
          airline: "Bonza Aviation"
        },
        {
          iata: "AD",
          airline: "Azul"
        },
        {
          iata: "AE",
          airline: "Mandarin Airlines"
        },
        {
          iata: "AG",
          airline: "Aruba Airlines"
        },
        {
          iata: "AH",
          airline: "Air Algérie"
        },
        {
          iata: "AK",
          airline: "Air Asia"
        },
        {
          iata: "AQ",
          airline: "9 Air"
        },
        {
          iata: "AU",
          airline: "Jetlines"
        },
        {
          iata: "AW",
          airline: "Africa World Airlines (AWA)"
        },
        {
          iata: "B0",
          airline: "La Compagnie"
        },
        {
          iata: "B2",
          airline: "Belavia"
        },
        {
          iata: "BC",
          airline: "Skymark Airlines"
        },
        {
          iata: "BG",
          airline: "Biman Bangladesh Airlines"
        },
        {
          iata: "BI",
          airline: "Royal Brunei Airlines"
        },
        {
          iata: "BJ",
          airline: "Nouvelair"
        },
        {
          iata: "BK",
          airline: "Okay Airways"
        },
        {
          iata: "BL",
          airline: "Pacific Airlines (JetstarPacific)"
        },
        {
          iata: "BP",
          airline: "Air Botswana"
        },
        {
          iata: "BS",
          airline: "US-Bangla Airlines"
        },
        {
          iata: "BU",
          airline: "Compagnie Africaine d'Aviation"
        },
        {
          iata: "BW",
          airline: "Caribbean Airlines"
        },
        {
          iata: "BZ",
          airline: "Blue Bird Airways"
        },
        {
          iata: "C3",
          airline: "Trade Air"
        },
        {
          iata: "C8",
          airline: "Cargolux Italia"
        },
        {
          iata: "CC",
          airline: "Air Atlanta Icelandic"
        },
        {
          iata: "CE",
          airline: "Chalair Aviation"
        },
        {
          iata: "CG",
          airline: "Airlines PNG"
        },
        {
          iata: "CN",
          airline: "Grand China Air"
        },
        {
          iata: "CQ",
          airline: "Coastal Aviation"
        },
        {
          iata: "CU",
          airline: "Cubana"
        },
        {
          iata: "CY",
          airline: "Charlie Airlines Limited dba Cyprus Airways"
        },
        {
          iata: "CZ",
          airline: "China Southern Airlines"
        },
        {
          iata: "D2",
          airline: "Severstal Aircompany"
        },
        {
          iata: "D3",
          airline: "Daallo Airlines"
        },
        {
          iata: "D4",
          airline: "Dart Airlines"
        },
        {
          iata: "D7",
          airline: "AirAsia X"
        },
        {
          iata: "D8",
          airline: "Norwegian Air International"
        },
        {
          iata: "DD",
          airline: "Nok Air"
        },
        {
          iata: "DI",
          airline: "Marabu"
        },
        {
          iata: "DJ",
          airline: "AirAsia Japan"
        },
        {
          iata: "DK",
          airline: "Sunclass Airlines"
        },
        {
          iata: "DR",
          airline: "Ruili Airlines"
        },
        {
          iata: "DT",
          airline: "TAAG Angola Airways"
        },
        {
          iata: "DV",
          airline: "Aircompany SCAT"
        },
        {
          iata: "DX",
          airline: "Danish Air Transport"
        },
        {
          iata: "DZ",
          airline: "Donghai Airlines"
        },
        {
          iata: "E5",
          airline: "Air Arabia Egypt"
        },
        {
          iata: "E9",
          airline: "Evelop Airlines"
        },
        {
          iata: "EB",
          airline: "Wamos Air"
        },
        {
          iata: "EC",
          airline: "easyJet Europe"
        },
        {
          iata: "EK",
          airline: "Emirates"
        },
        {
          iata: "EP",
          airline: "Iran Aseman Airlines"
        },
        {
          iata: "EU",
          airline: "Chengdu Airlines"
        },
        {
          iata: "F2",
          airline: "Safarilink Aviation"
        },
        {
          iata: "F3",
          airline: "Flyadeal"
        },
        {
          iata: "F8",
          airline: "Flair Airlines"
        },
        {
          iata: "F9",
          airline: "Frontier Airlines"
        },
        {
          iata: "FA",
          airline: "FlySafair Safair"
        },
        {
          iata: "FB",
          airline: "Bulgaria Air"
        },
        {
          iata: "FC",
          airline: "Viva Colombia"
        },
        {
          iata: "FD",
          airline: "Thai AirAsia"
        },
        {
          iata: "FG",
          airline: "Ariana Afghan Airlines"
        },
        {
          iata: "FH",
          airline: "Freebird Airlines"
        },
        {
          iata: "FJ",
          airline: "Fiji Airways"
        },
        {
          iata: "FM",
          airline: "Shanghai Airlines"
        },
        {
          iata: "FN",
          airline: "Fastjet Airlines"
        },
        {
          iata: "FT",
          airline: "Fly Egypt"
        },
        {
          iata: "FW",
          airline: "Ibex Airlines"
        },
        {
          iata: "FY",
          airline: "Firefly"
        },
        {
          iata: "FZ",
          airline: "Fly Dubai"
        },
        {
          iata: "G4",
          airline: "Allegiant Air"
        },
        {
          iata: "G5",
          airline: "China Express Airlines"
        },
        {
          iata: "G6",
          airline: "FlyArna"
        },
        {
          iata: "G8",
          airline: "Go First (GoAir)"
        },
        {
          iata: "G9",
          airline: "Air Arabia"
        },
        {
          iata: "GF",
          airline: "Gulf Air"
        },
        {
          iata: "GJ",
          airline: "Zhejiang Loong Airlines"
        },
        {
          iata: "GK",
          airline: "Jetstar Japan"
        },
        {
          iata: "GL",
          airline: "Air Greenland"
        },
        {
          iata: "GM",
          airline: "Tri-MG Intra Asia Airlines"
        },
        {
          iata: "GQ",
          airline: "Sky Express SA"
        },
        {
          iata: "GR",
          airline: "Aurigny Air Services"
        },
        {
          iata: "GS",
          airline: "Tianjin Airlines"
        },
        {
          iata: "GX",
          airline: "GX Airlines"
        },
        {
          iata: "GY",
          airline: "Colorful Guizhou Airlines"
        },
        {
          iata: "GZ",
          airline: "Air Rarotonga"
        },
        {
          iata: "H2",
          airline: "Sky Airline"
        },
        {
          iata: "H7",
          airline: "HiSky Moldova"
        },
        {
          iata: "H9",
          airline: "Himalaya Airlines"
        },
        {
          iata: "HA",
          airline: "Hawaiian Airlines"
        },
        {
          iata: "HB",
          airline: "Greater Bay Airlines"
        },
        {
          iata: "HD",
          airline: "Air Do Airdo"
        },
        {
          iata: "HF",
          airline: "Air Côte d’Ivoire"
        },
        {
          iata: "HH",
          airline: "Taban Air"
        },
        {
          iata: "HK",
          airline: "Skippers Aviation"
        },
        {
          iata: "HM",
          airline: "Air Seychelles"
        },
        {
          iata: "HO",
          airline: "Juneyao Airlines"
        },
        {
          iata: "HP",
          airline: "Amapola Flyg"
        },
        {
          iata: "HU",
          airline: "Hainan Airlines"
        },
        {
          iata: "HV",
          airline: "Transavia Airlines"
        },
        {
          iata: "HX",
          airline: "Hong Kong Airlines"
        },
        {
          iata: "HY",
          airline: "Uzbekistan Airways"
        },
        {
          iata: "HZ",
          airline: "Aurora Airlines"
        },
        {
          iata: "I5",
          airline: "AirAsia India"
        },
        {
          iata: "I8",
          airline: "Izhavia"
        },
        {
          iata: "ID",
          airline: "Batik Air"
        },
        {
          iata: "IE",
          airline: "Solomon Airlines"
        },
        {
          iata: "IF",
          airline: "Fly Baghdad Airlines"
        },
        {
          iata: "IJ",
          airline: "Spring Airlines Japan"
        },
        {
          iata: "IL",
          airline: "Trigana Air Service"
        },
        {
          iata: "IN",
          airline: "NAM Air"
        },
        {
          iata: "IQ",
          airline: "Qazaq Air"
        },
        {
          iata: "IR",
          airline: "Iran Air"
        },
        {
          iata: "IT",
          airline: "Tigerair Taiwan"
        },
        {
          iata: "IV",
          airline: "VVB Aviation Malta"
        },
        {
          iata: "IW",
          airline: "Wings Abadi Airlines"
        },
        {
          iata: "IY",
          airline: "Yemenia"
        },
        {
          iata: "IZ",
          airline: "Arkia Israeli Airlines"
        },
        {
          iata: "J2",
          airline: "Azerbaijan Airlines"
        },
        {
          iata: "J4",
          airline: "Badr Airlines"
        },
        {
          iata: "J9",
          airline: "Jazeera Airways"
        },
        {
          iata: "JA",
          airline: "JetSmart"
        },
        {
          iata: "JD",
          airline: "Beijing Capital Airlines"
        },
        {
          iata: "JH",
          airline: "Fuji Dream Airlines"
        },
        {
          iata: "JI",
          airline: "Meraj Air"
        },
        {
          iata: "JR",
          airline: "Joy Air"
        },
        {
          iata: "JU",
          airline: "Air Serbia"
        },
        {
          iata: "JX",
          airline: "Starlux Airlines"
        },
        {
          iata: "JY",
          airline: "Inter Caribbean Airways"
        },
        {
          iata: "K6",
          airline: "Cambodia Angkor Air"
        },
        {
          iata: "K7",
          airline: "Air KBZ"
        },
        {
          iata: "KA",
          airline: "Cathay Dragon"
        },
        {
          iata: "KB",
          airline: "Drukair"
        },
        {
          iata: "KC",
          airline: "Air Astana"
        },
        {
          iata: "KI",
          airline: "KrasAvia"
        },
        {
          iata: "KM",
          airline: "Air Malta"
        },
        {
          iata: "KN",
          airline: "China United Airlines"
        },
        {
          iata: "KP",
          airline: "Asky Airlines"
        },
        {
          iata: "KS",
          airline: "Air Connect"
        },
        {
          iata: "KU",
          airline: "Kuwait Airways"
        },
        {
          iata: "KX",
          airline: "Cayman Airways"
        },
        {
          iata: "KY",
          airline: "Kunming Airlines"
        },
        {
          iata: "L5",
          airline: "Atlantique Air Assistance"
        },
        {
          iata: "L6",
          airline: "Mauritania Airlines International"
        },
        {
          iata: "L8",
          airline: "Afric Aviation"
        },
        {
          iata: "LA",
          airline: "LATAM Airlines"
        },
        {
          iata: "LF",
          airline: "Corporate Flight Management"
        },
        {
          iata: "LG",
          airline: "Luxair"
        },
        {
          iata: "LI",
          airline: "LIAT"
        },
        {
          iata: "LJ",
          airline: "Jin Air"
        },
        {
          iata: "LM",
          airline: "Loganair"
        },
        {
          iata: "LN",
          airline: "Libyan Airlines"
        },
        {
          iata: "LS",
          airline: "Jet2.com"
        },
        {
          iata: "LY",
          airline: "El Al"
        },
        {
          iata: "M0",
          airline: "Aero Mongolia"
        },
        {
          iata: "M2",
          airline: "MHS Aviation"
        },
        {
          iata: "MD",
          airline: "Madagascar Airlines"
        },
        {
          iata: "MI",
          airline: "Silk Air"
        },
        {
          iata: "MK",
          airline: "Air Mauritius"
        },
        {
          iata: "MM",
          airline: "Peach Aviation"
        },
        {
          iata: "MO",
          airline: "Calm Air"
        },
        {
          iata: "MR",
          airline: "Hunnu Air"
        },
        {
          iata: "MZ",
          airline: "Amakusa Airlines"
        },
        {
          iata: "N0",
          airline: "Norse Atlantic Airways"
        },
        {
          iata: "Z0",
          airline: "Norse Atlantic UK"
        },
        {
          iata: "N5",
          airline: "Nolinor Aviation"
        },
        {
          iata: "ND",
          airline: "FMI Air"
        },
        {
          iata: "NE",
          airline: "Nesma Airlines"
        },
        {
          iata: "NF",
          airline: "Air Vanuatu"
        },
        {
          iata: "NK",
          airline: "Spirit Airlines"
        },
        {
          iata: "NL",
          airline: "Shaheen Air International"
        },
        {
          iata: "NO",
          airline: "Neos"
        },
        {
          iata: "NP",
          airline: "Nile Air"
        },
        {
          iata: "NS",
          airline: "Hebei Airlines"
        },
        {
          iata: "NT",
          airline: "Binter Canarias"
        },
        {
          iata: "NX",
          airline: "Air Macau"
        },
        {
          iata: "OB",
          airline: "Boliviana de Aviación"
        },
        {
          iata: "OC",
          airline: "Oriental Air Bridge"
        },
        {
          iata: "OD",
          airline: "Batik Air Malaysia (Malindo Air)"
        },
        {
          iata: "OM",
          airline: "MIAT Mongolian Airlines"
        },
        {
          iata: "ON",
          airline: "Nauru Airlines"
        },
        {
          iata: "OR",
          airline: "TUI Airlines Nederland"
        },
        {
          iata: "P0",
          airline: "Proflight Zambia"
        },
        {
          iata: "P6",
          airline: "Privilege Style"
        },
        {
          iata: "PA",
          airline: "Airblue"
        },
        {
          iata: "PB",
          airline: "Provincial Airlines"
        },
        {
          iata: "PC",
          airline: "Pegasus Airlines"
        },
        {
          iata: "PD",
          airline: "Porter Airlines"
        },
        {
          iata: "PE",
          airline: "People’s"
        },
        {
          iata: "PF",
          airline: "Primera Air Scandinavia"
        },
        {
          iata: "PG",
          airline: "Bangkok Airways"
        },
        {
          iata: "PJ",
          airline: "Air St. Pierre"
        },
        {
          iata: "PK",
          airline: "Pakistan International Airlines"
        },
        {
          iata: "PM",
          airline: "Canary Fly"
        },
        {
          iata: "PN",
          airline: "China West Air"
        },
        {
          iata: "PR",
          airline: "Philippine Airlines"
        },
        {
          iata: "PU",
          airline: "Plus Ultra Líneas Aéreas"
        },
        {
          iata: "PW",
          airline: "Precision Air"
        },
        {
          iata: "PX",
          airline: "Air Niugini"
        },
        {
          iata: "PY",
          airline: "Surinam Airways"
        },
        {
          iata: "Q2",
          airline: "Maldivian"
        },
        {
          iata: "Q6",
          airline: "Volaris"
        },
        {
          iata: "Q8",
          airline: "Trans Air Congo"
        },
        {
          iata: "Q9",
          airline: "Minerva Airlines"
        },
        {
          iata: "QC",
          airline: "Camair-Co"
        },
        {
          iata: "QG",
          airline: "Citilink"
        },
        {
          iata: "QH",
          airline: "Bamboo Airways"
        },
        {
          iata: "QJ",
          airline: "Jet Airways"
        },
        {
          iata: "QL",
          airline: "LASER Airlines"
        },
        {
          iata: "QP",
          airline: "Akasa Air"
        },
        {
          iata: "QQ",
          airline: "Alliance Airlines"
        },
        {
          iata: "QS",
          airline: "Smart Wings"
        },
        {
          iata: "QV",
          airline: "Lao Airlines"
        },
        {
          iata: "QW",
          airline: "Qingdao Airlines"
        },
        {
          iata: "QZ",
          airline: "Indonesia AirAsia"
        },
        {
          iata: "R2",
          airline: "Orenburg Airlines"
        },
        {
          iata: "R3",
          airline: "Yakutia Airlines"
        },
        {
          iata: "R5",
          airline: "Jordan Aviation"
        },
        {
          iata: "RA",
          airline: "Nepal Airlines"
        },
        {
          iata: "RB",
          airline: "Syrian Air"
        },
        {
          iata: "RC",
          airline: "Atlantic Airways"
        },
        {
          iata: "RN",
          airline: "Eswatini Air"
        },
        {
          iata: "RQ",
          airline: "Kam Air"
        },
        {
          iata: "RT",
          airline: "Rainbow Airlines"
        },
        {
          iata: "RY",
          airline: "Jiangxi Air"
        },
        {
          iata: "S4",
          airline: "SATA International"
        },
        {
          iata: "S6",
          airline: "Sunrise Airways"
        },
        {
          iata: "S7",
          airline: "S7 Airlines"
        },
        {
          iata: "SB",
          airline: "Aircalin"
        },
        {
          iata: "SC",
          airline: "Shandong Airlines"
        },
        {
          iata: "SD",
          airline: "Sudan Airways"
        },
        {
          iata: "SF",
          airline: "Tassili Airlines"
        },
        {
          iata: "SG",
          airline: "SpiceJet"
        },
        {
          iata: "SJ",
          airline: "Sriwijaya Air"
        },
        {
          iata: "SL",
          airline: "Thai Lion Air"
        },
        {
          iata: "SM",
          airline: "Air Cairo"
        },
        {
          iata: "SP",
          airline: "SATA Air Açores"
        },
        {
          iata: "SS",
          airline: "Corsair International"
        },
        {
          iata: "SU",
          airline: "Aeroflot"
        },
        {
          iata: "SX",
          airline: "SkyWork Airlines"
        },
        {
          iata: "SY",
          airline: "Sun Country Airlines"
        },
        {
          iata: "SZ",
          airline: "Somon Air"
        },
        {
          iata: "T3",
          airline: "Eastern Airways"
        },
        {
          iata: "T5",
          airline: "Turkmenistan Airlines"
        },
        {
          iata: "T6",
          airline: "AirSWIFT"
        },
        {
          iata: "TB",
          airline: "Jetairfly"
        },
        {
          iata: "TC",
          airline: "Air Tanzania"
        },
        {
          iata: "TF",
          airline: "Malmö Aviation"
        },
        {
          iata: "TL",
          airline: "Airnorth"
        },
        {
          iata: "TM",
          airline: "Linhas Aéreas de Moçambique"
        },
        {
          iata: "TN",
          airline: "Air Tahiti Nui"
        },
        {
          iata: "TO",
          airline: "Transavia France"
        },
        {
          iata: "TR",
          airline: "Scoot"
        },
        {
          iata: "TS",
          airline: "Air Transat"
        },
        {
          iata: "TU",
          airline: "Tunisair"
        },
        {
          iata: "TV",
          airline: "Tibet Airlines"
        },
        {
          iata: "TW",
          airline: "T’way Air"
        },
        {
          iata: "TX",
          airline: "Air Caraïbes"
        },
        {
          iata: "TY",
          airline: "Air Calédonie"
        },
        {
          iata: "U4",
          airline: "Buddha Air"
        },
        {
          iata: "U6",
          airline: "Ural Airlines"
        },
        {
          iata: "U8",
          airline: "TUS Airways"
        },
        {
          iata: "UB",
          airline: "Myanmar National Airlines"
        },
        {
          iata: "UD",
          airline: "Hex’Air"
        },
        {
          iata: "UG",
          airline: "Tunisair Express"
        },
        {
          iata: "UK",
          airline: "Vistara"
        },
        {
          iata: "UM",
          airline: "Air Zimbabwe"
        },
        {
          iata: "UO",
          airline: "HK Express (Hong Kong Express Airways)"
        },
        {
          iata: "UP",
          airline: "Bahamasair"
        },
        {
          iata: "UQ",
          airline: "Urumqi Air"
        },
        {
          iata: "UT",
          airline: "UTair"
        },
        {
          iata: "UU",
          airline: "Air Austral"
        },
        {
          iata: "V0",
          airline: "Conviasa"
        },
        {
          iata: "V7",
          airline: "Volotea"
        },
        {
          iata: "VA",
          airline: "Virgin Australia"
        },
        {
          iata: "VB",
          airline: "Viva Aerobus"
        },
        {
          iata: "VC",
          airline: "Via Airlines"
        },
        {
          iata: "VJ",
          airline: "VietJet Air"
        },
        {
          iata: "VP",
          airline: "Villa Air"
        },
        {
          iata: "VR",
          airline: "TACV Cabo Verde Airlines"
        },
        {
          iata: "VT",
          airline: "Air Tahiti"
        },
        {
          iata: "VU",
          airline: "Veca Airlines"
        },
        {
          iata: "VZ",
          airline: "Thai Vietjet Air"
        },
        {
          iata: "W2",
          airline: "FlexFlight ApS"
        },
        {
          iata: "W3",
          airline: "Arik Air"
        },
        {
          iata: "W4",
          airline: "Wizz Air Malta"
        },
        {
          iata: "W5",
          airline: "Mahan Air"
        },
        {
          iata: "WA",
          airline: "KLM Cityhopper"
        },
        {
          iata: "WB",
          airline: "RwandAir"
        },
        {
          iata: "WF",
          airline: "Widerøe’s Flyveselskap"
        },
        {
          iata: "WG",
          airline: "Sunwing Airlines"
        },
        {
          iata: "WM",
          airline: "Winair"
        },
        {
          iata: "WP",
          airline: "Island Air"
        },
        {
          iata: "WY",
          airline: "Oman Air"
        },
        {
          iata: "WZ",
          airline: "Red Wings Airlines"
        },
        {
          iata: "X3",
          airline: "TUIfly"
        },
        {
          iata: "XC",
          airline: "Corendon Airlines"
        },
        {
          iata: "XJ",
          airline: "Thai AirAsia X"
        },
        {
          iata: "XK",
          airline: "Air Corsica"
        },
        {
          iata: "XQ",
          airline: "Sunexpress"
        },
        {
          iata: "XR",
          airline: "Corendon Airlines Europe"
        },
        {
          iata: "XU",
          airline: "African Express Airways"
        },
        {
          iata: "XY",
          airline: "Flynas"
        },
        {
          iata: "XZ",
          airline: "Aeroitalia"
        },
        {
          iata: "Y7",
          airline: "NordStar"
        },
        {
          iata: "Y8",
          airline: "Suparna Airlines"
        },
        {
          iata: "Y9",
          airline: "Kish Air"
        },
        {
          iata: "YB",
          airline: "Borajet"
        },
        {
          iata: "YC",
          airline: "Yamal Airlines"
        },
        {
          iata: "YK",
          airline: "Avia Traffic Company"
        },
        {
          iata: "YL",
          airline: "Libyan Wings"
        },
        {
          iata: "YN",
          airline: "Air Creebec"
        },
        {
          iata: "YP",
          airline: "Perimeter Aviation"
        },
        {
          iata: "YU",
          airline: "EuroAtlantic Airways"
        },
        {
          iata: "Z2",
          airline: "AirAsia Zest"
        },
        {
          iata: "Z8",
          airline: "Amas Bolivia"
        },
        {
          iata: "Z9",
          airline: "Bek Air"
        },
        {
          iata: "ZA",
          airline: "Sky Angkor Airlines"
        },
        {
          iata: "ZB",
          airline: "Air Albania"
        },
        {
          iata: "ZD",
          airline: "EWA Air"
        },
        {
          iata: "ZF",
          airline: "Azur Air"
        },
        {
          iata: "ZL",
          airline: "Regional Express Airlines"
        },
        {
          iata: "ZP",
          airline: "amas"
        },
        {
            iata: "2B",
            airline: "Albawings"
        },
        {
            iata: "DE",
            airline: "Condor"
        },
        {
            iata: "E4",
            airline: "Enter Air"
        },
        {
            iata: "BF",
            airline: "French bee"
        },
        {
            iata: "I2",
            airline: "Iberia Express"
        },
        {
            iata: "OG",
            airline: "PLAY"
        },
        {
            iata: "DP",
            airline: "Pobeda"
        },
        {
            iata: "FV",
            airline: "Rossiya Airlines"
        },
        {
            iata: "PQ",
            airline: "SkyUp Airlines"
        },
        {
            iata: "T7",
            airline: "Twin Jet"
        },
        {
            iata: "PS",
            airline: "Ukraine International Airlines"
        },
        {
            iata: "7O",
            airline: "Smartwings Hungary"
        },
        {
            iata: "IA",
            airline: "Iraqi Airways"
        },
        {
            iata: "P4",
            airline: "Air Peace"
        },
        {
            iata: "4Z",
            airline: "Airlink"
        },
        {
            iata: "8W",
            airline: "FlyAlways"
        },
        {
            iata: "JM",
            airline: "Jambojet"
        },
        {
            iata: "GE",
            airline: "LIFT"
        },
        {
            iata: "LP",
            airline: "LATAM Airlines Peru"
        },
        {
            iata: "DM",
            airline: "Arajet"
        },
        {
            iata: "MX",
            airline: "Breeze Airways"
        },
        {
            iata: "VE",
            airline: "EasyFly"
        },
        {
            iata: "BX",
            airline: "Air Busan"
        },
        {
            iata: "B3",
            airline: "Bhutan Airlines"
        },
        {
            iata: "KR",
            airline: "Cambodia Airways"
        },
        {
            iata: "C7",
            airline: "Cinnamon Air"
        },
        {
            iata: "LK",
            airline: "Lao Skyway"
        }      
      ]
    }
  ]
}
