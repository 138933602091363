import { Tools } from '../../../helpers/Tools'
import useStore from '../../../store'
import FilterPopover from './Components/FilterPopover'

export default function FilterConnectingAirport(props) {
  const globalDictionary = useStore((state) => state.dictionary)

  // The dictionary isn't ideal at the moment as it comes prefaced
  // with a cabin class. So we'll try to get a hold of it manually here...
  // Maybe this can become a bit more accessible later
  var localDictionary = Tools.extractDictionary(globalDictionary)

  const addAirport = (list, airport) => {
    return list.indexOf(airport) < 0 ? [...list, airport] : list
  }

  const removeAirport = (list, airport) => {
    return list.filter((a) => a !== airport)
  }

  const sortedEnrichedAirports = (airports) => {
    var list = []
    airports.forEach((airport) => {
      list.push({
        iata: airport,
        full_name: Tools.formatAirport(
          Tools.translateLocationWithDictionary(airport, localDictionary)
        )
      })
    })
    return [...list].sort((a, b) =>
      a.full_name.localeCompare(b.full_name, 'en', { sensitivity: 'base' })
    )
  }

  return (
    <FilterPopover
      title={'Connecting Airports'}
      selectedTitle={'Via Selected Airports'}
      active={
        props.value.sort().join(',') !== props.availableAirports.sort().join(',') &&
        props.value.length > 0
      }
      onCancel={() => {
        if (props.onCancel) props.onCancel()
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          fontSize: 14,
          userSelect: 'none'
        }}>
        <label>
          <input
            type="checkbox"
            checked={
              props.value.length > 0 && props.value.length === props.availableAirports.length
            }
            onChange={() => {
              if (props.value.length > 0 && props.value.length === props.availableAirports.length) {
                props.onChange([]) // Turn off all airports
              } else if (props.value.length > 0) {
                // Some are selected, so... default to turning all ON
                props.onChange(props.availableAirports)
              } else {
                // None are selected so select all
                props.onChange(props.availableAirports)
              }
            }}
          />{' '}
          <span style={{ fontWeight: 'bold' }}>All connecting airports</span>
        </label>
        <div style={{ borderBottom: '1px solid #ddd' }}></div>
        {sortedEnrichedAirports(props.availableAirports).map((airport, i) => {
          return (
            <label key={`connecting_airport_${i}`}>
              <input
                type="checkbox"
                checked={props.value.indexOf(airport.iata) >= 0}
                onChange={() => {
                  if (props.value.indexOf(airport.iata) >= 0) {
                    props.onChange(removeAirport(props.value, airport.iata))
                  } else {
                    props.onChange(addAirport(props.value, airport.iata))
                  }
                }}
              />{' '}
              {airport.full_name} ({airport.iata})
            </label>
          )
        })}
      </div>
    </FilterPopover>
  )
}
