import { useState } from 'react'
import TextInput from '../../common/Misc/TextInput'
import AirportAutoComplete from './AirportAutoComplete'
import './AirportSearchField.css'

import IATA from '../../../helpers/airports.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'

export default function AirportSearchField(props) {
  const [autocomplete, setAutocomplete] = useState([])
  const [showAutocomplete, setShowAutocomplete] = useState(false)
  const [locationLocked, setLocationLocked] = useState(false)
  const [locationHumanReadable, setLocation] = useState('')

  const onUpdateSearch = async (q) => {
    if (props.onChange) props.onChange(q.toUpperCase())
    if (q.trim().length > 2) {
      // First Preference: IATA Code
      var results = []
      Object.keys(IATA).forEach((k) => {
        if (k.toUpperCase().indexOf(q.toUpperCase()) >= 0) {
          results.push(k)
        }
      })

      // Try City Name
      let q_without_spaces = q.replace(' ', '').toUpperCase()
      Object.keys(IATA).forEach((k) => {
        if (IATA[k]['l'].replace(' ', '').toUpperCase().indexOf(q_without_spaces) >= 0) {
          if (results.indexOf(k) < 0) {
            results.push(k)
          }
        }
      })

      // Second Preference: Anything else!
      Object.keys(IATA).forEach((k) => {
        // We need to get a list of all the words we want to search, then
        // split them up into individual words, so we can only search the beginning of the word.
        let words = IATA[k]['n']
          .split(' ')
          .concat(IATA[k]['l'].split(' '))
          .concat(IATA[k]['a'].split(' '))
        if (
          words.find((w) => w.toUpperCase().indexOf(q.toUpperCase()) >= 0) ||
          words.find((w) => w.toUpperCase().indexOf(q.replace(' ', '').toUpperCase()) >= 0)
        ) {
          // Check if we already have this in the list...
          if (results.indexOf(k) < 0) {
            results.push(k)
          }
        }
      })

      let qualifyingLocations = results.map((k) => {
        return {
          iata: k,
          name: IATA[k]['n'],
          city: IATA[k]['l'],
          country: IATA[k]['c']
        }
      })
      setAutocomplete(qualifyingLocations.slice(0, 10))
      setShowAutocomplete(qualifyingLocations.length > 0)
    } else {
      setAutocomplete([])
      setShowAutocomplete(false)
    }
  }

  return (
    <div tabIndex={-1}>
      <div className="airport-search-label">
        {props.label || ''}
        <span style={{ fontSize: 16, position: 'relative' }} data-tip={props.tooltip || ''}>
          <FontAwesomeIcon
            icon="info-circle"
            className="info-circle"
            size="xs"
            style={{ display: props.tooltip ? 'inline-block' : 'none' }}
          />
          <ReactTooltip
            className={'tooltip-container'}
            type={'dark'}
            delayShow={200}
            place={'bottom'}
            multiline={true}
            backgroundColor={'#ffffff'}
            textColor={'#333333'}
            effect={'solid'}
          />
        </span>
      </div>
      {locationLocked && (
        <TextInput
          id={props.id || ''}
          debounce={true}
          disabled={true}
          noAutoComplete={true} /* this is for chrome */
          className="airport-search-input"
          placeholder={
            locationHumanReadable === '' ? props.placeholder || '' : locationHumanReadable
          }
          value={locationHumanReadable}
          onWhite={true}
          style={{ fontSize: 14, fontWeight: 'bold' }}
          onClick={() => {
            setLocationLocked(false)
          }}
        />
      )}
      {!locationLocked && (
        <TextInput
          id={props.id || ''}
          debounce={false}
          noAutoComplete={true} /* this is for chrome */
          className="airport-search-input"
          placeholder={props.placeholder}
          value={locationLocked ? locationHumanReadable : props.iata}
          onWhite={true}
          style={{ fontSize: 14, fontWeight: locationLocked ? 'bold' : 'normal' }}
          onChange={(q) => {
            setLocationLocked(false)
            setLocation('')
            onUpdateSearch(q)
          }}
          suggestionPanel={
            showAutocomplete && (
              <AirportAutoComplete
                suggestions={autocomplete}
                onClick={(result) => {
                  props.onChange(result.iata)
                  setShowAutocomplete(false)
                  setLocationLocked(true)
                  setLocation(`${result.city}, ${result.country} (${result.iata})`)
                }}
              />
            )
          }
          onBlur={() => {
            setShowAutocomplete(false)
          }}
        />
      )}
    </div>
  )
}
