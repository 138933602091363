import { Tools } from '../../../helpers/Tools'
import OfferPanelDesktop from './OfferPanelDesktop'
import OfferPanelMobile from './OfferPanelMobile'

export default function OfferPanel(props) {
  const windowSize = Tools.useWindowSize()
  const isMobile = windowSize.width && windowSize.width < 1000

  // Check if the incoming params are all valid
  var validationErrors = []
  if (!props.section) validationErrors.push('Section is missing')
  if (!props.habitToBeat) validationErrors.push('Habit to Beat is missing')
  if (!props.offer) validationErrors.push('Offer is missing')
  if (validationErrors.length > 0) {
    console.error('[OfferPanel] Errors: ', validationErrors)
    return <div></div>
  }

  const offerType = () => {
    if (props.section === 'best_match') return 'Best Match'
    if (props.section === 'better_match') return 'Comfort Match'
    if (props.section === 'habit_to_beat') return 'Habit to Beat'
    if (props.section === 'max_savings') return 'Max Savings'
    return 'Other'
  }

  const co2Savings = () => {
    let habitToBeat = props.habitToBeat.climate_impact
    let thisOption = props.offer.climate_impact
    let co2ImprovementInPercent = 100 - Math.round((thisOption / habitToBeat) * 100)
    return {
      roundtrip_co2_percentage: co2ImprovementInPercent,
      roundtrip_co2_kg: thisOption
    }
  }

  const costSavings = () => {
    let habitToBeat = props.habitToBeat.price.value
    if (parseInt(habitToBeat) === 0) {
      return {
        roundtrip_cost_percentage: 0,
        roundtrip_cost: 0
      }
    }
    let thisOption = props.offer.price.value
    let costImprovementInPercent = Math.round((thisOption / habitToBeat) * 100) - 100
    return {
      roundtrip_cost_percentage: costImprovementInPercent,
      roundtrip_cost: thisOption
    }
  }

  // If we're on mobile, then let's use our optimized mobile version for this
  if (isMobile) {
    return (
      <OfferPanelMobile
        showPolicyViolations={props.showPolicyViolations || false}
        disabled={props.disabled || false}
        topLabel={props.topLabel || offerType().toUpperCase()}
        confirmLabel={props.confirmLabel || 'Select'}
        offer={props.offer}
        habitToBeat={props.habitToBeat}
        section={props.section}
        selected={props.selected || false}
        co2Savings={co2Savings()}
        costSavings={costSavings()}
        hidePrice={props.hidePrice || parseInt(props.habitToBeat.price.value) === 0 || false}
        onSelect={props.onSelect}
        onConfirm={props.onConfirm}
        carbonPricing={props.carbonPricing || false}
        displayCurrency={props.displayCurrency || 'CHF'}
        currencyConversionRate={props.currencyConversionRate || 1.0}
      />
    )
  } else {
    return (
      <OfferPanelDesktop
        showPolicyViolations={props.showPolicyViolations || false}
        disabled={props.disabled || false}
        topLabel={props.topLabel || offerType().toUpperCase()}
        confirmLabel={props.confirmLabel || 'Select'}
        offer={props.offer}
        habitToBeat={props.habitToBeat}
        section={props.section}
        selected={props.selected || false}
        highlight={props.highlight || false}
        co2Savings={co2Savings()}
        costSavings={costSavings()}
        hidePrice={props.hidePrice || parseInt(props.habitToBeat.price.value) === 0 || false}
        onSelect={props.onSelect}
        onConfirm={props.onConfirm}
        carbonPricing={props.carbonPricing || false}
        displayCurrency={props.displayCurrency || 'CHF'}
        currencyConversionRate={props.currencyConversionRate || 1.0}
      />
    )
  }
}
