import { useCallback, useEffect, useState } from 'react'
import useEmployeeStore from '../../employee_store'
import { Networking } from '../../helpers/Networking'
import TripRow from '../../components/employee/Trips/TripRow'
import Loading from '../../components/common/Misc/Loading'
import PageTitle from '../../components/common/Misc/PageTitle'
import useStore from '../../store'
import { useNavigate } from 'react-router'

export default function EmployeeModuleTrips(props) {
  const trips = useEmployeeStore((state) => state.trips)
  const isAuthenticated = useStore((state) => state.isAuthenticated)
  const setTrips = useEmployeeStore((state) => state.setTrips)
  const [loadingState, setLoadingState] = useState(0)
  const navigate = useNavigate()

  const fetchTrips = useCallback(async () => {
    // Make sure we're having a valid user session!
    if (!isAuthenticated) {
      // For now, wait a bit, then try again
      setTimeout(() => {
        navigate('/login')
      }, 2000)
    } else {
      const data = await Networking.getTrips()
      setTrips(data.trips)
      setLoadingState(2)
    }
  }, [setTrips, setLoadingState, isAuthenticated, navigate])

  useEffect(() => {
    if (loadingState === 0) {
      setLoadingState(1)
      fetchTrips()
    }
  }, [trips, setTrips, loadingState, setLoadingState, fetchTrips])

  const confirmTrip = async (trip_id) => {
    const result = await Networking.updateTripStatus(
      { trip_id: trip_id, status: 'confirmed' },
      trip_id
    )
    if (result) {
      fetchTrips()
    }
  }

  const deleteTrip = async (trip_id) => {
    const result = await Networking.updateTripStatus(
      { trip_id: trip_id, status: 'deleted' },
      trip_id
    )
    if (result) {
      fetchTrips()
    }
  }

  const sortTrips = (trips) => {
    return trips.sort(
      (a, b) => new Date(b.timestamp_created).getTime() - new Date(a.timestamp_created).getTime()
    )
  }

  // Remove the deleted trips, and later, anything else we want to filter on
  const filterTrips = (trips) => {
    return trips.filter((trip) => {
      if (trip.status === 'deleted') return false
      return true
    })
  }

  if (loadingState === 1) {
    return (
      <>
        <PageTitle title="My Trips" />
        <div style={{ marginTop: 100, textAlign: 'center' }}>
          <Loading />
        </div>
      </>
    )
  }

  let availableTrips = sortTrips(filterTrips(trips))

  return (
    <>
      <PageTitle title="My Trips">
        {availableTrips.length > 0 && (
          <>
            Confirm booking, cancelation or any changes of your selected flights/travel options.
            <br />
            <br />
            If you have any questions about your trips,{' '}
            <span
              className="link"
              onClick={() => {
                window.$crisp.push(['set', 'message:text', ['I have a question about a trip...']])
                window.$crisp.push(['do', 'chat:open'])
              }}>
              reach out to us via the support chat
            </span>{' '}
            at any time.
          </>
        )}
      </PageTitle>

      {availableTrips.length === 0 && (
        <div style={{ marginTop: 30 }}>You haven't booked any trips yet.</div>
      )}

      {availableTrips.map((trip, i) => {
        return (
          <TripRow
            key={`trip_${i}`}
            trip={trip}
            onTripConfirmed={() => {
              confirmTrip(trip.trip_id)
            }}
            onTripDelete={() => {
              deleteTrip(trip.trip_id)
            }}
          />
        )
      })}
    </>
  )
}
