import { Tools } from '../../../helpers/Tools'
import './Row.css'

export default function ClimateOptionTrain(props) {
  const percentageColor = (i) => {
    return <span style={{ color: i >= 0 ? '#DD4155' : '#0a0' }}>{i}%</span>
  }

  const calculateClimateImpact = (compareTo) => {
    if (compareTo) {
      try {
        let best_match_climate_impact = compareTo.climate_impact
        if (isNaN(best_match_climate_impact)) return 0
        return Math.round(best_match_climate_impact * 0.1)
      } catch {
        return 0
      }
    } else return 0
  }

  const calculateClimateImprovement = (compareTo) => {
    if (compareTo) {
      try {
        let trainImpact = calculateClimateImpact(compareTo)
        let compareToImpact = compareTo.climate_impact
        return Tools.percentageImprovement(trainImpact, compareToImpact)
      } catch {
        return 0
      }
    } else return 0
  }

  return (
    <div
      style={{
        borderRadius: 10,
        boxShadow: '0 2px 6px 0 rgba(6,6,93,0.2)',
        marginBottom: 20,
        paddingBottom: 15,
        width: 950,
        position: 'relative',
        opacity: props.enabled ? 1.0 : 0.5
      }}>
      {/* This is a red x at the right of the flight bundle that allows us to clear up the slot */}
      {props.onToggleRow && (
        <div
          className="remove-option-row"
          onClick={() => {
            props.onToggleRow()
          }}>
          x
        </div>
      )}

      {/* This is the label at the top */}
      <div
        style={{
          position: 'relative',
          left: 0,
          top: 0,
          padding: '7px 15px',
          color: '#fff',
          fontSize: 10,
          letterSpacing: '0.8px',
          fontWeight: 600,
          display: 'inline-block',
          background: props.labelColor ? props.labelColor : '#0A1EAA',
          borderTopLeftRadius: 8,
          borderBottomRightRadius: 16
        }}>
        TRAIN
      </div>

      {/* In order to sync up the horizontal columns to match other items above this component, we need to fix the positions */}
      <table
        className="climate-options-table"
        style={{ width: 960, paddingLeft: 14, paddingRight: 20, marginTop: -10 }}
        cellPadding={0}
        cellSpacing={0}>
        {/* OUTBOUND LEG ----------------------------------------------------------- */}
        <tbody>
          <tr>
            {/* Cabin Class */}
            <td style={{ fontSize: 13, width: props.columnWidths[0] }}></td>
            {/* Label */}
            <td
              style={{
                textAlign: 'left',
                paddingLeft: 35,
                fontSize: 13,
                width:
                  props.columnWidths[1] +
                  props.columnWidths[2] +
                  props.columnWidths[3] +
                  props.columnWidths[4] +
                  props.columnWidths[5] +
                  props.columnWidths[6]
              }}>
              {props.enabled && 'Only offer for short-distance trips'}
              {!props.enabled && (
                <span>
                  This option is disabled and won't be offered to the client.
                  <br />
                  <span
                    onClick={props.onToggleRow}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                    Re-enable option
                  </span>
                </span>
              )}
            </td>
            {/* NOT IN USE: Duration */}
            <td style={{ width: props.columnWidths[7], fontSize: 13 }}></td>
            {/* NOT IN USE: Stop-Overs */}
            <td style={{ width: props.columnWidths[8], fontSize: 13 }}></td>
            {/* Climate Impact */}
            <td style={{ width: props.columnWidths[9], fontSize: 13, textAlign: 'right' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  alignItems: 'center',
                  paddingRight: 5
                }}>
                <div style={{ paddingRight: 5, textAlign: 'right', fontSize: 13 }}>
                  {percentageColor(calculateClimateImprovement(props.compareTo))}
                </div>
                <div>{Tools.formatNumber(calculateClimateImpact(props.compareTo))}</div>
              </div>
            </td>
            {/* Price */}
            <td style={{ width: props.columnWidths[10], textAlign: 'right' }}>&nbsp;</td>
            {/* FNZ Points */}
            <td style={{ textAlign: 'right', fontSize: 13, paddingRight: 5 }}>
              {/* We don't specify a width here to take up any space that is still available */}

              {/* 
              {calculateFNZ(props.bestMatch) === 0 ? (
                <span style={{ marginRight: 10 }}>-</span>
              ) : (
                <span style={{ color: '#39AD63' }}>
                  +{Tools.formatNumber(calculateFNZ(props.bestMatch))}
                </span>
              )}
              */}
              <span style={{ marginRight: 10 }}>-</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
