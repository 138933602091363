import { useRef } from 'react'
import { Transition } from 'react-transition-group'
import './ErrorText.css'

export default function ErrorText({ show, text, noMargin, children }) {
  const nodeRef = useRef(null)

  const defaultStyle = {
    transition: `opacity 600ms ease-in-out`,
    opacity: 0,
    width: '100%'
  }

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 }
  }

  if (!show) return <></>

  return (
    <div style={{ marginBottom: noMargin ? 0 : 30 }}>
      <Transition timeout={600} in={show} nodeRef={nodeRef}>
        {(state) => (
          <div ref={nodeRef} style={{ ...defaultStyle, ...transitionStyles[state] }}>
            <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
              <div className="error-text-container">{children}</div>
            </div>
          </div>
        )}
      </Transition>
    </div>
  )
}
