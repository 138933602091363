import { useEffect, useRef } from 'react'
import { Networking } from '../../../helpers/Networking'

export default function Map(props) {
  const mapRef = useRef(null)
  useEffect(() => {
    console.log('Map.js useEffect')

    const initMaps = () => {
      console.log('Map.js initMaps')
      return new Promise((resolve) => {
        window.mapsLoaded = () => {
          resolve()
          delete window.mapsLoaded
        }
        const script = document.createElement('script')
        script.src = `https://maps.googleapis.com/maps/api/js?key=${Networking.googleApiKey()}&callback=mapsLoaded`
        script.async = true
        document.body.appendChild(script)
      })
    }

    const displayMap = () => {
      console.log('Map.js displayMap')
      window.car_trip_google_maps = new window.google.maps.Map(mapRef.current, props.options)

      // If we have a directions service, then attach it
      if (window.car_trip_directions_renderer) {
        if (window.car_trip_google_maps) {
          console.log('Putting directions into map...')
          window.car_trip_directions_renderer.setMap(window.car_trip_google_maps)
        } else {
          console.log('ERROR: Unable to find a Google Maps reference object.')
        }
      } else {
        console.log("ERROR: We don't seem to have a directions service available?")
      }
    }

    if (!window.google) {
      initMaps().then(() => {
        displayMap()
      })
    } else {
      displayMap()
    }
  }, [props.id, props.options])

  return (
    <div
      id="car-trip-maps-container"
      ref={mapRef}
      style={{ width: '100%', height: 450, borderRadius: 15, marginTop: 10 }}></div>
  )
}
