import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCheck,
  faSearch,
  faClipboardList,
  faSlidersH,
  faQuestionCircle,
  faInfoCircle,
  faChalkboardTeacher,
  faSearchLocation,
  faCar,
  faTrain,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'

/* ---- AWS Amplify for Cognito User Authentication ---- */
import { Amplify } from 'aws-amplify'
import Router from './Router'
Amplify.configure({
  aws_project_region: process.env.REACT_APP_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_REGION,
  aws_user_pools_id: process.env.REACT_APP_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_WEB_CLIENT_ID,
  aws_cognito_verification_mechanisms: ['EMAIL']
})
/* ----------------------------------------------------- */

// This sets up our Font Awesome icons, so we can re-use them across the app.
library.add(
  faCheck,
  faSearch,
  faClipboardList,
  faSlidersH,
  faQuestionCircle,
  faInfoCircle,
  faChalkboardTeacher,
  faSearchLocation,
  faCar,
  faTrain,
  faExclamationTriangle
)

export default function App() {
  return <Router />
}
