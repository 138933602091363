import { Range } from 'react-range'
import { Tools } from '../../../helpers/Tools'
import FilterPopover from './Components/FilterPopover'
import useStore from '../../../store'

export default function FilterPrice(props) {
  const companyDetails = useStore((store) => store.companyDetails)
  const currencyRateToday = useStore((store) => store.currencyRateToday)

  const getTitle = (maxPrice) => {
    return `Up to ${Tools.formatNumber(maxPrice * currencyRateToday, companyDetails.currency)} ${
      companyDetails.currency
    }`
  }

  return (
    <FilterPopover
      title={'Price'}
      selectedTitle={getTitle(props.value)}
      active={Math.ceil(props.defaultValue, 10) !== Math.ceil(props.value, 10)}
      onCancel={() => {
        if (props.onCancel) props.onCancel()
      }}>
      <div style={{ fontSize: 14 }}>
        Up to {Tools.formatNumber(props.value * currencyRateToday, companyDetails.currency)}{' '}
        {companyDetails.currency}
      </div>
      <br />
      <div style={{ paddingLeft: 10, paddingRight: 10 }}>
        <Range
          step={1}
          min={0}
          max={Math.ceil(props.defaultValue * currencyRateToday) || 100000}
          values={[Math.ceil(props.value * currencyRateToday)]}
          onChange={(v) => {
            if (props.onChange) {
              props.onChange(Math.ceil(v[0]))
            }
          }}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '3px',
                width: '100%',
                backgroundColor: '#ccc'
              }}>
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '20px',
                width: '20px',
                borderRadius: 20,
                backgroundColor: '#0B1CAA'
              }}
            />
          )}
        />
      </div>
    </FilterPopover>
  )
}
