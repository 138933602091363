import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'
import { Tools } from '../../../helpers/Tools'
import Button from '../../common/Misc/Button'
import RadioButton from '../../common/Misc/RadioButton'
import Tooltip from '../../common/Misc/Tooltip'
import CarRowDesktop from './CarRowDesktop'

export default function OfferPanelCarDesktop(props) {
  var tooltipLabel = 'The is the trip you originally planned.'
  let isReturn = props.journey.searchQuery.route_direction === 'roundtrip'

  return (
    <div
      style={{
        marginTop: 10,
        marginBottom: 10,
        cursor: 'pointer',
        maxWidth: 1200
      }}
      onClick={props.onSelect}>
      <Tooltip />
      <div
        className="offer-panel-top-label"
        style={{
          position: 'relative',
          left: 40,
          top: 10,
          padding: '5px 15px',
          color: '#fff',
          fontSize: 12,
          fontWeight: 600,
          display: 'inline-block',
          backgroundColor: props.section === 'habit_to_beat' ? '#333' : '#0A1EAA',
          borderRadius: 4,
          borderTopLeftRadius: 16,
          borderBottomRightRadius: 16
        }}>
        HABIT TO BEAT
        <span
          style={{ position: 'absolute', right: -20, top: -2, fontSize: 20 }}
          data-tip={tooltipLabel}
          data-for={`offer-panel-top-label-0`}>
          <FontAwesomeIcon icon="info-circle" className="info-circle" size="xs" />
          <ReactTooltip
            id={`offer-panel-top-label-0`}
            className={'tooltip-container'}
            type={'dark'}
            delayShow={200}
            place={'bottom'}
            multiline={true}
            backgroundColor={'#ffffff'}
            textColor={'#333333'}
            effect={'solid'}
          />
        </span>
      </div>

      <div
        style={{
          backgroundColor: 'white',
          borderRadius: 10,
          boxShadow: props.selected
            ? '0 2px 4px 0 rgba(10,30,170,0.24)'
            : '0 2px 4px 0 rgba(10,30,170,0.24)',
          padding: 20
        }}>
        <div
          style={{
            backgroundColor:
              props.selected || props.highlight
                ? props.section === 'habit_to_beat'
                  ? 'rgba(10,30,170,0.04)'
                  : '#E0F88B'
                : 'rgba(10,30,170,0.04)',
            borderRadius: 32,
            padding: 12,
            display: 'flex',
            alignItems: 'center'
          }}>
          {!props.disabled && (
            <div style={{ paddingLeft: 15, paddingRight: 15 }}>
              <RadioButton selected={props.selected} onClick={props.onSelect} />
            </div>
          )}
          {props.disabled && <div style={{ width: 10 }}></div>}
          <div style={{ fontSize: 20 }}>
            <div>Your planned trip</div>
          </div>
          <div style={{ flexGrow: 1 }}></div>
          <div style={{ fontSize: 14, minWidth: 100, marginRight: 10 }}>
            {isReturn ? 'Round trip cost:' : 'Trip cost:'}
          </div>
          <div style={{ textAlign: 'right', minWidth: 100, marginRight: 20 }}>
            <div
              style={{
                fontSize: 20,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center'
              }}>
              <div style={{ fontWeight: 'bold' }}>0% &nbsp;</div>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 'normal',
                  minWidth: 91,
                  textAlign: 'right'
                }}>
                ca. {Tools.formatNumber(Math.round(props.journey.route.total_cost))} CHF
              </div>
            </div>
          </div>
        </div>

        <CarRowDesktop journey={props.journey} />

        {isReturn && (
          <>
            <div
              style={{
                borderBottom: '1px solid #ddd',
                marginLeft: 40,
                marginRight: 20
              }}></div>
            <CarRowDesktop journey={props.journey} />
          </>
        )}

        {props.selected && !props.disabled && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: 60,
              marginTop: 20
            }}>
            <div style={{ fontSize: 12, marginLeft: 40 }}>{props.children}</div>
            <div style={{ flexGrow: 1 }}></div>
            <div>
              <Button text={`Select`} onClick={props.onConfirm} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
