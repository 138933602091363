import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function TrainRowDesktop(props) {

  const formatRiders = (passengers) => {
    let totalRiders = parseInt(passengers||0);
    return totalRiders === 1 ? "1 person": `${totalRiders} persons`
  }

  const formatClimateImpact = (climateImpact) => {
    return (
      <>
      {climateImpact.toFixed(1)}kg CO
      <sub style={{ fontSize: 8 }}>2e</sub>
      </>
    )
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
      <div style={{ paddingLeft: 30, paddingRight: 30, fontSize: 13, width: 120 }}>
       <FontAwesomeIcon icon={'train'} size={'2x'} style={{marginRight:20}} className="car-icon" />
      </div>
      <div style={{ minWidth: 118 }}>
        <div style={{ fontWeight: 'bold', fontSize: 14 }}>
          2nd class, 50%
        </div>
        <div style={{ fontSize: 12 }}>{formatRiders(props.journey.searchQuery.passengers)}</div>
      </div>

      <div style={{ flexGrow: 1 }}></div>

      <div style={{ textAlign: 'right', minWidth: 100 }}>
        <div style={{ color: '#80B003', fontSize: 18, fontWeight: 'bold' }}>
          -{props.co2Percentage}%
        </div>
        <div style={{ fontSize: 12 }}>
          {formatClimateImpact(props.co2)}
        </div>
      </div>

      <div style={{ textAlign: 'right', minWidth: 100, marginRight: 22 }}>
        <div style={{ color: '#333', fontSize: 14, fontWeight: 'bold' }}>
          {Math.round(props.climatePoints)}
        </div>
        <div style={{ fontSize: 12 }}>CPs</div>
      </div>
    </div>
  )
}
