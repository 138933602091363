import { useState } from 'react'

import { Tools } from '../../../helpers/Tools'
import SearchButton from '../../common/Misc/SearchButton'
import AirportSearchField from './AirportSearchField'
import FlightDateField from './FlightDateField'

import './FlightSearchPanel.css'

export default function FlightSearchPanel(props) {
  const [isDeparturePickerOpen, setDeparturePickerOpen] = useState(false)
  const [isReturnPickerOpen, setReturnPickerOpen] = useState(false)

  return (
    <div className="flight-search-panel-container">
      <div className="flight-search-panel-container-top-filter-row">
        <select
          className="flight-search-panel-trip-type-dropdown"
          value={props.details.route_direction}
          onChange={(routeDirection) => {
            // If we're switching back from one-way to roundtrip,
            // then we need to restore the original trip dates
            if (routeDirection.target.value === 'roundtrip') {
              props.onUpdateSearch({
                ...props.details,
                route_direction: routeDirection.target.value,
                return_time: '00:00:00',
                return_date: props.defaultReturnDate
              })
            } else {
              props.onUpdateSearch({
                ...props.details,
                route_direction: routeDirection.target.value
              })
            }
            props.onSearchEdited()
          }}>
          <option value="roundtrip">&#x21C4;&nbsp;&nbsp;Round trip</option>
          <option value="oneway">&rarr;&nbsp;&nbsp;One way</option>
          <option value="multistop">☰&nbsp;&nbsp;Multi-Stop</option>
        </select>

        { props.details.route_direction === "multistop" && <span style={{paddingTop:10, paddingLeft: 30, paddingRight:30}}>
      Information on logging multi-stop flights can be found in the <a style={{borderBottom:"1px solid #ddd"}} target="_blank" rel="noreferrer" href="https://www.climpact.ai/cst-faq-multi-stop">FAQ/Flight Search</a>
        </span>}

        { props.details.route_direction !== "multistop" &&
        <>
          <select
            className="flight-search-panel-cabin-class-dropdown"
            value={props.details.cabin_class}
            onChange={(cabinClass) => {
              props.onUpdateSearch({ ...props.details, cabin_class: cabinClass.target.value })
              props.onSearchEdited()
            }}>
            <option value="ECONOMY">Economy</option>
            <option value="PREMIUM_ECONOMY">Premium Economy</option>
            <option value="BUSINESS">Business</option>
            <option value="FIRST">First</option>
          </select>
        </>}

      </div>


      { props.details.route_direction !== "multistop" &&
      <>

        <div className="flight-search-panel-container-bottom-filter-row">
          <AirportSearchField
            id="search_field_origin"
            label="Departure from"
            placeholder="Cities in English or airport codes"
            iata={props.details.origin}
            onChange={(iata) => {
              if (iata !== props.details.origin.iata) {
                props.onUpdateSearch({ ...props.details, origin: iata })
                props.onSearchEdited()
              }
            }}
          />
          <AirportSearchField
            id="search_field_destination"
            label="Destination"
            tooltip="For cities with multiple airports, please type in the IATA location code,<br/>e.g. NYC for New York City. To search all three airports at once,<br/>do not select a specific airport, just use NYC."
            iata={props.details.destination}
            onChange={(iata) => {
              if (iata !== props.details.destination.iata) {
                props.onUpdateSearch({ ...props.details, destination: iata })
                props.onSearchEdited()
              }
            }}
          />

          <FlightDateField
            id="search_departure_date"
            onBlur={() => {
              setDeparturePickerOpen(false)
            }}
            isOpen={isDeparturePickerOpen}
            whenOpened={() => {
              setDeparturePickerOpen(true)
              setReturnPickerOpen(false)
            }}
            whenClosed={() => {
              setDeparturePickerOpen(false)
            }}
            label="Departure Date"
            date={props.details.departure_date}
            minimumDate={
              props.pastFlight ? Tools.getDateByDayOffset(new Date(), -365 * 2) : new Date()
            }
            maximumDate={
              props.pastFlight
                ? Tools.getDateByDayOffset(new Date(), 365)
                : Tools.getDateByDayOffset(new Date(), 365 * 2)
            }
            onChange={(newDate) => {
              /* 
                  Important: If the return date is EARLIER than this new departure date, 
                  then we'll need to move the return date forward to the same day at the very least
                */
              if (props.details.return_date && props.details.return_date < newDate) {
                if (props.details.departure_date !== newDate) {
                  props.onUpdateSearch({
                    ...props.details,
                    departure_date: newDate,
                    return_date: newDate
                  })
                  props.onSearchEdited()
                }
              } else {
                if (props.details.departure_date !== newDate) {
                  props.onUpdateSearch({ ...props.details, departure_date: newDate })
                  props.onSearchEdited()
                }
              }
            }}
          />
          {props.details.route_direction && props.details.route_direction === 'roundtrip' && (
            <FlightDateField
              id="search_return_date"
              onBlur={() => {
                setReturnPickerOpen(false)
              }}
              isOpen={isReturnPickerOpen}
              whenOpened={() => {
                setReturnPickerOpen(true)
                setDeparturePickerOpen(false)
              }}
              whenClosed={() => {
                setReturnPickerOpen(false)
              }}
              label="Return Date"
              minimumDate={
                props.pastFlight
                  ? Tools.getDateByDayOffset(new Date(), -365 * 2)
                  : Tools.shortFormatDateToFullDate(props.details.departure_date)
              }
              maximumDate={
                props.pastFlight
                  ? Tools.getDateByDayOffset(new Date(), 365)
                  : Tools.getDateByDayOffset(new Date(), 365 * 2)
              }
              date={
                Tools.shortFormatDateToFullDate(props.details.return_date) <
                Tools.shortFormatDateToFullDate(props.details.departure_date)
                  ? props.details.departure_date
                  : props.details.return_date
              }
              onChange={(newDate) => {
                if (props.details.return_date !== newDate) {
                  props.onUpdateSearch({ ...props.details, return_date: newDate })
                  props.onSearchEdited()
                }
              }}
            />
          )}
        </div>

        {props.showSearchButton && (
          <div style={{ textAlign: 'center', marginTop: -20 }}>
            {!props.isLoading && (
              <SearchButton
                id="button-start-search"
                onClick={() => {
                  props.onSearch()
                }}
              />
            )}
          </div>
        )}

      </>}

      <div style={{ marginTop: 10 }}>{props.children}</div>
    </div>
  )
}
