export const CarbonPricing = {
  model_PerTonWithMinimum: (co2inKg, payload) => {
    var totalPrice = 0
    let minimumPrice = parseFloat(payload.split(',')[0])
    let pricePerTon = parseFloat(payload.split(',')[1])
    totalPrice = minimumPrice
    if (parseInt(co2inKg) > 1000) {
      totalPrice = minimumPrice + ((parseInt(co2inKg) - 1000) / 1000) * pricePerTon
    }
    return totalPrice.toFixed(2)
  },

  calculate: (carbonPricingDetails, co2InKg) => {
    try {
      if (!carbonPricingDetails) throw Error('Invalid data, missing details')
      if (!Array.isArray(carbonPricingDetails)) throw Error('Invalid data, malformed array')
      if (carbonPricingDetails.length === 0) throw Error('Invalid data, no pricing records')
      // Find an active pricing model in the list
      let active_model = carbonPricingDetails.find((model) => model.active)
      if (!active_model) throw Error('No active carbon pricing model found')
      // Let's see if we support the pricing model already here
      if (active_model.pricing_model === 'per_ton_with_minimum') {
        return CarbonPricing.model_PerTonWithMinimum(co2InKg, active_model.model_payload)
      }
    } catch (e) {
      console.log('This does not seem to be a correct carbon pricing model', carbonPricingDetails)
      return 0
    }
  }
}
