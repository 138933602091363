import './Modal.css'

export default function Modal(props) {
  if (!props.visible) {
    return (null)
  }
  return (
    <div className='modal-outer' onClick={() => { if (props.onClose) { props.onClose() } }}>
      {props.noContainer && props.children}
      {!props.noContainer && 
      <div className='modal-inner' onClick={(e) => { e.stopPropagation(); }}>
        {props.children}
      </div>
      }
    </div>
  )
}