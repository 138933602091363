import { useEffect, useState } from 'react'
import useStore from '../../store'

import ClimateOptionFlightList from '../../components/agency/ClimateOptions/FlightList'
import ClimateOptionRow from '../../components/agency/ClimateOptions/Row'

import Button from '../../components/common/Misc/Button'
import ErrorText from '../../components/common/Misc/ErrorText'
import Loading from '../../components/common/Misc/Loading'

import { Networking } from '../../helpers/Networking'
import TripSummaryFlight from '../../components/common/Misc/TripSummaryFlight'
import ClimateOptionOnline from '../../components/agency/ClimateOptions/Online'
import ClimateOptionTrain from '../../components/agency/ClimateOptions/Train'

export default function WizardClimateOptions(props) {
  // Global State
  const searchId = useStore((state) => state.searchId)

  const optionHabitToBeat = useStore((state) => state.habitToBeat)
  const optionBestMatch = useStore((state) => state.bestMatch)
  const optionBetterMatch = useStore((state) => state.betterMatch)
  const optionMaxSavings = useStore((state) => state.maxSavings)
  const optionTrain = useStore((state) => state.trainOption)
  const optionOnline = useStore((state) => state.onlineOption)

  const setHabitToBeat = useStore((state) => state.setHabitToBeat)
  const setBestMatch = useStore((state) => state.setBestMatch)
  const setBetterMatch = useStore((state) => state.setBetterMatch)
  const setMaxSavings = useStore((state) => state.setMaxSavings)
  const setTrainOption = useStore((state) => state.setTrainOption)
  const setOnlineOption = useStore((state) => state.setOnlineOption)

  // Local State
  const [isLoading, setLoading] = useState(false)
  const [queryFailed, setQueryFailed] = useState(false)
  const [recommendedFlights, setRecommendedFlights] = useState(null)

  // Some UI helpers, these allow to collapse the individual cabin classes
  const [showBusiness, setShowBusiness] = useState(true)
  const [showPremiumEconomy, setShowPremiumEconomy] = useState(true)
  const [showEconomy, setShowEconomy] = useState(true)

  const getRecommendedFlights = () => {
    setLoading(true)

    Networking.recommendFlights({
      id: searchId,
      habit_to_beat: {
        id: 0,
        price: {
          value: 0,
          currency: 'CHF'
        },
        fnz_points: 0,
        climate_impact: 0,
        outward_itinerary: optionHabitToBeat.outward_itinerary,
        return_itinerary: optionHabitToBeat.return_itinerary
      }
    })
      .then((data) => {
        // We need to declutter the backend result a bit as it's currently
        // split up by cabin classes, and we just need a list of all options
        // instead of filtering by cabin class.
        var roundtrips = []
        try {
          // Iterature through all cabin classes and concat all the results into our roundtrips collection
          Object.keys(data.result.connections.roundtrip_bundles).forEach((classOptions, i) => {
            roundtrips = roundtrips.concat(data.result.connections.roundtrip_bundles[classOptions])
          })
        } catch (e) {
          console.log(e)
        }

        // Now let's pick out any backend recommendations, if we get any...
        roundtrips.forEach((trip, i) => {
          if (trip.outward_itinerary.recommendation.habit_to_beat) {
            /* This one we technically already have (via props), but let's pick it up again fresh from here */
            setHabitToBeat(trip)
          }
          if (trip.outward_itinerary.recommendation.best_match) {
            setBestMatch(trip)
          }
          if (trip.outward_itinerary.recommendation.better_match) {
            setBetterMatch(trip)
          }
          if (trip.outward_itinerary.recommendation.max_savings) {
            setMaxSavings(trip)
          }
        })

        // All sorted, let's pass this to the state so we can render an UI
        setRecommendedFlights(roundtrips)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setQueryFailed(true)
        setLoading(false)
        setRecommendedFlights([])
      })
  }

  useEffect(() => {
    if (recommendedFlights === null && isLoading === false) {
      getRecommendedFlights()
    }
  })

  /* 
    Since we are essentially splitting up the table header,
    we need to sync the header and the individual flight result rows
    (ClimateOptionRow) in terms of column width. This is super annoying
    but there's not much of a way around it for now. So here are the
    constants that define the table width (from left to right)
  */
  const tableColumnWidths = [
    100, // Cabin Class
    70, // Priority
    65, // Carrier
    80, // Aircraft
    95, // Route
    45, // DEP
    45, // ARR
    75, // Duration
    75, // Stops
    110, // Climate Impact
    90, // Round Trip Fare
    70 // FNZ Points
  ]

  // This is just a developer helper function for the table width
  if (tableColumnWidths.reduce((a, b) => a + b, 0) > 922) {
    console.log(
      `WARNING: The table column width is too large (922px max, current: ${tableColumnWidths.reduce(
        (a, b) => a + b,
        0
      )})`
    )
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 30, width: 950 }}>
        <h1>Climate Options</h1>
        <div style={{ flexGrow: 1 }}></div>
        <div>
          <Button text="Adjust Offer" large={true} onClick={props.onCompleted} />
        </div>
      </div>

      <TripSummaryFlight trip={optionHabitToBeat} />

      {isLoading && <Loading />}

      <ErrorText show={queryFailed}>
        There was an issue trying to query the flight engine. Please try again.
      </ErrorText>

      <div
        style={{
          display: 'flex',
          width: 950,
          paddingLeft: 14,
          marginBottom: 20,
          fontSize: 13,
          fontWeight: 'bold',
          letterSpacing: '0.5px',
          justifyContent: 'left'
        }}>
        <div style={{ width: tableColumnWidths[0] }}>Cabin Class</div>
        <div style={{ width: tableColumnWidths[1], textAlign: 'center' }}>Priority</div>
        <div style={{ width: tableColumnWidths[2], textAlign: 'left' }}>Carrier</div>
        <div style={{ width: tableColumnWidths[3], textAlign: 'left' }}>Aircraft</div>
        <div style={{ width: tableColumnWidths[4] }}>Route</div>
        <div style={{ width: tableColumnWidths[5] }}>DEP</div>
        <div style={{ width: tableColumnWidths[6] }}>ARR</div>
        <div style={{ width: tableColumnWidths[7] }}>Duration</div>
        <div style={{ width: tableColumnWidths[8] }}>Stops</div>
        <div style={{ width: tableColumnWidths[9], textAlign: 'right', paddingRight: 5 }}>
          Climate Impact
          <br />
          <span style={{ color: '#aaa', fontSize: 10 }}>kg CO2e</span>
        </div>
        <div style={{ width: tableColumnWidths[10], textAlign: 'right', paddingRight: 5 }}>
          Round Trip
          <br />
          <span style={{ color: '#aaa', fontSize: 10 }}>CHF</span>
        </div>
        <div style={{ width: tableColumnWidths[11], textAlign: 'right' }}>CPs</div>
      </div>

      <ClimateOptionRow
        columnWidths={tableColumnWidths}
        label="Habit to Beat"
        labelColor={'#333333'}
        trip={optionHabitToBeat}
        habitToBeat={optionHabitToBeat}
      />
      <ClimateOptionRow
        columnWidths={tableColumnWidths}
        label="Best Match"
        trip={optionBestMatch}
        habitToBeat={optionHabitToBeat}
        onClearRow={() => {
          setBestMatch(false)
        }}
      />
      <ClimateOptionRow
        columnWidths={tableColumnWidths}
        label="Comfort Match"
        trip={optionBetterMatch}
        habitToBeat={optionHabitToBeat}
        onClearRow={() => {
          setBetterMatch(false)
        }}
      />
      <ClimateOptionRow
        columnWidths={tableColumnWidths}
        label="Max Savings"
        habitToBeat={optionHabitToBeat}
        trip={optionMaxSavings}
        onClearRow={() => {
          setMaxSavings(false)
        }}
      />
      <ClimateOptionTrain
        enabled={optionTrain}
        columnWidths={tableColumnWidths}
        compareTo={optionHabitToBeat}
        onToggleRow={() => {
          setTrainOption(!optionTrain)
        }}
      />
      <ClimateOptionOnline
        enabled={optionOnline}
        columnWidths={tableColumnWidths}
        compareTo={optionBestMatch}
        onToggleRow={() => {
          setOnlineOption(!optionOnline)
        }}
      />

      {recommendedFlights && recommendedFlights.length > 0 && (
        <div style={{ width: 950 }}>
          <h3 style={{ marginTop: 60 }}>All Flights</h3>

          <div
            style={{
              backgroundColor: '#ebebeb',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              fontSize: 14,
              lineHeight: '25px',
              letterSpacing: '1.3px',
              fontWeight: 'bold',
              padding: 10,
              userSelect: 'none',
              cursor: 'pointer'
            }}
            onClick={() => {
              setShowBusiness(!showBusiness)
            }}>
            {showBusiness && (
              <img src="/chevron.svg" style={{ width: 15, marginRight: 8 }} alt="close or open" />
            )}
            {!showBusiness && (
              <img
                src="/chevron.svg"
                style={{ width: 15, marginRight: 8, transform: 'rotate(180deg)' }}
                alt="close or open"
              />
            )}
            Business
          </div>
          <div style={{ display: showBusiness ? 'block' : 'none' }}>
            {
              <ClimateOptionFlightList
                habitToBeat={optionHabitToBeat}
                flights={recommendedFlights.filter(
                  (roundtrip, i) => roundtrip.outward_itinerary.cabin_class === 'BUSINESS'
                )}
                onPromote={(trip, slot) => {
                  if (slot === 'best_match') setBestMatch(trip)
                  if (slot === 'better_match') setBetterMatch(trip)
                  if (slot === 'max_savings') setMaxSavings(trip)
                }}
              />
            }
          </div>

          <div
            style={{
              backgroundColor: '#ebebeb',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              fontSize: 14,
              lineHeight: '25px',
              letterSpacing: '1.3px',
              fontWeight: 'bold',
              padding: 10,
              cursor: 'pointer',
              marginTop: 20,
              userSelect: 'none'
            }}
            onClick={() => {
              setShowPremiumEconomy(!showPremiumEconomy)
            }}>
            {showPremiumEconomy && (
              <img src="/chevron.svg" style={{ width: 15, marginRight: 8 }} alt="close or open" />
            )}
            {!showPremiumEconomy && (
              <img
                src="/chevron.svg"
                style={{ width: 15, marginRight: 8, transform: 'rotate(180deg)' }}
                alt="close or open"
              />
            )}
            Premium Economy
          </div>
          <div style={{ display: showPremiumEconomy ? 'block' : 'none' }}>
            {
              <ClimateOptionFlightList
                habitToBeat={optionHabitToBeat}
                flights={recommendedFlights.filter(
                  (roundtrip, i) => roundtrip.outward_itinerary.cabin_class === 'PREMIUM_ECONOMY'
                )}
                onPromote={(trip, slot) => {
                  if (slot === 'best_match') setBestMatch(trip)
                  if (slot === 'better_match') setBetterMatch(trip)
                  if (slot === 'max_savings') setMaxSavings(trip)
                }}
              />
            }
          </div>

          <div
            style={{
              backgroundColor: '#ebebeb',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              fontSize: 14,
              lineHeight: '25px',
              letterSpacing: '1.3px',
              fontWeight: 'bold',
              padding: 10,
              cursor: 'pointer',
              marginTop: 20,
              userSelect: 'none'
            }}
            onClick={() => {
              setShowEconomy(!showEconomy)
            }}>
            {showEconomy && (
              <img src="/chevron.svg" style={{ width: 15, marginRight: 8 }} alt="close or open" />
            )}
            {!showEconomy && (
              <img
                src="/chevron.svg"
                style={{ width: 15, marginRight: 8, transform: 'rotate(180deg)' }}
                alt="close or open"
              />
            )}{' '}
            Economy
          </div>
          <div style={{ display: showEconomy ? 'block' : 'none' }}>
            {
              <ClimateOptionFlightList
                habitToBeat={optionHabitToBeat}
                flights={recommendedFlights.filter(
                  (roundtrip, i) => roundtrip.outward_itinerary.cabin_class === 'ECONOMY'
                )}
                onPromote={(trip, slot) => {
                  if (slot === 'best_match') setBestMatch(trip)
                  if (slot === 'better_match') setBetterMatch(trip)
                  if (slot === 'max_savings') setMaxSavings(trip)
                }}
              />
            }
          </div>
        </div>
      )}
    </div>
  )
}
