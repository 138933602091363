import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import './PlaceAutocomplete.css'
export default function PlaceAutocomplete(props) {
  return (
    <div style={{ minWidth: 200, marginTop: 5 }}>
      <div style={{ fontSize: 12, color: '#aaa', marginBottom: 4 }}>{props.label || ''}</div>
      <GooglePlacesAutocomplete
        //apiKey={Networking.googleApiKey()}
        selectProps={{
          placeholder: 'Type to search...',
          value: props.location,
          onChange: props.onChange,
          classNamePrefix: 'autoCompleteBox',
          styles: {
            input: (provided) => ({
              ...provided,
              fontSize: 12,
              color: 'red'
            }),
            singleValue: (provided) => ({
              ...provided,
              color: 'blue'
            })
          }
        }}
      />
    </div>
  )
}
