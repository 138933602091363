import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import devtools from 'devtools-detect'
import { BrowserRouter } from 'react-router-dom'

import 'typeface-lato'
import './global.css'

window.$crisp = []
window.CRISP_WEBSITE_ID = '7bc56813-b6ee-486a-8c54-cb7b59a76adc'
;(function () {
  var d = document
  var s = d.createElement('script')
  s.src = 'https://client.crisp.chat/l.js'
  s.async = 1
  d.getElementsByTagName('head')[0].appendChild(s)
})()

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
)

window.addEventListener('devtoolschange', (event) => {
  if (devtools.isOpen) {
    console.log('\n\n==== DEBUG PANEL =======================================')
    console.log('API Endpoint: ', process.env.REACT_APP_API)
    console.log('Identity Pool: ', process.env.REACT_APP_IDENTITY_POOL_ID)
    console.log('User Pool:', process.env.REACT_APP_USER_POOLS_ID)
    console.log('Region:', process.env.REACT_APP_REGION)
    console.log('Environment: ', process.env.REACT_APP_ENVIRONMENT)
    console.log('========================================================\n\n')
  }
})
