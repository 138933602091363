import { Auth } from 'aws-amplify'
import { Company } from './Company'
import { Networking } from './Networking'
export const User = {
  get: () => {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser({ bypassCache: true })
        .then((user) => {
          resolve(user)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchUserMeta: async (user) => {
    // Now let's fetch some more user details from the backend,
    // like if we're an agency user or a company user
    const userDetails = await Networking.getUser()
    const companyDetails = await Networking.getCompany()
    const messages = await Networking.getAccountMessages()
    const costCenters = await Company.fetchCostCenters()
    console.log('Fetching Currency Rates: ', companyDetails.company.currency)
    const currencyRateToday =
      companyDetails.company.currency === 'CHF' ? 1.0 : await Networking.getCurrencyRate()
    if (userDetails && companyDetails) {
      // All good, place the data into our user management here
      return {
        userDetails: userDetails.user,
        companyDetails: companyDetails.company,
        messages: messages || [],
        awsAuthSpecialStepRequired: false,
        awsUserObject: user,
        loginFailed: false,
        passwordResetRequired: false,
        isAuthenticated: true,
        costCenters: costCenters || [],
        currencyRateToday: currencyRateToday
      }
    } else {
      return {
        loginFailed: true,
        isAuthenticated: false
      }
    }
  }
}
