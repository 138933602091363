import { useEffect, useState } from 'react'
import useStore from '../../../store'
import { Company } from '../../../helpers/Company'
import Loading from '../../../components/common/Misc/Loading'
import TextInput from '../../../components/common/Misc/TextInput'
import './CostCenterPanel.css'

export default function CostCenterPanel(props) {
  const costCenters = useStore((state) => state.costCenters)
  const setCostCenters = useStore((state) => state.setCostCenters)
  const [attemptedToFetchCostCenters, setAttemptedToFetchCostCenters] = useState(false)
  const [loading, setLoading] = useState(false)
  const [q, setQuery] = useState('')

  const filterResults = (q, data) => {
    if (q === '') return []
    if (q.length === 0) return []
    let results = data.filter((d) => {
      if (d.original_id && d.original_id.toLowerCase().indexOf(q.toLowerCase().trim()) >= 0)
        return true
      if (
        d.original_id &&
        d.original_id
          .replace('-', '')
          .replace(' ', '')
          .toLowerCase()
          .indexOf(q.toLowerCase().trim()) >= 0
      )
        return true
      if (d.description && d.description.toLowerCase().indexOf(q.toLowerCase().trim()) >= 0)
        return true
      if (
        d.description &&
        d.description
          .replace('-', '')
          .replace(' ', '')
          .toLowerCase()
          .indexOf(q.toLowerCase().trim()) >= 0
      )
        return true
      return false
    })
    // We need to optimize the results here. First, we need to make sure
    // we don't match hundreds of cost centers (or figure out a better way)
    // to scroll them perhaps, and second, we may need to prioritize some
    // items (for example: if there's a perfect match, that should be on top
    let weightedResults = results.map((r) => {
      if (r.original_id === q.trim()) {
        return {
          score: 2,
          data: r
        }
      }
      return { score: 0, data: r }
    })
    weightedResults.sort((a, b) => b.score - a.score)
    // Clean out the score again, trim and return
    return weightedResults
      .map((r) => {
        return r.data
      })
      .slice(0, 20)
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      if (costCenters.length === 0) {
        console.log('RELOADING COST CENTERS', costCenters.length)
        const data = await Company.fetchCostCenters()
        setCostCenters(data)
        setAttemptedToFetchCostCenters(true)
      }
      setLoading(false)
    }
    if (costCenters.length === 0 && !attemptedToFetchCostCenters) {
      fetchData()
    }
  }, [costCenters, setCostCenters, setAttemptedToFetchCostCenters, attemptedToFetchCostCenters])

  if (loading) {
    return (
      <div style={{ marginTop: 50, marginBottom: 50 }}>
        <Loading />
      </div>
    )
  }

  if (props.labelOnly) {
    // See if we know this cost center
    if (props.costCenterId && costCenters && costCenters.length > 0) {
      let cost_center_details = costCenters.find((cc) => cc.cost_center_id === props.costCenterId)
      if (cost_center_details) {
        return (
          <div style={{ paddingBottom: 5, paddingTop: 5, marginRight: 10 }}>
            <div
              style={{
                backgroundColor: '#fff',
                display: 'inline-block',
                fontSize: 14
              }}>
              {cost_center_details.original_id}
              <br />
              <span style={{ color: '#666' }}>{cost_center_details.description}</span>
            </div>
          </div>
        )
      }
    } else {
      return <div></div>
    }
  }

  return (
    <div className="cc-panel">
      {props.costCenter === null && (
        <>
          <TextInput
            type={'text'}
            name={'costcenter'}
            style={{ paddingLeft: 0, paddingTop: 0 }}
            value={q}
            placeholder="Type to start searching..."
            onWhite={true}
            onChange={(d) => {
              setQuery(d)
            }}
          />
          {q === '' && (
            <div style={{ fontSize: 12, color: '#666', fontStyle: 'italic' }}>
              Results will appear here once you've started the search
            </div>
          )}
        </>
      )}

      {props.costCenter === null &&
        filterResults(q, costCenters)
          .slice(0, 10)
          .map((result, i) => {
            return (
              <div
                className={'cc-result'}
                key={`cc_result_${i}`}
                onClick={() => {
                  if (props.onChange) {
                    props.onChange(result)
                  }
                }}>
                <div>{result.original_id}</div>
                <div style={{ fontSize: 13, color: '#666' }}>{result.description}</div>
              </div>
            )
          })}

      {props.costCenter !== null && (
        <div className={'cc-selected'}>
          {props.costCenter && props.costCenter.original_id}{' '}
          <div
            className={`cc-remove-button`}
            onClick={() => {
              if (props.onChange) {
                props.onChange(null)
              }
            }}>
            ✕
          </div>
        </div>
      )}
    </div>
  )
}
