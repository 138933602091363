import React, { useRef, useState } from 'react'
import { Popover } from 'react-tiny-popover'
import FilterButton from '../../../FilterButton'
import FilterPanel from './FilterPanel'
import './SearchFilters.css'

export default function FilterPopover(props) {
  const [popoverState, showPopover] = useState(false)
  const buttonRef = useRef()
  return (
    <Popover
      isOpen={popoverState}
      positions={['bottom', 'right']}
      align={'start'}
      ref={buttonRef}
      onClickOutside={() => {
        showPopover(false)
      }}
      content={
        <FilterPanel
          title={props.title || ''}
          onClose={() => {
            showPopover(false)
          }}>
          {props.children}
        </FilterPanel>
      }>
      <FilterButton
        ref={buttonRef}
        text={props.active ? props.selectedTitle : props.title}
        disabled={!props.active}
        onClick={() => {
          showPopover(!popoverState)
        }}
        onCancel={() => {
          if (props.onCancel) {
            props.onCancel()
          }
        }}
      />
    </Popover>
  )
}
