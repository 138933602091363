import { useState } from 'react'
import Button from '../../components/common/Misc/Button'
import ErrorText from '../../components/common/Misc/ErrorText'
import TextInput from '../../components/common/Misc/TextInput'
import FlightBundleResult from '../../components/FlightBundleResult'
import FlightSearchPanel from '../../components/common/FlightSearch/FlightSearchPanel'
import FilterAirlines from '../../components/common/SearchFilters/FilterAirlines'
import FilterConnectingAirport from '../../components/common/SearchFilters/FilterConnectingAirport'
import FilterLayover from '../../components/common/SearchFilters/FilterLayover'
import FilterPrice from '../../components/common/SearchFilters/FilterPrice'
import FilterStopovers from '../../components/common/SearchFilters/FilterStopovers'
import FilterTimes from '../../components/common/SearchFilters/FilterTimes'
import { Networking } from '../../helpers/Networking'
import { Tools } from '../../helpers/Tools'

import useStore from '../../store'

const getMaxPrice = (flights) => {
  var maxPrice = 0
  flights.forEach((f, i) => {
    if (f.price.value > maxPrice) {
      maxPrice = f.price.value
    }
  })
  return maxPrice
}

const getAvailableAirlines = (flights) => {
  var carriers = []
  flights.forEach((f) => {
    f.outward_itinerary.segments.forEach((segment) => {
      if (carriers.indexOf(Tools.figureOutCarrier(segment)) < 0)
        carriers.push(Tools.figureOutCarrier(segment))
    })
    f.return_itinerary.segments.forEach((segment) => {
      if (carriers.indexOf(Tools.figureOutCarrier(segment)) < 0)
        carriers.push(Tools.figureOutCarrier(segment))
    })
  })
  // Finally, sort them alphabetically, if possible
  return carriers.slice().sort()
}

const getConnectingAirportsForFlightLeg = (flight_itinerary) => {
  var airports = []
  if (flight_itinerary.segments.length > 1) {
    for (var i = 0; i < flight_itinerary.segments.length - 1; i++) {
      var airport = flight_itinerary.segments[i].destination
      if (airports.indexOf(airport) < 0) {
        airports.push(airport)
      }
    }
  }
  return airports
}

const getAvailableConnectingAirports = (flights) => {
  // Connecting Airports: 1 > 2 ... 2 > 3  (2)
  // Or: 1 > 2 ... 2 > 3 ... 3 > 4 (2 and 3)
  var airports = []
  flights.forEach((flight) => {
    getConnectingAirportsForFlightLeg(flight.outward_itinerary).forEach((airport) => {
      if (airports.indexOf(airport) < 0) airports.push(airport)
    })
    getConnectingAirportsForFlightLeg(flight.return_itinerary).forEach((airport) => {
      if (airports.indexOf(airport) < 0) airports.push(airport)
    })
  })
  return airports
}

const hasFlightSpecificConnectingAirport = (flight, airport) => {
  let airports_outbound = getConnectingAirportsForFlightLeg(flight.outward_itinerary)
  let airports_inbound = getConnectingAirportsForFlightLeg(flight.return_itinerary)
  if (airports_outbound.indexOf(airport) >= 0) return true
  if (airports_inbound.indexOf(airport) >= 0) return true
  return false
}

const hasLayoverFlights = (flights) => {
  var foundLayover = false
  flights.forEach((flight) => {
    if (flight.outward_itinerary.segments.length > 1) foundLayover = true
    if (flight.return_itinerary.segments.length > 1) foundLayover = true
  })
  return foundLayover
}

export default function WizardSearch(props) {
  // Global State
  const agencyClient = useStore((state) => state.agencyClient)
  const travelerId = useStore((state) => state.travelerId)
  const flightResults = useStore((state) => state.flightResults)
  const habitToBeat = useStore((state) => state.habitToBeat)
  const setSearchId = useStore((state) => state.setSearchId)
  const setHabitToBeat = useStore((state) => state.setHabitToBeat)
  const setFlightResults = useStore((state) => state.setFlightResults)

  // This contains all the airports and similar things
  const setDictionary = useStore((state) => state.setDictionary)

  // Local State
  const [isLoading, setLoading] = useState(false)
  const [queryFailed, setQueryFailed] = useState(false)
  const [noResultsFound, setNoResultsFound] = useState(false)
  const [textFilter, setTextFilter] = useState('')
  const [resultLimit, setResultLimit] = useState(20)

  // Local filters
  const defaultMaxStops = 5
  const [maxStops, setMaxStopsFilter] = useState(defaultMaxStops)
  const [maxPrice, setMaxPrice] = useState(getMaxPrice(flightResults))
  const defaultLayoverMinimum = 0
  const [layoverMinimum, setLayoverMinimum] = useState(defaultLayoverMinimum)
  const defaultLayoverMaximum = 24
  const [layoverMaximum, setLayoverMaximum] = useState(defaultLayoverMaximum)
  const [selectedAirlines, setAirlines] = useState(getAvailableAirlines(flightResults))
  const [connectingAirports, setConnectingAirports] = useState(
    getAvailableConnectingAirports(flightResults)
  )
  const [filterTimeOutboundDepartureFrom, setOutboundDepartureFrom] = useState(0)
  const [filterTimeOutboundDepartureTo, setOutboundDepartureTo] = useState(24)
  const [filterTimeOutboundArrivalFrom, setOutboundArrivalFrom] = useState(0)
  const [filterTimeOutboundArrivalTo, setOutboundArrivalTo] = useState(24)
  const [filterTimeReturnDepartureFrom, setReturnDepartureFrom] = useState(0)
  const [filterTimeReturnDepartureTo, setReturnDepartureTo] = useState(24)
  const [filterTimeReturnArrivalFrom, setReturnArrivalFrom] = useState(0)
  const [filterTimeReturnArrivalTo, setReturnArrivalTo] = useState(24)

  // Default Search
  const [searchDetails, updateSearchDetails] = useState({
    origin: '',
    destination: '',
    departure_date: Tools.getShortFormatDate(
      new Date(new Date().getTime() + 86400000 * 14)
    ) /* 2 weeks out */,
    departure_time: '00:00:00',
    return_date: Tools.getShortFormatDate(
      new Date(new Date().getTime() + 86400000 * 17)
    ) /* 2 weeks out + 3 days later */,
    return_time: '00:00:00',
    cabin_class: 'ECONOMY',
    max_stops: 2
  })

  const parseFlightResults = (data) => {
    if (Object.keys(data.result.connections.roundtrip_bundles).length === 0) {
      setFlightResults([])
      setSearchId(data.result.id)
      setNoResultsFound(true)
    } else {
      setNoResultsFound(false)
    }

    var roundtrips = []
    try {
      // Iterature through all cabin classes and concat all the results into our roundtrips collection
      Object.keys(data.result.connections.roundtrip_bundles).forEach((classOptions, i) => {
        roundtrips = roundtrips.concat(data.result.connections.roundtrip_bundles[classOptions])
      })
    } catch (e) {
      console.log(e)
    }

    // Prepare the dictionary first so it is ready when we'll need it
    setDictionary(data.result.dictionaries)

    // Okay, tell the state that we've got new flights ready to go!
    setFlightResults(roundtrips)

    // Let the wizard know what our search ID is. This ID will be used for
    // all subsequent requests, so that the backend knows what session we're talking about.
    setSearchId(data.result.id)
  }

  const launchSearch = () => {
    setLoading(true)
    setFlightResults([])
    setQueryFailed(false)
    setNoResultsFound(false)

    Networking.searchFlights({
      customer_id: agencyClient ? agencyClient.id : 0,
      traveler_id: travelerId.toString(),
      search_query: searchDetails
    })
      .then((data) => {
        parseFlightResults(data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setQueryFailed(true)
        setLoading(false)
      })
  }

  // Not really a pagination, more of a cut off to have a quick render
  // with the option to extend to the full list if needed
  const paginateFlightResults = (flights, limit) => {
    return limit <= 0 ? flights : flights.slice(0, limit)
  }

  const filterFlightResults = (flights, textFilter) => {
    var filteredData = flights

    // Filter by: TIMES
    filteredData = filteredData.filter((flight) => {
      if (
        Tools.parseServerTime(flight.outward_itinerary.segments[0].departure_time).getHours() <
        filterTimeOutboundDepartureFrom
      )
        return false
      if (
        Tools.parseServerTime(flight.outward_itinerary.segments[0].departure_time).getHours() >
        filterTimeOutboundDepartureTo
      )
        return false
      if (
        Tools.parseServerTime(
          flight.outward_itinerary.segments[flight.outward_itinerary.segments.length - 1]
            .arrival_time
        ).getHours() < filterTimeOutboundArrivalFrom
      )
        return false
      if (
        Tools.parseServerTime(
          flight.outward_itinerary.segments[flight.outward_itinerary.segments.length - 1]
            .arrival_time
        ).getHours() > filterTimeOutboundArrivalTo
      )
        return false

      if (
        Tools.parseServerTime(flight.return_itinerary.segments[0].departure_time).getHours() <
        filterTimeReturnDepartureFrom
      )
        return false
      if (
        Tools.parseServerTime(flight.return_itinerary.segments[0].departure_time).getHours() >
        filterTimeReturnDepartureTo
      )
        return false
      if (
        Tools.parseServerTime(
          flight.return_itinerary.segments[flight.return_itinerary.segments.length - 1].arrival_time
        ).getHours() < filterTimeReturnArrivalFrom
      )
        return false
      if (
        Tools.parseServerTime(
          flight.return_itinerary.segments[flight.return_itinerary.segments.length - 1].arrival_time
        ).getHours() > filterTimeReturnArrivalTo
      )
        return false

      return true
    })

    // Filter by: CONNECTING AIRPORTS
    if (connectingAirports.length > 0) {
      filteredData = filteredData.filter((flight) => {
        var foundAirport = false
        connectingAirports.forEach((airport) => {
          if (hasFlightSpecificConnectingAirport(flight, airport)) {
            foundAirport = true
          }
        })
        return foundAirport
      })
    }

    // Filter by: AIRLINES
    // At the moment, we're including any flight that has a segment that includes this
    if (selectedAirlines.length > 0) {
      filteredData = filteredData.filter((flight) => {
        // Include this flight, if any of the segments are done on a "wanted" airline
        let outboundMatch = flight.outward_itinerary.segments.find(
          (segment) => selectedAirlines.indexOf(Tools.figureOutCarrier(segment)) >= 0
        )
        let inboundMatch = flight.return_itinerary.segments.find(
          (segment) => selectedAirlines.indexOf(Tools.figureOutCarrier(segment)) >= 0
        )
        return inboundMatch || outboundMatch
      })
    }

    // Filter by: STOPS
    filteredData = filteredData.filter((flight) => {
      return (
        flight.outward_itinerary.segments.length - 1 <= parseInt(maxStops, 10) &&
        flight.return_itinerary.segments.length - 1 <= parseInt(maxStops, 10)
      )
    })

    // Filter by: MAX PRICE
    if (maxPrice > 0) {
      filteredData = filteredData.filter((flight) => {
        return flight.price.value < maxPrice
      })
    }

    // Filter by: LAYOVER DURATION
    filteredData = filteredData.filter((flight) => {
      return (
        Tools.calculateTotalLayoverDuration(flight.outward_itinerary.segments) <
          layoverMaximum * 60 &&
        Tools.calculateTotalLayoverDuration(flight.outward_itinerary.segments) >=
          layoverMinimum * 60 &&
        Tools.calculateTotalLayoverDuration(flight.return_itinerary.segments) >=
          layoverMinimum * 60 &&
        Tools.calculateTotalLayoverDuration(flight.return_itinerary.segments) < layoverMaximum * 60
      )
    })

    // Require at least 2 characters to start filtering
    if (textFilter && textFilter.length > 1) {
      var textFilterResults = []
      filteredData.forEach((flight) => {
        var includeScore = 0 // The score will help us later rank the most matching results first

        // Let's look at all flight segments combined of this bundle, to see what we can match in there
        flight.outward_itinerary.segments
          .concat(flight.return_itinerary.segments)
          .forEach((segment) => {
            if (
              segment &&
              (Tools.figureOutCarrier(segment) || '---').indexOf(textFilter.toUpperCase()) >= 0
            )
              includeScore++
            if (segment && segment.origin && segment.origin.indexOf(textFilter.toUpperCase()) >= 0)
              includeScore++
            if (
              segment &&
              segment.destination &&
              segment.destination.indexOf(textFilter.toUpperCase()) >= 0
            )
              includeScore++
            if (
              segment &&
              segment.flight_number &&
              segment.flight_number.toString().indexOf(textFilter.toUpperCase()) >= 0
            )
              includeScore++
            if (
              segment &&
              segment.aircraft_code &&
              segment.aircraft_code.toString().indexOf(textFilter.toUpperCase()) >= 0
            )
              includeScore++

            // Also look for combinations, like carrier and flight number
            if (segment && segment.flight_number) {
              // Make sure we have the necessary data
              let flightAndCarrier1 =
                Tools.figureOutCarrier(segment) + ' ' + segment.flight_number.toString()
              let flightAndCarrier2 =
                Tools.figureOutCarrier(segment) + '' + segment.flight_number.toString()
              if (textFilter.trim().toUpperCase() === flightAndCarrier1.toUpperCase())
                includeScore++
              if (textFilter.trim().toUpperCase() === flightAndCarrier2.toUpperCase())
                includeScore++
            }
          })

        if (includeScore > 0) textFilterResults.push(flight)
      })
      filteredData = textFilterResults
    }
    return filteredData
  }

  // Calculate filtered results
  let filteredResults = filterFlightResults(flightResults, textFilter) || []

  return (
    <div>
      <h1 style={{ marginBottom: 10 }}>Your Habit-to-Beat</h1>
      <h4 style={{ fontWeight: 'normal', color: '#666', marginBottom: 50 }}>
        Search &amp; select the flight you would normally choose. Your Climate Options are proposed
        in the next step.
      </h4>

      <FlightSearchPanel
        details={searchDetails}
        onUpdateSearch={(newDetails) => {
          updateSearchDetails(newDetails)
        }}
        onSearchEdited={() => {
          setFlightResults([])
        }}
        onSearch={launchSearch}
        isLoading={isLoading}
        showSearchButton={flightResults.length === 0}>
        {flightResults.length > 0 && (
          <div id="result-filter-container" style={{ display: 'flex', gap: 10, flexWrap: 'wrap' }}>
            {flightResults && flightResults.length > 0 && (
              <TextInput
                style={{ width: 70, fontSize: 12 }}
                debounce={true}
                type={'text'}
                value={textFilter}
                placeholder="Flight #"
                onWhite={true}
                onChange={(d) => {
                  setTextFilter(d)
                }}
              />
            )}

            {hasLayoverFlights(flightResults) && (
              <FilterStopovers
                defaultValue={defaultMaxStops} // Any number of stops = 5
                value={maxStops}
                onChange={(maxStops) => {
                  setMaxStopsFilter(parseInt(maxStops, 10))
                }}
                onCancel={() => {
                  setMaxStopsFilter(defaultMaxStops)
                }}
              />
            )}

            {flightResults.length > 0 && (
              <FilterPrice
                defaultValue={getMaxPrice(flightResults) || 100000}
                value={maxPrice || getMaxPrice(flightResults) || 100000}
                onChange={(newMaxPrice) => {
                  setMaxPrice(parseFloat(newMaxPrice))
                }}
                onCancel={() => {
                  setMaxPrice(getMaxPrice(flightResults))
                }}
              />
            )}

            {hasLayoverFlights(flightResults) && (
              <FilterLayover
                defaultValue={[defaultLayoverMinimum, defaultLayoverMaximum]}
                value={[layoverMinimum, layoverMaximum]}
                onChange={(layoverDuration) => {
                  setLayoverMinimum(parseInt(layoverDuration[0], 10))
                  setLayoverMaximum(parseInt(layoverDuration[1], 10))
                }}
                onCancel={() => {
                  setLayoverMinimum(defaultLayoverMinimum)
                  setLayoverMaximum(defaultLayoverMaximum)
                }}
              />
            )}

            <FilterAirlines
              defaultValue={getAvailableAirlines(flightResults)}
              value={selectedAirlines}
              availableAirlines={getAvailableAirlines(flightResults)}
              onChange={(selectedAirlines) => {
                setAirlines([...selectedAirlines])
              }}
              onCancel={() => {
                setAirlines(getAvailableAirlines(flightResults))
              }}
            />

            {getAvailableConnectingAirports(flightResults).length > 0 && (
              <FilterConnectingAirport
                defaultValue={getAvailableConnectingAirports(flightResults)}
                value={connectingAirports}
                availableAirports={getAvailableConnectingAirports(flightResults)}
                onChange={(connectingAirports) => {
                  setConnectingAirports([...connectingAirports])
                }}
                onCancel={() => {
                  setConnectingAirports(getAvailableConnectingAirports(flightResults))
                }}
              />
            )}

            {flightResults.length > 0 && (
              <FilterTimes
                outboundDepartureFrom={filterTimeOutboundDepartureFrom}
                outboundDepartureTo={filterTimeOutboundDepartureTo}
                outboundArrivalFrom={filterTimeOutboundArrivalFrom}
                outboundArrivalTo={filterTimeOutboundArrivalTo}
                returnDepartureFrom={filterTimeReturnDepartureFrom}
                returnDepartureTo={filterTimeReturnDepartureTo}
                returnArrivalFrom={filterTimeReturnArrivalFrom}
                returnArrivalTo={filterTimeReturnArrivalTo}
                onOutboundDepartureFromChange={(t) => setOutboundDepartureFrom(t)}
                onOutboundDepartureToChange={(t) => setOutboundDepartureTo(t)}
                onOutboundArrivalFromChange={(t) => setOutboundArrivalFrom(t)}
                onOutboundArrivalToChange={(t) => setOutboundArrivalTo(t)}
                onReturnDepartureFromChange={(t) => setReturnDepartureFrom(t)}
                onReturnDepartureToChange={(t) => setReturnDepartureTo(t)}
                onReturnArrivalFromChange={(t) => setReturnArrivalFrom(t)}
                onReturnArrivalToChange={(t) => setReturnArrivalTo(t)}
                onCancel={() => {
                  setOutboundDepartureFrom(0)
                  setOutboundDepartureTo(24)
                  setOutboundArrivalFrom(0)
                  setOutboundArrivalTo(24)
                  setReturnDepartureFrom(0)
                  setReturnDepartureTo(24)
                  setReturnArrivalFrom(0)
                  setReturnArrivalTo(24)
                }}
              />
            )}
          </div>
        )}
      </FlightSearchPanel>

      <div style={{ marginTop: 100, maxWidth: 860, display: queryFailed ? 'block' : 'none' }}>
        <ErrorText show={queryFailed}>
          There was an issue trying to query the flight engine. Please try again.
        </ErrorText>
      </div>

      {noResultsFound && (
        <div style={{ padding: 20, marginTop: 100, textAlign: 'center' }}>
          Sorry, there are no available flights for this cabin class or time-frame.
        </div>
      )}

      {/* Just for now: we're adding a SECOND continue button up here (before results), so
          we don't confuse the user too much with the Search Flights button still being visible. */}
      {flightResults.length > 0 && (
        <div
          style={{
            marginTop: 50,
            borderTop: '1px solid #ddd',
            borderBottom: '1px solid #ddd',
            paddingTop: 20,
            paddingBottom: 20,
            paddingRight: 20,
            textAlign: 'right',
            maxWidth: 875,
            fontSize: 14,
            color: '#666'
          }}>
          You can move to the next step once you've selected a flight &nbsp; &nbsp; &nbsp;
          <Button
            id="selected-flight-and-continue"
            disabled={!habitToBeat}
            text="Continue"
            onClick={props.onCompleted}
          />
        </div>
      )}

      {flightResults.length > 0 && (
        <div style={{ marginTop: 10 }}>
          <FlightBundleResult
            results={paginateFlightResults(filteredResults, resultLimit)}
            onResultPicked={setHabitToBeat}
          />
          <br />
          {!!resultLimit && resultLimit < filteredResults.length && (
            <div
              style={{
                textAlign: 'center',
                fontSize: 13,
                color: '#666',
                width: 875,
                marginBottom: 20
              }}>
              We are only showing the top-most results.{' '}
              <span
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => {
                  setResultLimit(0)
                }}>
                Show all {filteredResults.length} results
              </span>
            </div>
          )}
          <br />
          <Button
            disabled={!habitToBeat}
            id="selected-flight-and-continue-bottom"
            text="Continue"
            onClick={props.onCompleted}
          />
        </div>
      )}
    </div>
  )
}
