import { Auth } from 'aws-amplify'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Button from '../components/common/Misc/Button'
import ErrorText from '../components/common/Misc/ErrorText'
import Loading from '../components/common/Misc/Loading'
import TextInput from '../components/common/Misc/TextInput'
import PasswordChecklist from 'react-password-checklist'

const useQuery = () => {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export default function PasswordResetPage() {
  let query = useQuery()

  // Local state
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordVerification, setPasswordVerification] = useState('')
  const [code, setCode] = useState(query.get('code') || '')
  const [loading, setIsLoading] = useState(false)
  const [localError, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [needsCode, setNeedsCode] = useState(false)
  const [passwordValid, setPasswordValid] = useState(false)

  const alreadyHadCode = query.get('code') ? true : false

  const resetPasswordWithCode = (e) => {
    e.preventDefault()
    if (password !== passwordVerification) {
      alert('Your two passwords do not match. Please type them again.')
      setPassword('')
      setPasswordVerification('')
      return false
    }
    setIsLoading(true)
    Auth.forgotPasswordSubmit(username.trim(), code, password.trim())
      .then((data) => {
        setSuccess(true)
        setNeedsCode(false)
        setIsLoading(false)
        console.log(data)
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
    return false
  }

  const requestReset = (e) => {
    e.preventDefault()
    setIsLoading(true)

    // If we already have a code in the URL,
    // then we can skip forward (password may have been reset)
    // and go to the code screen
    if (code !== '') {
      console.log('Code already provided...')
      setNeedsCode(true)
      setIsLoading(false)
    } else {
      Auth.forgotPassword(username.trim())
        .then((data) => {
          setError(false)
          setNeedsCode(true)
          console.log(data)
          setIsLoading(false)
        })
        .catch((err) => {
          setError(true)
          setNeedsCode(false)
          console.log(err)
          setIsLoading(false)
          if (err.toString().indexOf('cannot be empty') >= 0) {
            alert('You need to specify a username to continue. Please try again.')
            return
          }
        })
    }
    return false
  }

  return (
    <div
      style={{
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 100,
        padding: 20,
        backgroundColor: 'white',
        boxShadow: '5px 5px 10px #eee',
        borderRadius: 10
      }}>
      <div style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold', marginBottom: 20 }}>
        <img src="/logo.png" alt="Logo" height="20" />
      </div>

      {loading && (
        <div style={{ marginTop: 50, marginBottom: 50 }}>
          <Loading />
        </div>
      )}
      <ErrorText show={localError}>Please try again or reach out to us directly.</ErrorText>

      {success && (
        <div style={{ marginBottom: 20, textAlign: 'center' }}>
          <strong>Reset completed.</strong>
          <br />
          <br />
          Please log in with your new password now.
          <br />
          <br />
          <Button
            testPoint="back-to-login-button"
            type="button"
            text={'Login'}
            onClick={() => {
              document.location.href = '/login'
            }}
          />
        </div>
      )}

      {!loading && needsCode && (
        <form onSubmit={resetPasswordWithCode}>
          <div style={{ marginBottom: 50 }}>
            {alreadyHadCode && (
              <>
                <strong>
                  Please verify the code below matches the one you received by e-mail.
                </strong>
                <br />
                <br />
                If it matches, then type in your new password twice to initiate a reset.
              </>
            )}
            {!alreadyHadCode && (
              <>
                <strong>You should have received an e-mail with a code.</strong>
                <br />
                <br />
                Type in the code from the e-mail, as well as your new password twice to initiate a
                reset.
              </>
            )}
          </div>

          <TextInput
            type={'number'}
            label={'Verification Code'}
            name={'code'}
            value={code}
            placeholder="Enter the code..."
            onWhite={true}
            onChange={(d) => {
              setCode(d)
            }}
          />

          <TextInput
            type={'password'}
            label={'Password'}
            name={'password'}
            value={password}
            placeholder="New Password"
            onWhite={true}
            onChange={(d) => {
              setPassword(d)
            }}
          />

          <TextInput
            type={'password'}
            label={'Repeat your Password'}
            name={'password2'}
            value={passwordVerification}
            placeholder="Repeat Password"
            onWhite={true}
            onChange={(d) => {
              setPasswordVerification(d)
            }}
          />

          <br />

          <PasswordChecklist
            style={{ fontSize: 14 }}
            rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase', 'match']}
            minLength={8}
            value={password}
            valueAgain={passwordVerification}
            onChange={(isValid) => {
              setPasswordValid(isValid)
            }}
          />

          <div style={{ textAlign: 'right' }}>
            <Button
              disabled={!passwordValid}
              testPoint="password-reset-button-confirm"
              type="submit"
              text={'Reset Password'}
            />
          </div>
        </form>
      )}

      {!loading && !success && !needsCode && (
        <form onSubmit={requestReset}>
          <div style={{ marginBottom: 50, paddingTop:20 }}>
            {alreadyHadCode && (
              <>
                <strong>
                  To finish this process, please confirm the e-mail address you just used.
                </strong>
                <br />
                <br />
                <div style={{fontSize: 14, color: "#666"}}>
                Use the exact same e-mail address you received the verification code on a moment ago.
                </div>
              </>
            )}
            {!alreadyHadCode && (
              <>
                <strong>Type in the e-mail address used for this account.</strong>
                <br />
                <br />
                If we can find an account with this address, we will send you an e-mail with
                instructions on how to reset your password.
              </>
            )}
          </div>

          <TextInput
            type={'text'}
            label={'E-mail address'}
            name={'username'}
            value={username}
            placeholder="E-mail Address"
            onWhite={true}
            onChange={(d) => {
              setUsername(d)
            }}
          />

          <br />

          <div style={{ textAlign: 'right' }}>
            <Button testPoint="password-reset-button" type="submit" text={'Continue'} />
          </div>
        </form>
      )}
    </div>
  )
}
