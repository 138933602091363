import './SearchFilters.css'

export default function FilterPanel(props) {
  return (
    <div className={`filterPopover`}>
      <div className="filterPopoverTitle">
        <div style={{ marginTop: -5, fontWeight: 'bold', fontSize: 16 }}>{props.title || ''}</div>
        <div
          style={{
            fontSize: 20,
            position: 'relative',
            marginTop: -8,
            marginRight: 0,
            cursor: 'pointer',
            userSelect: 'none'
          }}
          onClick={() => props.onClose()}>
          &#10005;
        </div>
      </div>
      <div className="filterPopoverBody">{props.children}</div>
    </div>
  )
}
