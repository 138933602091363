import { useState } from 'react'
import { Popover } from 'react-tiny-popover'
import { Tools } from '../../../helpers/Tools'
import useStore from '../../../store'

export default function StopoverPopover(props) {
  const [showPopover, setShowPopover] = useState(false)
  const dictionary = useStore((state) => state.dictionary)

  const calculateLayover = (legIndex) => {
    let departure = Tools.parseServerTime(props.segments[legIndex + 1].departure_time)
    let arrival = Tools.parseServerTime(props.segments[legIndex].arrival_time)
    let layover = parseInt(Math.abs(departure.getTime() - arrival.getTime()) / 1000 / 60)
    return ` ${Tools.minutesToDuration(layover)} `
  }

  // There's a chance we have a manual flight (entered by user, only minimal information)
  // if so, let's make sure we only show what we have.
  var isMinimalFlight = false;
  try {
    if (props.segments[0].departure_time === "1970-01-01T00:00:00+00:00") {
      isMinimalFlight = true
    }
  } catch (e) {
    isMinimalFlight = true
  }

  return (
    <Popover
      isOpen={showPopover}
      positions={[props.position ? props.position : 'left']}
      align={'middle'}
      onClickOutside={() => {
        setShowPopover(false)
      }}
      content={
        <div
          style={{
            backgroundColor: 'white',
            padding: 15,
            boxShadow: '0 2px 10px 0 rgba(6,6,93,0.2)',
            borderRadius: 4,
            minWidth: 180,
            margin: 15
          }}>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: 14,
              letterSpacing: '0.78px',
              padding: '5px 5px'
            }}>
            Flight Details
          </div>
          <div style={{ borderBottom: '1px solid #ddd', paddingTop: 5, marginBottom: 5 }}></div>
          {props.segments &&
            props.segments.map((segment, i) => {
              return (
                <div
                  key={`segment_${i}`}
                  style={{
                    display: 'flex',
                    fontSize: 13,
                    paddingTop: 10,
                    paddingBottom: 10
                  }}>
                  {/* This is the line and dots on the left of the layover popover */}
                  <div
                    style={{
                      width: 20,
                      display: 'flex',
                      flexDirection: 'column'
                    }}>
                    <div
                      style={{
                        backgroundColor: '#aaa',
                        borderRadius: 15,
                        width: 5,
                        height: 5,
                        position: 'relative',
                        top: 5
                      }}></div>
                    <div
                      style={{
                        flexGrow: 1,
                        borderLeft: '1px dashed #ddd',
                        marginLeft: 2
                      }}></div>
                    <div
                      style={{
                        border: '1px solid #ddd',
                        borderRadius: 15,
                        width: 11,
                        height: 11,
                        marginLeft: -3
                      }}></div>
                    {i !== props.segments.length - 1 && <div style={{ height: 60 }}></div>}
                  </div>
                  <div>
                    <div style={{ display: 'flex' }}>
                      <div style={{ width: isMinimalFlight ? 0 : 50 }}>                        
                        {!isMinimalFlight && Tools.flightTimeFromDate(Tools.parseServerTime(segment.departure_time))}
                      </div>
                      <div>{Tools.translateLocationWithDictionary(segment.origin, dictionary)}</div>
                    </div>
                    <div
                      style={{
                        marginLeft: 50,
                        fontSize: 11,
                        letterSpacing: '0.75px',
                        color: '#999',
                        paddingTop: 10,
                        paddingBottom: 10
                      }}>
                        {!isMinimalFlight && 
                          <>
                          {Tools.minutesToDuration(segment.duration)} flight time
                          </>
                        }
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div style={{ width: isMinimalFlight ? 0 : 50 }}>
                        {!isMinimalFlight && Tools.flightTimeFromDate(Tools.parseServerTime(segment.arrival_time))}
                      </div>
                      <div>
                        {Tools.translateLocationWithDictionary(segment.destination, dictionary)}
                      </div>
                    </div>
                    {i !== props.segments.length - 1 && (
                      <div
                        style={{
                          overflow: 'hidden',
                          borderTop: '1px solid #ddd',
                          borderBottom: '1px solid #ddd',
                          paddingTop: 10,
                          paddingBottom: 10,
                          marginTop: 20,
                          fontSize: 12,
                          color: '#999',
                          paddingRight: 40,
                          letterSpacing: '1px'
                        }}>
                        {calculateLayover(i)}
                        layover
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
        </div>
      }>
      <div
        style={{
          cursor: 'pointer',
          paddingBottom: 2,
          borderBottom: '1px dotted #0000aa',
          display: 'inline-block'
        }}
        onMouseOver={() => {
          setShowPopover(true)
        }}
        onMouseOut={() => {
          setShowPopover(false)
        }}>
        {/* This is the label of the hover string that shows the popover (x Stops) */}
        {Tools.renderStopOvers(props.segments)}
      </div>
    </Popover>
  )
}
