import { useEffect, useRef, useState } from 'react'
import IATA from './airports.json'

export const Tools = {
  logger: (data) => {
    const logSuccess = console.log.bind(window.console, '%c %s', 'background: green; color: white')
    logSuccess(data)
  },

  zeroPad: (i) => {
    return i.toString().length < 2 ? `0${i}` : `${i}`
  },

  formatNumber: (n, currency = 'CHF') => {
    if (currency === 'CHF' || currency === '') {
      return new Intl.NumberFormat('de-CH').format(n)
    }
    if (currency === 'EUR') {
      return new Intl.NumberFormat('de-DE').format(n)
    }
    return new Intl.NumberFormat().format(n)
  },

  getShortFormatDate: (d) => {
    return (
      d.getFullYear() + '-' + Tools.zeroPad(d.getMonth() + 1) + '-' + Tools.zeroPad(d.getDate())
    )
  },

  getLongFormatDate: (d) => {
    return (
      d.getFullYear() +
      '-' +
      Tools.zeroPad(d.getMonth() + 1) +
      '-' +
      Tools.zeroPad(d.getDate()) +
      'T' +
      Tools.zeroPad(d.getHours()) +
      ':' +
      Tools.zeroPad(d.getMinutes()) +
      ':00'
    )
  },

  getDateByDayOffset: (start, days) => {
    var date = new Date(start || Date.now())
    var n = Number(days)
    date.setDate(date.getDate() + n)
    return date
  },

  getThisWeekdayInNumberOfMonths: (date, numOfMonths) => {
    var t = new Date(+date)
    var startDayNum = date.getDay()
    t.setMonth(date.getMonth() + numOfMonths)
    var diff = startDayNum - t.getDay()
    diff = diff > 3 ? diff - 7 : diff
    t.setDate(t.getDate() + diff)
    return t
  },

  getSameDayByWeekOffset: (date, numOfWeeks) => {
    let modifiedDate = new Date(date)
    modifiedDate.setDate(date.getDate() + 7 * numOfWeeks)
    return modifiedDate
  },

  differenceInWeeks: (d1, d2) => {
    const msInWeek = 1000 * 60 * 60 * 24 * 7
    return Math.floor(Math.abs(d2 - d1) / msInWeek)
  },

  countWeekOffsetForFutureSearch: (originalDate) => {
    var now = new Date()
    for (var i = 1; i < 104; i++) {
      var potentialFutureDate = Tools.getSameDayByWeekOffset(originalDate, i)
      if (potentialFutureDate < now) continue
      if (Tools.differenceInWeeks(now, potentialFutureDate) >= 8) {
        // We found a good enough distance (about 8 weeks), so return from here
        return i
      }
    }
    return 0
  },

  /*
  getThisWeekdayInNumberOfMonthsFromToday: (date, numOfMonths) => {
    // For the "date", we're just interested in the WEEKDAY there
    // Then we'll take TODAY as a base, and add numOfMonths to move to the future
    // Lastly, we move back or forward to find the nearest exact WEEKDAY from the "date"
    var currentDay = new Date()
    var startDayNum = date.getDay()
    currentDay.setMonth(currentDay.getMonth() + numOfMonths)
    var diff = startDayNum - currentDay.getDay()
    diff = diff > 3 ? diff - 7 : diff
    // Important addition: If diff is negative, then go the other way and
    // instead add a full week (so we don't have a departure that that is after a return date by accident)
    if (diff < 0) {
      currentDay.setDate(currentDay.getDate() + diff + 7)
    } else {
      currentDay.setDate(currentDay.getDate() + diff)
    }
    return currentDay
  },*/

  formatCabinClass: (cabin_class) => {
    var c = cabin_class.replace('PREMIUM_ECONOMY', 'Premium Economy') // Economy and Business can be taken as-is
    return Tools.capitalizeCase(c)
  },

  formatAirport: (airport) => {
    if (typeof airport === 'undefined') return '(Unknown)'
    if (airport.indexOf(':') < 0) return airport
    return Tools.capitalizeCase(airport.split(':')[1])
  },

  isNextDay: (segments) => {
    let startDay = Tools.parseServerTime(segments[0].departure_time)
    let arrivalDay = Tools.parseServerTime(segments[segments.length - 1].arrival_time)
    if (startDay.getDate() !== arrivalDay.getDate()) return true
    return false
  },

  formatTravelDate: (d) => {
    let dayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
    d = d.split('T')[0]
    let incomingYear = d.split('-')[0]
    let incomingMonth = d.split('-')[1]
    let incomingDay = d.split('-')[2]
    let jsDate = new Date(
      incomingYear,
      incomingMonth - 1 /* damn you javascript */,
      incomingDay,
      0,
      0,
      0,
      0
    )
    return (
      dayOfWeek[jsDate.getDay()] +
      ', ' +
      jsDate.getDate() +
      ' ' +
      months[jsDate.getMonth()] +
      ' ' +
      jsDate.getFullYear()
    )
  },

  formatTravelTime: (d) => {
    d = d.split('T')[1]
    return d.split(':')[0] + ':' + d.split(':')[1]
  },

  getHumanReadableDate: (d) => {
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
    if (d && typeof d.getDate === 'function') {
      return Tools.zeroPad(d.getDate()) + ' ' + months[d.getMonth()] + ' ' + d.getFullYear()
    } else {
      return ''
    }
  },

  shortFormatDateToFullDate: (d) => {
    if (d.length !== 10) return new Date() // Or null?
    let incomingYear = d.split('-')[0]
    let incomingMonth = d.split('-')[1]
    let incomingDay = d.split('-')[2]
    return new Date(incomingYear, incomingMonth - 1, incomingDay, 0, 0, 0, 0)
  },

  flightTimeFromDate: (d) => {
    return d.getHours() + ':' + Tools.zeroPad(d.getMinutes())
  },

  parseServerTime: (d) => {
    let [date, time] = d.split('T')
    let incomingYear = date.split('-')[0]
    let incomingMonth = date.split('-')[1]
    let incomingDay = date.split('-')[2]
    let incomingHour = time.split(':')[0]
    let incomingMinute = time.split(':')[1]
    return new Date(
      incomingYear,
      incomingMonth - 1,
      incomingDay,
      incomingHour,
      incomingMinute,
      0,
      0
    )
  },

  minutesToDuration: (minutes) => {
    var h = Math.floor(minutes / 60)
    var m = minutes % 60
    if (h === 0) return `${m}m`
    return `${h}h ${m}m`
  },

  percentageImprovement: (a, base) => {
    if (base === 0) return 0
    return -1 * (100 - Math.round((100 * a) / base))
  },

  capitalizeCase: (word) => {
    return word
      ? word
          .toLowerCase()
          .split(' ')
          .map((e) => e.charAt(0).toUpperCase() + e.substring(1))
          .join(' ')
      : ''
  },

  reduceCarriers: (segments) => {
    // This function tries to reduce the double-mentioning of carriers.
    // So instead of "Swiss, Swiss" it would reduce it to just "Swiss".
    let reducedCarrierList = []
    segments.forEach((segment, i) => {
      let carrier = Tools.figureOutCarrier(segment)
      if (reducedCarrierList.indexOf(carrier) < 0) reducedCarrierList.push(carrier)
    })
    return reducedCarrierList
  },

  figureOutCarrier: (segment) => {
    //return segment.operating_carrier_code ? segment.operating_carrier_code : segment.carrier_code
    return segment.carrier_code // Meeting 12. Sept 22, Ticket 170
  },

  translateLocationWithDictionary: (term, dictionary) => {
    try {
      let iata_detail = IATA[term]['n']
      if (iata_detail) {
        return `${term}: ${iata_detail}`
      }
      //      return dictionary.locations[term].additional.detailed_name
    } catch (e) {
      return term
    }
  },

  extractDictionary: (globalDictionary) => {
    if (globalDictionary['ECONOMY']) {
      return globalDictionary['ECONOMY']
    } else if (globalDictionary['PREMIUM_ECONOMY']) {
      return globalDictionary['PREMIUM_ECONOMY']
    } else if (globalDictionary['BUSINESS']) {
      return globalDictionary['BUSINESS']
    } else if (globalDictionary['FIRST']) {
      return globalDictionary['FIRST']
    }
    return globalDictionary
  },

  translateCarrierWithDictionary: (term, dictionary) => {
    try {
      return dictionary.carriers[term]
    } catch (e) {
      return term
    }
  },

  renderStopOvers: (segments) => {
    if (segments.length <= 1) return 'Non-Stop'
    let label = segments.length === 2 ? 'Stop' : 'Stops'
    return `${segments.length - 1} ${label}: ${segments
      .slice(0, segments.length - 1)
      .map((segment) => segment.destination)
      .join(', ')}`
  },

  calculateTotalLayoverDuration: (segments) => {
    var layoverTime = 0
    if (segments.length === 0) return 0
    if (segments.length === 1) return 0

    var legs = segments.length / 2
    for (var i = 0; i < legs; i++) {
      let departure = Tools.parseServerTime(segments[i + 1].departure_time)
      let arrival = Tools.parseServerTime(segments[i].arrival_time)
      layoverTime += parseInt(Math.abs(departure.getTime() - arrival.getTime()) / 1000 / 60)
    }
    return layoverTime
  },

  calculateTotalFlightDuration: (segments) => {
    var duration = 0
    segments.forEach((segment, i) => {
      duration += parseInt(segment.duration)
    })
    duration += Tools.calculateTotalLayoverDuration(segments)
    return duration
  },

  calculateTotalClimateImpact: (segments) => {
    var impact = 0
    segments.forEach((segment, i) => {
      impact += parseInt(segment.climate_impact)
    })
    if (impact > 99999) return 99999
    return impact
  },

  getAirlineLogo: (segments) => {
    if (Tools.isSingleCarrier(segments)) {
      return `/airlines/${Tools.figureOutCarrier(segments[0]).toLowerCase()}.png`
    } else {
      return '/multiple_airlines.svg'
    }
  },

  isSingleCarrier: (flightSegments) => {
    var carriers = []
    flightSegments.forEach((segment) => {
      if (carriers.indexOf(Tools.figureOutCarrier(segment)) < 0) {
        carriers.push(Tools.figureOutCarrier(segment))
      }
    })
    return carriers.length <= 1
  },

  figureOutCabinClass: (itinerary) => {
    // Just in case we get invalid data
    if (itinerary.segments.length === 0) return 'UNKNOWN'

    // On Non-Stop Flights: Easy (just use the one cabin class)
    if (itinerary.segments.length === 1) {
      return itinerary.segments[0].cabin_class
    }
    // On Multi-Stop Flights:
    // If there's PREM ECO and ECO on a route, then we consider this a PREM ECO flight
    // If there's FIRST and BUSINESS on a route, then we just show BUSINESS (!)
    if (
      itinerary.segments.find((segment) => segment.cabin_class === 'PREMIUM_ECONOMY') &&
      itinerary.segments.find((segment) => segment.cabin_class === 'ECONOMY')
    ) {
      return 'PREMIUM_ECONOMY'
    }
    if (
      itinerary.segments.find((segment) => segment.cabin_class === 'FIRST') &&
      itinerary.segments.find((segment) => segment.cabin_class === 'BUSINESS')
    ) {
      return 'BUSINESS'
    }

    // If we're still here, then determine cabin class by looking at the longer flight segment!
    var cabin_class = itinerary.segments[0].cabin_class
    var cabin_class_duration = itinerary.segments[0].duration
    itinerary.segments.forEach((segment) => {
      if (segment.duration > cabin_class_duration) {
        cabin_class = segment.cabin_class
      }
    })

    return cabin_class
  },

  roundtripContainsCO2Estimates: (roundtrip) => {
    return (
      roundtrip.outward_itinerary.segments.find((s) => s.co2_source === 'api') ||
      (roundtrip.return_itinerary || { segments: [] }).segments.find((s) => s.co2_source === 'api')
    )
  },

  /* This is a helper function that tells us if the window is resizing, or, more importantly,
    if we're in a mobile-sized environment, where we should be showing a mobile-optimized components */
  useWindowSize() {
    const [windowSize, setWindowSize] = useState({ width: undefined, height: undefined })
    useEffect(() => {
      function handleResize() {
        var Android = /Android/.test(navigator.userAgent)
        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
        var iw = iOS || Android ? window.screen.width : window.innerWidth,
          ih = iOS || Android ? window.screen.height : window.innerHeight
        setWindowSize({
          width: iw,
          height: ih
        })
      }
      window.addEventListener('resize', handleResize)
      handleResize()
      return () => window.removeEventListener('resize', handleResize)
    }, [])
    return windowSize
  },

  /* This helps us use timers in components and handles unmounting/deconstructing automatically */
  useInterval: (callback, delay) => {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current()
      }
      if (delay !== null) {
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
  },

  useTimeout: (callback, delay) => {
    const savedCallback = useRef(callback)
    // Remember the latest callback if it changes.
    useEffect(() => {
      savedCallback.current = callback
    }, [callback])
    // Set up the timeout.
    useEffect(() => {
      // Don't schedule if no delay is specified.
      // Note: 0 is a valid value for delay.
      if (!delay && delay !== 0) {
        return
      }
      const id = setTimeout(() => savedCallback.current(), delay)
      return () => clearTimeout(id)
    }, [delay])
  },

  hasDevFlag: (userEmail, flagName) => {
    if (flagName === 'car_mode') {
      if (userEmail === 'support@climatesmart.travel') {
        return true
      }
    }
    return false
  }
}
