import './AirportAutoComplete.css'
export default function AirportAutoComplete(props) {
  return (
    <div id="iata-dropdown" className="airport-suggestions" tabIndex={-1}>
      {props.suggestions.map((result, i) => {
        return (
          <div
            className="airport-suggestion"
            key={`ac_${i}`}
            onClick={() => {
              props.onClick(result)
            }}>
            <div>
              {result.name} ({result.iata})
            </div>
            <div style={{ fontSize: 11, color: '#888' }}>
              {result.city}, {result.country}
            </div>
          </div>
        )
      })}
    </div>
  )
}
