import './CarSearchPanel.css'
import PlaceAutocomplete from './PlaceAutocomplete'
import FlightDateField from '../FlightSearch/FlightDateField'
import { useState } from 'react'
import { Tools } from '../../../helpers/Tools'
import SearchButton from '../Misc/SearchButton'

export default function CarSearchBox(props) {
  const [isDeparturePickerOpen, setDeparturePickerOpen] = useState(false)
  const [isReturnPickerOpen, setReturnPickerOpen] = useState(false)

  // Check if the search/submit button is enabled
  var canSearch = true
  if (props.details.origin === null) {
    canSearch = false
  }
  if (props.details.destination === null) {
    canSearch = false
  }

  return (
    <div className="car-search-panel-container">
      <div className="car-search-panel-container-top-filter-row">
        <div>
          <div style={{ fontSize: 12, color: '#aaa', marginBottom: 5 }}>Trip Type</div>
          <select
            className="car-search-panel-trip-type-dropdown"
            value={props.details.route_direction}
            onChange={(routeDirection) => {
              // If we're switching back from one-way to roundtrip,
              // then we need to restore the original trip dates
              if (routeDirection.target.value === 'roundtrip') {
                props.onUpdateSearch({
                  ...props.details,
                  route_direction: routeDirection.target.value,
                  return_time: '00:00:00',
                  return_date: props.defaultReturnDate
                })
              } else {
                props.onUpdateSearch({
                  ...props.details,
                  route_direction: routeDirection.target.value
                })
              }
              props.onSearchEdited()
            }}>
            <option value="roundtrip">&#x21C4;&nbsp;&nbsp;Round trip</option>
            <option value="oneway">&rarr;&nbsp;&nbsp;One way</option>
          </select>
        </div>

        <div>
          <div style={{ fontSize: 12, color: '#aaa', marginBottom: 5 }}>Car Fuel Type</div>
          <select
            className="car-search-panel-fuel-type-dropdown"
            value={props.details.fuel_type}
            onChange={(fuelType) => {
              props.onUpdateSearch({
                ...props.details,
                fuel_type: fuelType.target.value
              })
              props.onSearchEdited()
            }}>
            <option value="diesel">Diesel</option>
            <option value="petrol">Petrol</option>
            <option value="hybrid">Hybrid</option>
            <option value="eletric">Fully Electric</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div>
          <div style={{ fontSize: 12, color: '#aaa', marginBottom: 5 }}>Fuel Consumption</div>
          <select
            className="car-search-panel-fuel-consumption-dropdown"
            value={props.details.fuel_consumption}
            onChange={(fuelConsumption) => {
              props.onUpdateSearch({
                ...props.details,
                fuel_consumption: fuelConsumption.target.value
              })
              props.onSearchEdited()
            }}>
            <option value="0">Does not apply</option>
            <option value="1">1l / 100km</option>
            <option value="2">2l / 100km</option>
            <option value="3">3l / 100km</option>
            <option value="4">4l / 100km</option>
            <option value="5">5l / 100km</option>
            <option value="6">6l / 100km</option>
            <option value="7">7l / 100km</option>
            <option value="8">8l / 100km</option>
            <option value="9">9l / 100km</option>
            <option value="10">10l / 100km</option>
            <option value="11">11l / 100km</option>
            <option value="12">12l / 100km</option>
            <option value="13">13l / 100km</option>
            <option value="14">14l / 100km</option>
            <option value="15">15l / 100km</option>
          </select>
        </div>

        <div>
          <div style={{ fontSize: 12, color: '#aaa', marginBottom: 5 }}>Are you the driver?</div>
          <select
            className="car-search-panel-driver-dropdown"
            value={props.details.is_driver}
            onChange={(isDriver) => {
              props.onUpdateSearch({
                ...props.details,
                is_driver: isDriver.target.value
              })
              props.onSearchEdited()
            }}>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>

        <div>
          <div style={{ fontSize: 12, color: '#aaa', marginBottom: 5 }}>Total Passengers</div>
          <select
            className="car-search-panel-passengers-dropdown"
            value={props.details.passengers}
            onChange={(passengers) => {
              props.onUpdateSearch({
                ...props.details,
                passengers: passengers.target.value
              })
              props.onSearchEdited()
            }}>
            <option value="1">1 Passenger</option>
            <option value="2">2 Passengers</option>
            <option value="3">3 Passengers</option>
            <option value="4">4 Passengers</option>
            <option value="5">5 Passengers</option>
            <option value="6">6 Passengers</option>
            <option value="7">7 Passengers</option>
            <option value="8">8 Passengers</option>
          </select>
        </div>
      </div>
      <div style={{ marginTop: 20, display: 'flex', gap: 20 }}>
        <PlaceAutocomplete
          label="From"
          location={props.details.origin}
          onChange={(l) => {
            props.onUpdateSearch({
              ...props.details,
              origin: l
            })
            props.onSearchEdited()
          }}
        />

        <PlaceAutocomplete
          label="To"
          location={props.details.destination}
          onChange={(l) => {
            props.onUpdateSearch({
              ...props.details,
              destination: l
            })
            props.onSearchEdited()
          }}
        />

        <FlightDateField
          id="search_departure_date"
          onBlur={() => {
            setDeparturePickerOpen(false)
          }}
          isOpen={isDeparturePickerOpen}
          whenOpened={() => {
            setDeparturePickerOpen(true)
            setReturnPickerOpen(false)
          }}
          whenClosed={() => {
            setDeparturePickerOpen(false)
          }}
          label="Departure Date"
          date={props.details.departure_date}
          minimumDate={new Date()}
          maximumDate={Tools.getDateByDayOffset(new Date(), 365 * 2)}
          onChange={(newDate) => {
            /* 
                Important: If the return date is EARLIER than this new departure date, 
                 then we'll need to move the return date forward to the same day at the very least
              */
            if (props.details.return_date && props.details.return_date < newDate) {
              if (props.details.departure_date !== newDate) {
                props.onUpdateSearch({
                  ...props.details,
                  departure_date: newDate,
                  return_date: newDate
                })
                props.onSearchEdited()
              }
            } else {
              if (props.details.departure_date !== newDate) {
                props.onUpdateSearch({ ...props.details, departure_date: newDate })
                props.onSearchEdited()
              }
            }
          }}
        />
        {props.details.route_direction && props.details.route_direction === 'roundtrip' && (
          <FlightDateField
            style={{ marginLeft: -15 }}
            id="search_return_date"
            onBlur={() => {
              setReturnPickerOpen(false)
            }}
            isOpen={isReturnPickerOpen}
            whenOpened={() => {
              setReturnPickerOpen(true)
              setDeparturePickerOpen(false)
            }}
            whenClosed={() => {
              setReturnPickerOpen(false)
            }}
            label="Return Date"
            minimumDate={Tools.shortFormatDateToFullDate(props.details.departure_date)}
            maximumDate={Tools.getDateByDayOffset(new Date(), 365 * 2)}
            date={
              Tools.shortFormatDateToFullDate(props.details.return_date) <
              Tools.shortFormatDateToFullDate(props.details.departure_date)
                ? props.details.departure_date
                : props.details.return_date
            }
            onChange={(newDate) => {
              if (props.details.return_date !== newDate) {
                props.onUpdateSearch({ ...props.details, return_date: newDate })
                props.onSearchEdited()
              }
            }}
          />
        )}
      </div>
      <div style={{ textAlign: 'center', marginTop: -20 }}>
        {!props.isLoading && (
          <SearchButton
            disabled={!canSearch}
            style={{ backgroundColor: canSearch ? 'rgb(0, 79, 176)' : '#aaa' }}
            id="button-start-search"
            onClick={() => {
              props.onSearch()
            }}
          />
        )}
      </div>
    </div>
  )
}
