import useStore from '../../../store'
import useEmployeeStore from '../../../employee_store'
import { parse } from 'protobufjs'
import Button from '../../common/Misc/Button'

export default function BookingOptions(props) {
  const companyDetails = useStore((state) => state.companyDetails)
  const selectedTrip = useEmployeeStore((state) => state.selectedTrip)

  const googleFlightsProto = `
    syntax="proto3";
    message FlightSearch {
      int32 unknown_1=1;
      int32 unknown_2=2;
      repeated Journey journeys=3;
      repeated uint32 passengers=8 [packed=false];
      CabinClass cabin_class=9;
      int32 unknown_5=14;
      UnknownParam unknown_6=16;
      TripType trip_type=19;
    }
    message Location {
      int32 location_type=1;
      string location_code=2;
    }
    message Journey {
      required string departure_date=2;
      repeated FlightSegment segments=4;
      optional string operating_carrier=6;
      Location from=13;
      Location to=14;
    }
    message FlightSegment {
      string from=1;
      string departure_date=2;
      string to=3;
      string airline=5;
      string flight_number=6;
    }
    message UnknownParam {
      int64 unknown_long_id=1;
    }
    enum TripType {
      ROUNDTRIP = 1;
      ONEWAY = 2;
      MULTICITY = 3;
    }
    enum CabinClass {
      ECONOMY = 1;
      PREMIUM_ECONOMY = 2;
      BUSINESS = 3;
      FIRST = 4;
    }
  `

  const transformSearchToUrl = (protobufRoot, searchObject) => {
    var flightSearch = protobufRoot.lookupType('FlightSearch')
    var message = flightSearch.fromObject(searchObject)
    var buffer = flightSearch.encode(message).finish()
    var b64 = btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)))
    b64 = b64
      .replace(/\//g, '_')
      .replace(/={1,2}$/, '')
      .replace(/\+/g, '-') // Need to remove padding and use slight adjusted encoding!
    return `https://www.google.com/travel/flights/booking?tfs=${b64}`
  }

  const convertFlightSegment = (protobufRoot, segment) => {
    var flight = protobufRoot.lookupType('FlightSegment')
    return flight.fromObject({
      from: segment.from,
      to: segment.to,
      departure_date: segment.departure_date,
      airline: segment.airline,
      flight_number: segment.flight_number.toString()
    })
  }

  const bookWithGoogle = async () => {
    var root = parse(googleFlightsProto, { keepCase: true }).root
    var journey = root.lookupType('Journey')
    var flightSearchClass = root.lookupType('FlightSearch')

    console.log(selectedTrip)

    var outbound_segments = selectedTrip.outward_itinerary.segments.map((segment, i) => {
      return convertFlightSegment(root, {
        from: segment.origin,
        to: segment.destination,
        departure_date: segment.departure_time.split('T')[0],
        airline: segment.carrier_code,
        flight_number: segment.flight_number
      })
    })

    var return_segments = []
    if (selectedTrip.return_itinerary) {
      return_segments = selectedTrip.return_itinerary.segments.map((segment, i) => {
        return convertFlightSegment(root, {
          from: segment.origin,
          to: segment.destination,
          departure_date: segment.departure_time.split('T')[0],
          airline: segment.carrier_code,
          flight_number: segment.flight_number
        })
      })
    }

    var outbound_departure_date =
      selectedTrip.outward_itinerary.segments[0].departure_time.split('T')[0]
    var outbound_from = selectedTrip.outward_itinerary.segments[0].origin
    var outbound_to =
      selectedTrip.outward_itinerary.segments[selectedTrip.outward_itinerary.segments.length - 1]
        .destination
    var outbound_journey = journey.fromObject({
      departure_date: outbound_departure_date,
      segments: outbound_segments,
      from: { location_type: 1, location_code: outbound_from },
      to: { location_type: 1, location_code: outbound_to }
    })

    // If we have a return journey, assemble it
    if (return_segments.length > 0) {
      var return_departure_date =
        selectedTrip.return_itinerary.segments[0].departure_time.split('T')[0]
      var return_from = selectedTrip.return_itinerary.segments[0].origin
      var return_to =
        selectedTrip.return_itinerary.segments[selectedTrip.return_itinerary.segments.length - 1]
          .destination
      var return_journey = journey.fromObject({
        departure_date: return_departure_date,
        segments: return_segments,
        from: { location_type: 1, location_code: return_from },
        to: { location_type: 1, location_code: return_to }
      })
    }

    var journeys = return_journey ? [outbound_journey, return_journey] : [outbound_journey]

    // How many passengers?
    /*
    THIS IS HOW IT WOULD BE ENCODED, BUT WE ONLY HAVE 1 PASSENGER (NO PASSENGER FIELD IN THE SEARCH MASK)
    SO IT DOES NOT MATTER FOR US (FOR NOW) AND WE CAN HARD-CODE IT.
    var passengers = 1 // TODO
    var passengerField = [1]
    if (passengers === 2) {
      passengerField = [1, 1]
    }
    if (passengers === 3) {
      passengerField = [1, 1, 1]
    }
    */

    // Cabin Class? We'll just take it from the first trip for now...
    var cabin_class_str = selectedTrip.outward_itinerary.segments[0].cabin_class.toUpperCase()
    var flightSearchObject = flightSearchClass.fromObject({
      journeys: journeys,
      passengers: [1],
      cabin_class: cabin_class_str,
      unknown_1: 28,
      unknown_2: 2,
      unknown_5: 1,
      unknown_6: {
        unknown_long_id: -1
      },
      trip_type: return_segments.length > 0 ? 'ROUNDTRIP' : 'ONEWAY'
    })

    console.log(flightSearchObject)

    var targetUrl = transformSearchToUrl(root, flightSearchObject)
    console.log(targetUrl)
    window.open(targetUrl, '_blank')
  }

  return (
    <div style={{ marginTop: 40 }}>
      <h2>Booking options</h2>
      <div style={{ color: 'rgb(102, 102, 102)' }}>
        Note: air fares may differ for short distance flights, where Google and the prefered travel
        agent may show airline “light” fares
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: 20,
          backgroundColor: 'white',
          borderRadius: 10,
          boxShadow: 'rgba(10, 30, 170, 0.24) 0px 2px 4px 0px',
          padding: 30
        }}>
        <div style={{ width: '50%' }}>
          <a className={'img-link'} href="https://www.google.com/flights" target="blank">
            <img src="/google_flights.png" alt="Google Flights" style={{ height: 26 }} />
          </a>
          <div style={{ height: 14 }}></div>
          <strong>You can use Google Flights to book your trip</strong>
          <div
            style={{
              marginTop: 10,
              paddingBottom: 10,
              color: '#666',
              fontSize: 15,
              lineHeight: 1.3
            }}></div>

          <div style={{ marginTop: 15 }}>
            <Button title="Book with Google Flights" onClick={bookWithGoogle} />
          </div>
        </div>
        {companyDetails.booking_link !== '' && (
          <div style={{ width: '50%' }}>
            <a className={'img-link'} href="https://www.kuster-reisen.ch/" target="blank">
              <img src="/KusterReisen.png" alt="Kuster Reisen" style={{ height: 20 }} />
            </a>
            <div style={{ height: 20 }}></div>
            <strong>Online booking tool 'Onesto' of our business travel specialist</strong>
            <div
              style={{
                marginTop: 10,
                paddingBottom: 10,
                color: '#666',
                fontSize: 15,
                lineHeight: 1.3
              }}>
              <div style={{ fontWeight: 'bold', color: 'rgb(128, 176, 0)' }}>
                Same air fare but service included:
              </div>
              a small service fee for the travel agent can <br />
              <strong>make a big difference in case of cancellation or rebooking</strong>
            </div>

            <div style={{ marginTop: 15 }}>
              <Button
                title="Online booking with Kuster Reisen"
                onClick={() => {
                  window.open(
                    companyDetails.booking_link
                      ? companyDetails.booking_link
                      : 'https://climpact.ai?nolink'
                  )
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
