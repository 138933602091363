import { useCallback, useState } from 'react'
import { Tools } from '../../../helpers/Tools'
import { Buffer } from 'buffer'
import './TextInput.css'

export default function TextInput(props) {
  let debounceTime = 200
  let debouncer = props.debounce || false
  const [delayedOnChange, setDelayedOnChange] = useState(null)
  const [lastChangeMade, setLastChangeMade] = useState(0)
  const [localCopyBeforeDebounceHits, setLocalCopy] = useState(props.value || '')

  // We need this little helper to make auto-focus work on the input
  // when they're placed inside of Popups and similar elements.
  const callbackRef = useCallback(
    (inputElement) => {
      if (inputElement && props.autoFocus) {
        setTimeout(() => {
          inputElement.focus()
          inputElement.select()
        }, 250)
      }
    },
    [props.autoFocus]
  )

  // If debouncing is enabled, this helps us do that
  Tools.useInterval(() => {
    if (debouncer) {
      // Do we even have something waiting to be sent out?
      if (delayedOnChange !== null) {
        // Okay, we do, so is it time yet?
        if (new Date().getTime() - lastChangeMade > debounceTime) {
          props.onChange(delayedOnChange)
          setDelayedOnChange(null)
          setLastChangeMade(null)
        }
      }
    }
  }, 500)

  // Return our customized input field
  let styleBase = {
    paddingLeft: props.onWhite ? 0 : 10,
    borderBottom: props.onWhite ? '1px solid #ddd' : 'none',
    textAlign: props.textAlign ? props.textAlign : 'left'
  }

  const onBlurHandler = (e) => {
    setTimeout(() => {
      // Check if the new activeElement is a child of the original container
      if (e.relatedTarget && e.relatedTarget.id && e.relatedTarget.id === 'iata-dropdown') {
        // This means we have just selected something in the dropdown
        // So, do nothing!
      } else {
        // This means we have clicked outside of the dropdown
        if (props.onBlur) props.onBlur()
      }
    }, 0)
  }

  return (
    <div onBlur={onBlurHandler} tabIndex={-1}>
      <div>
        {props.label && props.label.length > 0 && (
          <div style={{ fontSize: 12, color: '#333', marginBottom: 5 }}>{props.label}</div>
        )}
        <input
          ref={callbackRef}
          autoComplete={props.noAutoComplete ? 'off' : 'on'}
          autoFocus={props.autoFocus || false}
          className={`text-input ${props.className || ''}`}
          style={{
            styleBase,
            ...(props.style || {})
          }}
          id={props.id || Buffer.from(props.placeholder || '').toString('base64')}
          name={props.name || ''}
          type={props.type || 'text'}
          placeholder={props.placeholder}
          value={debouncer ? localCopyBeforeDebounceHits : props.value}
          onChange={(e) => {
            if (debouncer) {
              setDelayedOnChange(e.target.value)
              setLastChangeMade(new Date().getTime())
              setLocalCopy(e.target.value) // This is so we can already show it in the box, but not tell the "parent" about it
            } else {
              props.onChange(e.target.value)
            }
          }}
          onClick={() => {
            if (props.onClick) props.onClick()
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && props.onEnter) props.onEnter()
          }}
        />
      </div>
      {props.suggestionPanel && props.suggestionPanel}
    </div>
  )
}
