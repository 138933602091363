import { Tools } from '../../../helpers/Tools'


export default function TripSummaryFlight(props) {
  if (props === {}) return;
  if (!props.trip) return;

  var departure_date = false;
  
  // Quick sanity check for the basics:
  if (!props.trip.outward_itinerary) {
    console.warn("Invalid trip object supplied [no_outward_itinerary]: ", props.trip)
    return;
  }
  if (!props.trip.outward_itinerary.segments ) {
    console.warn("Invalid trip object supplied [no_outward_segments]: ", props.trip)
    return;
  }
  if (props.trip.outward_itinerary.segments.length === 0) {
    console.warn("Invalid trip object supplied [outward_segments_empty]: ", props.trip)
    return;
  }

  if (props.trip.return_itinerary && props.trip.return_itinerary.segments.length === 0) {
    // We accept this, but it's not ideal. Return should be an empty object instead!
    console.warn("Invalid trip object supplied [return_segments_empty]: ", props.trip)
    props.trip.return_itinerary = null; // Overwrite to fix this
  }

  try { 
    departure_date = Tools.parseServerTime(
      props.trip.outward_itinerary.segments[0].departure_time
    )
    if (props.trip.return_itinerary !== null) {
      if (props.trip.return_itinerary.segments.length !== 0) {
        var return_date = Tools.parseServerTime(props.trip.return_itinerary.segments[0].departure_time)
        if (props.return_date) {
          return_date = Tools.shortFormatDateToFullDate(props.return_date)
        }  
      }
    }
    // Quick check: if date is way in the past, then mark it as non-existent
    if (departure_date.getTime() <= 0) {
      departure_date = false;
    }
    // Quick check: if date is way in the past, then mark it as non-existent
    if (return_date && return_date.getTime() <= 0) {
      return_date = false;
    }
  } catch (e) {
    departure_date = false;
    return_date = false;
    console.warn("Invalid departure date. Likely no outward itinerary. Trip Object: ", props.trip)
  }

  // If this was a "past flight", then let's take the date (if available) this was originally
  // search for (or when it actually happened)
  if (props.departure_date) {
    departure_date = Tools.shortFormatDateToFullDate(props.departure_date)
  }

  // If the date is invalid, we may have a manually created trip (only limited data available)
  // so only show what we have at this point
  //var hasTiming = (props.departure_time !== "1970-01-01T00:00:00+00:00")
  //console.log(props.trip)

  return (
    <div
      onClick={() => {
        if (props.onClick) {
          props.onClick()
        }
      }}
      style={{
        userSelect: 'none',
        cursor: props.onClick ? 'pointer' : 'default',
        display: 'flex',
        backgroundColor: props.noBackground ? 'transparent' : '#f0f0f5',
        boxShadow: props.noBackground ? '0 2px 4px 0 rgba(10,30,170,0.24)' : 'none',
        padding: 15,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 10,
        fontSize: 14,
        letterSpacing: '0.75px',
        fontWeight: 'bold',
        alignItems: 'center',
        width: '100%'
      }}>
      <img src="/departure.svg" width="20" alt="Departure" style={{ marginRight: 10 }} />
      <span style={{width:40}}>
        {props.trip.outward_itinerary.segments[0].origin}
      </span>
      <div>
        <div style={{ color: '#999', marginLeft: 20, marginRight: 10, marginTio: -5 }}>→</div>
        {props.trip.return_itinerary !== null && (
          <div style={{ color: '#999', marginLeft: 20, marginRight: 10, marginTop: -9 }}>←</div>
        )}
      </div>
      <img
        src="/arrival.svg"
        width="20"
        alt="Arrival"
        style={{ marginRight: 10, marginLeft: 10 }}
      />
      <span style={{width:40}}>
      {
        props.trip.outward_itinerary.segments[props.trip.outward_itinerary.segments.length - 1]
          .destination
      }
      </span>
      <div style={{ borderLeft: '1px solid #ddd', marginLeft: 20, marginRight: 20 }}></div>
      {Tools.getHumanReadableDate(departure_date)}
      {props.trip.return_itinerary !== null && Tools.getHumanReadableDate(return_date) !== "" && (
        <>
          <span style={{ marginLeft: 10, marginRight: 10, color: '#999' }}>&mdash;</span>
          {Tools.getHumanReadableDate(return_date)}
        </>
      )}
      <div style={{ flexGrow: 1 }}></div>
      <div>{props.children}</div>
    </div>
  )
}
