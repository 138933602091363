export default function PageTitle(props) {
  return (
    <div
      className="page-title"
      style={{ userSelect: 'none' }}
      onClick={() => {
        if (props.onClick) {
          props.onClick()
        }
      }}>
      <h1>{props.title}</h1>
      {props.children && (
        <div
          style={{ color: '#666', lineHeight: 1.4, marginBottom: props.noBottomMargin ? 0 : 25 }}>
          {props.children}
        </div>
      )}
    </div>
  )
}
