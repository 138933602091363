import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useEmployeeStore from '../../employee_store'
import useStore from '../../store'

import Button from '../../components/common/Misc/Button'
import TripSummaryFlight from '../../components/common/Misc/TripSummaryFlight'
import PageTitle from '../../components/common/Misc/PageTitle'
import CostCenterPanel from '../../components/employee/CostCenter/CostCenterPanel'
import OfferPanel from '../../components/employee/ClimateOptions/OfferPanel'

import { Networking } from '../../helpers/Networking'
import { Flights } from '../../helpers/Flights'
import OfferPanelGeneric from '../../components/employee/ClimateOptions/OfferPanelGeneric'

export default function EmployeeModuleCostCenter(props) {
  const pastFlight = useEmployeeStore((state) => state.pastFlight)
  const searchId = useEmployeeStore((state) => state.searchId)
  const climateOptionSection = useEmployeeStore((state) => state.climateOptionSection)
  const habitToBeat = useEmployeeStore((state) => state.habitToBeat)
  const recommendations = useEmployeeStore((state) => state.recommendations)
  const selectedTrip = useEmployeeStore((state) => state.selectedTrip)

  const companyDetails = useStore((state) => state.companyDetails)
  const currencyRateToday = useStore((store) => store.currencyRateToday)

  const [costCenter, setCostCenter] = useState(null)
  const [canSkip, setCanSkip] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    console.log('[CostCenter] [useEffect] Habit to Beat: ', habitToBeat)
    if (!habitToBeat) {
      navigate('/?htb_missing')
    }
  }, [habitToBeat, navigate])

  const completeCostCenter = async () => {
    let nextStepUrl = pastFlight ? '/employee/flights/options' : '/employee/flights/done'

    // We already have a trip object (or should have one),
    // so let's update the cost center on that.
    if (costCenter !== null) {
      let payload = {
        trip_id: searchId,
        cost_center: costCenter.cost_center_id
      }
      const result = await Networking.updateTripCostCenter(payload, searchId)
      if (result) {
        // Depending on which workflow we're in,
        // we are either done, or need to go to a last
        // step that shows the user their future options
        navigate(nextStepUrl)
      }
    } else {
      // Assume the user skipped, so let's just proceed
      navigate(nextStepUrl)
    }
  }

  // Prepare the offer panel
  var offerPanelSection = climateOptionSection
  var offerPanelHabitToBeat = Flights.getSectionFromRecommendations(
    recommendations,
    'habit_to_beat'
  )
  var offerPanelOffer = selectedTrip
  console.log('Is Past Flight? ', pastFlight)
  if (pastFlight) {
    offerPanelSection = 'habit_to_beat'
    offerPanelHabitToBeat = habitToBeat
    offerPanelOffer = habitToBeat
    console.log('CostCenter: HTB: ', habitToBeat)
  }

  return (
    <div>
      <PageTitle title={'Assign Cost Center'}></PageTitle>
      <h4 style={{ fontWeight: 'normal', color: '#666', marginBottom: 50 }}>
        Identify your cost center or PSP code
      </h4>

      <div style={{ marginTop: 30, marginBottom: 40 }}>
        <TripSummaryFlight
          trip={habitToBeat}
          departure_date={habitToBeat.departure_date || null}
          return_date={habitToBeat.return_date || null}
          noBackground={true}
        />
      </div>

      {climateOptionSection !== 'train' && climateOptionSection !== 'online' && (
        <OfferPanel
          showPolicyViolations={companyDetails.feature_policies || false}
          key={`offer_panel`}
          section={offerPanelSection}
          disabled={true}
          carbonPricing={companyDetails.feature_carbon_pricing}
          habitToBeat={offerPanelHabitToBeat}
          offer={offerPanelOffer}
          selected={true}
          hidePrice={pastFlight || false}
          displayCurrency={companyDetails.currency || 'CHF'}
          currencyConversionRate={currencyRateToday || 1.0}
        />
      )}

      {climateOptionSection === 'train' && (
        <OfferPanelGeneric
          key="option_train"
          section="option_train"
          compareTo={habitToBeat}
          disabled={true}
          selected={true}
        />
      )}
      {climateOptionSection === 'online' && (
        <OfferPanelGeneric
          key="option_online"
          section="option_online"
          compareTo={habitToBeat}
          disabled={true}
          selected={true}
        />
      )}

      <div>
        <h2>Identify the cost center</h2>
        {!canSkip && (
          <CostCenterPanel
            costCenter={costCenter}
            onChange={(cc) => {
              setCostCenter(cc)
            }}
          />
        )}
      </div>

      <div style={{ fontSize: 14, marginTop: 20, display: 'flex', alignItems: 'center' }}>
        <label>
          <input
            type="checkbox"
            id="skip-cost-center"
            name="skip-cost-center"
            value={canSkip}
            onChange={(e) => {
              setCanSkip(e.target.checked)
              if (e.target.checked) {
                setCostCenter(null)
              }
            }}
          />
          &nbsp; Skip Cost Center for now. I will add this later.
        </label>
      </div>

      <div style={{ marginTop: 30, textAlign: 'right' }}>
        <Button
          disabled={costCenter === null && !canSkip}
          text={canSkip ? 'Skip Cost Center' : 'Submit Cost Center'}
          large={false}
          onClick={completeCostCenter}
        />
      </div>
    </div>
  )
}
