import { CarbonPricing } from '../../../helpers/CarbonPricing'
import ReactTooltip from 'react-tooltip'
import { Tools } from '../../../helpers/Tools'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useStore from '../../../store'

export default function CarbonPricingLabel(props) {
  const companyDetails = useStore((store) => store.companyDetails)

  let carbon_price = CarbonPricing.calculate(
    companyDetails.carbon_pricing,
    props.trip.climate_impact
  )
  let containsApiValues = Tools.roundtripContainsCO2Estimates(props.trip)
  let flightPrice = ` + flight cost ${Tools.formatNumber(
    parseInt(props.trip.price.value * props.currencyConversionRate),
    props.displayCurrency
  )} ${props.displayCurrency}`

  return (
    <>
      <ReactTooltip
        id={`cpl-${props.trip.id}-estimated`}
        className={'tooltip-container'}
        type={'dark'}
        place={'bottom'}
        multiline={true}
        backgroundColor={'#ffffff'}
        textColor={'#333333'}
        effect={'solid'}
      />

      <div
        className="carbon-price-label"
        style={{
          fontSize: 12,
          borderTop: 'none',
          padding: 8,
          paddingTop: 12,
          width: 'auto',
          display: 'inline-block',
          position: 'relative',
          top: -1,
          marginRight: 50,
          boxShadow: '0px 3px 5px rgb(10 30 170 / 24%)',
          borderRadius: 10,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0
        }}>
        CO
        <sub style={{ fontSize: 12 }}>2e</sub> price:{' '}
        {Tools.formatNumber(props.trip.climate_impact, props.displayCurrency)} kg ={' '}
        {Tools.formatNumber(carbon_price, props.displayCurrency)} {props.displayCurrency}
        {!props.hidePrice && flightPrice}
        {containsApiValues && (
          <span style={{ marginLeft: 10, fontWeight: 'normal', color: '#0000fa' }}>
            Estimated
            <span
              style={{ fontSize: 16, position: 'relative' }}
              data-tip={
                'This trip contains a CO2 approximation.<br/>We are trying our best to get more accurate data and<br/>will update the trip where possible.'
              }>
              <FontAwesomeIcon icon="info-circle" className="info-circle" size="xs" />
            </span>
          </span>
        )}
      </div>
    </>
  )
}
