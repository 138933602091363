import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tools } from '../../../helpers/Tools'

export default function TripSummaryCar(props) {
  let isReturn = props.journey.searchQuery.route_direction === 'roundtrip'
  let departure_date = Tools.shortFormatDateToFullDate(props.journey.searchQuery.departure_date)
  let return_date = Tools.shortFormatDateToFullDate(props.journey.searchQuery.return_date)
  let origin_label = props.journey.searchQuery.origin.value.structured_formatting.main_text
  let destination_label =
    props.journey.searchQuery.destination.value.structured_formatting.main_text

  return (
    <div
      onClick={() => {
        if (props.onClick) {
          props.onClick()
        }
      }}
      style={{
        userSelect: 'none',
        cursor: props.onClick ? 'pointer' : 'default',
        display: 'flex',
        backgroundColor: props.noBackground ? 'transparent' : '#f0f0f5',
        boxShadow: props.noBackground ? '0 2px 4px 0 rgba(10,30,170,0.24)' : 'none',
        padding: 15,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 10,
        fontSize: 14,
        letterSpacing: '0.75px',
        fontWeight: 'bold',
        alignItems: 'center',
        width: '100%'
      }}>
      <FontAwesomeIcon icon={'car'} size={'lg'} style={{ marginRight: 20 }} className="car-icon" />
      {origin_label}
      <div style={{ marginRight: 10 }}>
        <div style={{ color: '#999', marginLeft: 20, marginRight: 10, marginTio: -5 }}>→</div>
        {isReturn && (
          <div style={{ color: '#999', marginLeft: 20, marginRight: 10, marginTop: -9 }}>←</div>
        )}
      </div>
      {destination_label}
      <div style={{ borderLeft: '1px solid #ddd', marginLeft: 20, marginRight: 20 }}></div>
      {Tools.getHumanReadableDate(departure_date)}
      {isReturn && (
        <>
          <span style={{ marginLeft: 10, marginRight: 10, color: '#999' }}>&mdash;</span>
          {Tools.getHumanReadableDate(return_date)}
        </>
      )}
      <div style={{ flexGrow: 1 }}></div>
      <div>{props.children}</div>
    </div>
  )
}
