import { Tools } from './Tools'

const Search = {
  findRoundtripBundleFromFlights: (roundtrips, outward_itinerary, return_itinerary) => {
    return roundtrips.find((roundtrip) => {
      if (
        Search.sameFlights(roundtrip.outward_itinerary.segments, outward_itinerary) &&
        Search.sameFlights(roundtrip.return_itinerary.segments, return_itinerary)
      ) {
        return true
      } else {
        return false
      }
    })
  },

  findRoundtripBundleFromOnewayFlight: (roundtrips, outward_itinerary) => {
    // This is weird and needs to be rewritten later. We simply pick ANY
    // roundtrip bundle that contains the requested flight as an outbound flight
    // and don't care about the return flight for now. Since the entire system is
    // currently built on roundtrips only, this is the only way to handle it
    // within the time constraints we're in. Classic.

    return roundtrips.find((roundtrip) => {
      return Search.sameFlights(roundtrip.outward_itinerary.segments, outward_itinerary)
    })
  },

  debugFlightOutput: (flight) => {
    console.log(
      '[OUTBOUND]: ',
      flight.outward_itinerary.segments.map((s, i) => {
        return `${s.carrier_code} ${s.flight_number}`
      })
    )
    console.log(
      '[RETURN]: ',
      flight.return_itinerary.segments.map((s, i) => {
        return `${s.carrier_code} ${s.flight_number}`
      })
    )
  },

  getMaxPrice: (flights) => {
    var maxPrice = 0
    flights.forEach((f, i) => {
      if (f.price.value > maxPrice) {
        maxPrice = f.price.value
      }
    })
    return maxPrice
  },

  getMaxRoundtripPrice: (flights) => {
    var maxPrice = 0
    flights.forEach((f, i) => {
      if (f[0].roundtrip_price > maxPrice) {
        maxPrice = f[0].roundtrip_price
      }
    })
    return maxPrice
  },

  getAvailableAirlines: (flights) => {
    var carriers = []
    flights.forEach((f) => {
      f.outward_itinerary.segments.forEach((segment) => {
        if (carriers.indexOf(Tools.figureOutCarrier(segment)) < 0)
          carriers.push(Tools.figureOutCarrier(segment))
      })
      if (f.return_itinerary) {
        f.return_itinerary.segments.forEach((segment) => {
          if (carriers.indexOf(Tools.figureOutCarrier(segment)) < 0)
            carriers.push(Tools.figureOutCarrier(segment))
        })
      }
    })
    // Finally, sort them alphabetically, if possible
    return carriers.slice().sort()
  },

  // Eventually needs to be removed because of the function underneath this
  getConnectingAirportsForFlightLeg: (flight_itinerary) => {
    var airports = []
    if (!flight_itinerary) return []
    if (flight_itinerary.segments.length > 1) {
      for (var i = 0; i < flight_itinerary.segments.length - 1; i++) {
        var airport = flight_itinerary.segments[i].destination
        if (airports.indexOf(airport) < 0) {
          airports.push(airport)
        }
      }
    }
    return airports
  },

  getConnectingAirportsForFlightSegments: (flight) => {
    var airports = []
    if (flight.length > 1) {
      for (var i = 0; i < flight.length - 1; i++) {
        var airport = flight[i].destination
        if (airports.indexOf(airport) < 0) {
          airports.push(airport)
        }
      }
    }
    return airports
  },

  getAvailableConnectingAirports: (flights) => {
    // Connecting Airports: 1 > 2 ... 2 > 3  (2)
    // Or: 1 > 2 ... 2 > 3 ... 3 > 4 (2 and 3)
    var airports = []
    flights.forEach((flight) => {
      Search.getConnectingAirportsForFlightLeg(flight.outward_itinerary).forEach((airport) => {
        if (airports.indexOf(airport) < 0) airports.push(airport)
      })
      if (flight.return_itinerary) {
        Search.getConnectingAirportsForFlightLeg(flight.return_itinerary).forEach((airport) => {
          if (airports.indexOf(airport) < 0) airports.push(airport)
        })
      }
    })
    return airports
  },

  hasFlightSpecificConnectingAirport: (flight, airport) => {
    let airports_outbound = Search.getConnectingAirportsForFlightLeg(flight.outward_itinerary)
    let airports_inbound = flight.return_itinerary
      ? Search.getConnectingAirportsForFlightLeg(flight.return_itinerary)
      : []
    if (airports_outbound.indexOf(airport) >= 0) return true
    if (airports_inbound.indexOf(airport) >= 0) return true
    return false
  },

  hasRouteSpecificConnectingAirport: (flight, airport) => {
    let airports = Search.getConnectingAirportsForFlightSegments(flight)
    if (airports.indexOf(airport) >= 0) return true
    return false
  },

  hasRouteAnyAcceptedAirport: (flight, airports) => {
    return (
      airports.find(
        (airport) => Search.getConnectingAirportsForFlightSegments(flight).indexOf(airport) >= 0
      ) || false
    )
  },

  hasLayoverFlights: (flights) => {
    var foundLayover = false
    flights.forEach((flight) => {
      if (flight.outward_itinerary.segments.length > 1) foundLayover = true
      if (flight.return_itinerary !== null) {
        if (flight.return_itinerary.segments.length > 1) foundLayover = true
      }
    })
    return foundLayover
  },

  hasNonstopFlights: (flights) => {
    var foundNonStop = false
    flights.forEach((flight) => {
      if (flight.outward_itinerary.segments.length === 1) foundNonStop = true
      if (flight.return_itinerary !== null) {
        // mindful of a oneway flight
        if (flight.return_itinerary.segments.length === 1) foundNonStop = true
      }
    })
    return foundNonStop
  },

  hasNonstopOutboundFlights: (flights) => {
    var foundNonStop = false
    flights.forEach((flight) => {
      if (flight.outward_itinerary.segments.length === 1) foundNonStop = true
    })
    return foundNonStop
  },

  flightWithinTiming(
    flight,
    outbound_departure_from,
    outbound_departure_to,
    outbound_arrival_from,
    outbound_arrival_to,
    return_departure_from,
    return_departure_to,
    return_arrival_from,
    return_arrival_to
  ) {
    let outbound_flight = flight.outward_itinerary.segments
    let return_flight = flight.return_itinerary === null ? false : flight.return_itinerary.segments

    if (
      Tools.parseServerTime(outbound_flight[0].departure_time).getHours() <
      (outbound_departure_from || 0)
    ) {
      console.log('Disqualified by Departure Timing (Early):', flight)
      return false
    }
    if (
      Tools.parseServerTime(outbound_flight[0].departure_time).getHours() >
      (outbound_departure_to || 0)
    ) {
      console.log('Disqualified by Departure Timing (Late):', flight)
      return false
    }
    if (
      Tools.parseServerTime(outbound_flight[outbound_flight.length - 1].arrival_time).getHours() <
      (outbound_arrival_from || 0)
    ) {
      console.log('Disqualified by Arrival Timing (Early):', flight)
      return false
    }
    if (
      Tools.parseServerTime(outbound_flight[outbound_flight.length - 1].arrival_time).getHours() >
      (outbound_arrival_to || 0)
    ) {
      console.log('Disqualified by Arrival Timing (Late):', flight)
      return false
    }

    if (return_flight) {
      if (
        Tools.parseServerTime(return_flight[0].departure_time).getHours() <
        (return_departure_from || 0)
      ) {
        console.log('Disqualified by Departure Timing (Early) RETURN:', flight)
        return false
      }

      if (
        Tools.parseServerTime(return_flight[0].departure_time).getHours() >
        (return_departure_to || 0)
      ) {
        console.log('Disqualified by Departure Timing (Late) RETURN:', flight)
        return false
      }
      if (
        Tools.parseServerTime(return_flight[return_flight.length - 1].arrival_time).getHours() <
        (return_arrival_from || 0)
      ) {
        console.log('Disqualified by Arrival Timing (Early) RETURN:', flight)
        return false
      }

      if (
        Tools.parseServerTime(return_flight[return_flight.length - 1].arrival_time).getHours() >
        (return_arrival_to || 0)
      ) {
        console.log('Disqualified by Arrival Timing (Late) RETURN:', flight)
        return false
      }
    }

    return true
  },

  sameFlights: (a, b) => {
    // Go segment by segment
    if (a.length !== b.length) return false
    if (
      a.find((segment, i) => {
        if (segment.aircraft_code !== b[i].aircraft_code) return false
        if (segment.arrival_time !== b[i].arrival_time) return false
        if (segment.booking_class !== b[i].booking_class) return false
        if (segment.cabin_class !== b[i].cabin_class) return false
        if (segment.carrier_code !== b[i].carrier_code) return false
        if (segment.departure_time !== b[i].departure_time) return false
        if (segment.destination !== b[i].destination) return false
        if (segment.duration !== b[i].duration) return false
        if (segment.flight_number !== b[i].flight_number) return false
        if (segment.operating_carrier_code !== b[i].operating_carrier_code) return false
        if (segment.origin !== b[i].origin) return false
        return true
      })
    ) {
      return true
    } else {
      return false
    }
  },

  filterByText: (incoming, filterText) => {
    var textFilterResults = []
    incoming.forEach((flight) => {
      var includeScore = 0 // The score will help us later rank the most matching results first

      // Let's look at all flight segments combined of this bundle, to see what we can match in there
      flight.forEach((segment) => {
        if (
          segment &&
          (Tools.figureOutCarrier(segment) || '---').indexOf(filterText.toUpperCase()) >= 0
        )
          includeScore++
        if (segment && segment.origin && segment.origin.indexOf(filterText.toUpperCase()) >= 0)
          includeScore++
        if (
          segment &&
          segment.destination &&
          segment.destination.indexOf(filterText.toUpperCase()) >= 0
        )
          includeScore++
        if (
          segment &&
          segment.flight_number &&
          segment.flight_number.toString().indexOf(filterText.toUpperCase()) >= 0
        )
          includeScore++
        if (
          segment &&
          segment.aircraft_code &&
          segment.aircraft_code.toString().indexOf(filterText.toUpperCase()) >= 0
        )
          includeScore++

        // Also look for combinations, like carrier and flight number
        if (segment && segment.flight_number) {
          // Make sure we have the necessary data
          let flightAndCarrier1 =
            Tools.figureOutCarrier(segment) + ' ' + segment.flight_number.toString()
          let flightAndCarrier2 =
            Tools.figureOutCarrier(segment) + '' + segment.flight_number.toString()
          if (filterText.trim().toUpperCase() === flightAndCarrier1.toUpperCase()) includeScore++
          if (filterText.trim().toUpperCase() === flightAndCarrier2.toUpperCase()) includeScore++
        }
      })

      if (includeScore > 0) textFilterResults.push(flight)
    })
    return textFilterResults
  },

  // Applies to roundtrip flights
  filterFlights: (filters, flights) => {
    let filterDebugger = false
    return flights
      .slice()
      .filter((flight) => {
        // TIMING
        if (
          !Search.flightWithinTiming(
            flight,
            filters.time.outbound_departure_from,
            filters.time.outbound_departure_to,
            filters.time.outbound_arrival_from,
            filters.time.outbound_arrival_to,
            filters.time.return_departure_from,
            filters.time.return_departure_to,
            filters.time.return_arrival_from,
            filters.time.return_arrival_to
          )
        ) {
          if (filterDebugger) console.log('[FILTER] [OUT] Timing: ', flight)
          return false
        }

        // CONNECTING AIRPORTS
        if (filters.connecting_airports.length > 0) {
          var outwardSegments = flight.outward_itinerary.segments
          var returnSegments = []
          if (flight.return_itinerary && flight.return_itinerary.segments) {
            returnSegments = flight.return_itinerary.segments
          }

          if (
            !Search.hasRouteAnyAcceptedAirport(outwardSegments, filters.connecting_airports) && // Only if NEITHER route goes over a requested airport, then we'll drop this.
            !Search.hasRouteAnyAcceptedAirport(returnSegments, filters.connecting_airports)
          ) {
            if (filterDebugger) console.log('[FILTER] [OUT] Connecting Airports: ', flight)
            return false
          }
        }

        // STOPS (Only if both directions have more stops than requested, we'll drop this result)
        if (filters.max_stops !== 5) {
          // 5 = No preference!
          if (
            flight.outward_itinerary.segments.length - 1 >
            parseInt(filters.max_stops, 10) //&&
            //          flight.return_itinerary.segments.length - 1 > parseInt(filters.max_stops, 10) // For now: only outbound
          ) {
            if (filterDebugger)
              console.log(
                `[FILTER] [OUT] Max Stops: [max: ${filters.max_stops}] [has: ${
                  flight.outward_itinerary.segments.length - 1
                }]`,
                flight
              )
            return false
          }
        }

        // AIRLINES
        // Only drop the flight, if neither route includes the requested airline
        if (filters.airlines.length > 0) {
          let outwardCarrierCodes = flight.outward_itinerary.segments.map((s) => s.carrier_code)
          let returnCarrierCodes = flight.return_itinerary
            ? flight.return_itinerary.segments.map((s) => s.carrier_code)
            : []
          let outwardIsValid = outwardCarrierCodes.find(
            (code) => filters.airlines.indexOf(code) >= 0
          )
          let returnIsValid = returnCarrierCodes.find((code) => filters.airlines.indexOf(code) >= 0)

          // This filter depends on the direction (treat isolated)
          if (filters.direction === 0) {
            // We're going outward?
            if (!outwardIsValid) {
              return false
            }
          } else {
            // We're looking at return flights...
            if (!returnIsValid) {
              // Return flights not found, but if the outbound flight is going there
              // then we'll include it for now, see ticket PDMVP1-183
              if (!outwardIsValid) {
                return false
              }
            }
          }
        }

        // MAX PRICE
        if (filters.max_price > 0) {
          if (flight.price.value > filters.max_price) {
            if (filterDebugger) console.log('[FILTER] [OUT] Max Price: ', flight)
            return false
          }
        }

        // LAYOVER DURATION
        // This filter only applies if we don't have a non-stop filter selected
        if (filters.max_stops !== 0) {
          if (
            Tools.calculateTotalLayoverDuration(flight.outward_itinerary.segments) >
              filters.layover.max * 60 ||
            (filters.layover.min > 0 &&
              Tools.calculateTotalLayoverDuration(flight.outward_itinerary.segments) <=
                filters.layover.min * 60) ||
            Tools.calculateTotalLayoverDuration(
              flight.return_itinerary === null ? [] : flight.return_itinerary.segments
            ) >
              filters.layover.max * 60 ||
            (filters.layover.min > 0 &&
              Tools.calculateTotalLayoverDuration(
                flight.return_itinerary === null ? [] : flight.return_itinerary.segments
              ) <=
                filters.layover.min * 60)
          ) {
            if (filterDebugger)
              console.log(
                `[FILTER] [OUT] Layover Duration [max: ${filters.layover.max * 60}] [min: ${
                  filters.layover.min * 60
                }] [Out Duration: ${Tools.calculateTotalLayoverDuration(
                  flight.outward_itinerary.segments
                )}] [Return Duration: ${Tools.calculateTotalLayoverDuration(
                  flight.return_itinerary === null ? [] : flight.return_itinerary.segments
                )}]: `,
                flight
              )

            return false
          }
        }
        return true
      })
      .slice()
  },

  sortResults: (sortBy, flights) => {
    if (sortBy === '') return flights
    if (sortBy === 'price')
      return flights
        .slice()
        .sort(
          (a, b) =>
            (a[0].roundtrip_price > b[0].roundtrip_price) -
            (a[0].roundtrip_price < b[0].roundtrip_price)
        )
    if (sortBy === 'duration')
      return flights
        .slice()
        .sort(
          (a, b) =>
            (Tools.calculateTotalFlightDuration(a) > Tools.calculateTotalFlightDuration(b)) -
            (Tools.calculateTotalFlightDuration(a) < Tools.calculateTotalFlightDuration(b))
        )
    if (sortBy === 'departure')
      return flights
        .slice()
        .sort(
          (a, b) =>
            (a[0].departure_time > b[0].departure_time) -
            (a[0].departure_time < b[0].departure_time)
        )

    if (sortBy === 'arrival')
      return flights
        .slice()
        .sort(
          (a, b) =>
            (a[a.length - 1].arrival_time > b[b.length - 1].arrival_time) -
            (a[a.length - 1].arrival_time < b[b.length - 1].arrival_time)
        )

    /*
    if (sortBy === 'emissions')
      return flights
        .slice()
        .sort(
          (a, b) =>
            (Tools.calculateTotalClimateImpact(a) > Tools.calculateTotalClimateImpact(b)) -
            (Tools.calculateTotalClimateImpact(a) < Tools.calculateTotalClimateImpact(b))
        )
    */
    return flights
  },

  // This function helps compress duplicate outbound flights into
  // just one flight (the cheapest one)
  compressFlightResults: (flights) => {
    var compressedFlights = []
    flights.forEach((flight) => {
      // Check if flight is already in the list,
      // if so, then check if the flight we have here is cheaper and replace.
      let found = compressedFlights.find((existingFlight) =>
        Search.sameFlights(flight, existingFlight)
      )
      if (found) {
        if (flight[0].roundtrip_price < found[0].roundtrip_price) {
          compressedFlights.splice(compressedFlights.indexOf(found), 1, flight)
        }
      } else {
        // Not found, so add it to the list
        compressedFlights.push(flight)
      }
    })
    return compressedFlights
  },

  getOutboundFlights: (flights) => {
    var results = []
    flights.forEach((flight) => {
      /*
      if (
        !results.find((existingFlight) =>
          Search.sameFlights(flight.outward_itinerary.segments, existingFlight)
        )
      ) { */
      results.push(
        // Note: we need to inject the roundtrip price into the segments
        // DIRTY DIRTY UPDATE 2023: We also inject the POLICIES here
        // which is "currently" at the "Itinerary" level, but since we're
        // using segments only from here on forward, we would lose this
        // information. This could all be solved by having proper hashes/IDs
        // for each flight, each itinerary, each segment.
        flight.outward_itinerary.segments.map((segment) => {
          return { ...segment, roundtrip_price: flight.price.value, policies: flight.outward_itinerary.policies }
        })
      )
      //}
    })
    return results
  },

  getReturnFlights: (flights, outboundFlight) => {
    // Here, we only want return flights that match the outbound flight
    let results = flights
      .map((flight) => {
        if (Search.sameFlights(flight.outward_itinerary.segments, outboundFlight)) {
          if (flight.return_itinerary) {
            return (
              // Note: we need to inject the roundtrip price into the segments
              // We also inject top-level policy violations (see getOutboundFlights for details)
              flight.return_itinerary.segments.map((segment) => {
                return { ...segment, roundtrip_price: flight.price.value, policies: flight.return_itinerary.policies }
              })
            )
          } else {
            return null
          }
        } else {
          return null
        }
      })
      .filter((flight) => flight !== null)
    return results
  },

  backdateFlightItineraryDates: (itinerary, numOfWeeks) => {
    // TODO: For now, we'll just subtract the same offset from each date
    // that we're using in the search. Later, we need to make sure this is all actually working and correct.
    itinerary.segments.forEach((s, i) => {
      s.departure_time = Tools.getLongFormatDate(
        Tools.getSameDayByWeekOffset(Tools.parseServerTime(s.departure_time), -numOfWeeks)
      )
      s.arrival_time = Tools.getLongFormatDate(
        Tools.getSameDayByWeekOffset(Tools.parseServerTime(s.arrival_time), -numOfWeeks)
      )
    })
    return itinerary
  },

  calculateWeekOffsetForPastFlight: (departure_date, return_date) => {
    let pastDeparture = Tools.shortFormatDateToFullDate(departure_date)
    let pastReturn = Tools.shortFormatDateToFullDate(return_date)
    let forwardDepartureWeekOffset = Tools.countWeekOffsetForFutureSearch(pastDeparture)
    let forwardDatedDeparture = Tools.getSameDayByWeekOffset(
      pastDeparture,
      forwardDepartureWeekOffset
    )
    var forwardReturnWeekOffset = Tools.countWeekOffsetForFutureSearch(pastReturn)
    var forwardDatedReturn = Tools.getSameDayByWeekOffset(pastReturn, forwardReturnWeekOffset)
    // This is a bit hacky, but if we return before we depart, let's just add exactly 1 week
    // and that should solve the issue.
    if (forwardDatedDeparture > forwardDatedReturn) {
      forwardReturnWeekOffset++
      forwardDatedReturn = Tools.getSameDayByWeekOffset(pastReturn, forwardReturnWeekOffset)
    }
    return {
      departure_week_offset: forwardDepartureWeekOffset,
      return_week_offset: forwardReturnWeekOffset,
      forward_dated_departure_date: forwardDatedDeparture,
      forward_dated_return_date: forwardDatedReturn
    }
  },

  needsTimeshift: (departure_date) => {
    var departure = departure_date
    // Could this already be a date object?
    if (typeof departure_date.getMonth !== 'function') {
      // If not, then parse the string
      departure = Tools.shortFormatDateToFullDate(departure_date)
    }

    if (departure < new Date()) {
      // The flight departs before today, so we need to do some time-shifting magic
      return true
    }
    return false
  }
}
export default Search
