import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useStore from '../../../store'

export function getPolicyViolations(params) {

  // We can either accept a "flight" prop, that includes a single direction route (=Itinerary)
  // OR, we can accept a "trip" prop that consists of one or two flight directions (i.e. a roundtrip)
  var singleDirection = false;
  if (params.flight) {
    singleDirection = true;
  }
  if (!singleDirection && !params.trip) {
    console.log("Error in OutOfPolicyLabel — we have no valid trip data.")
    return [];
  }

  var flights = [];
  if (singleDirection) {
    flights = params.flight
  } else {
    if (params.trip["outward_itinerary"]) {
      flights.push(params.trip["outward_itinerary"])
    }
    if (params.trip["return_itinerary"]) {
      flights.push(params.trip["return_itinerary"])
    }
  }

  // The `policies` object/array was injected by the frontend and is
  // originally placed at the Itinerary level.
  var allPolicies = []
  flights.forEach((f) => {
    if (f.policies && f.policies.length>0) {
      allPolicies = allPolicies.concat(f.policies);
    }
  })
  var uniquePolicies = []
  if (allPolicies.length>0) {
    uniquePolicies = allPolicies.filter((value, index, array) => array.indexOf(value) === index);
  }  

  return uniquePolicies
}

export default function OutOfPolicyLabel(props) {

  const companyDetails = useStore((state) => state.companyDetails)
  if (!companyDetails.feature_policies) {
    return (null);
  }

  let uniquePolicies = getPolicyViolations(props);

  var elementId = props.rowId || props.trip.id || '0'; // Annoying, but needed to render the react tooltip shadow correctly!

  if (uniquePolicies.length===0) {
    return (null)
  }

  let policyLabel = uniquePolicies.map((p) => {
    if (p === "min_flight_duration") {
      return "This flight is below the minimum duration required by your company policy."
    } else {
      return (null)
    }
  })

  return (
    <>      
      <span style={{color:"#dd0000", fontWeight:"bold", fontSize:14}}>
        Out of Policy
      </span>
      <span style={{ fontSize: 20, position: "relative", top: -1, left: 3 }} data-tip={policyLabel} data-for={`oop-tooltip-${elementId}`}>
        <FontAwesomeIcon
          icon="info-circle"
          className="info-circle"
          size="xs"/>        
        <ReactTooltip
          id={`oop-tooltip-${elementId}`}
          className={'tooltip-container'}                    
          type={'dark'}
          delayShow={200}
          place={'bottom'}
          multiline={true}
          backgroundColor={'#ffffff'}
          textColor={'#333333'}
          effect={'solid'}
        />                
      </span>
    </>
  )
}