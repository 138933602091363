import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useStore from '../store'

import { Networking } from '../helpers/Networking'

import TextInput from '../components/common/Misc/TextInput'
import Button from '../components/common/Misc/Button'
import Loading from '../components/common/Misc/Loading'

export default function LobbyPage() {
  const refreshCustomers = useStore((state) => state.refreshCustomers)
  const fetchCabinClasses = useStore((state) => state.fetchCabinClasses)
  const agencyCustomers = useStore((state) => state.agencyCustomers)
  const agencyCustomersLoaded = useStore((state) => state.agencyCustomersLoaded)
  const selectAgencyClient = useStore((state) => state.selectAgencyClient)
  const setTravelerId = useStore((state) => state.setTravelerId)
  const cognitoLogout = useStore((state) => state.cognitoLogout)

  const [loading, setLoading] = useState(false)
  const [existingOfferId, setExistingOfferId] = useState('')
  const [agencyClientId, setAgencyClientId] = useState('')
  const [email, setEmail] = useState('')

  const navigate = useNavigate()

  // We need to pass the traveler e-mail address to the backend to
  // receive a unique ID we can use to identify this person/offer in the future.
  // Once we receive this, we'll move on to the next screen.
  const prepareClient = () => {
    setLoading(true)
    Networking.generateTravelerId(email)
      .then((travelerObject) => {
        // This may have to be changed to string comparison of the ID in the near future...
        selectAgencyClient(agencyCustomers.find((item) => item.customer_id === agencyClientId))
        setTravelerId(travelerObject.id)
        navigate('/agency/flights/search')
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  const openOffer = () => {
    var offerId = existingOfferId
    if (offerId.length > 36) {
      // Maybe a full URL was pasted, so let's try and get the UUID off it
      if (offerId.indexOf('#') >= 0) {
        var actualOfferId = offerId.split('#')[1]
        window.open(`https://offer.climatesmart.travel/#${actualOfferId}`, '_blank')
      }
    } else {
      if (offerId.length === 36) {
        window.open(`https://offer.climatesmart.travel/#${offerId}`, '_blank')
      }
    }
  }

  // This runs ONCE when the component mounts
  useEffect(() => {
    refreshCustomers() // Load customers for this account login (we'll need to use a token later here)
    fetchCabinClasses() // Load available cabin classes from the backend
  }, [refreshCustomers, fetchCabinClasses])

  if (loading || !agencyCustomersLoaded) {
    return (
      <div style={{ textAlign: 'center', marginTop: 100 }}>
        <Loading />
      </div>
    )
  }

  return (
    <div
      style={{ width: 1024, marginLeft: 'auto', marginRight: 'auto', marginTop: 100, padding: 20 }}>
      <div style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold', marginBottom: 40 }}>
        <img src="/logo.png" alt="Logo" height="20" />
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            padding: 50,
            margin: 20,
            minWidth: 450,
            backgroundColor: '#fafafa',
            borderRadius: 10
          }}>
          <h2>Start a new offer</h2>
          <select
            id="agency-client"
            placeholder="Please choose..."
            value={agencyClientId}
            onChange={(e) => {
              setAgencyClientId(e.target.value)
            }}>
            <option value="">(Choose your client)</option>
            {agencyCustomers.map((customer, i) => {
              return (
                <option key={`client_customer_${i}`} value={customer.customer_id}>
                  {customer.name}
                </option>
              )
            })}
          </select>
          <br />
          <br />
          <TextInput
            name="traveler-email"
            value={email}
            onChange={(txt) => {
              setEmail(txt)
            }}
            onEnter={prepareClient}
            placeholder="Traveler e-mail"
            style={{
              boxShadow: '0px 0px 5px rgba(0,0,0,0.1)',
              borderRadius: 5
            }}
          />
          <br />
          <Button
            testPoint="continue-button"
            disabled={
              agencyClientId === '' ||
              email.length < 2 ||
              email.indexOf('@') < 0 ||
              email.indexOf('.') < 0
            }
            text="Continue"
            onClick={prepareClient}
          />
        </div>
        <div
          style={{
            padding: 50,
            margin: 20,
            minWidth: 450,
            backgroundColor: '#fafafa',
            borderRadius: 10
          }}>
          <h2>View existing offer</h2>
          <TextInput
            style={{
              boxShadow: '0px 0px 5px rgba(0,0,0,0.1)',
              borderRadius: 5
            }}
            placeholder="Enter your Offer ID"
            value={existingOfferId}
            onChange={(eoi) => {
              setExistingOfferId(eoi)
            }}
          />
          <br />
          <Button disabled={existingOfferId.length < 3} text="View" onClick={openOffer} />
        </div>
      </div>

      <div
        style={{
          textAlign: 'center',
          position: 'fixed',
          bottom: 20,
          left: '50%',
          transform: 'translateX(-50%)'
        }}>
        <button
          onClick={() => {
            cognitoLogout().then(() => {
              document.location.href = '/#bye'
            })
          }}
          style={{
            background: 'transparent',
            border: 'none',
            padding: 10,
            fontSize: 12,
            color: '#999',
            cursor: 'pointer'
          }}>
          Log out
        </button>
      </div>
    </div>
  )
}
