import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Tools } from '../../../helpers/Tools'
import useStore from '../../../store'
import Tabs from './Tabs'

export default function LogTripTabs(props) {
  const companyDetails = useStore((state) => state.companyDetails)
  const userDetails = useStore((state) => state.userDetails)
  const [activeTab, setActiveTab] = useState(null)

  const navigate = useNavigate()
  const location = useLocation()

  // Figure out which tabs we have available
  let tabs = []
  var tabIndex = 0
  var allowsPastFlights =
    companyDetails.feature_cost_center || companyDetails.feature_carbon_pricing

  // First position: Check if we allow past flights, and if we do, put it into the first slot
  if (allowsPastFlights) {
    tabs.push({
      id: 'already_booked',
      title: 'Already booked flight',
      label: 'Use this for a flight you already booked',
      url: '/employee/flights/identify',
      index: tabs.length
    })
  }

  // Second position: Flights (this will always be there)
  tabs.push({
    id: 'new_flight',
    title: 'New flight',
    label: 'Use this for a flight you intend to book',
    url: '/employee/flights/search',
    index: tabs.length
  })

  if (Tools.hasDevFlag(userDetails.email, 'car_mode')) {
    tabs.push({
      id: 'car',
      title: 'By Car',
      label: 'Use this for a car trip you plan',
      url: '/employee/car/search',
      index: tabs.length
    })
  }

  // Figure out the tab index
  tabIndex = activeTab === null ? 0 : activeTab.index

  // Use the URL to try and match the correct tab
  let tabMatch = tabs.find((t) => location.pathname.indexOf(t.url) >= 0)
  if (tabMatch) {
    tabIndex = tabMatch.index
  }

  // There may be an ACTIVE TAB override, check that...
  if (props.activeTabId) {
    let tab = tabs.find((t) => t.id === props.activeTabId)
    if (tab && tab.id) {
      tabIndex = tab.index
    }
  }

  return (
    <Tabs
      tabs={tabs.map((t) => t.title) || []}
      tabInfos={tabs.map((t) => t.label) || []}
      onChange={(newIndex) => {
        setActiveTab(tabs[newIndex])
        // Now, we can either report back the change
        // if requested, or we'll try to navigate directly.
        if (props.onChange) {
          props.onChange(tabs[newIndex])
        } else {
          navigate(tabs[newIndex].url)
        }
      }}
      selectedIndex={tabIndex}
    />
  )
}
