import { Range } from 'react-range'
import FilterPopover from './Components/FilterPopover'

export default function FilterLayover(props) {
  const getTitle = (layoverDuration) => {
    if (layoverDuration[0] === 0) {
      return `Layover up to ${layoverDuration[1]} hours`
    }
    return `Layover ${layoverDuration[0]} to ${layoverDuration[1]} hours`
  }

  return (
    <FilterPopover
      title={'Layover Duration'}
      selectedTitle={getTitle(props.value)}
      active={
        parseInt(props.defaultValue[0], 10) !== parseInt(props.value[0], 10) ||
        parseInt(props.defaultValue[1], 10) !== parseInt(props.value[1], 10)
      }
      onCancel={() => {
        if (props.onCancel) props.onCancel()
      }}>
      <div style={{ fontSize: 14 }}>
        From {props.value[0]} to {props.value[1]} hours
      </div>
      <br />
      <div style={{ paddingLeft: 10, paddingRight: 10 }}>
        <Range
          step={1}
          min={0}
          max={props.defaultValue[1]} // Hours
          values={props.value}
          onChange={(v) => {
            if (props.onChange) {
              props.onChange(v)
            }
          }}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '3px',
                width: '100%',
                backgroundColor: '#ccc'
              }}>
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '20px',
                width: '20px',
                borderRadius: 20,
                backgroundColor: '#0B1CAA'
              }}
            />
          )}
        />
      </div>
    </FilterPopover>
  )
}
