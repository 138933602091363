import { Tools } from '../../../helpers/Tools'
import RadioButton from '../../common/Misc/RadioButton'
import Button from '../../common/Misc/Button'

export default function OfferPanelGenericDesktop(props) {
  const calculateFNZ = (compareTo, factor) => {
    if (compareTo) {
      try {
        let baseline_fnz = compareTo.fnz_points
        if (isNaN(baseline_fnz)) return 0
        return Math.round(baseline_fnz * factor)
      } catch {
        return 0
      }
    } else return 0
  }

  return (
    <div
      style={{
        marginTop: 20,
        marginBottom: 40,
        cursor: 'pointer',
        maxWidth: 1200
      }}
      onClick={props.onSelect}>
      <div
        style={{
          position: 'relative',
          left: 40,
          top: 10,
          padding: '5px 15px',
          color: '#fff',
          fontSize: 12,
          fontWeight: 600,
          display: 'inline-block',
          backgroundColor: props.section === 'habit_to_beat' ? '#333' : '#0A1EAA',
          borderRadius: 4,
          borderTopLeftRadius: 16,
          borderBottomRightRadius: 16
        }}>
        {props.topLabel}
      </div>

      <div
        style={{
          backgroundColor: 'white',
          borderRadius: 10,
          boxShadow: props.selected
            ? '0 2px 4px 0 rgba(10,30,170,0.24)'
            : '0 2px 4px 0 rgba(10,30,170,0.24)',
          padding: 20
        }}>
        <div
          style={{
            backgroundColor: props.selected
              ? props.section === 'habit_to_beat'
                ? 'rgba(10,30,170,0.04)'
                : '#E0F88B'
              : 'rgba(10,30,170,0.04)',
            borderRadius: 32,
            padding: 12,
            display: 'flex',
            alignItems: 'center'
          }}>
          {!props.disabled && (
            <div style={{ paddingLeft: 15, paddingRight: 15 }}>
              <RadioButton selected={props.selected} onClick={props.onSelect} />
            </div>
          )}
          {props.disabled && <div style={{ width: 10 }}></div>}
          <div style={{ fontSize: 20 }}>
            <div>
              Save{' '}
              <strong>
                {props.section === 'option_online' ? 100 : 85}% CO
                <sub style={{ fontSize: 12 }}>2e</sub>
              </strong>{' '}
              {calculateFNZ(props.compareTo, props.section === 'option_online' ? 2.5 : 0) > 0 && (
                <span>
                  and earn{' '}
                  <strong>
                    {Tools.formatNumber(
                      calculateFNZ(props.compareTo, props.section === 'option_online' ? 2.5 : 0)
                    )}{' '}
                    Climate Points
                  </strong>{' '}
                </span>
              )}
            </div>
          </div>
          <div style={{ paddingLeft: 10, color: '#666', fontSize: 14 }}>{props.offerLabel}</div>
        </div>

        {!props.disabled && props.selected && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: 60,
              marginTop: 20
            }}>
            <div style={{ flexGrow: 1 }}></div>
            <div>
              <Button text={props.confirmLabel || 'Select'} onClick={props.onConfirm} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
