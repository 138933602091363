import axios from 'axios'
import { Auth } from 'aws-amplify'
import useEmployeeStore from '../employee_store'

export const Networking = {
  apiServer: () => {
    return process.env.REACT_APP_API.endsWith('/')
      ? process.env.REACT_APP_API.slice(0, -1)
      : process.env.REACT_APP_API
  },

  googleApiKey: () => {
    return process.env.REACT_APP_GOOGLE_MAPS_API
  },

  prepareHeaders: (token) => {
    var headers = {
      Authorization: `${token}`,
      'X-Frontend-Version': window.fnzBuildVersion || '0.0'
    }
    if (useEmployeeStore.getState().delegateEmployeeId !== '') {
      headers['X-Frontend-Delegate'] = useEmployeeStore.getState().delegateEmployeeId
    }
    return headers
  },

  // This is a wrapper for API REST calls (POST) that need to be authenticated via AWS Cognito
  _post: (url, data) => {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then((session) => {
          /* The call needs AWS Cognito Credentials */
          axios
            .post(`${Networking.apiServer()}${url}`, data, {
              headers: Networking.prepareHeaders(session.idToken.jwtToken)
            })
            .then((res) => {
              resolve(res.data)
            })
            .catch((error) => {
              console.log('Error: ', error)
              reject(error)
            })
        })
        .catch((error) => {
          console.trace('Error while trying to send a POST request: ', error)

          // Unauthorized? Let's hard-redirect to the root
          setTimeout(() => {
            document.location.href = '/'
          }, 500)
          reject(error)
        })
    })
  },

  // This is a wrapper for API REST calls (GET) that need to be authenticated via AWS Cognito
  _get: (url, data) => {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then((session) => {
          // Prepare the URL params
          let urlParameters =
            !data || data.length === 0
              ? ''
              : '?' +
                Object.entries(data)
                  .map((e) => e.join('='))
                  .join('&')

          /* The call needs AWS Cognito Credentials */
          axios
            .get(`${Networking.apiServer()}${url}${urlParameters}`, {
              headers: Networking.prepareHeaders(session.idToken.jwtToken)
            })
            .then((res) => {
              resolve(res.data)
            })
            .catch((error) => {
              console.log('Error: ', error)
              reject(error)
            })
        })
        .catch((error) => {
          console.trace('[Amplify Error] while trying to send a GET request: ', error)
          reject(error)
        })
    })
  },

  // API CALLS ----------------------------------------------------------

  searchFlights: (payload) => {
    return Networking._post(`/v1/flights/search`, payload)
  },

  searchFlightsProxy: (payload) => {
    return Networking._post(`/v1/flights/search/proxy`, payload)
  },

  recommendFlights: (payload) => {
    return Networking._post(`/v1/flights/recommend`, payload)
  },

  saveOffer: (payload) => {
    return Networking._post(`/v1/agency/offer`, payload)
  },

  getCustomers: () => {
    return Networking._get(`/v1/agency/customers`)
  },

  generateTravelerId: (email) => {
    return Networking._post(`/v1/utility/generate_id`, { text: email })
  },

  getCabinClasses: () => {
    return Networking._get(`/v1/utility/cabin_classes`)
  },

  getLocations: (payload) => {
    return Networking._post(`/v1/utility/locations`, payload)
  },

  updateUserHabit: (payload) => {
    alert('To-do: Not implemented yet...')
    //return Networking._post(`/v1/updateUserHabit`, payload)
  },

  updateOfferStatus: (payload) => {
    return Networking._post(`/v1/agency/offer/status`, payload)
  },

  getUser: () => {
    return Networking._get(`/v1/account`)
  },

  getCompany: () => {
    return Networking._get(`/v1/company`)
  },

  getCostCenters: () => {
    return Networking._get(`/v1/company/costcenters`)
  },

  saveTrip: (payload) => {
    return Networking._post(`/v1/trips`, payload)
  },

  getTrips: () => {
    return Networking._get(`/v1/trips`)
  },

  getTrip: (tripId) => {
    return Networking._get(`/v1/trip/${tripId}`)
  },

  updateTripCostCenter: (payload, tripId) => {
    return Networking._post(`/v1/trips/${tripId}/costcenter`, payload)
  },

  updateTripStatus: (payload, tripId) => {
    return Networking._post(`/v1/trips/${tripId}/status`, payload)
  },

  getAccountMessages: (payload, tripId) => {
    return Networking._get(`/v1/account/messages`, payload)
  },

  getCurrencyRate: async () => {
    return Networking._get(`/v1/company/currency_rate`)
  },

  saveManualTrip: async (payload) => {
    try {
      return await Networking._post(`/v1/trips/manual`, payload)
    } catch (error) {
      return false
    }
  },

  confirmAccount: async (email, code, jwt, full_name) => {
    try {
      await Auth.confirmSignUp(email, code, { clientMetadata: { jwt: jwt, full_name: full_name } })
      return { status: true, error: false }
    } catch (error) {
      console.log('Error while trying to confirm sign-up:', error)
      if (error.toString().indexOf('CodeMismatchException') >= 0) {
        return { status: false, error: 'invalid_code' }
      }
      if (error.toString().indexOf('status is CONFIRMED') >= 0) {
        return { status: false, error: 'already_confirmed' }
      }
      return { status: false, error: 'unknown' }
    }
  },

  createAccount: async (email, password) => {
    try {
      await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email: email
        },
        autoSignIn: {
          enabled: true // After use is confirmed (by link click in email)
        }
      })
      return { status: true, error: false }
    } catch (error) {
      console.log('Error while trying to sign up:', error)
      if (error.toString().indexOf('InvalidPasswordException') >= 0) {
        let details =
          error.toString().indexOf('with policy:') >= 0 ? error.toString().split('policy: ')[1] : ''
        return { status: false, error: 'invalid_password', details: details }
      }
      if (error.toString().indexOf('UsernameExists') >= 0) {
        return { status: false, error: 'user_exists' }
      }
      if (error.toString().indexOf('Invalid email') >= 0) {
        return { status: false, error: 'invalid_email' }
      }
      return { status: false, error: 'unknown' }
    }
  }
}
