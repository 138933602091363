import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './SearchButton.css'
export default function SearchButton(props) {
  var showIcon = props.noIcon ? false : true
  return (
    <button
      disabled={props.disabled || false}
      id={props.id || ''}
      className={props.confirm ? `basic-button confirm-button` : `basic-button search-button`}
      style={props.style || {}}
      onClick={() => {
        props.onClick && props.onClick()
      }}>
      {props.title || 'Search'}{' '}
      {showIcon && (
        <FontAwesomeIcon icon="search" className="radio-selection-icon fa-flip-horizontal" />
      )}
    </button>
  )
}
