export default function UZHIntro(props) {
  return (
    <div
      style={{
        marginTop: 80,
        width: '100%',
        maxWidth: 900,
        border: '1px solid #ddd',
        padding: 15,
        paddingLeft: 28,
        paddingRight: 28,
        borderRadius: 10,
        fontSize: 16,
        lineHeight: 1.35
      }}>
      <p>
        Starting Oct 1, 22 <strong>all employees and PhD students</strong> of our Faculty will need
        to <strong>log all their flights</strong> paid for by cost centers or projects of the PhF.
      </p>
      <p>
        <img src="/logo.png" height={11} alt="ClimateSmart.Travel" /> will generate a monthly report
        for billing the incentive fee to the corresponding cost centers.
      </p>
      <br />
      <strong>There are two workflows:</strong>
      <br />
      <p>
        <span style={{ color: '#024DB0', fontWeight: 'bold' }}>1. "Already booked flight":</span>{' '}
        calculate the CO<sub>2</sub> incentive fee and choose a cost center.
        <br />
        <span style={{ color: '#024DB0', fontWeight: 'bold' }}>2. "New flight":</span> actively
        reduce your carbon footprint by using{' '}
        <img src="/logo.png" height={11} alt="ClimateSmart.Travel" /> for your travel planning
        process and log the CO<sub>2</sub> incentive fee and cost center in one go.
      </p>
      <p>
        Based on your intended flight, you will receive alternative suggestions as{' '}
        <span style={{ color: '#024DB0', fontWeight: 'bold' }}>"Climate Options"</span>
        that save carbon and costs. As this tool is not a booking tool, you are free to book your
        flights after your final selection via your preferred booking channel or our suggested
        travel agency partner.
      </p>
    </div>
  )
}
