import { useEffect, useState } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useStore from './store'
import { User } from './helpers/User'

import Loading from './components/common/Misc/Loading'
import BookingWizard from './pages/BookingWizard'
import LobbyPage from './pages/Lobby'
import LoginPage from './pages/Login'
import PasswordResetPage from './pages/PasswordReset'
import EmployeeModuleSearchFlight from './pages/employee/SearchFlight'
import EmployeeModuleHabitSurveyPage from './pages/employee/HabitSurvey'
import EmployeeModuleClimateOptionsFlight from './pages/employee/ClimateOptionsFlight'
import EmployeeModuleCostCenter from './pages/employee/CostCenter'
import EmployeeModuleTrips from './pages/employee/Trips'
import EmployeeModuleCompleted from './pages/employee/Completed'
import EmployeeModulePreferences from './pages/employee/Preferences'

import Home from './Home'
import PageContainer from './components/common/Misc/PageContainer'
import EmployeeSidebar from './components/employee/Misc/Sidebar'
import MainContainer from './components/common/Misc/MainContainer'
import EmployeeModuleSearchCar from './pages/employee/SearchCar'
import EmployeeModuleClimateOptionsCar from './pages/employee/ClimateOptionsCar'
import RegisterPage from './pages/Register'
import DelegatePage from './pages/Delegate'
import useEmployeeStore from './employee_store'

export default function Router() {
  const cognitoRestoreUserSession = useStore((state) => state.cognitoRestoreUserSession)
  const userDetails = useStore((state) => state.userDetails)
  const companyDetails = useStore((state) => state.companyDetails)
  const messages = useStore((state) => state.messages)
  const delegateEmployeeId = useEmployeeStore((state) => state.delegateEmployeeId)
  const setDelegateEmployeeId = useEmployeeStore((state) => state.setDelegateEmployeeId)

  const [isLoading, setLoading] = useState(true)
  const [authCheckCompleted, setAuthCheckCompleted] = useState(false)

  const navigate = useNavigate()

  // This is a hard-override: If there's a code attached to the root, we're likely
  // reacting to a password reset e-mail. So let's take the user to the correct place
  if (document.location.pathname === '/' || document.location.pathname === '') {
    if (document.location.search.indexOf('code=') >= 0) {
      navigate('/reset/' + document.location.search)
    }
  }

  useEffect(() => {
    const fetchUser = async () => {
      // Check if the user is authenticated...
      setLoading(true)
      User.get()
        .then((user) => {
          console.log('[OK] User Details found. Session valid: ', user)
          cognitoRestoreUserSession(user).finally(() => {
            setLoading(false)
            setAuthCheckCompleted(true)
          })
        })
        .catch((error) => {
          console.log('[NO USER DETAILS] Session not found.', error)
          setLoading(false)
          setAuthCheckCompleted(true)
        })
    }
    fetchUser()
  }, [cognitoRestoreUserSession])

  if (isLoading || !authCheckCompleted)
    return (
      <div style={{ marginTop: 100 }}>
        <Loading />
      </div>
    )

  console.log(
    `[ROUTER]: [User: ${userDetails.email}] [Company ID: ${userDetails.company_id}] [Company: ${companyDetails.company_name}]`
  )

  // Keep Support Chat enriched
  if (userDetails.email) {
    window.$crisp.push(['set', 'user:email', [userDetails.email]])
  }
  if (companyDetails.company_name && userDetails.id) {
    window.$crisp.push(['set', 'user:company', [companyDetails.company_name]])
    window.$crisp.push(['set', 'session:data', ['employee_id', userDetails.id]])
  }

  if (messages && messages.system && messages.system.length > 0) {
    let maintenanceMode = messages.system.find((m) => m.message_type === 'maintenance')
    if (maintenanceMode) {
      setTimeout(() => {
        document.location.reload()
      }, 30000)
      return (
        <PageContainer>
          <div style={{ width: '100%', textAlign: 'center', marginTop: 200 }}>
            {maintenanceMode.message}
          </div>
        </PageContainer>
      )
    }
  }

  const DelegateWarningPanel = () => {
    return (
      <div
        style={{
          backgroundColor: '#ff0000',
          color: '#fff',
          padding: 10,
          width: '100%',
          position: 'fixed',
          zIndex: 10000,
          fontSize: 12
        }}>
        <FontAwesomeIcon
          icon="exclamation-triangle"
          size="1x"
          style={{ marginRight: 10 }}
          className="exclamation-triangle"
        />
        <span style={{ fontWeight: 'bold', color: '#fff' }}>Attention:</span> You are currently
        acting on behalf of this user. Make sure you log out as soon as you are done.
        <div
          style={{
            fontWeight: 'bold',
            marginLeft: 15,
            display: 'inline-block',
            cursor: 'pointer',
            color: '#ffd500'
          }}
          onClick={() => {
            setDelegateEmployeeId('')
            setTimeout(() => {
              document.location.href = '/?'
            }, 1000)
          }}>
          Back to Support Account
        </div>
      </div>
    )
  }

  const EmployeePageContainer = (props) => {
    return (
      <>
        {delegateEmployeeId !== '' && <DelegateWarningPanel />}
        <PageContainer>
          <EmployeeSidebar />
          <MainContainer>
            <div style={{ marginBottom: 30, maxWidth: 950, width: '100%' }}>{props.children}</div>
          </MainContainer>
        </PageContainer>
      </>
    )
  }
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register/:token" element={<RegisterPage />} />
      <Route path="/delegate/:token" element={<DelegatePage />} />
      <Route path="/reset" element={<PasswordResetPage />} />
      <Route
        path="/employee/trips"
        element={
          <EmployeePageContainer>
            <EmployeeModuleTrips />
          </EmployeePageContainer>
        }
      />
      <Route
        path="/employee/preferences"
        element={
          <EmployeePageContainer>
            <EmployeeModulePreferences />
          </EmployeePageContainer>
        }
      />
      <Route
        path="/employee/preferences/habit"
        element={
          <EmployeePageContainer>
            <EmployeeModuleHabitSurveyPage />
          </EmployeePageContainer>
        }
      />

      <Route
        path="/employee/flights/search"
        element={
          <EmployeePageContainer>
            <EmployeeModuleSearchFlight engine={'v1'} />
          </EmployeePageContainer>
        }
      />

      <Route
        path="/employee/flights/search/v2"
        element={
          <EmployeePageContainer>
            <EmployeeModuleSearchFlight engine={'v2'} endpoint={'travelport_development'} />
          </EmployeePageContainer>
        }
      />

      <Route
        path="/employee/flights/search/v2/production"
        element={
          <EmployeePageContainer>
            <EmployeeModuleSearchFlight engine={'v2'} endpoint={'travelport_production'} />
          </EmployeePageContainer>
        }
      />

      <Route
        path="/employee/flights/identify"
        element={
          <EmployeePageContainer>
            <EmployeeModuleSearchFlight engine={'v1'} alreadyBooked={true} />
          </EmployeePageContainer>
        }
      />

      <Route
        path="/employee/flights/costcenter"
        element={
          <EmployeePageContainer>
            <EmployeeModuleCostCenter />
          </EmployeePageContainer>
        }
      />
      <Route
        path="/employee/flights/options"
        element={
          <EmployeePageContainer>
            <EmployeeModuleClimateOptionsFlight />
          </EmployeePageContainer>
        }
      />
      <Route
        path="/employee/flights/done"
        element={
          <EmployeePageContainer>
            <EmployeeModuleCompleted />
          </EmployeePageContainer>
        }
      />

      <Route
        path="/employee/car/search"
        element={
          <EmployeePageContainer>
            <EmployeeModuleSearchCar />
          </EmployeePageContainer>
        }
      />
      <Route
        path="/employee/car/options"
        element={
          <EmployeePageContainer>
            <EmployeeModuleClimateOptionsCar />
          </EmployeePageContainer>
        }
      />

      <Route path="/agency/client" element={<LobbyPage />} />
      <Route path="/agency/flights/search" element={<BookingWizard />} />
    </Routes>
  )
}
