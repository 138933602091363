// This is the "/" entry point that decides where to go from here depending
// on which user type we're dealing with.

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Loading from './components/common/Misc/Loading'
import useStore from './store'

export default function Home(props) {
  let navigate = useNavigate()
  const isAuthenticated = useStore((state) => state.isAuthenticated)
  const companyDetails = useStore((state) => state.companyDetails)

  useEffect(() => {
    // Just to make sure we keep trying if we're stuck on this page
    let stuckTimer = setTimeout(() => {
      document.location.href = '/?'
    }, 2500)

    if (isAuthenticated) {
      console.log('[HOME] Decide if Agency or Employee Module...', companyDetails)
      if (companyDetails && parseInt(companyDetails.company_type) === 1) {
        navigate('/employee/flights/search')
      } else if (companyDetails && parseInt(companyDetails.company_type) === 0) {
        navigate('/agency/client')
      } else {
        // Seems to be some kind of trouble, let's wait a bit and then retry
        setTimeout(() => {
          document.location.href = '/?trouble=100'
        }, 1500)
      }
    } else {
      console.log('[Home] [useEffect: isAuthenticated] False')
      navigate('/login')
    }

    return () => {
      clearTimeout(stuckTimer)
    }
  }, [isAuthenticated, companyDetails, navigate])

  return (
    <div
      style={{
        maxWidth: 300,
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 150,
        textAlign: 'center'
      }}>
      <Loading />
      <br />
      <br />
      One moment, boarding soon...
    </div>
  )
}
