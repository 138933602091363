import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../Misc/Button";

const MissingFlight = (props) => {
  let searchDetails = props.searchDetails;
  return (
    <div style={{
      marginTop: 50,
      fontSize: 14,
      color: '#666',
      display: 'flex',
      alignItems: 'center',
      backgroundColor:'#fff',
      boxShadow: "3px 3px 10px #ddd",
      padding: 15,
      borderRadius:10,
      width: 540,
      textAlign: "center"
    }}>

      <div style={{ width: 50, marginRight:10 }}>
        <FontAwesomeIcon icon="search-location" size="2x" className="primary-icon-color" />
      </div>

      <div style={{width: 430}}>
        <div style={{display:'flex', width: "100%", alignItems:"center"}}>
          <div style={{marginRight:20}}>
            If you cannot find the flight you are looking for, use:
          </div>
          <div style={{flexGrow:1}}></div>
          <div style={{textAlign:"right"}}>
            <Button small title={'Identify Flight'} onClick={() => {
              window.scrollTo(0,0)
              if (props.onLaunch) props.onLaunch();
            }}/>
          </div>
        </div>

        <div style={{display:'flex', width: "100%", alignItems:"center", marginTop:10}}>
          <div style={{marginRight:20}}>
            For help use this <a rel="noreferrer" style={{textDecoration:"underline"}} target="_blank" href="https://www.climpact.ai/cst-faq-identify-flight">FAQ page</a>. Only if you still need help:
          </div>
          <div style={{flexGrow:1}}></div>
          <div style={{textAlign:"right"}}>
            <Button
              small
              secondary
              title={'Reach out'}
              onClick={() => {
                // Semi-intelligent support text generator
                var q = ''
                if (searchDetails) {
                  if (searchDetails.origin && searchDetails.destination) {
                    q += `From [${searchDetails.origin}] to [${
                      searchDetails.destination
                    }], ${searchDetails.route_direction.toUpperCase()}, in ${
                      searchDetails.cabin_class
                    } class.`
                    if (searchDetails.departure_date) {
                      q += ` Leaving on ${searchDetails.departure_date}`
                      if (searchDetails.return_date && searchDetails.return_date !== '') {
                        q += `, and returning on ${searchDetails.return_date}.`
                      } else {
                        q += '.'
                      }
                    }
                  }
                }
                q +=
                  ' I couldn’t find these flights: flight # (or airline name & departure time): '
                //window.$crisp.push(['do', 'session:reset'])
                window.$crisp.push(['do', 'chat:open'])
                setTimeout(() => {
                  window.$crisp.push([
                    'do',
                    'message:show',
                    [
                      'text',
                      'Please provide the flight number (or the airline name and all departure times) for all segments of the flight including stop overs.'
                    ]
                  ])
                  window.$crisp.push(['set', 'message:text', [q]])
                }, 1000)
              }}
            />                
          </div>
        </div>
      </div>
    </div>
  )
}

export default MissingFlight;