import { Tools } from '../../../helpers/Tools'

export default function FlightRowMobile(props) {
  const colorWrapNumber = (n, postfix = '') => {
    let isNullorNegative = parseInt(n.replace('+', '').replace('-', '').replace('%', '')) <= 0
    return (
      <span style={{ color: isNullorNegative ? '#DD4155' : '#80B000' }}>
        {isNullorNegative ? '-' : '+'}
        {Math.abs(parseInt(n))}
        {postfix || ''}
      </span>
    )
  }

  const colorWrapNumberInverse = (n, postfix = '') => {
    let isNullorNegative = parseInt(n) <= 0
    return (
      <span style={{ color: isNullorNegative ? '#80B000' : '#DD4155' }}>
        {isNullorNegative ? '-' : '+'}
        {Math.abs(parseInt(n))}
        {postfix || ''}
      </span>
    )
  }

  const renderDuration = (minutes) => {
    var h = Math.floor(minutes / 60)
    var m = minutes % 60
    if (h === 0) return `${m} min`
    return `${h}h ${m} min`
  }

  const co2Savings = () => {
    return (
      -1 *
      (100 -
        Math.round(
          (100 * props.offer[props.direction].climate_impact) /
            props.habitToBeat[props.direction].climate_impact
        ))
    )
  }

  return (
    <div>
      {/* Top Row (Cabin Class, Priority, Airline) */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 10,
          marginTop: 10
        }}>
        <div>
          <div style={{ fontWeight: 'bold', fontSize: 18 }}>
            {Tools.formatCabinClass(Tools.figureOutCabinClass(props.offer[props.direction]))}
          </div>
          <div style={{ fontSize: 12, marginTop: 2 }}>
            {props.offer[props.direction].segments
              .map((segment) => {
                return segment.aircraft_code
              })
              .join(', ')}
          </div>
        </div>
        <div>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: 10,
              backgroundColor: props.offer[props.direction].priority ? '#ddd' : '#fff',
              color: '#000',
              padding: '2px 3px',
              borderRadius: 5
            }}>
            {props.offer[props.direction].priority ? 'Priority' : ''}
          </div>
        </div>
        <div style={{ fontSize: 12 }}>
          {props.offer[props.direction].segments
            .map((segment) => {
              return `${Tools.figureOutCarrier(segment)} ${segment.flight_number}`
            })
            .join(', ')}
        </div>
      </div>
      {/* Departure/Arrival Times and Airports */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 20,
          marginTop: 20
        }}>
        <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 15 }}>
            <img src="/departure.svg" alt="Departure" />
          </div>
          <div>
            <div style={{ fontWeight: 'bold', fontSize: 14 }}>
              {Tools.formatTravelTime(props.offer[props.direction].segments[0].departure_time)}
            </div>
            <div style={{ fontSize: 14, marginTop: 3 }}>
              {props.offer[props.direction].segments[0].origin}
            </div>
          </div>
        </div>
        <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 15 }}>
            <img src="/arrival.svg" alt="Departure" />
          </div>
          <div>
            <div style={{ fontWeight: 'bold', fontSize: 14 }}>
              {Tools.formatTravelTime(
                props.offer[props.direction].segments[
                  props.offer[props.direction].segments.length - 1
                ].arrival_time
              )}
              {Tools.isNextDay(props.offer[props.direction].segments) && (
                <div
                  style={{
                    display: 'inline-block',
                    backgroundColor: '#ddd',
                    borderRadius: 4,
                    padding: '2px 3px',
                    fontSize: 9,
                    position: 'relative',
                    top: -1,
                    marginLeft: 5
                  }}>
                  +1
                </div>
              )}
            </div>
            <div style={{ fontSize: 14, marginTop: 3 }}>
              {
                props.offer[props.direction].segments[
                  props.offer[props.direction].segments.length - 1
                ].destination
              }
            </div>
          </div>
        </div>
      </div>
      {/* Stats table at the bottom (Duration, Stops, etc.) */}
      <div style={{ display: 'flex', width: '100%' }}>
        {/* LEFT COLUMN */}
        <div style={{ width: '50%', fontSize: 14, lineHeight: '1.5' }}>
          <div>Duration</div>
          <div>Stops</div>
          <div>Climate Impact</div>
          <div>Climate Points</div>
        </div>
        {/* MIDDLE COLUMN */}
        <div style={{ fontSize: 14, lineHeight: '1.5' }}>
          <div>{renderDuration(props.offer[props.direction].duration)}</div>
          <div>{Tools.renderStopOvers(props.offer[props.direction].segments)}</div>
          <div
            style={{
              overflowX: 'hidden',
              overflowY: 'hidden',
              maxWidth: 100,
              display: 'block',
              wordBreak: 'break-all',
              whiteSpace: 'nowrap'
            }}>
            {props.offer[props.direction].climate_impact} kg CO
            <sub style={{ fontSize: 12 }}>2e</sub>
          </div>
          <div>
            {props.habitToBeat === props.offer && '0'}
            {props.habitToBeat !== props.offer &&
              colorWrapNumber(`${props.offer[props.direction].fnz_points}`)}
          </div>
        </div>
        {/* RIGHT COLUMN */}
        <div style={{ flexGrow: 1 }}></div>
        <div
          style={{
            textAlign: 'right',
            fontSize: 14,
            lineHeight: '1.5',
            width: 50
          }}>
          <div>&nbsp;</div> {/* Duration */}
          <div>&nbsp;</div> {/* Stops */}
          <div>
            {props.habitToBeat === props.offer && '0%'}
            {props.habitToBeat !== props.offer && colorWrapNumberInverse(co2Savings(), '%')}
          </div>
          <div>&nbsp;</div>
        </div>
      </div>
    </div>
  )
}
