import { useState } from 'react'
import PageTitle from '../../components/common/Misc/PageTitle'
import TripSummaryCar from '../../components/common/Misc/TripSummaryCar'
import OfferPanelCarDesktop from '../../components/employee/ClimateOptions/OfferPanelCarDesktop'
import OfferPanelTrainDesktop from '../../components/employee/ClimateOptions/OfferPanelTrainDesktop'
import useEmployeeStore from '../../employee_store'

export default function EmployeeModuleClimateOptionsCar(props) {
  const carJourney = useEmployeeStore((store) => store.carJourney)
  const [selectedOption, selectOption] = useState('train')

  // Calculate train estimates
  let trainCost = 0.185 * carJourney.route.total_distance * carJourney.searchQuery.passengers
  let trainCostPercentage = Math.round((100.0 * trainCost) / carJourney.route.total_cost) - 100
  let trainCo2 = 0.1 * carJourney.route.total_co2
  let trainCo2Percentage = 90
  let trainClimatePoints = 0.5 * (carJourney.route.total_co2 - trainCo2)

  return (
    <div>
      <div style={{ maxWidth: 950, width: '100%' }}>
        <PageTitle noBottomMargin={true} title={'Your Climate Options'}>
          <>Pick your preferred trip option below, we'll keep it in your trip journal for later.</>
        </PageTitle>
      </div>

      <div style={{ marginTop: 30, marginBottom: 40 }}>
        <TripSummaryCar journey={carJourney} noBackground={true} />
      </div>

      <div>
        <OfferPanelCarDesktop
          journey={carJourney}
          selected={selectedOption === 'car'}
          onSelect={() => {
            selectOption('car')
          }}
        />
      </div>
      <div style={{ marginTop: 40 }}>
        <OfferPanelTrainDesktop
          journey={carJourney}
          selected={selectedOption === 'train'}
          onSelect={() => {
            selectOption('train')
          }}
          cost={trainCost}
          costPercentage={trainCostPercentage}
          co2={trainCo2}
          co2Percentage={trainCo2Percentage}
          climatePoints={trainClimatePoints}
        />
      </div>
    </div>
  )
}
