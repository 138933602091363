import FilterPopover from './Components/FilterPopover'

export default function FilterStopovers(props) {
  let options = [
    { title: 'Any number of stops', value: 5 },
    { title: 'Nonstop', value: 0 },
    { title: '1 stop or fewer', value: 1 },
    { title: '2 stops or fewer', value: 2 }
  ]

  const getTitle = (options, currentOption) => {
    return options.find((o) => parseInt(o.value, 10) === parseInt(currentOption, 10)).title
  }

  return (
    <FilterPopover
      title={'Stops'}
      selectedTitle={getTitle(options, props.value)}
      active={parseInt(props.defaultValue, 10) !== parseInt(props.value, 10)}
      onCancel={() => {
        if (props.onCancel) {
          props.onCancel()
        }
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          fontSize: 14
        }}
        onChange={(e) => {
          props.onChange(parseInt(e.target.value, 10))
        }}>
        {options.map((option, i) => {
          return (
            <label key={`stopover_option_${i}`}>
              <input
                className="filterRadioButton"
                type="radio"
                value={option.value}
                defaultChecked={props.value === option.value}
                name="stops"
              />{' '}
              {option.title}
            </label>
          )
        })}
      </div>
    </FilterPopover>
  )
}
