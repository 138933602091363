import { Tools } from '../../../helpers/Tools'
import Button from '../../common/Misc/Button'

export default function OfferPanelGenericMobile(props) {
  const calculateFNZ = (compareTo, factor) => {
    if (compareTo) {
      try {
        let baseline_fnz = compareTo.fnz_points
        if (isNaN(baseline_fnz)) return 0
        return Math.round(baseline_fnz * factor)
      } catch {
        return 0
      }
    } else return 0
  }

  return (
    <div
      style={{
        marginTop: 0,
        marginBottom: 20,
        cursor: 'pointer',
        width: '100%'
      }}
      onClick={props.onSelect}>
      {/* This is the top label or badge ------------------------ */}
      <div
        style={{
          position: 'relative',
          left: '50%',
          width: 140,
          textAlign: 'center',
          marginLeft: -70,
          top: 10,
          padding: '5px 15px',
          color: '#fff',
          fontSize: 12,
          fontWeight: 600,
          display: 'inline-block',
          backgroundColor: '#0A1EAA',
          borderRadius: 4,
          borderTopLeftRadius: 16,
          borderBottomRightRadius: 16
        }}>
        {props.topLabel}
      </div>{' '}
      {/* End of Top Label */}
      {/* Body of the white panel container begins here --------------------------------------------- */}
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: 40,
          boxShadow: props.selected
            ? '0 16px 56px 0 rgba(10,30,170,0.24)'
            : '0 2px 8px 0 rgba(10,30,170,0.24)',
          padding: 20
        }}>
        {/* Title Row (Save 42% of CO2e and earn 1200 FNZ) in green or gray ------------------------ */}
        <div
          style={{
            backgroundColor: props.selected
              ? props.section === 'habit_to_beat'
                ? 'rgba(10,30,170,0.04)'
                : '#E0F88B'
              : 'rgba(10,30,170,0.04)',
            borderRadius: 32,
            padding: 12,
            marginBottom: 20,
            alignItems: 'center'
          }}>
          <div style={{ fontSize: 12, textAlign: 'center' }}>
            <div>
              Save{' '}
              <strong>
                {props.section === 'option_online' ? 100 : 90}% CO
                <sub style={{ fontSize: 12 }}>2e</sub>
              </strong>{' '}
              {calculateFNZ(props.compareTo, props.section === 'option_online' ? 2.5 : 0) > 0 && (
                <span>
                  and earn{' '}
                  <strong>
                    {Tools.formatNumber(
                      calculateFNZ(props.compareTo, props.section === 'option_online' ? 2.5 : 0)
                    )}{' '}
                    Climate Points
                  </strong>
                </span>
              )}
            </div>
          </div>
        </div>
        {/* End of Title Row */}

        <div style={{ marginTop: 20, textAlign: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <Button
              fullWidth={true}
              text={props.confirmLabel || 'Select'}
              onClick={() => {
                /* Mobile works a bit differently, we need to select the correct item first
                and then send the confirm signal to the server slightly afterwards */
                props.onSelect()
                setTimeout(() => {
                  props.onConfirm()
                }, 300)
              }}
            />
          </div>
        </div>
      </div>
      {/* End of White Body Container */}
    </div>
  )
}
