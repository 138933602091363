import { useState, useMemo } from 'react'
import TextInput from './TextInput'
import './AirlineSearchPanel.css'
import { Airlines } from '../../../helpers/Airlines'
import Button from './Button'

const listOfAirlines = (airlineData) => {
  var airlines = [];
  airlineData.alliances.forEach((alliance) => {
    alliance.members.forEach((member) => {
      airlines.push(member);
    })
  })
  return airlines;
}

export default function AirlineSearchPanel(props) {
  const airlines = useMemo(() => listOfAirlines(Airlines), [])
  const [q, setQuery] = useState('')
  const [localImageOverwrite, setLocalImageOverwrite] = useState(false)

  const filterResults = (q, data) => {
    if (q === '') return []
    if (q.length === 0) return []
    let iataResults = data.filter((d) => {
      if (d.iata.indexOf(q.toUpperCase())>=0) return true;
      return false;
    })
    let airlineResults = data.filter((d) => {
      if (d.airline.toLowerCase().indexOf(q.toLowerCase())>=0) return true;
      return false;
    })

    return [...new Set([...iataResults, ...airlineResults])]
  }

  return (
    <div className="as-panel">
      {props.airline === null && (
        <>
          <TextInput
            type={'text'}
            name={'iata'}
            style={{ paddingLeft: 0, paddingTop: 0, fontSize: 14, width: props.width ? props.width : 185 }}
            value={q}
            placeholder="Enter name or IATA code..."
            onWhite={true}
            onChange={(d) => {
              setQuery(d)
            }}
          />          
        </>
      )}

      <div style={{overflowY:"auto", maxHeight: 200}}>
      {props.airline === null &&
        filterResults(q, airlines)
          .slice(0, 10)
          .map((result, i) => {
            return (
              <div
                className={'as-result'}
                key={`as_result_${i}`}
                onClick={() => {
                  if (props.onChange) {
                    props.onChange(result)
                  }
                }}>
                <div style={{ fontWeight: "bold", fontSize:13 }}>{result.iata}</div>
                <div style={{ fontSize: 13, color: '#666' }}>{result.airline}</div>
              </div>
            )
          })
      }
      </div>

      {props.airline !== null && (
        <div className={'as-selected'}>          
          <img alt="airline_logo" src={localImageOverwrite ? localImageOverwrite : `/airlines/${props.airline.iata.toLowerCase()}.png`} style={{width:20, height:20, marginRight:5}} onError={() => {
            setLocalImageOverwrite(`/departure.svg`)
          }}/>
          {`${props.airline.airline} (${props.airline.iata})`}
          <Button
            x
            red
            onClick={() => {
              if (props.onChange) {
                setLocalImageOverwrite(false)
                props.onChange(null)
              }
            }}
          />
        </div>
      )}
    </div>
  )
}
