import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tools } from '../../../helpers/Tools'

export default function CarRowDesktop(props) {

  const formatDuration = (minutes) => {
    var h = Math.floor(parseInt(minutes) / 60)
    var m = parseInt(minutes) % 60
    if (h === 0) return `${m} min`
    return `${h}h ${m} min`
  }

  const formatFuelType = (fuel_type) => {
    return Tools.capitalizeCase(fuel_type || "Diesel")
  }

  const formatRiders = (passengers) => {
    let totalRiders = parseInt(passengers||0);
    return totalRiders === 1 ? "1 person": `${totalRiders} persons`
  }

  const formatDistance = (distance) => {
    return parseInt(distance) + " km"
  }

  const formatClimateImpact = (climateImpact) => {
    return (
      <>
      {climateImpact.toFixed(1)}kg CO
      <sub style={{ fontSize: 8 }}>2e</sub>
      </>
    )
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
      <div style={{ paddingLeft: 30, paddingRight: 30, fontSize: 13, width: 120 }}>
       <FontAwesomeIcon icon={'car'} size={'2x'} style={{marginRight:20}} className="car-icon" />
      </div>
      <div style={{ minWidth: 118 }}>
        <div style={{ fontWeight: 'bold', fontSize: 14 }}>
          {formatFuelType(props.journey.searchQuery.fuel_type)}, {props.journey.searchQuery.fuel_consumption} L
        </div>
        <div style={{ fontSize: 12 }}>{formatRiders(props.journey.searchQuery.passengers)}</div>
      </div>
      <div style={{fontSize: 12}}>
        {formatDistance(props.journey.route.distance)}
      </div>
      <div style={{ minWidth: 40, textAlign: 'right', marginRight: 15 }}>
        <img src="/duration.svg" width="20" alt="duration" />
      </div>
      <div>
        <div style={{ fontSize: 12, marginBottom: 2 }}> 
          {formatDuration(props.journey.route.duration)}
        </div>
      </div>

      <div style={{ flexGrow: 1 }}></div>

      <div style={{ textAlign: 'right', minWidth: 100 }}>
        <div style={{ color: '#333', fontSize: 18, fontWeight: 'bold' }}>
          0%
        </div>
        <div style={{ fontSize: 12 }}>
          {formatClimateImpact(props.journey.route.co2)}
        </div>
      </div>

      <div style={{ textAlign: 'right', minWidth: 100, marginRight: 22 }}>
        <div style={{ color: '#333', fontSize: 14, fontWeight: 'bold' }}>
          0
        </div>
        <div style={{ fontSize: 12 }}>CPs</div>
      </div>
    </div>
  )
}
