import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Networking } from '../../helpers/Networking'
import Button from '../../components/common/Misc/Button'
import './HabitSurvey.css'

export default function EmployeeModuleHabitSurveyPage(props) {
  let navigate = useNavigate()

  const [isStarAllianceMember, setStarAllianceMember] = useState(false)
  const [isOneworldMember, setOneworldMember] = useState(false)
  const [isSkyTeamMember, setSkyTeamMember] = useState(false)
  const [loyaltyPreference, setLoyaltyPreference] = useState(0)
  const [businessClassEligibility, setBusinessClassEligibility] = useState(0)
  const [businessClassPreference, setBusinessClassPreference] = useState(0)

  const isLoyaltyMember = () => {
    return isStarAllianceMember || isOneworldMember || isSkyTeamMember
  }

  const submitSurvey = async () => {
    let payload = {
      loyalty_programs: {
        star_alliance: isStarAllianceMember,
        oneworld: isOneworldMember,
        skyteam: isSkyTeamMember
      },
      loyalty_preference: loyaltyPreference,
      business_class_eligibility: businessClassEligibility,
      business_class_preference: businessClassPreference
    }
    //TODO
    alert('This is not saved to the profile yet')
    await Networking.updateUserHabit(payload)
    navigate('/employee/preferences')
  }

  return (
    <form className="habit-survey">
      <h3>If you are a member of airline loyalty programs, please let us know which:</h3>
      <label>
        <input
          type="checkbox"
          value={isStarAllianceMember}
          onChange={(e) => {
            setStarAllianceMember(e.target.checked)
          }}
        />{' '}
        Star Alliance
      </label>
      <br />
      <label>
        <input
          type="checkbox"
          value={isOneworldMember}
          onChange={(e) => {
            setOneworldMember(e.target.checked)
          }}
        />{' '}
        Oneworld
      </label>
      <br />
      <label>
        <input
          type="checkbox"
          value={isSkyTeamMember}
          onChange={(e) => {
            setSkyTeamMember(e.target.checked)
          }}
        />{' '}
        SkyTeam
      </label>

      {isLoyaltyMember() && (
        <>
          <h3>Do you prefer to fly with an airline of your loyalty program?</h3>
          <div
            onChange={(v) => {
              setLoyaltyPreference(parseInt(v.target.value, 10))
            }}>
            <label>
              <input name="prefer_loyalty" type="radio" value={0} /> Yes, status and/or bonus miles
              are important for me
            </label>
            <br />
            <label>
              <input name="prefer_loyalty" type="radio" value={1} /> Yes, but it depends. Most time
              it does influence my booking decision
            </label>
            <br />
            <label>
              <input name="prefer_loyalty" type="radio" value={2} /> No, it does not influence my
              flight booking decision
            </label>
          </div>
        </>
      )}

      {
        <>
          <h3>Does your travel policy allow you to fly Business Class?</h3>
          <div
            onChange={(v) => {
              setBusinessClassEligibility(parseInt(v.target.value, 10))
            }}>
            <label>
              <input name="business_eligibility" type="radio" value={3} /> Yes, long distance (on
              flights longer than 8 hours)
            </label>
            <br />
            <label>
              <input name="business_eligibility" type="radio" value={2} /> Yes, medium distance (on
              flights 4 to 8 hours) time it does influence my booking decision
            </label>
            <br />
            <label>
              <input name="business_eligibility" type="radio" value={1} /> Yes, short distance (less
              than 4 hours) flight booking decision
            </label>
            <br />
            <label>
              <input name="business_eligibility" type="radio" value={0} /> No, not allowed to fly
              business typically
            </label>
          </div>
        </>
      }

      {businessClassEligibility > 0 && (
        <>
          <h3>Do you normally fly Business Class when you are allowed to do so?</h3>
          <div
            onChange={(v) => {
              setBusinessClassPreference(parseInt(v.target.value, 10))
            }}>
            <label>
              <input name="business_preference" type="radio" value={1} /> Yes, most times (or
              always)
            </label>
            <br />
            <label>
              <input name="business_preference" type="radio" value={0} /> No, not important to me
            </label>
          </div>
        </>
      )}

      <div style={{ marginTop: 50 }}>
        <Button type="button" text="Save Flight Habits" onClick={submitSurvey} />
      </div>
    </form>
  )
}
