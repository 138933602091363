import React from 'react'
import './FilterButton.css'
const FilterButton = React.forwardRef((props, ref) => {
  var buttonIsInResetState = false
  if (props.disabled) buttonIsInResetState = true
  return (
    <button
      ref={ref}
      type="button"
      className={`filter-button-component ${props.disabled ? 'filter-button-disabled' : ''}`}
      onClick={() => {
        if (props.onClick) {
          props.onClick()
        }
      }}>
      {props.text}
      {!buttonIsInResetState && (
        <div
          className="filter-button-cancel"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            if (props.onCancel) props.onCancel()
          }}>
          &#10005;
        </div>
      )}
    </button>
  )
})
export default FilterButton
