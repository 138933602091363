import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../components/common/Misc/Button'
import ErrorText from '../components/common/Misc/ErrorText'
import TextInput from '../components/common/Misc/TextInput'
import { CognitoPasswordUpdate } from '../components/common/Authentication/CognitoPasswordUpdate'

import useStore from '../store'
import Loading from '../components/common/Misc/Loading'

export default function LoginPage(props) {
  const navigate = useNavigate()

  // Global state
  const attemptCognitoLogin = useStore((state) => state.attemptCognitoLogin)
  const clearLoginError = useStore((state) => state.clearLoginError)
  const isAuthenticated = useStore((state) => state.isAuthenticated)
  const loginFailed = useStore((state) => state.loginFailed)
  const passwordResetRequired = useStore((state) => state.passwordResetRequired)
  const awsAuthSpecialStepRequired = useStore((state) => state.awsAuthSpecialStepRequired)

  // Local state
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isAuthenticated) {
      console.log('[Login] [useEffect:isAuthenticated] True')
      navigate('/') // Head back to the root, decide what to do there (with the new user auth)
    }
  }, [isAuthenticated, navigate])

  // Actions
  const signIn = async (e) => {
    setIsLoading(true)
    e.preventDefault()
    const result = await attemptCognitoLogin(username.trim(), password.trim())
    if (result) {
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }

  // AWS may require us to react to certain challenges. One of those challenges is the NEW PASSWORD REQUIRED
  // Challenge. This fires up if we set up a user in AWS Cognito, and then the user logs in the first time.
  // AWS requires them to set up their first password, so we need to give them a screen to do that.
  if (awsAuthSpecialStepRequired && awsAuthSpecialStepRequired === 'NEW_PASSWORD_REQUIRED') {
    return <CognitoPasswordUpdate />
  }

  return (
    <div
      style={{
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 100,
        padding: 20,
        backgroundColor: 'white',
        boxShadow: '5px 5px 10px #eee',
        borderRadius: 10
      }}>
      <div style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold', marginBottom: 40 }}>
        <img src="/logo.png" alt="Logo" height="20" />
      </div>

      <ErrorText show={loginFailed && !passwordResetRequired}>
        There was an issue trying to sign you in. Please try again.
      </ErrorText>

      <ErrorText show={passwordResetRequired}>
        <div style={{color:"white", fontSize: 16, padding:10}}>Temporary Password Expired</div>
      </ErrorText>
      {passwordResetRequired && 
      <div style={{padding:15, textAlign: "center", backgroundColor: "#fafafa", borderRadius: 15, marginBottom: 25, fontSize: 16}}>
        Please click the button below to contact support. You need a new temporary password to be able to set up your account.
        <br />
        <br />
        <Button title="Contact Support" onClick={() => {
          let q = "My company e-mail address is: " + username;
          window.$crisp.push(['do', 'chat:open'])
          setTimeout(() => {
            window.$crisp.push([
              'do',
              'message:show',
              [
                'text',
                'Please confirm that the email address below is your correct username. You will then receive an email with a new temporary password. This process may take a few days. Please make sure you set up the account to avoid the new temporary password from expiring again.'
              ]
            ])
            window.$crisp.push(['set', 'message:text', [q]])
          }, 1000)
        }}></Button>
      </div>
      }

      {loading && (
        <div style={{ marginTop: 50, marginBottom: 50 }}>
          <Loading />
        </div>
      )}

      {!loading && (
        <form onSubmit={signIn}>
          <TextInput
            type={'text'}
            label={'Username'}
            name={'username'}
            value={username}
            placeholder="Your Username"
            onWhite={true}
            onChange={(d) => {
              setUsername(d)
              if (loginFailed) clearLoginError()
            }}
          />

          <br />

          <TextInput
            type={'password'}
            label={'Password'}
            name={'password'}
            value={password}
            placeholder="Your Password"
            onWhite={true}
            onChange={(d) => {
              setPassword(d)
              if (loginFailed) clearLoginError()
            }}
          />

          <br />

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <a style={{ fontSize: 13, color: '#999' }} href="/reset">
                Forgot Password?
              </a>
            </div>
            <div style={{ flexGrow: 1 }}></div>
            <Button testPoint="sign-in-button" type="submit" text={'Sign in'} />
          </div>
        </form>
      )}
    </div>
  )
}
