import { Networking } from './Networking'
export const Company = {
  fetchCostCenters: async () => {
    let data = await Networking.getCostCenters()
    if (data && data.costcenters && data.costcenters.length > 0) {
      return data.costcenters
    } else {
      return []
    }
  }
}
