import { useState } from 'react'
import { Range } from 'react-range'
import Tabs from '../../common/Misc/Tabs'
import FilterPopover from './Components/FilterPopover'

export default function FilterTimes(props) {
  const [tab, setTab] = useState(0)

  const anyChangesMade = (props) => {
    if (props.outboundDepartureFrom > 0) return true
    if (props.outboundDepartureTo < 24) return true
    if (props.outboundArrivalFrom > 0) return true
    if (props.outboundArrivalTo > 24) return true
    if (props.returnDepartureFrom > 0) return true
    if (props.returnDepartureTo < 24) return true
    if (props.returnArrivalFrom > 0) return true
    if (props.returnArrivalTo > 24) return true
    return false
  }

  const getTitle = (props) => {
    if (!anyChangesMade(props)) return 'Times'
    return `Custom Times`
  }

  const renderTime = (a, b) => {
    return `${a}:00 — ${b}:00`
  }

  return (
    <FilterPopover
      title={'Times'}
      selectedTitle={getTitle(props)}
      active={anyChangesMade(props)}
      onCancel={() => {
        if (props.onCancel) props.onCancel()
      }}>
      <div style={{ marginBottom: 20, marginTop: -15 }}>
        <Tabs tabs={['Outbound', 'Return']} selectedIndex={tab} onChange={(i) => setTab(i)} />
      </div>

      <div style={{ paddingLeft: 10, paddingRight: 10 }}>
        {tab === 0 && (
          <div>
            <div style={{ fontSize: 14, marginBottom: 15 }}>
              Departure<span style={{ color: '#999', marginLeft: 10 }}>·</span>
              <span style={{ color: '#0000ff', marginLeft: 10, fontSize: 13 }}>
                {renderTime(props.outboundDepartureFrom, props.outboundDepartureTo)}
              </span>
            </div>
            <Range
              step={1}
              min={0}
              max={24}
              values={[props.outboundDepartureFrom, props.outboundDepartureTo]}
              onChange={(v) => {
                props.onOutboundDepartureFromChange(v[0])
                props.onOutboundDepartureToChange(v[1])
              }}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '3px',
                    width: '100%',
                    backgroundColor: '#ccc'
                  }}>
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '20px',
                    width: '20px',
                    borderRadius: 20,
                    backgroundColor: '#0B1CAA'
                  }}
                />
              )}
            />
            <div style={{ fontSize: 14, marginTop: 30, marginBottom: 15 }}>
              Arrival<span style={{ color: '#999', marginLeft: 10 }}>·</span>
              <span style={{ color: '#0000ff', marginLeft: 10, fontSize: 13 }}>
                {renderTime(props.outboundArrivalFrom, props.outboundArrivalTo)}
              </span>
            </div>
            <Range
              step={1}
              min={0}
              max={24}
              values={[props.outboundArrivalFrom, props.outboundArrivalTo]}
              onChange={(v) => {
                props.onOutboundArrivalFromChange(v[0])
                props.onOutboundArrivalToChange(v[1])
              }}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '3px',
                    width: '100%',
                    backgroundColor: '#ccc'
                  }}>
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '20px',
                    width: '20px',
                    borderRadius: 20,
                    backgroundColor: '#0B1CAA'
                  }}
                />
              )}
            />
            <br />
          </div>
        )}

        {tab === 1 && (
          <div>
            <div style={{ fontSize: 14, marginBottom: 15 }}>
              Departure<span style={{ color: '#999', marginLeft: 10 }}>·</span>
              <span style={{ color: '#0000ff', marginLeft: 10, fontSize: 13 }}>
                {renderTime(props.returnDepartureFrom, props.returnDepartureTo)}
              </span>
            </div>
            <Range
              step={1}
              min={0}
              max={24}
              values={[props.returnDepartureFrom, props.returnDepartureTo]}
              onChange={(v) => {
                props.onReturnDepartureFromChange(v[0])
                props.onReturnDepartureToChange(v[1])
              }}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '3px',
                    width: '100%',
                    backgroundColor: '#ccc'
                  }}>
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '20px',
                    width: '20px',
                    borderRadius: 20,
                    backgroundColor: '#0B1CAA'
                  }}
                />
              )}
            />
            <div style={{ fontSize: 14, marginTop: 30, marginBottom: 15 }}>
              Arrival<span style={{ color: '#999', marginLeft: 10 }}>·</span>
              <span style={{ color: '#0000ff', marginLeft: 10, fontSize: 13 }}>
                {renderTime(props.returnArrivalFrom, props.returnArrivalTo)}
              </span>
            </div>
            <Range
              step={1}
              min={0}
              max={24}
              values={[props.returnArrivalFrom, props.returnArrivalTo]}
              onChange={(v) => {
                props.onReturnArrivalFromChange(v[0])
                props.onReturnArrivalToChange(v[1])
              }}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '3px',
                    width: '100%',
                    backgroundColor: '#ccc'
                  }}>
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '20px',
                    width: '20px',
                    borderRadius: 20,
                    backgroundColor: '#0B1CAA'
                  }}
                />
              )}
            />
            <br />
          </div>
        )}
      </div>
    </FilterPopover>
  )
}
