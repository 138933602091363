import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SearchButton from '../Misc/SearchButton'
import { Tools } from '../../../helpers/Tools'
import './CarRoutePanel.css'


function PanelBox(props) {
  var formattedValue = props.value || "";
  if (props.format) {
    if (props.format === "km") {
      formattedValue = `${parseInt(formattedValue)} km`
    }
    if (props.format === "minutes") {
      var h = Math.floor(parseInt(formattedValue) / 60)
      var m = parseInt(formattedValue) % 60
      if (h === 0) { 
        formattedValue = `${m} min` 
      } 
      else {
        formattedValue = `${h}h ${m}min`  
      }
    }
    if (props.format === "co2") {
      formattedValue = `${parseFloat(formattedValue).toFixed(1)} kg`
    }
    if (props.format === "currency") {
      formattedValue = `CHF ${Tools.formatNumber(formattedValue.toFixed(2))}`
    }

  }

  return (
    <div style={{ padding: 5 }}>
      <div style={{ fontSize: 13, color: '#999', marginBottom: 8 }}>{props.label || ''}</div>
      <div style={{ fontSize: 16, fontWeight: 'bold' }}>{formattedValue}</div>
    </div>
  )
}

export default function CarRoutePanel(props) {

  return (
    <div
      style={{
        border: '1px solid #ddd',
        borderRadius: 15,
        padding: 20,
        display: 'flex',
        alignItems: 'center',
        gap: 25
      }}>
      <div style={{ marginRight: 15 }}>
        <FontAwesomeIcon icon={'car'} size={'2x'} className="car-icon" />
      </div>
      <PanelBox label={"Outward Trip"} format="km" value={props.route.distance || 'N/A'} />
      <PanelBox label="Estimated Time" format="minutes" value={props.route.duration || 'N/A'} />
      <PanelBox label="Estimated CO2" format="co2" value={props.route.co2 || 'N/A'} />
      <PanelBox label="Estimated Cost" format="currency" value={props.route.cost || 'N/A'} />
      <div style={{ flexGrow: 2 }}></div>
      <SearchButton
        confirm={true}
        noIcon={true}
        title={'Confirm'}
        onClick={() => {
          if (props.onConfirm) {
            props.onConfirm()
          }
        }}
      />
    </div>
  )
}
