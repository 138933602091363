/*
  store.js
  
  State Machine and Globals 
  Using the very simple Zustand (https://github.com/pmndrs/zustand) as a reactive state helper

  This is simply a live-data store for the entire app as it runs.
*/

import create from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'

const initialState = {
  pastFlight: false,
  habitToBeat: false,
  bestMatch: false,
  betterMatch: false,
  maxSavings: false,
  searchId: '',
  trips: [],
  recommendations: null,
  climateOptionSection: null,
  selectedTrip: null,
  carJourney: false,
  delegateEmployeeId: ''
}

const useEmployeeStore = create((set, get) => ({
  pastFlight: initialState.pastFlight,
  habitToBeat: initialState.habitToBeat,
  bestMatch: initialState.bestMatch,
  betterMatch: initialState.betterMatch,
  maxSavings: initialState.maxSavings,
  searchId: initialState.searchId,
  trips: initialState.trips,
  recommendations: initialState.recommendations,
  climateOptionSection: initialState.climateOptionSection,
  selectedTrip: initialState.selectedTrip,
  carJourney: initialState.carJourney,
  delegateEmployeeId: initialState.delegateEmployeeId,

  setDelegateEmployeeId: (employee_id) => {
    set({ delegateEmployeeId: employee_id })
  },

  setCarJourney: (journey) => {
    set({ carJourney: journey })
  },

  setSelectedTrip: (trip) => {
    set({ selectedTrip: trip })
  },

  setRecommendations: (recommendations) => {
    set({ recommendations: recommendations })
  },

  setClimateOptionSection: (section) => {
    set({ climateOptionSection: section })
  },

  setTrips: (trips) => {
    set({ trips: trips })
  },

  setPastFlight: (state) => {
    set({ pastFlight: state })
  },

  setSearchId: (searchId) => {
    set({ searchId: searchId })
  },

  setHabitToBeat: (trip) => {
    if (!trip) {
      set({ habitToBeat: false })
      return
    }
    set({
      habitToBeat: {
        ...trip,
        recommendation: {
          /* Make sure to replace the `recommendations` object with the correct identification */
          ...trip.recommendation,
          habit_to_beat: true,
          best_match: false,
          better_match: false,
          max_savings: false
        }
      }
    })
  },

  setBestMatch: (trip) => {
    if (!trip) {
      set({ bestMatch: false })
      return
    }
    set({
      bestMatch: {
        ...trip,
        recommendation: {
          /* Make sure to replace the `recommendations` object with the correct identification */
          ...trip.recommendation,
          habit_to_beat: false,
          best_match: true,
          better_match: false,
          max_savings: false
        }
      }
    })
  },

  setBetterMatch: (trip) => {
    if (!trip) {
      set({ betterMatch: false })
      return
    }
    set({
      betterMatch: {
        ...trip,
        recommendation: {
          /* Make sure to replace the `recommendations` object with the correct identification */
          ...trip.recommendation,
          habit_to_beat: false,
          best_match: false,
          better_match: true,
          max_savings: false
        }
      }
    })
  },

  setMaxSavings: (trip) => {
    if (!trip) {
      set({ maxSavings: false })
      return
    }
    set({
      maxSavings: {
        ...trip,
        recommendation: {
          /* Make sure to replace the `recommendations` object with the correct identification */
          ...trip.recommendation,
          habit_to_beat: false,
          best_match: false,
          better_match: false,
          max_savings: true
        }
      }
    })
  }
}))

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('EmployeeStore', useEmployeeStore)
}

export default useEmployeeStore
