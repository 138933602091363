import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import CarRoutePanel from '../../components/common/CarSearch/CarRoutePanel'
import CarSearchPanel from '../../components/common/CarSearch/CarSearchPanel'
import Loading from '../../components/common/Misc/Loading'
import LogTripTabs from '../../components/common/Misc/LogTripTabs'
import Map from '../../components/common/Misc/Map'
import PageTitle from '../../components/common/Misc/PageTitle'
import useEmployeeStore from '../../employee_store'
import { Networking } from '../../helpers/Networking'
import { Tools } from '../../helpers/Tools'

export default function EmployeeModuleSearchCar() {
  // Local State
  const isLoading = false
  const [showResult, setShowResult] = useState(false)
  const [routeDetails, setRouteDetails] = useState(false)
  const [routingError, setRoutingError] = useState(false)
  const [googleReady, setGoogleReady] = useState(false)

  // Prepare some default dates
  const defaultDates = {
    initialDepartureDate: new Date(new Date().getTime() + 86400000),
    initialReturnDate: new Date(new Date().getTime() + 86400000 * 2)
  }

  // Default Car Search
  const [carSearchDetails, updateCarSearchDetails] = useState({
    route_direction: 'roundtrip',
    departure_date: Tools.getShortFormatDate(defaultDates.initialDepartureDate),
    return_date: Tools.getShortFormatDate(defaultDates.initialReturnDate),
    origin: null,
    destination: null,
    fuel_consumption: 5,
    fuel_type: 'diesel',
    is_driver: true,
    passengers: 1
  }) // TODO: Later, we'll have multiple stops, so part of this will go into an array

  // Global state
  const setCarJourney = useEmployeeStore((store) => store.setCarJourney)

  const navigate = useNavigate()

  const fetchRoute = () => {
    var request = {
      origin: { placeId: carSearchDetails.origin.value.place_id },
      destination: { placeId: carSearchDetails.destination.value.place_id },
      travelMode: 'DRIVING'
    }
    window.car_trip_directions_service.route(request, (response, status) => {
      if (status === 'OK') {
        if (response.routes.length > 0) {
          console.log('[Routing] SUCCESS')
          let route = response.routes[0]
          var distance = 0
          var duration = 0
          route.legs.forEach((leg) => {
            distance += leg.distance.value / 1000 // in meters, convert to km
            duration += leg.duration.value / 60 // in seconds, convert to minutes
          })
          let cost = distance * (carSearchDetails.fuel_consumption / 100) * 2 * 1.5
          let co2 = 2.68 * (carSearchDetails.fuel_consumption / 100.0) * distance
          // If this is a roundtrip, then we need to double the total distance
          let isReturn = carSearchDetails.route_direction === 'roundtrip'
          let totalDistance = isReturn ? distance * 2 : distance
          let totalDuration = isReturn ? duration * 2 : duration
          let totalCost = isReturn ? cost * 2 : cost
          let totalCo2 = isReturn ? co2 * 2 : co2
          setRouteDetails({
            distance: distance,
            total_distance: totalDistance,
            duration: duration,
            total_duration: totalDuration,
            cost: cost,
            total_cost: totalCost,
            co2: co2, // diesel (need to get other values)
            total_co2: totalCo2
          })

          window.car_trip_directions_renderer.setMap(window.car_trip_google_maps)
          window.car_trip_directions_renderer.setDirections(response)

          setShowResult(true)
          setRoutingError(false)
        } else {
          setRoutingError('There was an issue trying to find a route between these two locations.')
        }
      } else {
        setRoutingError(
          'There was an issue trying to query Google Maps for a route between these two locations.'
        )
      }
    })
  }

  useEffect(() => {
    const initDirectionsService = () => {
      return new Promise((resolve) => {
        window.directionServiceLoaded = () => {
          resolve()
          delete window.directionServiceLoaded
        }
        const script = document.createElement('script')
        script.src = `https://maps.googleapis.com/maps/api/js?key=${Networking.googleApiKey()}&callback=directionServiceLoaded&libraries=places,routes,maps`
        script.async = true
        document.body.appendChild(script)
      })
    }

    if (!googleReady) {
      console.log('[State] Google not ready... ', window.car_trip_google_maps)
      if (!window.car_trip_google_maps) {
        initDirectionsService().then(() => {
          if (!window.car_trip_directions_service) {
            window.car_trip_directions_service = new window.google.maps.DirectionsService()
            window.car_trip_directions_renderer = new window.google.maps.DirectionsRenderer()
            console.log('Direction Service and Renderer loaded.')
          }
          setGoogleReady(true)
        })
      } else {
        setGoogleReady(true)
      }
    }
  }, [setGoogleReady, googleReady])

  if (!googleReady) {
    return <Loading />
  }

  return (
    <div>
      <PageTitle title={'Car Trip'}></PageTitle>

      <LogTripTabs activeTabId={'car'} />

      <CarSearchPanel
        details={carSearchDetails}
        defaultReturnDate={Tools.getShortFormatDate(defaultDates.initialReturnDate)}
        onUpdateSearch={(newDetails) => {
          updateCarSearchDetails(newDetails)
        }}
        onSearchEdited={() => {
          //
        }}
        onSearch={() => {
          fetchRoute()
        }}
        isLoading={isLoading}
      />

      {routingError && (
        <div style={{ border: '1px solid #dd0000', padding: 10, borderRadius: 15 }}>
          {routingError}
        </div>
      )}

      {showResult && (
        <div style={{ marginTop: 60 }}>
          <h2>Your Route</h2>

          <CarRoutePanel
            route={routeDetails}
            onConfirm={() => {
              setCarJourney({
                searchQuery: carSearchDetails,
                route: routeDetails
              })
              navigate('/employee/car/options')
            }}
          />

          <Map
            key={'car-trip-map'}
            id="car-trip-map"
            options={{
              zoom: 8
            }}
          />
        </div>
      )}
    </div>
  )
}
