import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useStore from '../../store'
import useEmployeeStore from '../../employee_store'

import { Networking } from '../../helpers/Networking'
import { Flights } from '../../helpers/Flights'

import ErrorText from '../../components/common/Misc/ErrorText'
import Loading from '../../components/common/Misc/Loading'
import PageTitle from '../../components/common/Misc/PageTitle'
import OfferPanel from '../../components/employee/ClimateOptions/OfferPanel'
import OfferPanelGeneric from '../../components/employee/ClimateOptions/OfferPanelGeneric'
import Button from '../../components/common/Misc/Button'
import TripSummaryFlight from '../../components/common/Misc/TripSummaryFlight'

export default function EmployeeModuleClimateOptionsFlight(props) {
  // Global State
  const searchId = useEmployeeStore((store) => store.searchId)
  const habitToBeat = useEmployeeStore((store) => store.habitToBeat)
  const bestMatch = useEmployeeStore((store) => store.bestMatch)
  const betterMatch = useEmployeeStore((store) => store.betterMatch)
  const maxSavings = useEmployeeStore((store) => store.maxSavings)
  const pastFlight = useEmployeeStore((store) => store.pastFlight)
  const recommendations = useEmployeeStore((store) => store.recommendations)

  const setHabitToBeat = useEmployeeStore((store) => store.setHabitToBeat)
  const setBestMatch = useEmployeeStore((store) => store.setBestMatch)
  const setBetterMatch = useEmployeeStore((store) => store.setBetterMatch)
  const setMaxSavings = useEmployeeStore((store) => store.setMaxSavings)
  const setRecommendations = useEmployeeStore((store) => store.setRecommendations)
  const setClimateOptionSection = useEmployeeStore((store) => store.setClimateOptionSection)
  const setSelectedTrip = useEmployeeStore((store) => store.setSelectedTrip)

  const originalSearchQuery = useStore((store) => store.searchQuery)
  const companyDetails = useStore((store) => store.companyDetails)
  const currencyRateToday = useStore((store) => store.currencyRateToday)

  // Local State
  const [isLoading, setLoading] = useState(false)
  const [queryFailed, setQueryFailed] = useState(false)
  const [selectedOption, selectOption] = useState(habitToBeat)

  // Debugging/Fine-Tuning
  const [weightTiming, setWeightTiming] = useState(0.8)
  const [weightClimate, setWeightClimate] = useState(0.3)
  const [weightComfort, setWeightComfort] = useState(0.4)
  const [weightDuration, setWeightDuration] = useState(0.5)
  const [weightCost, setWeightCost] = useState(0.4)
  const [weightStopOver, setWeightStopOver] = useState(0.9)

  const [debugMode, setDebugMode] = useState(false)
  const [debugDetectorString, setDebugDetectorString] = useState('')

  const navigate = useNavigate()

  const getWeightPayload = () => {
    return {
      timing: parseFloat(weightTiming),
      climate: parseFloat(weightClimate),
      comfort: parseFloat(weightComfort),
      duration: parseFloat(weightDuration),
      cost: parseFloat(weightCost),
      extra_stops: parseFloat(weightStopOver)
    }
  }

  const getRecommendedFlights = useCallback(async (searchId, habitToBeat, weights) => {
    console.log('STARTING [recommendFlights]', habitToBeat)
    return await Flights.recommendFlights(searchId, habitToBeat, weights)
  }, [])

  const getAdditionalFlights = (habitToBeat, allFlights) => {
    return (allFlights || []).filter((flight) => {
      // Filter out any flights that have a HIGHER climpact impact than the
      // flight the person picked. Those wouldn't make sense.
      if (flight.climate_impact > habitToBeat.climate_impact) return false

      // Filter out flights that are shown on top already
      if (flight.recommendation.habit_to_beat) return false
      if (flight.recommendation.max_savings) return false
      if (flight.recommendation.better_match) return false
      if (flight.recommendation.best_match) return false
      return true
    })
  }

  const confirmOffer = async () => {
    setLoading(true)
    var tripType = 'flight' // Default
    if (selectedOption === 'online') tripType = 'online'
    if (selectedOption === 'train') tripType = 'train'
    let payload = {
      search_id: searchId,
      flight: tripType === 'flight' ? selectedOption : null,
      trip_type: tripType,
      route_direction: originalSearchQuery.route_direction,
      already_booked: pastFlight,
      departure_date: originalSearchQuery.departure_date,
      return_date: originalSearchQuery.return_date
    }
    const result = await Networking.saveTrip(payload)
    setLoading(false)
    if (result) {
      // If this is for a future flight, and the company
      // has cost centers enabled, we need to add a cost center step here
      if (!pastFlight) {
        if (companyDetails.feature_cost_center) {
          // But only if it's a flight, for train/online we skip further ahead for now
          if (tripType === 'flight') {
            navigate('/employee/flights/costcenter')
          } else {
            navigate('/employee/flights/done')
          }
        } else {
          navigate('/employee/flights/done')
        }
      } else {
        navigate('/employee/flights/done')
      }
    } else {
      alert('There was an error trying to save this trip. Please try again or reach out to us.')
    }
  }

  const getTrainAndOnlineOptionToCompareTo = (trips) => {
    // Ideally, we'd have a BEST MATCH. If that's not available, let's try the others...
    if (Flights.getSectionFromRecommendations(trips, 'best_match'))
      return Flights.getSectionFromRecommendations(trips, 'best_match')
    if (Flights.getSectionFromRecommendations(trips, 'better_match'))
      return Flights.getSectionFromRecommendations(trips, 'better_match')
    if (Flights.getSectionFromRecommendations(trips, 'max_savings'))
      return Flights.getSectionFromRecommendations(trips, 'max_savings')
    if (Flights.getSectionFromRecommendations(trips, 'habit_to_beat')) return habitToBeat
    return false
  }

  useEffect(() => {
    const launchRecommendationEngine = async (searchId, habitToBeat) => {
      let result = await getRecommendedFlights(searchId, habitToBeat)
      if (result) {
        setHabitToBeat(habitToBeat)
        //if (result.habit_to_beat) setHabitToBeat(result.habit_to_beat)
        if (result.best_match) setBestMatch(result.best_match)
        if (result.better_match) setBetterMatch(result.better_match)
        if (result.max_savings) setMaxSavings(result.max_savings)
        if (result.recommended_flights) setRecommendations(result.recommended_flights)
        setLoading(false)
      } else {
        setQueryFailed(true)
        setLoading(false)
      }
    }
    if (recommendations === null && isLoading === false) {
      setLoading(true)
      launchRecommendationEngine(searchId, habitToBeat)
    }
  }, [
    getRecommendedFlights,
    habitToBeat,
    isLoading,
    recommendations,
    searchId,
    setBestMatch,
    setBetterMatch,
    setHabitToBeat,
    setMaxSavings,
    setRecommendations
  ])

  const keyActivator = useCallback(
    (event) => {
      setDebugDetectorString(debugDetectorString + event.key)
      if ((debugDetectorString + event.key).indexOf('debug') >= 0) {
        setDebugMode(true)
        setDebugDetectorString('')
        document.querySelectorAll('.debug-mode').forEach((e) => {
          e.style.display = 'flex'
        })
      }
    },
    [debugDetectorString]
  )

  useEffect(() => {
    document.addEventListener('keydown', keyActivator, false)
    return () => {
      document.removeEventListener('keydown', keyActivator, false)
    }
  }, [keyActivator])

  const sectionAvailable = (section, trips) => {
    if (section === 'train') {
      return habitToBeat.outward_itinerary.duration <= 120
    }
    console.log(
      `Section Available? [${section}]: `,
      Flights.getSectionFromRecommendations(trips, section)
    )
    return Flights.getSectionFromRecommendations(trips, section) ? true : false
  }

  var panelOrder = pastFlight
    ? ['habit_to_beat', 'best_match', 'better_match', 'max_savings', 'train', 'online']
    : ['best_match', 'habit_to_beat', 'better_match', 'max_savings', 'train', 'online']

  if (recommendations && recommendations.length > 0 && !habitToBeat) {
    return (
      <div
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          padding: 20,
          textAlign: 'center',
          maxWidth: 400,
          width: '100%',
          marginTop: 200
        }}>
        We're terribly sorry, but something has broken.
        <br />
        <br />
        The system was unable to calculate recommendations because of a missing flight (HTB).
        <br />
        <br />
        <Button
          title={'Retry'}
          onClick={async () => {
            let result = await getRecommendedFlights(searchId, habitToBeat, getWeightPayload())
            if (result) {
              setHabitToBeat(habitToBeat)
              setBestMatch(result.best_match)
              setBetterMatch(result.better_match)
              setMaxSavings(result.max_savings)
              setRecommendations(result.recommended_flights)
              setLoading(false)
            } else {
              setQueryFailed(true)
              setLoading(false)
            }
          }}
        />
      </div>
    )
  }

  const additionalFlights = getAdditionalFlights(habitToBeat, recommendations).slice(0, 20)

  return (
    <div>
      <div style={{ maxWidth: 950, width: '100%' }}>
        <PageTitle
          noBottomMargin={true}
          title={pastFlight ? 'Next time: optimize your booking decision' : 'Your Climate Options'}>
          {!pastFlight && (
            <>
              Pick your preferred flight below, we'll keep it in your trip journal for later. Once
              you have booked the flight, you simply confirm it to complete the record and
              accumulate climate points.
            </>
          )}
          {pastFlight && (
            <>
              Use ClimateSmart.Travel during your booking process and select a suggested Climate
              Option to save on carbon and cost. You will be able to submit your cost center during
              this process.
              <br />
              <br />
            </>
          )}
        </PageTitle>
      </div>

      {isLoading && (
        <div style={{ marginTop: 100 }}>
          <Loading />
        </div>
      )}

      <ErrorText show={queryFailed}>
        There was an issue trying to query the flight engine. Please try again.
      </ErrorText>

      {debugMode && (
        <div
          style={{
            marginBottom: 50,
            border: '1px solid #ddd',
            padding: 20,
            backgroundColor: '#DEEFF5',
            borderRadius: 20
          }}>
          <h3 style={{ margin: 0 }}>Best Match Calibration</h3>
          <div style={{ fontSize: 12, marginBottom: 20 }}>
            <br />A value of 0 means this indicator is ignored in ranking. A value of 1 means that
            the indiciator is an important contributor to the ranking of each flight.
            <br />
            <br />
            <strong>
              For the BEST MATCH (only), how important are the following indicators?
              <br />
            </strong>
            (red values can only weigh a flight down, green values can only improve a flight's
            ranking, anything else can do either)
          </div>

          <div style={{ display: 'flex', marginBottom: 20, gap: 10, fontSize: 12 }}>
            <div>
              <label>
                <span style={{ color: '#aa0000' }}>Timing compared to HTB</span>
                <select
                  style={{ marginTop: 5 }}
                  value={weightTiming}
                  onChange={(e) => setWeightTiming(e.target.value)}>
                  <option value={0.0}>0 (Ignore)</option>
                  <option value={0.1}>0.1</option>
                  <option value={0.2}>0.2</option>
                  <option value={0.3}>0.3</option>
                  <option value={0.4}>0.4</option>
                  <option value={0.5}>0.5</option>
                  <option value={0.6}>0.6</option>
                  <option value={0.7}>0.7</option>
                  <option value={0.8}>0.8</option>
                  <option value={0.9}>0.9</option>
                  <option value={1}>1.0 (Important)</option>
                </select>
              </label>
            </div>
            <div>
              <label>
                <span style={{ color: '#00aa00' }}>CO2 Improvement</span>
                <select
                  style={{ marginTop: 5 }}
                  value={weightClimate}
                  onChange={(e) => setWeightClimate(e.target.value)}>
                  <option value={0.0}>0 (Ignore)</option>
                  <option value={0.1}>0.1</option>
                  <option value={0.2}>0.2</option>
                  <option value={0.3}>0.3</option>
                  <option value={0.4}>0.4</option>
                  <option value={0.5}>0.5</option>
                  <option value={0.6}>0.6</option>
                  <option value={0.7}>0.7</option>
                  <option value={0.8}>0.8</option>
                  <option value={0.9}>0.9</option>
                  <option value={1}>1.0 (Important)</option>
                </select>
              </label>
            </div>
            <div>
              <label>
                Cost
                <select
                  style={{ marginTop: 5 }}
                  value={weightCost}
                  onChange={(e) => setWeightCost(e.target.value)}>
                  <option value={0.0}>0 (Ignore)</option>
                  <option value={0.1}>0.1</option>
                  <option value={0.2}>0.2</option>
                  <option value={0.3}>0.3</option>
                  <option value={0.4}>0.4</option>
                  <option value={0.5}>0.5</option>
                  <option value={0.6}>0.6</option>
                  <option value={0.7}>0.7</option>
                  <option value={0.8}>0.8</option>
                  <option value={0.9}>0.9</option>
                  <option value={1}>1.0 (Important)</option>
                </select>
              </label>
            </div>
            <div>
              <label>
                <span style={{ color: '#aa0000' }}>Worse Cabin Class than HTB</span>
                <select
                  style={{ marginTop: 5 }}
                  value={weightComfort}
                  onChange={(e) => setWeightComfort(e.target.value)}>
                  <option value={0.0}>0 (Accept class downgrades)</option>
                  <option value={0.1}>0.1</option>
                  <option value={0.2}>0.2</option>
                  <option value={0.3}>0.3</option>
                  <option value={0.4}>0.4</option>
                  <option value={0.5}>0.5</option>
                  <option value={0.6}>0.6</option>
                  <option value={0.7}>0.7</option>
                  <option value={0.8}>0.8</option>
                  <option value={0.9}>0.9</option>
                  <option value={1}>1.0 (Discourage a class downgrade)</option>
                </select>
              </label>
            </div>
            <div>
              <label>
                Total Duration (+Layovers)
                <select
                  style={{ marginTop: 5 }}
                  value={weightDuration}
                  onChange={(e) => setWeightDuration(e.target.value)}>
                  <option value={0.0}>0 (Longer flights and layovers are okay)</option>
                  <option value={0.1}>0.1</option>
                  <option value={0.2}>0.2</option>
                  <option value={0.3}>0.3</option>
                  <option value={0.4}>0.4</option>
                  <option value={0.5}>0.5</option>
                  <option value={0.6}>0.6</option>
                  <option value={0.7}>0.7</option>
                  <option value={0.8}>0.8</option>
                  <option value={0.9}>0.9</option>
                  <option value={1}>1.0 (Try to be time-optimized)</option>
                </select>
              </label>
            </div>
            <div>
              <label>
                Extra (or fewer) Stop-Overs
                <select
                  style={{ marginTop: 5 }}
                  value={weightStopOver}
                  onChange={(e) => setWeightStopOver(e.target.value)}>
                  <option value={0.0}>0 (Accept any extra stop-overs)</option>
                  <option value={0.1}>0.1</option>
                  <option value={0.2}>0.2</option>
                  <option value={0.3}>0.3</option>
                  <option value={0.4}>0.4</option>
                  <option value={0.5}>0.5</option>
                  <option value={0.6}>0.6</option>
                  <option value={0.7}>0.7</option>
                  <option value={0.8}>0.8</option>
                  <option value={0.9}>0.9</option>
                  <option value={1}>1.0 (Try to avoid stop-overs)</option>
                </select>
              </label>
            </div>
          </div>

          <Button
            title={'Retry Recommendations'}
            onClick={async () => {
              console.log('LAUNCH ENGINE [MANUAL]')
              let result = await getRecommendedFlights(searchId, habitToBeat, getWeightPayload())
              if (result) {
                setHabitToBeat(habitToBeat)
                setBestMatch(result.best_match)
                setBetterMatch(result.better_match)
                setMaxSavings(result.max_savings)
                setRecommendations(result.recommended_flights)
                setLoading(false)
              } else {
                setQueryFailed(true)
                setLoading(false)
              }
            }}
          />
        </div>
      )}

      {!isLoading && recommendations && (
        <div>
          <div style={{ marginTop: 30, marginBottom: 40 }}>
            <TripSummaryFlight
              trip={habitToBeat}
              departure_date={habitToBeat.departure_date || null}
              return_date={habitToBeat.return_date || null}
              noBackground={true}
            />
          </div>

          <div
            style={{
              width: '100%',
              maxWidth: 950,
              paddingLeft: 0 /* Was 14, not sure why? */,
              marginBottom: 20,
              fontSize: 13,
              fontWeight: 'bold',
              letterSpacing: '0.5px',
              justifyContent: 'left'
            }}>
            {panelOrder.map((panelName, i) => {
              if (panelName === 'best_match') {
                if (sectionAvailable('best_match', recommendations)) {
                  return (
                    <OfferPanel
                      showPolicyViolations={companyDetails.feature_policies || false}
                      key={`offer_panel_${i}`}
                      section="best_match"
                      disabled={pastFlight}
                      highlight={true}
                      habitToBeat={habitToBeat}
                      carbonPricing={companyDetails.feature_carbon_pricing}
                      offer={Flights.getSectionFromRecommendations(recommendations, 'best_match')}
                      selected={selectedOption && selectedOption === bestMatch}
                      onSelect={() => {
                        selectOption(bestMatch)
                        setClimateOptionSection('best_match')
                        setSelectedTrip(bestMatch)
                      }}
                      hidePrice={pastFlight}
                      onConfirm={confirmOffer}
                      confirmLabel={'Log Option'}
                      displayCurrency={companyDetails.currency || 'CHF'}
                      currencyConversionRate={currencyRateToday || 1.0}
                    />
                  )
                } else {
                  return (
                    <div
                      key={`no-best-match`}
                      style={{ padding: 20, marginBottom: 20, fontSize: 16 }}>
                      Good choice. There are no flight options that leave a smaller carbon
                      footprint. Try to meet online if possible.
                    </div>
                  )
                }
              }
              if (panelName === 'habit_to_beat') {
                if (habitToBeat) {
                  return (
                    <div key={`offer_panel_container_${i}`}>
                      <OfferPanel
                        showPolicyViolations={companyDetails.feature_policies || false}
                        key={`offer_panel_${i}`}
                        section="habit_to_beat"
                        disabled={pastFlight}
                        habitToBeat={habitToBeat}
                        carbonPricing={companyDetails.feature_carbon_pricing}
                        offer={habitToBeat}
                        selected={selectedOption && selectedOption === habitToBeat}
                        onSelect={() => {
                          selectOption(habitToBeat)
                          setClimateOptionSection('habit_to_beat')
                          setSelectedTrip(habitToBeat)
                        }}
                        hidePrice={pastFlight}
                        confirmLabel={'Log Option'}
                        onConfirm={confirmOffer}
                        displayCurrency={companyDetails.currency || 'CHF'}
                        currencyConversionRate={currencyRateToday || 1.0}
                      />
                      {pastFlight && (
                        <div
                          style={{
                            marginTop: 20,
                            marginBottom: 20,
                            fontWeight: 'bold',
                            fontSize: 18
                          }}>
                          As an example, this is how much you could have saved:
                        </div>
                      )}
                    </div>
                  )
                }
              }
              if (panelName === 'better_match') {
                if (sectionAvailable('better_match', recommendations)) {
                  return (
                    <OfferPanel
                      showPolicyViolations={companyDetails.feature_policies || false}
                      key={`offer_panel_${i}`}
                      section="better_match"
                      disabled={pastFlight}
                      habitToBeat={habitToBeat}
                      carbonPricing={companyDetails.feature_carbon_pricing}
                      offer={Flights.getSectionFromRecommendations(recommendations, 'better_match')}
                      selected={selectedOption && selectedOption === betterMatch}
                      onSelect={() => {
                        selectOption(betterMatch)
                        setClimateOptionSection('better_match')
                        setSelectedTrip(betterMatch)
                      }}
                      hidePrice={pastFlight}
                      confirmLabel={'Log Option'}
                      onConfirm={confirmOffer}
                      displayCurrency={companyDetails.currency || 'CHF'}
                      currencyConversionRate={currencyRateToday || 1.0}
                    />
                  )
                }
              }
              if (panelName === 'max_savings') {
                if (sectionAvailable('max_savings', recommendations)) {
                  return (
                    <OfferPanel
                      showPolicyViolations={companyDetails.feature_policies || false}
                      key={`offer_panel_${i}`}
                      section="max_savings"
                      disabled={pastFlight}
                      habitToBeat={habitToBeat}
                      carbonPricing={companyDetails.feature_carbon_pricing}
                      offer={Flights.getSectionFromRecommendations(recommendations, 'max_savings')}
                      selected={selectedOption && selectedOption === maxSavings}
                      onSelect={() => {
                        selectOption(maxSavings)
                        setClimateOptionSection('max_savings')
                        setSelectedTrip(maxSavings)
                      }}
                      hidePrice={pastFlight}
                      confirmLabel={'Log Option'}
                      onConfirm={confirmOffer}
                      displayCurrency={companyDetails.currency || 'CHF'}
                      currencyConversionRate={currencyRateToday || 1.0}
                    />
                  )
                }
              }

              if (panelName === 'online') {
                return (
                  <OfferPanelGeneric
                    key="option_online"
                    section="option_online"
                    compareTo={getTrainAndOnlineOptionToCompareTo(recommendations)}
                    disabled={pastFlight}
                    selected={selectedOption && selectedOption === 'online'}
                    onSelect={() => {
                      selectOption('online')
                      setClimateOptionSection('online')
                      setSelectedTrip(habitToBeat)
                    }}
                    confirmLabel={'Log Option'}
                    onConfirm={confirmOffer}
                  />
                )
              }

              if (panelName === 'train') {
                if (sectionAvailable('train', recommendations)) {
                  return (
                    <OfferPanelGeneric
                      key="option_train"
                      section="option_train"
                      compareTo={getTrainAndOnlineOptionToCompareTo(recommendations)}
                      disabled={pastFlight}
                      selected={selectedOption && selectedOption === 'train'}
                      onSelect={() => {
                        selectOption('train')
                        setClimateOptionSection('train')
                        setSelectedTrip(habitToBeat)
                      }}
                      confirmLabel={'Log Option'}
                      onConfirm={confirmOffer}
                    />
                  )
                }
              }

              return null
            })}

            {!pastFlight && additionalFlights && additionalFlights.length > 0 && (
              <div style={{ marginTop: 100 }}>
                <h2>Alternative Flight Options</h2>
                {additionalFlights.map((flight, i) => {
                  return (
                    <OfferPanel
                      showPolicyViolations={companyDetails.feature_policies || false}
                      key={`offer_panel_additional_${i}`}
                      section="custom"
                      disabled={pastFlight}
                      habitToBeat={habitToBeat}
                      carbonPricing={companyDetails.feature_carbon_pricing}
                      offer={flight}
                      selected={selectedOption && selectedOption === flight}
                      onSelect={() => {
                        selectOption(flight)
                        setClimateOptionSection('custom')
                        setSelectedTrip(flight)
                      }}
                      hidePrice={pastFlight}
                      confirmLabel={'Log Option'}
                      onConfirm={confirmOffer}
                      displayCurrency={companyDetails.currency || 'CHF'}
                      currencyConversionRate={currencyRateToday || 1.0}
                    />
                  )
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
