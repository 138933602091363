import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'
import { Tools } from '../../../helpers/Tools'
import Button from '../../common/Misc/Button'
import CarbonPricingLabel from '../../common/Misc/CarbonPricingLabel'
import RadioButton from '../../common/Misc/RadioButton'
import Tooltip from '../../common/Misc/Tooltip'
import OutOfPolicyLabel from '../Misc/OutOfPolicyLabel'
import FlightRowDesktop from './FlightRowDesktop'
import { getPolicyViolations } from '../Misc/OutOfPolicyLabel'

export default function OfferPanelDesktop(props) {
  var debugMode = true
  if (!props.offer.recommendation || !props.offer.recommendation.indicators) {
    debugMode = false
  }

  var tooltipLabel = ''
  if (props.section === 'habit_to_beat') tooltipLabel = 'Your selected flight you normally book.'
  if (props.section === 'best_match')
    tooltipLabel = 'Recommended best option based on CO2 savings, price and convenience criteria.'
  if (props.section === 'better_match')
    tooltipLabel =
      'The Comfort Match has reasonable CO2 savings<br/>with minimal compromise in comfort'
  if (props.section === 'max_savings')
    tooltipLabel =
      'Flight option with maximized CO2 savings combined with reasonable duration, timing but lower cabin class or stop over.'
  if (props.section === 'other' || props.section === 'custom')
    tooltipLabel =
      'This is just an alternative option, in case our recommendations<br/>are not showing the flight you were looking for.'

  return (
    <div
      style={{
        marginTop: 10,
        marginBottom: 10,
        cursor: 'pointer',
        maxWidth: 1200
      }}
      onClick={props.onSelect}>
      <Tooltip />
      <div
        className="offer-panel-top-label"
        style={{
          position: 'relative',
          left: 40,
          top: 10,
          padding: '5px 15px',
          color: '#fff',
          fontSize: 12,
          fontWeight: 600,
          display: 'inline-block',
          backgroundColor: props.section === 'habit_to_beat' ? '#333' : '#0A1EAA',
          borderRadius: 4,
          borderTopLeftRadius: 16,
          borderBottomRightRadius: 16
        }}>
        {props.topLabel}
        <span
          style={{ position: 'absolute', right: -20, top: -2, fontSize: 20 }}
          data-tip={tooltipLabel}
          data-for={`offer-panel-top-label-${props.offer.id}`}>
          <FontAwesomeIcon icon="info-circle" className="info-circle" size="xs" />
          <ReactTooltip
            id={`offer-panel-top-label-${props.offer.id}`}
            className={'tooltip-container'}
            type={'dark'}
            delayShow={200}
            place={'bottom'}
            multiline={true}
            backgroundColor={'#ffffff'}
            textColor={'#333333'}
            effect={'solid'}
          />
        </span>
      </div>

      {props.showPolicyViolations && getPolicyViolations({ trip: props.offer }).length > 0 && (
        <div
          style={{
            position: 'relative',
            display: 'inline-block',
            left: 90,
            top: 11,
            width: 140,
            textAlign: 'center',
            backgroundColor: 'white',
            border: '1px solid #eee',
            borderRadius: 15
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center'
            }}>
            <OutOfPolicyLabel trip={props.offer} />
          </div>
        </div>
      )}

      <div
        style={{
          backgroundColor: 'white',
          borderRadius: 10,
          boxShadow: props.selected
            ? '0 2px 4px 0 rgba(10,30,170,0.24)'
            : '0 2px 4px 0 rgba(10,30,170,0.24)',
          padding: 20
        }}>
        <div
          style={{
            backgroundColor:
              props.selected || props.highlight
                ? props.section === 'habit_to_beat'
                  ? 'rgba(10,30,170,0.04)'
                  : '#E0F88B'
                : 'rgba(10,30,170,0.04)',
            borderRadius: 32,
            padding: 12,
            display: 'flex',
            alignItems: 'center'
          }}>
          {!props.disabled && (
            <div style={{ paddingLeft: 15, paddingRight: 15 }}>
              <RadioButton selected={props.selected} onClick={props.onSelect} />
            </div>
          )}
          {props.disabled && <div style={{ width: 10 }}></div>}
          <div style={{ fontSize: 20 }}>
            {props.section === 'habit_to_beat' && <div>Your selected flight</div>}
            {props.section !== 'habit_to_beat' && (
              <div style={{ letterSpacing: 0.83 }}>
                Save{' '}
                <strong>
                  {props.co2Savings.roundtrip_co2_percentage}% CO
                  <sub style={{ fontSize: 12 }}>2e</sub>
                </strong>{' '}
                {props.offer.fnz_points > 0 && (
                  <span>
                    and earn{' '}
                    <strong>
                      {Tools.formatNumber(props.offer.fnz_points, props.displayCurrency || 'CHF')}{' '}
                      Climate Points
                    </strong>
                    <span
                      data-for={`offer-panel-cps-${props.offer.id}`}
                      data-tip={
                        'Climate Points (CPs) measure your saving efforts<br/>and build the basis for incentives.'
                      }>
                      <FontAwesomeIcon icon="info-circle" className="info-circle" size="xs" />
                      <ReactTooltip
                        id={`offer-panel-cps-${props.offer.id}`}
                        className={'tooltip-container'}
                        type={'dark'}
                        delayShow={200}
                        place={'bottom'}
                        multiline={true}
                        backgroundColor={'#ffffff'}
                        textColor={'#333333'}
                        effect={'solid'}
                      />
                    </span>
                  </span>
                )}
              </div>
            )}
          </div>
          <div style={{ flexGrow: 1 }}></div>
          <div style={{ fontSize: 14, minWidth: 100, marginRight: 10 }}>
            {!props.hidePrice &&
              (props.offer.return_itinerary !== null ? 'Round trip cost:' : 'Trip cost:')}
          </div>
          <div style={{ textAlign: 'right', minWidth: 100, marginRight: 20 }}>
            {!props.hidePrice && (
              <div
                style={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <div style={{ fontWeight: 'bold' }}>
                  {props.costSavings.roundtrip_cost_percentage > 0 ? '+' : ''}
                  {props.costSavings.roundtrip_cost_percentage}% &nbsp;
                </div>
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 'normal',
                    minWidth: 91,
                    textAlign: 'right'
                  }}>
                  {Tools.formatNumber(
                    parseInt(props.costSavings.roundtrip_cost * props.currencyConversionRate),
                    props.displayCurrency || 'CHF'
                  )}{' '}
                  {props.displayCurrency || 'CHF'}
                </div>
              </div>
            )}
          </div>
        </div>

        <FlightRowDesktop
          direction="outward_itinerary"
          section={props.section}
          offer={props.offer}
          habitToBeat={props.habitToBeat}
        />

        {props.offer.return_itinerary !== null && (
          <>
            <div
              style={{
                borderBottom: '1px solid #ddd',
                marginLeft: 40,
                marginRight: 20
              }}></div>
            <FlightRowDesktop
              direction="return_itinerary"
              section={props.section}
              offer={props.offer}
              habitToBeat={props.habitToBeat}
            />
          </>
        )}

        {props.selected && !props.disabled && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: 60,
              marginTop: 20
            }}>
            <div style={{ fontSize: 12, marginLeft: 40 }}>{props.children}</div>
            <div style={{ flexGrow: 1 }}></div>
            <div>
              <Button text={props.confirmLabel || `Select`} onClick={props.onConfirm} />
            </div>
          </div>
        )}

        {debugMode && (
          <div
            className={`debug-mode`}
            style={{
              display: 'none',
              marginLeft: 40,
              color: '#aa0000',
              justifyContent: 'space-evenly',
              borderTop: '1px dotted #aa0000',
              paddingTop: 10,
              fontSize: 11
            }}>
            <div>ID: {props.offer.id}</div>
            <div>Timing off by: {props.offer.recommendation.indicators.timing_delta || 0} min</div>
            <div>
              CO2 Improvement: {-1 * props.offer.recommendation.indicators.climate_delta || 0}
            </div>
            <div>Cost: {props.offer.recommendation.indicators.cost_delta || 0}</div>
            <div>
              Cabin Class Discomfort: {props.offer.recommendation.indicators.comfort_delta || 0}
            </div>
            <div>
              Extra Duration: {props.offer.recommendation.indicators.duration_delta || 0} min
            </div>
            <div>
              StopOver Weight: {props.offer.recommendation.indicators.extra_stops_delta || 0}
            </div>
          </div>
        )}
      </div>
      {props.carbonPricing && (
        <div style={{ textAlign: 'right' }}>
          <CarbonPricingLabel
            trip={props.offer}
            hidePrice={props.hidePrice || false}
            currencyConversionRate={props.currencyConversionRate || 1.0}
            displayCurrency={props.displayCurrency || 'CHF'}
          />
        </div>
      )}
    </div>
  )
}
