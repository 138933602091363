import { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import useStore from '../../store'
import Button from '../../components/common/Misc/Button'

const getOfferPageUrl = () => {
  if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    return `https://dev.offer.climatesmart.travel/#`
  }
  if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
    return `https://staging.offer.climatesmart.travel/#`
  }
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    return `https://offer.climatesmart.travel/#`
  }
}

export default function WizardShare(props) {
  // Global State
  const searchId = useStore((state) => state.searchId)
  const offerId = useStore((state) => state.agencyOfferId)

  // Local State
  const [isCopied, wasCopied] = useState(false)
  const [shareUrl] = useState(`${getOfferPageUrl()}${searchId}`)

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 30, width: 950 }}>
        <h1>All done!</h1>
        <div style={{ flexGrow: 1 }}></div>
        <div>
          <Button
            text="Start Over"
            large={true}
            onClick={() => {
              props.onCompleted({})
            }}
          />
        </div>
      </div>

      <div style={{ width: 950 }}>
        <div
          style={{
            borderBottom: '1px solid #ddd',
            marginBottom: 50,
            display: 'flex',
            alignItems: 'center'
          }}>
          <h2>The offer is ready to be shared</h2>
          <div style={{ flexGrow: 1 }}></div>
          <h1>{offerId}</h1>
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 70, border: '1px solid #ddd', borderRadius: 20, padding: 20 }}>
            <div style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>1</div>
          </div>
          <div
            style={{
              padding: 20,
              backgroundColor: '#EFF0F5',
              borderRadius: 20,
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              height: 65,
              marginLeft: 10
            }}>
            Before sending it off, you can double-check your offer, or start-over.
            <div style={{ flexGrow: 1 }}></div>
            <Button
              text="Verify Offer"
              onClick={() => {
                window.open(shareUrl, '_blank')
              }}
            />
          </div>
        </div>
        <div style={{ display: 'flex', marginTop: 50, alignItems: 'center' }}>
          <div style={{ width: 70, border: '1px solid #ddd', borderRadius: 20, padding: 20 }}>
            <div style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>2</div>
          </div>
          <div
            style={{
              padding: 20,
              backgroundColor: '#EFF0F5',
              borderRadius: 20,
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              height: 100,
              marginLeft: 10
            }}>
            <div style={{ marginLeft: 0 }}>
              <div style={{ marginBottom: 10, fontWeight: 'bold' }}>
                Use this URL to share the offer with your client:
              </div>
              <input
                readOnly={true}
                type="text"
                style={{
                  border: 'none',
                  fontSize: 15,
                  width: '100%',
                  minWidth: 540,
                  padding: 8,
                  borderRadius: 10,
                  textAlign: 'left'
                }}
                value={shareUrl}
              />
            </div>
            <div style={{ flexGrow: 1 }}></div>
            <div style={{ marginLeft: 10 }}>
              <CopyToClipboard
                text={shareUrl}
                onCopy={() => {
                  wasCopied(true)
                  setTimeout(() => {
                    wasCopied(false)
                  }, 5000)
                }}>
                <Button text={isCopied ? 'Copied' : 'Copy to Clipboard'} />
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
