import { Airlines } from '../../../helpers/Airlines'
import useStore from '../../../store'
import FilterPopover from './Components/FilterPopover'

export default function FilterAirlines(props) {
  const globalDictionary = useStore((state) => state.dictionary)
  var airlineDictionary = {}

  // This is a bit hacky
  ;['ECONOMY', 'PREMIUM_ECONOMY', 'BUSINESS', 'FIRST'].forEach((cabin_class) => {
    if (globalDictionary[cabin_class]) {
      airlineDictionary = Object.assign(
        {},
        airlineDictionary,
        globalDictionary[cabin_class].carriers
      )
    }
  })

  // Since Amadeus Airlines aren't formatted very nicely, we can override this with our own list
  // if we have a better (manually created) airline name available
  Object.keys(airlineDictionary).forEach((airline) => {
    Airlines.alliances.forEach((alliance) => {
      alliance.members.forEach((member_airline) => {
        if (member_airline.iata === airline) {
          airlineDictionary[airline] = member_airline.airline
        }
      })
    })
  })

  const isAllianceChecked = (list, availableAirlines, alliance_id) => {
    // This is a bit tricky, because we cannot assume the full list of airlines
    // is available in the search results. So we remove any airline from the alliances
    // that is not available in the search results.
    // So:
    // "availableAirlines" is what we have in the search results
    // "list" is the currently selected airline list

    let alliance = Airlines.alliances.find((alliance) => alliance.id === alliance_id)
    if (alliance) {
      // Remove any airline from this alliance that is not in the search results
      var availableAirlinesForThisAlliance = []
      alliance.members.forEach((alliance_member) => {
        if (availableAirlines.indexOf(alliance_member.iata) >= 0) {
          availableAirlinesForThisAlliance.push(alliance_member.iata)
        }
      })
      // Now, if the list is emtpy (this alliance is not in the results at all)
      // then assume it is not checked (or checkable)
      if (availableAirlinesForThisAlliance.length === 0) return false

      // Check if this alliance is fully checked, based on the airlines in the results
      return availableAirlinesForThisAlliance.every((iata) => list.indexOf(iata) >= 0)
    }
    return false
  }

  const addAirline = (list, airline) => {
    return list.indexOf(airline) < 0 ? [...list, airline] : list
  }

  const removeAirline = (list, airline) => {
    return list.filter((a) => a !== airline)
  }

  const setAlliance = (list, availableAirlines, alliance_id) => {
    // First, find the correct alliance
    Airlines.alliances.forEach((alliance) => {
      if (alliance.id === alliance_id) {
        // Found it, so now go through all members and check anyone
        // we have in the search results
        alliance.members.forEach((alliance_member) => {
          if (availableAirlines.indexOf(alliance_member.iata) >= 0) {
            list = addAirline(list, alliance_member.iata) // Will do nothing if already on there
          }
        })
      }
    })
    return list
  }

  const removeAlliance = (list, availableAirlines, alliance_id) => {
    // First, find the correct alliance
    Airlines.alliances.forEach((alliance) => {
      if (alliance.id === alliance_id) {
        // Found it, so now go through all members and check anyone
        // we have in the search results
        alliance.members.forEach((alliance_member) => {
          if (availableAirlines.indexOf(alliance_member.iata) >= 0) {
            list = removeAirline(list, alliance_member.iata) // Will do nothing if already on there
          }
        })
      }
    })
    return list
  }

  const sortedEnrichedAirlines = (airlines) => {
    var list = []
    airlines.forEach((airline) => {
      list.push({
        iata: airline,
        full_name: (airlineDictionary[airline] || "").trim() || airline
      })
    })
    return [...list].sort((a, b) =>
      a.full_name.localeCompare(b.full_name, 'en', { sensitivity: 'base' })
    )
  }

  return (
    <FilterPopover
      title={'Airlines'}
      selectedTitle={props.value.length === 0 ? 'All Airlines' : 'Selected Airlines'}
      active={
        props.value.sort().join(',') !== props.availableAirlines.sort().join(',') &&
        props.value.length > 0
      }
      onCancel={() => {
        if (props.onCancel) {
          props.onCancel()
        }
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          fontSize: 14,
          userSelect: 'none'
        }}>
        <div style={{ fontWeight: 'bold' }}>Alliances</div>
        {Airlines.alliances.map((alliance) => {
          return (
            <label key={`alliances_${alliance.id}`}>
              <input
                type="checkbox"
                name={`alliance_${alliance.id}`}
                checked={isAllianceChecked(
                  props.value,
                  props.availableAirlines,
                  parseInt(alliance.id, 10)
                )}
                onChange={(e) => {
                  if (
                    isAllianceChecked(
                      props.value,
                      props.availableAirlines,
                      parseInt(alliance.id, 10)
                    )
                  ) {
                    props.onChange(
                      removeAlliance(
                        props.value,
                        props.availableAirlines,
                        parseInt(alliance.id, 10)
                      )
                    )
                  } else {
                    props.onChange(
                      setAlliance(props.value, props.availableAirlines, parseInt(alliance.id, 10))
                    )
                  }
                }}
              />{' '}
              {alliance.name}
            </label>
          )
        })}

        <div style={{ fontWeight: 'bold', marginTop: 30 }}>Airlines</div>

        {sortedEnrichedAirlines(props.availableAirlines).map((airline, i) => {
          return (
            <label key={`airline_${i}`}>
              <input
                className="filterAirlineCheckbox"
                type="checkbox"
                checked={props.value.indexOf(airline.iata) >= 0}
                onChange={(e) => {
                  if (props.value.indexOf(airline.iata) >= 0) {
                    props.onChange(removeAirline(props.value, airline.iata))
                  } else {
                    props.onChange(addAirline(props.value, airline.iata))
                  }
                }}
                name={`airline_${i}`}
              />{' '}
              {airline.full_name}
            </label>
          )
        })}
      </div>
    </FilterPopover>
  )
}
